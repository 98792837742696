import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { storesList, blockunblockstore, logoutUser, brandsList } from '../../redux'
import { StoreProps } from '../../interfaces/store';
import QRCode from 'qrcode.react'
import { statusChangeReasons, syncStores, unresolvedordersList, UnsuspendStore, uploadStoreImage } from '../../redux/actions/storeAction';
import { API_URL, QR_BASE_URL } from '../../client-config'
import Select from 'react-select'
import './index.css'
class ActionFormatter extends Component<{ row: any, itemStatusReasons: any, unresolvedStoreOrder: any, unresolvedOrderLength: (obj: any) => {}, uploadStoreImage: (id:any, data:any) => {}, blockunblock: (id: any, is_active: any, data: any) => {}, UnsuspendStore: (id: any, data: any) => {} }, { [x: number]: any, statusReason: any, image: any }> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            statusReason: "",
            image: null
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    handleBlockUnblock = (id: any, is_active: any) => {
        //  sessionStorage.ge
        let { row } = this.props;
        let { statusReason } = this.state
        let token: any = sessionStorage.getItem('token')
        let userInfo: any = jwt.decode(token)
        let data: any = {
            role: userInfo.role,
            user_name: userInfo.first_name + " " + userInfo.last_name,
            reason: statusReason,
            activity_type: 'Stores',
            activity_name: row.store_name,
            status: is_active == 0 ? "Unblock" : "Block"
        }
        this.props.blockunblock(id, is_active, data);
    };
    handleUnsuspendStore = (id: any, is_active: any) => {
        //  sessionStorage.ge
        let { row } = this.props;
        let obj = {
            is_suspend: 0,
            is_active: 1
        }
        this.props.UnsuspendStore(id, obj)
    };
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    getOrdersLength = (store_id: any) => {
        let obj = {
            store_id: store_id,
            days: 1
        }
        this.props.unresolvedOrderLength(obj)
    }

    imageReady = () => {
        return this.state.image && this.state.image[0].size <= 1000000
    }
    fileSelectedHandler = (e: any) => {
        this.setState({ image: e.target.files })
    }
    handleImageSubmit = (store_id: any) => {
        let { image } = this.state;
        const data: any = new FormData()
        if (image) {
            data.append('file', image[0])
        }
        this.props.uploadStoreImage(store_id, data);
    }

    render() {
        const { row, itemStatusReasons, unresolvedStoreOrder } = this.props
        let roleId; 
        if (sessionStorage.token) {
            let tokendata: any = jwt.decode(sessionStorage.token)
            roleId = tokendata.role_id;
        }
        return (
            <div>
                {!row.is_suspend &&  
                    <Link to="#" title={row.is_active === 0 ? "Activate" : "Deactivate"} data-toggle="modal" data-target={row.brand_id == 1 || row.brand_id == 5 || roleId == 1 || roleId == 7 ? `#blockunblock${row.store_id}` :''} className={row.is_active === 0 ? "btn btn-outline-success m-2" : "btn btn-outline-danger m-2"}><i className={row.is_active === 0 ? "fa fa-unlock" : "fa fa-lock"}></i></Link>
                }
                {row.is_suspend == 1 &&
                    <Link to="#" onClick={() => this.getOrdersLength(row.store_id)} title={row.is_suspend === 1 ? "Reinstate" : "Suspend"} data-toggle="modal" data-target={`#suspend${row.store_id}`} className="btn btn-outline-success m-2"><i className="fa fa-refresh"></i></Link>
                }
                <Link to="#" title="Store Image" data-toggle="modal" data-target={`#storeImage${row.store_id}`} className={row.store_image ? 'btn btn-outline-success m-2' : 'btn btn-outline-primary m-2'}><i className="fa fa-camera"></i></Link>
                <Link title="Edit Store" className="btn btn-outline-primary mx-2" to={`/edit-store/${row.store_id}`}><i className="fa fa-edit"></i></Link>
                {/* <!-- B/U Modal--> */}
                <div id={`blockunblock${row.store_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{row.is_active === 0 ? "Activate" : "Deactivate"} Store</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <p>Are you sure,you want to {row.is_active === 0 ? "Activate" : "Deactivate"} this store?</p>
                                    </div>
                                </div>
                                {row.is_active === 1 &&
                                    <div className="col-12">
                                        <div className="form-group">
                                            <select
                                                name="statusReason"
                                                onChange={this.handleInputChange}
                                                className="form-control mt-2">
                                                <option value="">Select Reason</option>
                                                {itemStatusReasons &&
                                                    itemStatusReasons.map((reason: any, index: any) => (
                                                        <option key={index} value={reason.reason}>{reason.reason}</option>
                                                    ))
                                                }
                                            </select>

                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.handleBlockUnblock(row.store_id, row.is_active)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id={`suspend${row.store_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Reinstate Store</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        {unresolvedStoreOrder.length > 0 ?
                                            <p>Store has some unresolved orders pending. </p>
                                            :
                                            <p>Are you sure,you want to reinstate this store?</p>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                {unresolvedStoreOrder.length > 0 ?
                                    <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                    :
                                    <>
                                        <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                        <button onClick={() => this.handleUnsuspendStore(row.store_id, row.is_suspend)} className="btn btn-primary">Confirm</button>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div id={`storeImage${row.store_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Upload Store Image</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-control-label mb-3">Store Image <span className="text-danger"> </span></label>
                                            <input id="file" type="file" name="file" className="form-control-file"  onChange={this.fileSelectedHandler} />
                                            {
                                                this.imageReady() ? '' : <span style={{ fontSize: '13px' }} className="text-danger">Image size should be less than 1MB</span>
                                            }
                                            {
                                                this.state.image && this.state.image[0] ? 
                                                    <img src={URL.createObjectURL(this.state.image[0])} className='store-image my-2' /> 
                                                    : 
                                                    row.store_image ?                                                    
                                                        <img src={API_URL + '/' + row.store_image} className='store-image my-2' />
                                                    : 
                                                    ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button disabled={!this.imageReady()} onClick={() => this.handleImageSubmit(row.store_id)} className="btn btn-primary">Upload</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} unresolvedStoreOrder={props.unresolvedStoreOrder} uploadStoreImage={props.uploadStoreImage} unresolvedOrderLength={props.unresolvedordersList} blockunblock={props.blockunblockstore} UnsuspendStore={props.UnsuspendStore} itemStatusReasons={props.itemStatusReasons} />
    );
}
class WeekylyTimingFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    IsJsonString = (str: any) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    render() {
        const { row } = this.props;
        return (
            <div>
                {(row.daysTiming && row.isDays == 1) &&
                    <button title="View weeklyTimings" data-toggle="modal" data-target={`#weeklytiming${row.store_id}`} className="btn btn-outline-info"><i className="fa fa-list"></i></button>
                }
                {/* <!-- Modal--> */}
                <div id={`weeklytiming${row.store_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Weekly Timing</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group text-capitalize">
                                            <BootstrapTable version='4' data={(row.daysTiming && this.IsJsonString(row.daysTiming)) ? JSON.parse(row.daysTiming) : ""} hover>
                                                <TableHeaderColumn dataField='day' dataSort={true} isKey>Day</TableHeaderColumn>
                                                <TableHeaderColumn dataField='opening' columnTitle>Opening</TableHeaderColumn>
                                                <TableHeaderColumn dataField='closing' columnTitle>Closing</TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
function weeklyTimingFormatter(cell: any, row: any, props: any) {
    return (
        <WeekylyTimingFormatter row={row} data={props} />
    );
}

class Stores extends Component<StoreProps, { brand_id: any }> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            brand_id: {
                value: 0,
                label: "All"
            }
        }
    }
    componentDidMount() {
        this.props.statusChangeReasons();
        this.props.brandsList()
        this.props.storesList(this.state.brand_id.value)
        document.title = "SimplexCMS | Stores"
    }
    handleBrandsInputChange = (e: any, index: any) => {
        this.setState({ brand_id: { value: e.value, label: e.label } })
        if (e && e.value > 0) {
            this.props.storesList(e.value)
        } else {
            this.props.storesList(0)
        }
    };
    syncStores = ()=>{
        let decoded:any = jwt.decode(sessionStorage.token)
        let brand:any = this.state.brand_id.value
        if(decoded.role_id != 1) {
            brand = decoded.brand_id
        }
        this.props.syncStores({brand_id:brand})
    }
    render() {
        let roleId: any = ""
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                } else {
                    roleId = decoded.role_id
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const options: any = {
            // sizePerPageList: [5,10,15],
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/add-store" /> },
            noDataText: 'Stores Not Found'
        };
        // const selectRowProp: any = {
        //     mode: 'checkbox',
        //     bgColor: '#4ac1ec',
        //     columnWidth: '40px'
        // };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="row py-2">
                                    <div className="col-lg-8 col-md-8 col-12">
                                        <h4 className="mt-2">Stores Management</h4>
                                    </div>
                                    {roleId == 1 &&
                                        <div className="col-lg-4 col-md-4 col-12">
                                            <div className="row">
                                                <div className="col-12">
                                                    <Select
                                                        name="brand_id"
                                                        options={this.props.brands}
                                                        value={this.state.brand_id}
                                                        className="text-capitalize select mt-2"
                                                        classNamePrefix="select"
                                                        onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                        {this.state.brand_id.value == 3 && <button onClick={this.syncStores} type="button" data-dismiss="modal" className="btn btn-primary">Sync Stores with Simplex Sync</button>}
                                            <div className="card-body">
                                                <BootstrapTable version='4' data={this.props.data} search={true} pagination={this.props.data.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='stores.csv' hover>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='store_id' csvHeader='#' width='70' dataSort={true} isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='150' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='branch_code' csvHeader='BranchId' width='100' columnTitle>Branch Id</TableHeaderColumn>
                                                    {/* <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='fiscal_pos_code' csvHeader='Branch Code' width='100' columnTitle>Branch Code</TableHeaderColumn> */}
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='store_name' csvHeader='StoreName' width='130' columnTitle>Store name</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='is_active' width='100' dataFormat={statusFormatter} export={false}>Status</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='store_email' csvHeader='StoreEmail' width='100' columnTitle>Store email</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='brand_name' csvHeader='Brand' width='100' columnTitle>Brand</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='channel_name' csvHeader='Channel' width='100' columnTitle>Sales Channel</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='store_type_name' csvHeader='Type' width='100' columnTitle>Store Type</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='ntn_number' csvHeader='Ntn number' width='100' columnTitle>NTN</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='city' csvHeader='City' width='100' columnTitle>City</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='state_name' csvHeader='State' width='100' columnTitle>State</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='country_name' csvHeader='Country' width='100' columnTitle>Country</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='address' csvHeader='Address' width='150' columnTitle>Address</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='store_open_time' dataFormat={timeFormatter} csvHeader='OpeningTime' width='100' columnTitle>Opening time</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='store_close_time' dataFormat={timeFormatter} csvHeader='ClosingTime' width='100' columnTitle>Closing time</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='100' dataFormat={weeklyTimingFormatter} export={false}>Weekly Timings</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='escalation_email' csvHeader='EscalationEmail' width='100' columnTitle>Escalation email</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='contact1' csvHeader='Contact1' width='130' columnTitle>Contact 1</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='qrCode' csvHeader='qrCode' dataFormat={qrCode} export={false} width='160' columnTitle>QR Code</TableHeaderColumn>
                                                </BootstrapTable>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
;
function timeFormatter(cell: any) {
    let Finaltime: any = "00:00:00"
    //convert the opening time 24 hour to 12 hour
    if (cell != null) {
        let time = cell.split(":")
        let Hours = time[0]
        let AmOrPm = Hours >= 12 ? 'PM' : 'AM';
        Hours = (Hours % 12) || 12;
        Finaltime = Hours + ":" + time[1] + " " + AmOrPm;
    }
    return Finaltime;
}

function qrCode(cell: any, props: any) {

    let url = `${QR_BASE_URL}/home?store_id=${props.store_id}&md=qr-Pickup`
    // props.store_id
    // return <a style={{ cursor: 'pointer' }}>Click Here</a>;
    return <div>
        <QRCode
            id={`qr_${props.store_id}`}
            value={url}
            includeMargin={true}
        />

        <div style={{ display: 'none' }}>
            <QRCode
                id={`qr2_${props.store_id}`}
                value={url}
                includeMargin={true}
                size={3000}
            />
        </div>
        <a style={{ cursor: 'pointer', marginLeft: '20px' }} onClick={() => downloadQR(props.store_id, props.store_name)}> Download QR </a>
    </div>
}

function downloadQR(id: any, name: any) {
    let doc = document as any
    const canvas = doc.getElementById(`qr2_${id}`);
    const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.style.height = '1000px'
    downloadLink.href = pngUrl;
    downloadLink.download = `QR_${name}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}
function statusFormatter(cell: any, row: any) {
    return (
        <div className="text-capitalize">
            <span {...(cell == 1 && { className: "badge badge-success p-2" }) || (cell == 0 && { className: "badge badge-danger p-2" })}>{cell == 1 ? "active" : "Inactive"}</span>
            {row.is_suspend == 1 &&
                <span className="badge badge-danger p-2 mt-1">Suspended</span>
            }
        </div>
    )
}
const mapStateToProps = (state: any) => {
    return {
        data: state.store.data,
        itemStatusReasons: state.store.statusChangeReasons,
        unresolvedStoreOrder: state.store.unresolvedStoreOrder,
        brands: state.brand.brands
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        storesList: function (brand: any) {
            dispatch(storesList(brand))
        },
        brandsList: function () {
            dispatch(brandsList("GET_BY_BRAND"))
        },
        blockunblockstore: function (id: any, is_active: any, data: any) {
            dispatch(blockunblockstore(id, is_active, data))
        },
        UnsuspendStore: function (id: any, data: any) {
            dispatch(UnsuspendStore(id, data))
        },
        statusChangeReasons: function () {
            dispatch(statusChangeReasons())
        },
        unresolvedordersList: function (obj: any) {
            dispatch(unresolvedordersList(obj))
        },
        syncStores:(data:any)=>{
            dispatch(syncStores(data))
        },
        uploadStoreImage(data:any,store_id:any){
            dispatch(uploadStoreImage(data,store_id))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Stores);