import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Sidebar from '../../components/sidebar';
import Topbar from '../../components/topbar'
import Footer from '../../components/footer/main'
import { segmentationProps, segmentationStates } from '../../interfaces/marketing';
import { itemsListForMultiSelect } from '../../redux/actions/menuAction';
import { brandsList, logoutUser } from '../../redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { data } from 'jquery';
import { createAudienceList, customerFilteration, deleteAudience, getAudience, getCampaigns } from '../../redux/actions/marketing';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Link, Redirect } from 'react-router-dom';
import { currency } from '../../client-config'
import _ from 'lodash';
import moment from 'moment';
import { EmailCampaignProps, EmailCampaignStates } from '../../interfaces/campaigns';
import { secretKey } from '../../secret';
import jwt from 'jsonwebtoken'



// const createSliderWithTooltip = Slider.createSliderWithTooltip;
// const Range = createSliderWithTooltip(Slider.Range);
class SmsCampaign extends Component<EmailCampaignProps, EmailCampaignStates> {
    constructor(props: any) {
        super(props);
        this.state = {
            customDateFlag: false,
            brand: {
                value: 0,
                label: "All"
            }
        }

    }

    componentWillMount() {
        let obj = {
            campaign_type: 'sms',
            brand_id:0
        }
        this.props.brandsList()
        this.props.getCampaigns(obj);
    }

    componentDidMount() {
    }



    onValueChange = (e: any) => {
        console.log("marking value of checkbox", e.target.value)
    }


    handleInputChange = (event: { target: { name: any; value: any; }; }) => {
        if (event.target.value === 'custom') {
            this.setState({ customDateFlag: true });
        }
        else {
            this.setState({
                [event.target.name]: event.target.value
            });
        }
    }

    handleBrandsInputChange = (e: any, index: any) => {
        this.setState({ brand: { value: e.value, label: e.label } })
        if (e && e.value > 0) {
            let obj = {
                campaign_type: 'email',
                brand_id:e.value
            }
            this.props.getCampaigns(obj);
        } else {
            let obj = {
                campaign_type: 'email',
                brand_id:0
            }
            this.props.getCampaigns(obj);
        }
    };



    // createAudience = () => {
    //     const {audienceList } = this.props;
    //     const { audience_name, audience_desc} = this.state;
    //     let objData = {
    //         name: audience_name,
    //         desc: audience_desc,
    //         customerArr: audienceList
    //     }
    //     this.props.createAudienceList(objData);
    // }

    render() {
        let roleId
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                } else {
                    roleId = decoded.role_id
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const options: any = {
            // sizePerPageList: [5,10,15],
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/add-sms-campaign" /> },
            noDataText: 'SMS Campaign Not Found'
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">                           
                        <section className="tables">
                            <div className="container-fluid">
                            {roleId == 1 &&
                                        <div className="col-12 mb-3 ml-0 mr-0">
                                            <div className="row">
                                                <div className="col-12">
                                                    <Select
                                                        name="brand"
                                                        options={this.props.brands}
                                                        value={this.state.brand}
                                                        className="text-capitalize select mt-2"
                                                        classNamePrefix="select"
                                                        onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                            }
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <BootstrapTable version='4' data={this.props.campaignList} search={true} pagination={this.props.campaignList.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='menus.csv' hover>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField="id" csvHeader='#'dataSort={true} isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='campaign_name' csvHeader='Audience' columnTitle>Campaign Name</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='campaign_type' csvHeader='Audience'  columnTitle>Campaign Type</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='email_type' csvHeader='Audience'  columnTitle>Email Type</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='subject' csvHeader='Audience'  columnTitle>Subject</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='message' csvHeader='Audience' columnTitle>Content</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='audience.audience_name' dataFormat={dataFormatter} csvHeader='Email' columnTitle>Audience Name</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='audience.participants' dataFormat={participantFormatter} csvHeader='Phone' columnTitle>Participants</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='campaign_start_time' dataFormat={scheduleDateFormatter}  csvHeader='Phone' columnTitle>Scheduled At</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='date_created' dataFormat={dateFormatter}  csvHeader='Phone' columnTitle>Date Created</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='150' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
class ActionFormatter extends Component<{ row: any, deleteAudience: (id: any) => {}, }, { [x: number]: any, statusReason: any }> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            statusReason: ""
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    deleteAudience = (id: any) => {
        //  sessionStorage.ge
       console.log(id)
        this.props.deleteAudience(id);
    };
    
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    render() {
        const { row, itemStatusReasons, unresolvedStoreOrder } = this.props
        return (
            <div>
                <Link to="#" title={"Delete"} data-toggle="modal" data-target={`#blockunblock${row.audience_id}`} className={"btn btn-outline-danger mx-2"}><i className={"fa fa-trash"}></i></Link>
                <Link title="Edit" className="btn btn-outline-primary mx-2" to={`/edit-sms-campaign/${row.id}`}><i className="fa fa-edit"></i></Link>     
                {/* <!-- B/U Modal--> */}
                <div id={`blockunblock${row.audience_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Delete Audience</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <p>Are you sure,you want to delete this audience?</p>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.deleteAudience(row.id)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row}  deleteAudience={props.deleteAudience} />
    );
}
function dateFormatter(cell: any, row: any) {
    if (row.date_created) {
        return moment(row.date_created).local().format('YYYY-MM-DD HH:mm')
    }
    else {
        return 'N/A'
    }
}

function scheduleDateFormatter(cell: any, row: any) {
    if (row.campaign_start_time) {
        return moment(row.campaign_start_time).local().format('YYYY-MM-DD HH:mm')
    }
    else {
        return 'N/A'
    }
}

function dataFormatter(cell: any, row: any) {
    if (row.audience) {
        return row.audience.audience_name
    }
    else {
        return 'N/A'
    }
}

function participantFormatter(cell: any, row: any) {
    if (row.audience) {
        return row.audience.participants
    }
    else {
        return 'N/A'
    }
}

const mapStateToProps = (state: any) => {
    return {
        campaignList: state.marketing.campaignList,
        brands: state.brand.brands
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        getCampaigns: function (obj: any) {
            dispatch(getCampaigns(obj))
        },
        brandsList: function () {
            dispatch(brandsList("GET_BY_BRAND"))
        },
        logoutUser: function () {
            dispatch(logoutUser());
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SmsCampaign);