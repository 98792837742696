import jwtDecode from 'jwt-decode';
import moment from 'moment';
import React, { Component } from 'react';
import ReactToPrint from "react-to-print";
import {API_URL, currency, hungerstation_currency} from '../../client-config'
import './invoice.css'
var QRCode = require('qrcode.react');
class InvoiceAggregator extends Component<{ data: any, orderId: any, }, {}> {
    componentRef: any;
    calcSubTotalTotal = (orderItems: any) => {
        let sum = "";
        orderItems.forEach((item: any) => {
            sum += item.order_item_grossprice;
        });
        return parseFloat(sum).toFixed(2);
    };
    calcTotal = (orderItems: any) => {
        let sum = 0;
        orderItems.forEach((item: any) => {
            sum += item.order_item_grossprice;
        });
        return sum;
    };
    render() {
        let token:any = sessionStorage.getItem("token")
        let decoded:any = jwtDecode(token)
        let { data, orderId } = this.props;
        // console.log("data.orderDetail.fbr_invoice_number",data.orderDetail.fbr_invoice_number);
        let coming_currency:string;
        coming_currency = currency
        if(data.orderDetail.order_channel == 'Hungerstation'){
             coming_currency = hungerstation_currency
        }
        let DateCreated: any = "";
        let FutureDate: any = "";
        let utcDateTime: any = "";
        let FbrNumber: any = "";
        let FbrNumberComplete: any = "";
        if (data.orderDetail) {
            DateCreated = moment(data.orderDetail.date_created).local().format('YYYY-MM-DD HH:mm').split(' ');
            DateCreated = DateCreated[1] + " , " + DateCreated[0];
            if (data.orderDetail.fbr_invoice_number != undefined) {
                FbrNumberComplete = data.orderDetail.fbr_invoice_number;
                FbrNumber = FbrNumberComplete.split(':');
                FbrNumber = FbrNumber[1];
                console.log("FbrNumber", FbrNumber);
            }
        }
        if (data.orderDetail && data.orderDetail.future_date) {
            utcDateTime = moment(data.orderDetail.future_date).utc(false);
            FutureDate = moment(utcDateTime).local().format('YYYY-MM-DD HH:mm').split(' ');
            FutureDate = FutureDate[1] + " , " + FutureDate[0];
        }
        // let orderChannel:any
        // if(data.orderDetail.order_channel == 'web' || data.orderDetail.order_channel == 'callcenter' || data.orderDetail.order_channel == 'mobile') {
        //     orderChannel = 'master_cms'
        // } else {
        //     orderChannel = 'sync_sync'
        // }
        if(data.orderDetail.order_channel == 'Hungerstation')
        {
            
            return(
                <div>
                <div className="d-flex justify-content-end ">
                    {
                        // data.orderDetail.order_status_code !== 1 &&
                        <ReactToPrint
                            trigger={() => <button type="button" className="btn btn-success">Print</button>}
                            content={() => this.componentRef}
                        />
                    }
                </div>
                <div style={{ width: '108mm' }} ref={el => (this.componentRef = el)}>
                    <header className="clearfix">
                        <div id="logo">
                            {/* <img width={150} height={80} src={process.env.PUBLIC_URL + "/assets/img/ky.png"} /> */}
                            {/* <img width={180} height={100} src={`${data && data.orderDetail && data.orderDetail.brand_id == 3? process.env.PUBLIC_URL + "/assets/img/BR.png":process.env.PUBLIC_URL + "/assets/img/BR.png"}`} /> */}
                            <img width={150} height={80} src={`${data && data.orderDetail.order_channel === 'deliveroo' ?  `${API_URL}${data.orderDetail && data.orderDetail.landing_image }` :  data.orderDetail && data.orderDetail.landing_image ? `${API_URL}/${data.orderDetail.landing_image}` : process.env.PUBLIC_URL + "/assets/img/BR.png"}`} />
                        </div>
                        <h6 className="text-center mt-3">
                            {/* <b>{decoded && decoded.brand && decoded.brand.brand_name}</b> */}
                            {data.orderDetail && data.orderDetail.brand_name}
                        </h6>
                        <h6 className="text-center mt-3">{data.orderDetail.city}</h6>

                        <div id="project" className="invoiceh1">
                            
                            {/* <h3>Customer Information</h3> */}
                            <div style={{ display: 'flex' }}><span><b>ORDER NO:</b><br/><b>رقم الطلب</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {orderId}</span></div>
                            <div style={{ display: 'flex' }}><span><b>Order Date:</b><br/><b>تاريخ الطلب</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {data.orderDetail && DateCreated}</span></div>
                            <div style={{ display: 'flex' }}><span><b>Customer:</b><br/><b>اسم العميل</b>
                                </span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>
                                 {/* {data.orderDetail && data.orderDetail.customer_name? data.orderDetail.customer_name: 'N/A' } */}
                                 { data.orderDetail && (data.orderDetail.order_channel === 'deliveroo' || data.orderDetail.order_channel === 'Hungerstation') ? data.orderDetail.customer_name  : data.orderDetail && data.orderDetail.login_name ? data.orderDetail.login_name: 'N/A' }
                                </span>
                            </div>
                            
                            {(data.orderDetail && data.orderDetail.phone_number) && <div style={{ display: 'flex' }}><span><b>Phone:</b><br/><b>هاتف</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {data.orderDetail.phone_number}</span></div>}
                            <div style={{ display: 'flex' }}><span><b>Store:</b><br/><b>متجر</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{data.orderDetail && data.orderDetail.store_name}</span></div>
                            <div style={{ display: 'flex' }}><span><b>From:</b><br/><b>عنوان الفرع</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{data.orderDetail && data.orderDetail.address? data.orderDetail.address: `Hungerstation Delivery`}</span></div>
                            {(data.orderDetail && data.orderDetail.delivery_status == 'Delivery') && <div style={{ display: 'flex' }}><span><b>Delivery at:</b><br/><b>عنوان التسليم</b> </span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {data.orderDetail.delivery_address?data.orderDetail.delivery_address:`Hungerstation Delivery`}</span></div>}
                            <div style={{ display: 'flex' }}><span><b>Special Ins:</b><br/><b>تعليمات خاصة</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{data.orderDetail && data.orderDetail.special_inst? data.orderDetail.special_inst : 'N/A' }</span></div>
                            {utcDateTime._isValid && <div><span><b>{data.orderDetail && data.orderDetail.delivery_status === "Delivery" ? 'Delivery' : 'Pickup'} Time:</b><br/><b>زمن</b></span><br /><span>{FutureDate}</span></div>}
                            {FbrNumber && <div style={{ display: 'flex' }}><span><b>FBR Invoice:</b><br/><b>فاتورة FBR</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{FbrNumber}</span><span style={{ whiteSpace: "normal", textAlign: 'right', flex: 'auto' }}><QRCode size='50' value={FbrNumber} /></span></div>}
                            {/* {(data.orderDetail.fbr_invoice_number!=undefined) ? 
                            <div style={{ display: 'flex' }}><span style={{ textAlign: "left", fontWeight:'bold' }}>{FbrNumber}</span><span style={{ whiteSpace: "normal", textAlign: 'right', flex: 'auto' }}> <QRCode size='50' value={FbrNumber}/></span></div>
                            :  
                            <div></div>
                            } */}
                        </div>
                        <div id="secProject">
                            <div id="project2">
                                <div><h6 style={{ fontSize: 13 }}>Payment Type</h6><h6>طريقة الدفع</h6>{data.orderDetail && data.orderDetail.payment_method}</div>
                            </div>
                            <div id="project2">
                                <div>
                                    {/* <h6 style={{ fontSize: 13 }}>Ordering Channel</h6><h6>تم الطلب خلال</h6>{data.orderDetail && data.orderDetail.order_channel +` (${data.orderDetail.external_orderId})`} */}
                                    <h6 style={{ fontSize: 13 }}>Ordering Channel</h6><h6>تم الطلب خلال</h6>{data.orderDetail && data.orderDetail.order_channel + (data.orderDetail.external_orderId ? data.orderDetail.external_orderId : '')}
                                </div>
                            </div>
                            <div id="project2">
                                <div>
                                    <h6 style={{ fontSize: 13 }}>Ordering Mode</h6><h6>نوع الطلب</h6>{data.orderDetail && data.orderDetail.delivery_status}
                                </div>
                            </div>
                        </div>
                    </header>
                    <main>
                    <table className="invoicetable">
                            <thead>
                                <tr>
                                    <th style={{ width: 40 }}>#</th>
                                    <th style={{ width: 135 }}>Name</th>
                                    <th style={{ width: 70, overflow: 'unset' }}>PRICE</th>
                                    <th style={{ width: 70, overflow: 'unset' }}>% VAT</th>
                                    <th style={{ width: 40, overflow: 'unset' }}>QTY</th>
                                    <th style={{ width: 60, overflow: 'unset' }}>TAX</th>
                                    <th style={{ width: 60, overflow: 'unset' }}>TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.Items.map((obj: any, index: any) => {
                                    let AddOnsPrice: any = 0;
                                    let AddOnsTax: any = 0;
                                    let comboChoicesPrice: any = 0;
                                    let comboChoiceTax: any = 0;
                                    let qcPrice:any = 0
                                    let qctax = 0
                                    obj.quick_combos && obj.quick_combos.map((qc:any)=>{
                                        qcPrice = qcPrice + (qc.mrp * qc.quantity)
                                        qctax = Number(qc.tax_amount)
                                    })
                                    const ingredients = obj.combo_choices && JSON.parse(obj.combo_choices).map((element: any) => {
                                        return <li><b>{element.quantity * obj.order_item_quantity} </b>{element.item_name} ({element.size && JSON.parse(element.size).size}){element.extra_price > 0 && `+${coming_currency} ${element.extra_price * obj.order_item_quantity}`}</li>
                                    })
                                    return (
                                        <>  
                                            <tr title={(obj.combo_name !== "" && obj.combo_name) || (obj.item_name !== "" && `${obj.item_name}(${JSON.parse(obj.item_size).size}) x ${obj.order_item_quantity}`)}>
                                                <td style={{ whiteSpace: 'normal' }} colSpan={7}>
                                                    <b>
                                                        {(obj.combo_name !== "" && obj.combo_name) || (obj.item_name !== "" && `${obj.item_name}(${JSON.parse(obj.item_size).size})  x ${obj.order_item_quantity}`)}
                                                    </b>
                                                </td>
                                            </tr>
                                            <tr key={index}>
                                                <td title={`${index + 1}`}>{index + 1}</td>
                                                <td style={{ padding: 10, width: 150 }}>
                                                    <ul style={{ whiteSpace: 'normal' }}>
                                                        {ingredients}
                                                        <br />
                                                        <b>{(obj.modifiers && JSON.parse(obj.modifiers).length > 0) && "Add Ons"}</b>
                                                    </ul>
                                                </td>
                                                <td>{obj.modifiers && JSON.parse(obj.modifiers).map((element: any) => {
                                                    AddOnsPrice += (element.quantity * element.modifier_sale_price).toFixed(2);
                                                    AddOnsTax += element.tax_amount * element.quantity;
                                                })} 
                                                    {obj.combo_choices && JSON.parse(obj.combo_choices).map((choiceElement: any) => {
                                                        comboChoicesPrice += (choiceElement.extra_price * choiceElement.quantity);
                                                        comboChoiceTax += choiceElement.tax_amount * choiceElement.quantity;
                                                    })}
                                                    {
                                                        // `${coming_currency} ${obj.order_item_netprice}`
                                                        `${coming_currency} ${ obj.order_item_grossprice && data.orderDetail.order_channel === 'call center'  ? ((parseInt(obj.order_item_grossprice) - (parseInt(obj.order_item_grossprice) * ((parseInt(obj.tax_percent)) / 100 ))).toFixed(2)) : parseFloat(obj.order_item_netprice).toFixed(2)}`
                                                    }
                                                </td>
                                                <td title={`${obj.tax_percent}`}>{obj.tax_percent}%</td>
                                                <td title={`${obj.order_item_quantity}`}>{obj.order_item_quantity}</td>
                                                {/* <td title={`${obj.tax_amount}`}>{obj.tax_amount}</td> */}
                                                {/* <td title={`${parseFloat(obj.tax_amount).toFixed(2)}`}>{parseFloat(obj.tax_amount).toFixed(2)}</td> */}
                                                {console.log("iam near taxamount")}
                                                <td title={`${obj.tax_amount && data.orderDetail.order_channel === 'call center' ? ((parseInt(obj.order_item_grossprice) * parseInt(obj.tax_percent)) / 100 ).toFixed(2) : obj.tax_amount }`}>{`${coming_currency} ${ obj.tax_amount}`} </td>
                                                <td title={data.orderDetail.tax_type == 1 ? `${coming_currency} ${obj.order_item_grossprice}` : `${coming_currency} ${obj.order_item_grossprice}`}>{ `${coming_currency} ${obj.order_item_grossprice}`}</td>
                                            </tr>
                                            {
                                                obj.modifiers && JSON.parse(obj.modifiers).map((element: any) => {
                                                    return <tr>
                                                        <td></td>
                                                        <td style={{ textAlign: "left" }}>
                                                            <b>{element.quantity} </b>{element.name}
                                                        </td>
                                                        <td >{(element?.modifier_sale_price/(1+(element.tax_percent)/100)).toFixed(2)}</td>
                                                            
                                                            <td title={`${element.tax_percent ?element.tax_percent : 0}`}>{element.tax_percent? element.tax_percent: 0 }%</td>
                                                            <td title={`${element.quantity}`}>{element.quantity}</td>
                                                            <td title={`${parseFloat(element.tax_amount? element.tax_amount:0 * element.quantity).toFixed(2)}`}>{(element.tax_percent? ((JSON.parse(element.tax_percent? element.tax_percent : 0)/ 100) * JSON.parse((element?.modifier_sale_price/(1+(element.tax_percent)/100)).toFixed(2))) : 0).toFixed(2)}</td>
                                                            <td title={data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.modifier_sale_price * element.quantity) + Math.round(element.tax_amount? element.tax_amount : 0 * element.quantity)}` : `${coming_currency} ${(element.modifier_sale_price)}`}>{data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.modifier_sale_price * element.quantity) + Math.round(element.tax_amount? element.tax_amount: 0 * element.quantity)}` : `${coming_currency} ${(element.modifier_sale_price)}`}</td>
                                                    </tr>
                                                })
                                            }

                                            {obj.quick_combos && obj.quick_combos.length > 0 && <tr title="Quick Combos">
                                                <td style={{ whiteSpace: 'normal' }} colSpan={7}>
                                                    <b>
                                                        Make a combo
                                                    </b>
                                                </td>
                                            </tr>}
                                            {
                                                obj.quick_combos && obj.quick_combos.length > 0 && obj.quick_combos.map((element: any) => {
                                                    return <tr>
                                                        <td></td>
                                                        <td style={{ textAlign: "left" }}>
                                                            <b>{element.quantity} </b>{element.item_name} ({element.size})
                                                        </td>
                                                        <td title={data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.mrp * element.quantity)}` : `${coming_currency} ${(element.mrp * element.quantity) - (element.tax_amount)}`}>{data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.mrp * element.quantity)}` : `${coming_currency} ${(element.mrp * element.quantity) - (element.tax_amount? element.tax_amount: 0)}`}</td>
                                                        <td title={`${element.tax_percent ? element.tax_percent : 0}`}>{element.tax_percent ? element.tax_percent : 0 }%</td>
                                                        <td title={`${element.quantity}`}>{element.quantity}</td>
                                                        <td title={`${element.tax_amount? element.tax_amount: 0}`}>{element.tax_amount? element.tax_amount: 0}</td>
                                                        <td title={data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.mrp * element.quantity) + Math.round(element.tax_amount? element.tax_amount: 0)}` : `${coming_currency} ${(element.mrp * element.quantity)}`}>{data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.mrp * element.quantity) + Math.round(element.tax_amount? element.tax_amount: 0)}` : `${coming_currency} ${(element.mrp * element.quantity)}`}</td>
                                                        {/* <td title={data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.modifier_sale_price * element.quantity) + Math.round(element.tax_amount * element.quantity)}` : `${coming_currency} ${(element.modifier_sale_price * element.quantity)}`}>{data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.modifier_sale_price * element.quantity) + Math.round(element.tax_amount * element.quantity)}` : `${coming_currency} ${(element.modifier_sale_price * element.quantity)}`}</td> */}
                                                    </tr>
                                                })
                                            }
                                        </>
                                    )
                                })}
                                <tr>
                                    <td style={{ border: 'none' }}></td>
                                    <td style={{ border: 'none' }}></td>
                                    <td colSpan={3} style={{ textAlign: 'left' }} className="total">Sub Total <b>المبلغ الاجمالي</b></td>
                                    <td colSpan={2} className="total" title={`${coming_currency} ${data.orderDetail && data.orderDetail.order_channel === 'call center' ? ( parseFloat(data.orderDetail.order_grossprice) - parseFloat(data.orderDetail.order_grossprice) * (parseFloat(data.orderDetail.tax_percent) / 100)).toFixed(2) : data && data.orderDetail && parseFloat(data.orderDetail.order_netprice).toFixed(2) }`}>{coming_currency} { data.orderDetail && data.orderDetail.order_channel === 'call center' ? ( parseFloat(data.orderDetail.order_grossprice) - parseFloat(data.orderDetail.order_grossprice) * (parseFloat(data.orderDetail.tax_percent) / 100)).toFixed(2) : data && data.orderDetail && parseFloat(data.orderDetail.order_netprice).toFixed(2) }</td>
                                    
                                </tr>
                                {data.orderDetail.coupon &&
                                    <tr>
                                        <td style={{ border: 'none' }}></td>
                                        <td style={{ border: 'none' }}></td>
                                        <td colSpan={3} style={{ textAlign: 'left' }} className="discount total">Discount</td>
                                        <td colSpan={2} className="discount total" title={`${coming_currency} ${Math.round(data.orderDetail.coupon_discount_value)}`}>{coming_currency} {Math.round(data.orderDetail.coupon_discount_value)} </td> {/* {data.Items.length > 0 && Math.round((this.calcSubTotalTotal(data.Items) * tax)/100)} PKR */}
                                    </tr>
                                }
                                {
                                    data.orderDetail.discount > 0 &&
                                    <tr>
                                        <td style={{ border: 'none' }}></td>
                                        <td style={{ border: 'none' }}></td>
                                        <td colSpan={3} style={{ textAlign: 'left' }} className="discount total">Discounts  <b> الخصم</b></td>
                                        <td colSpan={2} className="discount total" title={`${coming_currency} ${Math.round(data.orderDetail.discount)}`}>{coming_currency} {parseFloat(data.orderDetail.discount).toFixed(2)} </td>
                                    </tr>
                                }
                                <tr>
                                    <td style={{ border: 'none' }}></td>
                                    <td style={{ border: 'none' }}></td>
                                    <td colSpan={3} style={{ textAlign: 'left' }} className="total">{data.orderDetail.tax_percent} % VAT <b> الضريبة</b></td>
                                    {/* <td colSpan={2} className="total" title={`${coming_currency} ${data.orderDetail.tax_amount}`}>{coming_currency} {data && data.orderDetail? data.orderDetail.tax_amount:0} </td>  */}
                                    <td colSpan={2} className="total" title={`${coming_currency} ${parseFloat(data.orderDetail.tax_amount).toFixed(2)}`}>{`${coming_currency} ${ data.orderDetail.tax_amount && data.orderDetail.order_channel === 'call center' ? ((parseInt(data.orderDetail.order_grossprice) * parseInt(data.orderDetail.tax_percent)) / 100 ).toFixed(2) : parseFloat(data.orderDetail.tax_amount).toFixed(2) }`} </td>
                                    {/* {data.Items.length > 0 && Math.round((this.calcSubTotalTotal(data.Items) * tax)/100)} PKR */}
                                </tr>
                                {(data.orderDetail && data.orderDetail.delivery_status == "Delivery") && <tr>
                                    <td style={{ border: 'none' }}></td>
                                    <td style={{ border: 'none' }}></td>
                                    <td colSpan={3} style={{ textAlign: 'left' }} className="total">Delivery Charges <b> رسوم توصيل </b></td>
                                    {/* <td colSpan={2} className="total" title={`${coming_currency} ${data.orderDetail && data.orderDetail.delivery_fee}`}>{coming_currency} {data.orderDetail && data.orderDetail.delivery_fee? data.orderDetail.delivery_fee:0 }</td> */}
                                    <td colSpan={2} className="total" title={`${coming_currency} ${data.orderDetail && data.orderDetail.delivery_fee}`}>{coming_currency} {data.orderDetail && data.orderDetail.delivery_fee? parseInt(data.orderDetail.delivery_fee).toFixed(2) : '0.00' }</td>
                                </tr>
                                }
                                {data.orderDetail.pos_service_fee > 0 &&
                                    <>
                                        <td style={{ border: 'none' }}></td>
                                        <td style={{ border: 'none' }}></td>
                                        <td colSpan={3} style={{ textAlign: 'left' }} className="total">Pos Service Fee</td>
                                        {/* <td colSpan={2} className="total" title={`${coming_currency} ${data.orderDetail.pos_service_fee}`}>{coming_currency} {data.orderDetail.pos_service_fee)}</td> */}
                                        <td colSpan={2} className="total" title={`${coming_currency} ${data.orderDetail.pos_service_fee}`}>{coming_currency} {parseInt(data.orderDetail.pos_service_fee).toFixed(2)}</td>
                                    </>
                                }
                                <tr>
                                    <td style={{ border: 'none' }}></td>
                                    <td style={{ border: 'none' }}></td>
                                    <td colSpan={3} style={{ textAlign: 'left' }} className="grand total"><b>Grand Total</b><b> المبلغ الإجمالي للطلب</b></td>
                                    <td colSpan={2} className="grand total">
                                        {/* <b>{`${coming_currency} ${data.orderDetail.order_grossprice }`}</b>  */}
                                        <b>{`${coming_currency} ${parseFloat(data.orderDetail.order_grossprice).toFixed(2) }`}</b> 
                                    </td>
                                </tr>
                            </tbody>
                    </table>
                      {/* <p className="text-center mt-3">{decoded && decoded.brand && decoded.brand.brand_name}</p> */}
                      <p className="text-center mt-3">{data.orderDetail && data.orderDetail.brand_name}</p>
                        {/* <p className="text-center mt-3">NTN #<br /><b> الرقم الضريبي الوطني</b>  {data.orderDetail.ntn_number}</p> */}
                        {/* <p className="text-center mt-3">{decoded && decoded.brand && decoded.brand.website_link}</p> */}
                        <p className="text-center mt-3">{data.orderDetail.website_link == 'null' ? '' : data.orderDetail.website_link}</p>
                        <h6 className="text-center mt-3">{data.orderDetail.contact1}</h6>
                        <footer className="invoicefooter mb-3">
                            {/* "Thank you for Ordering {decoded && decoded.brand && decoded.brand.brand_name}" */}
                            "Thank you for Ordering {data.orderDetail && data.orderDetail.brand_name}"
                        </footer>
                    </main>
                </div>
            </div>

            )   
        }
        else if(data.orderDetail.order_channel == 'Talabat')
        {
            return(
                <div>
                <div className="d-flex justify-content-end ">
                    {
                        // data.orderDetail.order_status_code !== 1 &&
                        <ReactToPrint
                            trigger={() => <button type="button" className="btn btn-success">Print</button>}
                            content={() => this.componentRef}
                        />
                    }
                </div>
                <div style={{ width: '108mm' }} ref={el => (this.componentRef = el)}>
                    <header className="clearfix">
                        <div id="logo">
                            {/* <img width={150} height={80} src={process.env.PUBLIC_URL + "/assets/img/ky.png"} /> */}
                            {/* <img width={180} height={100} src={`${data && data.orderDetail && data.orderDetail.brand_id == 3? process.env.PUBLIC_URL + "/assets/img/BR.png":process.env.PUBLIC_URL + "/assets/img/BR.png"}`} /> */}
                            <img width={150} height={80} src={`${data && data.orderDetail.order_channel === 'deliveroo' ?  `${API_URL}${data.orderDetail && data.orderDetail.landing_image }` :  data.orderDetail && data.orderDetail.landing_image ? `${API_URL}/${data.orderDetail.landing_image}` : process.env.PUBLIC_URL + "/assets/img/BR.png"}`} />
                        </div>
                        <h6 className="text-center mt-3">
                            {/* <b>{decoded && decoded.brand && decoded.brand.brand_name}</b> */}
                            {data.orderDetail && data.orderDetail.brand_name}
                        </h6>
                        <h6 className="text-center mt-3">{data.orderDetail.city}</h6>

                        <div id="project" className="invoiceh1">
                            
                            {/* <h3>Customer Information</h3> */}
                            <div style={{ display: 'flex' }}><span><b>ORDER NO:</b><br/><b>رقم الطلب</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {orderId}</span></div>
                            <div style={{ display: 'flex' }}><span><b>Order Date:</b><br/><b>تاريخ الطلب</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {data.orderDetail && DateCreated}</span></div>
                            <div style={{ display: 'flex' }}><span><b>Customer:</b><br/><b>اسم العميل</b>
                                </span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>
                                 {/* {data.orderDetail && data.orderDetail.customer_name? data.orderDetail.customer_name: 'N/A' } */}
                                 { data.orderDetail && (data.orderDetail.order_channel === 'deliveroo' || data.orderDetail.order_channel === 'Hungerstation') ? data.orderDetail.customer_name  : data.orderDetail && data.orderDetail.login_name ? data.orderDetail.login_name: 'N/A' }
                                </span>
                            </div>
                            
                            {(data.orderDetail && data.orderDetail.phone_number) && <div style={{ display: 'flex' }}><span><b>Phone:</b><br/><b>هاتف</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {data.orderDetail.phone_number}</span></div>}
                            <div style={{ display: 'flex' }}><span><b>Store:</b><br/><b>متجر</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{data.orderDetail && data.orderDetail.store_name}</span></div>
                            <div style={{ display: 'flex' }}><span><b>From:</b><br/><b>عنوان الفرع</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{data.orderDetail && data.orderDetail.address? data.orderDetail.address: `Hungerstation Delivery`}</span></div>
                            {(data.orderDetail && data.orderDetail.delivery_status == 'Delivery') && <div style={{ display: 'flex' }}><span><b>Delivery at:</b><br/><b>عنوان التسليم</b> </span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {data.orderDetail.delivery_address?data.orderDetail.delivery_address:`Hungerstation Delivery`}</span></div>}
                            <div style={{ display: 'flex' }}><span><b>Special Ins:</b><br/><b>تعليمات خاصة</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{data.orderDetail && data.orderDetail.special_inst? data.orderDetail.special_inst : 'N/A' }</span></div>
                            {utcDateTime._isValid && <div><span><b>{data.orderDetail && data.orderDetail.delivery_status === "Delivery" ? 'Delivery' : 'Pickup'} Time:</b><br/><b>زمن</b></span><br /><span>{FutureDate}</span></div>}
                            {FbrNumber && <div style={{ display: 'flex' }}><span><b>FBR Invoice:</b><br/><b>فاتورة FBR</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{FbrNumber}</span><span style={{ whiteSpace: "normal", textAlign: 'right', flex: 'auto' }}><QRCode size='50' value={FbrNumber} /></span></div>}
                            {/* {(data.orderDetail.fbr_invoice_number!=undefined) ? 
                            <div style={{ display: 'flex' }}><span style={{ textAlign: "left", fontWeight:'bold' }}>{FbrNumber}</span><span style={{ whiteSpace: "normal", textAlign: 'right', flex: 'auto' }}> <QRCode size='50' value={FbrNumber}/></span></div>
                            :  
                            <div></div>
                            } */}
                        </div>
                        <div id="secProject">
                            <div id="project2">
                                <div><h6 style={{ fontSize: 13 }}>Payment Type</h6><h6>طريقة الدفع</h6>{data.orderDetail && data.orderDetail.payment_method}</div>
                            </div>
                            <div id="project2">
                                <div>
                                    {/* <h6 style={{ fontSize: 13 }}>Ordering Channel</h6><h6>تم الطلب خلال</h6>{data.orderDetail && data.orderDetail.order_channel +` (${data.orderDetail.external_orderId})`} */}
                                    <h6 style={{ fontSize: 13 }}>Ordering Channel</h6><h6>تم الطلب خلال</h6>{data.orderDetail && data.orderDetail.order_channel + (data.orderDetail.external_orderId ? data.orderDetail.external_orderId : '')}
                                </div>
                            </div>
                            <div id="project2">
                                <div>
                                    <h6 style={{ fontSize: 13 }}>Ordering Mode</h6><h6>نوع الطلب</h6>{data.orderDetail && data.orderDetail.delivery_status}
                                </div>
                            </div>
                        </div>
                    </header>
                    <main>
                    <table className="invoicetable">
                            <thead>
                                <tr>
                                    <th style={{ width: 40 }}>#</th>
                                    <th style={{ width: 135 }}>Name</th>
                                    <th style={{ width: 70, overflow: 'unset' }}>PRICE</th>
                                    <th style={{ width: 70, overflow: 'unset' }}>% VAT</th>
                                    <th style={{ width: 40, overflow: 'unset' }}>QTY</th>
                                    <th style={{ width: 60, overflow: 'unset' }}>TAX</th>
                                    <th style={{ width: 60, overflow: 'unset' }}>TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.Items.map((obj: any, index: any) => {
                                    let AddOnsPrice: any = 0;
                                    let AddOnsTax: any = 0;
                                    let comboChoicesPrice: any = 0;
                                    let comboChoiceTax: any = 0;
                                    let qcPrice:any = 0
                                    let qctax = 0
                                    obj.quick_combos && obj.quick_combos.map((qc:any)=>{
                                        qcPrice = qcPrice + (qc.mrp * qc.quantity)
                                        qctax = Number(qc.tax_amount)
                                    })
                                    const ingredients = obj.combo_choices && JSON.parse(obj.combo_choices).map((element: any) => {
                                        return <li><b>{element.quantity * obj.order_item_quantity} </b>{element.item_name} ({element.size && JSON.parse(element.size).size}){element.extra_price > 0 && `+${coming_currency} ${element.extra_price * obj.order_item_quantity}`}</li>
                                    })
                                    return (
                                        <>  
                                            <tr title={(obj.combo_name !== "" && obj.combo_name) || (obj.item_name !== "" && `${obj.item_name}(${JSON.parse(obj.item_size).size}) x ${obj.order_item_quantity}`)}>
                                                <td style={{ whiteSpace: 'normal' }} colSpan={7}>
                                                    <b>
                                                        {(obj.combo_name !== "" && obj.combo_name) || (obj.item_name !== "" && `${obj.item_name}(${JSON.parse(obj.item_size).size})  x ${obj.order_item_quantity}`)}
                                                    </b>
                                                </td>
                                            </tr>
                                            <tr key={index}>
                                                <td title={`${index + 1}`}>{index + 1}</td>
                                                <td style={{ padding: 10, width: 150 }}>
                                                    <ul style={{ whiteSpace: 'normal' }}>
                                                        {ingredients}
                                                        <br />
                                                        <b>{(obj.modifiers && JSON.parse(obj.modifiers).length > 0) && "Add Ons"}</b>
                                                    </ul>
                                                </td>
                                                <td>{obj.modifiers && JSON.parse(obj.modifiers).map((element: any) => {
                                                    AddOnsPrice += (element.quantity * element.modifier_sale_price).toFixed(2);
                                                    AddOnsTax += element.tax_amount * element.quantity;
                                                })} 
                                                    {obj.combo_choices && JSON.parse(obj.combo_choices).map((choiceElement: any) => {
                                                        comboChoicesPrice += (choiceElement.extra_price * choiceElement.quantity);
                                                        comboChoiceTax += choiceElement.tax_amount * choiceElement.quantity;
                                                    })}
                                                    {
                                                        // `${coming_currency} ${obj.order_item_netprice}`
                                                        `${coming_currency} ${ obj.order_item_grossprice && data.orderDetail.order_channel === 'call center'  ? ((parseInt(obj.order_item_grossprice) - (parseInt(obj.order_item_grossprice) * ((parseInt(obj.tax_percent)) / 100 ))).toFixed(2)) : parseFloat(obj.order_item_netprice).toFixed(2)}`
                                                    }
                                                </td>
                                                <td title={`${obj.tax_percent}`}>{obj.tax_percent}%</td>
                                                <td title={`${obj.order_item_quantity}`}>{obj.order_item_quantity}</td>
                                                {/* <td title={`${obj.tax_amount}`}>{obj.tax_amount}</td> */}
                                                {/* <td title={`${parseFloat(obj.tax_amount).toFixed(2)}`}>{parseFloat(obj.tax_amount).toFixed(2)}</td> */}
                                                {console.log("iam near taxamount")}
                                                <td title={`${obj.tax_amount && data.orderDetail.order_channel === 'call center' ? ((parseInt(obj.order_item_grossprice) * parseInt(obj.tax_percent)) / 100 ).toFixed(2) : obj.tax_amount }`}>{`${coming_currency} ${ obj.tax_amount}`} </td>
                                                <td title={data.orderDetail.tax_type == 1 ? `${coming_currency} ${obj.order_item_grossprice}` : `${coming_currency} ${obj.order_item_grossprice}`}>{ `${coming_currency} ${obj.order_item_grossprice}`}</td>
                                            </tr>
                                            {
                                                obj.modifiers && JSON.parse(obj.modifiers).map((element: any) => {
                                                    return <tr>
                                                        <td></td>
                                                        <td style={{ textAlign: "left" }}>
                                                            <b>{element.quantity} </b>{element.name}
                                                        </td>
                                                        <td >{(element?.modifier_sale_price/(1+(element.tax_percent)/100)).toFixed(2)}</td>
                                                            
                                                            <td title={`${element.tax_percent ?element.tax_percent : 0}`}>{element.tax_percent? element.tax_percent: 0 }%</td>
                                                            <td title={`${element.quantity}`}>{element.quantity}</td>
                                                            <td title={`${parseFloat(element.tax_amount? element.tax_amount:0 * element.quantity).toFixed(2)}`}>{(element.tax_percent? ((JSON.parse(element.tax_percent? element.tax_percent : 0)/ 100) * JSON.parse((element?.modifier_sale_price/(1+(element.tax_percent)/100)).toFixed(2))) : 0).toFixed(2)}</td>
                                                            <td title={data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.modifier_sale_price * element.quantity) + Math.round(element.tax_amount? element.tax_amount : 0 * element.quantity)}` : `${coming_currency} ${(element.modifier_sale_price)}`}>{data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.modifier_sale_price * element.quantity) + Math.round(element.tax_amount? element.tax_amount: 0 * element.quantity)}` : `${coming_currency} ${(element.modifier_sale_price)}`}</td>
                                                    </tr>
                                                })
                                            }

                                            {obj.quick_combos && obj.quick_combos.length > 0 && <tr title="Quick Combos">
                                                <td style={{ whiteSpace: 'normal' }} colSpan={7}>
                                                    <b>
                                                        Make a combo
                                                    </b>
                                                </td>
                                            </tr>}
                                            {
                                                obj.quick_combos && obj.quick_combos.length > 0 && obj.quick_combos.map((element: any) => {
                                                    return <tr>
                                                        <td></td>
                                                        <td style={{ textAlign: "left" }}>
                                                            <b>{element.quantity} </b>{element.item_name} ({element.size})
                                                        </td>
                                                        <td title={data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.mrp * element.quantity)}` : `${coming_currency} ${(element.mrp * element.quantity) - (element.tax_amount)}`}>{data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.mrp * element.quantity)}` : `${coming_currency} ${(element.mrp * element.quantity) - (element.tax_amount? element.tax_amount: 0)}`}</td>
                                                        <td title={`${element.tax_percent ? element.tax_percent : 0}`}>{element.tax_percent ? element.tax_percent : 0 }%</td>
                                                        <td title={`${element.quantity}`}>{element.quantity}</td>
                                                        <td title={`${element.tax_amount? element.tax_amount: 0}`}>{element.tax_amount? element.tax_amount: 0}</td>
                                                        <td title={data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.mrp * element.quantity) + Math.round(element.tax_amount? element.tax_amount: 0)}` : `${coming_currency} ${(element.mrp * element.quantity)}`}>{data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.mrp * element.quantity) + Math.round(element.tax_amount? element.tax_amount: 0)}` : `${coming_currency} ${(element.mrp * element.quantity)}`}</td>
                                                        {/* <td title={data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.modifier_sale_price * element.quantity) + Math.round(element.tax_amount * element.quantity)}` : `${coming_currency} ${(element.modifier_sale_price * element.quantity)}`}>{data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.modifier_sale_price * element.quantity) + Math.round(element.tax_amount * element.quantity)}` : `${coming_currency} ${(element.modifier_sale_price * element.quantity)}`}</td> */}
                                                    </tr>
                                                })
                                            }
                                        </>
                                    )
                                })}
                                <tr>
                                    <td style={{ border: 'none' }}></td>
                                    <td style={{ border: 'none' }}></td>
                                    <td colSpan={3} style={{ textAlign: 'left' }} className="total">Sub Total <b>المبلغ الاجمالي</b></td>
                                    <td colSpan={2} className="total" title={`${coming_currency} ${data.orderDetail && data.orderDetail.order_channel === 'call center' ? ( parseFloat(data.orderDetail.order_grossprice) - parseFloat(data.orderDetail.order_grossprice) * (parseFloat(data.orderDetail.tax_percent) / 100)).toFixed(2) : data && data.orderDetail && parseFloat(data.orderDetail.order_netprice).toFixed(2) }`}>{coming_currency} { data.orderDetail && data.orderDetail.order_channel === 'call center' ? ( parseFloat(data.orderDetail.order_grossprice) - parseFloat(data.orderDetail.order_grossprice) * (parseFloat(data.orderDetail.tax_percent) / 100)).toFixed(2) : data && data.orderDetail && parseFloat(data.orderDetail.order_netprice).toFixed(2) }</td>
                                    
                                </tr>
                                {data.orderDetail.coupon &&
                                    <tr>
                                        <td style={{ border: 'none' }}></td>
                                        <td style={{ border: 'none' }}></td>
                                        <td colSpan={3} style={{ textAlign: 'left' }} className="discount total">Discount</td>
                                        <td colSpan={2} className="discount total" title={`${coming_currency} ${Math.round(data.orderDetail.coupon_discount_value)}`}>{coming_currency} {Math.round(data.orderDetail.coupon_discount_value)} </td> {/* {data.Items.length > 0 && Math.round((this.calcSubTotalTotal(data.Items) * tax)/100)} PKR */}
                                    </tr>
                                }
                                {
                                    data.orderDetail.discount > 0 &&
                                    <tr>
                                        <td style={{ border: 'none' }}></td>
                                        <td style={{ border: 'none' }}></td>
                                        <td colSpan={3} style={{ textAlign: 'left' }} className="discount total">Discounts  <b> الخصم</b></td>
                                        <td colSpan={2} className="discount total" title={`${coming_currency} ${Math.round(data.orderDetail.discount)}`}>{coming_currency} {parseFloat(data.orderDetail.discount).toFixed(2)} </td>
                                    </tr>
                                }
                                <tr>
                                    <td style={{ border: 'none' }}></td>
                                    <td style={{ border: 'none' }}></td>
                                    <td colSpan={3} style={{ textAlign: 'left' }} className="total">{data.orderDetail.tax_percent} % VAT <b> الضريبة</b></td>
                                    {/* <td colSpan={2} className="total" title={`${coming_currency} ${data.orderDetail.tax_amount}`}>{coming_currency} {data && data.orderDetail? data.orderDetail.tax_amount:0} </td>  */}
                                    <td colSpan={2} className="total" title={`${coming_currency} ${parseFloat(data.orderDetail.tax_amount).toFixed(2)}`}>{`${coming_currency} ${ data.orderDetail.tax_amount && data.orderDetail.order_channel === 'call center' ? ((parseInt(data.orderDetail.order_grossprice) * parseInt(data.orderDetail.tax_percent)) / 100 ).toFixed(2) : parseFloat(data.orderDetail.tax_amount).toFixed(2) }`} </td>
                                    {/* {data.Items.length > 0 && Math.round((this.calcSubTotalTotal(data.Items) * tax)/100)} PKR */}
                                </tr>
                                {(data.orderDetail && data.orderDetail.delivery_status == "Delivery") && <tr>
                                    <td style={{ border: 'none' }}></td>
                                    <td style={{ border: 'none' }}></td>
                                    <td colSpan={3} style={{ textAlign: 'left' }} className="total">Delivery Charges <b> رسوم توصيل </b></td>
                                    {/* <td colSpan={2} className="total" title={`${coming_currency} ${data.orderDetail && data.orderDetail.delivery_fee}`}>{coming_currency} {data.orderDetail && data.orderDetail.delivery_fee? data.orderDetail.delivery_fee:0 }</td> */}
                                    <td colSpan={2} className="total" title={`${coming_currency} ${data.orderDetail && data.orderDetail.delivery_fee}`}>{coming_currency} {data.orderDetail && data.orderDetail.delivery_fee? parseInt(data.orderDetail.delivery_fee).toFixed(2) : '0.00' }</td>
                                </tr>
                                }
                                {data.orderDetail.pos_service_fee > 0 &&
                                    <>
                                        <td style={{ border: 'none' }}></td>
                                        <td style={{ border: 'none' }}></td>
                                        <td colSpan={3} style={{ textAlign: 'left' }} className="total">Pos Service Fee</td>
                                        {/* <td colSpan={2} className="total" title={`${coming_currency} ${data.orderDetail.pos_service_fee}`}>{coming_currency} {data.orderDetail.pos_service_fee)}</td> */}
                                        <td colSpan={2} className="total" title={`${coming_currency} ${data.orderDetail.pos_service_fee}`}>{coming_currency} {parseInt(data.orderDetail.pos_service_fee).toFixed(2)}</td>
                                    </>
                                }
                                <tr>
                                    <td style={{ border: 'none' }}></td>
                                    <td style={{ border: 'none' }}></td>
                                    <td colSpan={3} style={{ textAlign: 'left' }} className="grand total"><b>Grand Total</b><b> المبلغ الإجمالي للطلب</b></td>
                                    <td colSpan={2} className="grand total">
                                        {/* <b>{`${coming_currency} ${data.orderDetail.order_grossprice }`}</b>  */}
                                        <b>{`${coming_currency} ${parseFloat(data.orderDetail.order_grossprice).toFixed(2) }`}</b> 
                                    </td>
                                </tr>
                            </tbody>
                    </table>
                      {/* <p className="text-center mt-3">{decoded && decoded.brand && decoded.brand.brand_name}</p> */}
                      <p className="text-center mt-3">{data.orderDetail && data.orderDetail.brand_name}</p>
                        {/* <p className="text-center mt-3">NTN #<br /><b> الرقم الضريبي الوطني</b>  {data.orderDetail.ntn_number}</p> */}
                        {/* <p className="text-center mt-3">{decoded && decoded.brand && decoded.brand.website_link}</p> */}
                        <p className="text-center mt-3">{data.orderDetail.website_link == 'null' ? '' : data.orderDetail.website_link}</p>
                        <h6 className="text-center mt-3">{data.orderDetail.contact1}</h6>
                        <footer className="invoicefooter mb-3">
                            {/* "Thank you for Ordering {decoded && decoded.brand && decoded.brand.brand_name}" */}
                            "Thank you for Ordering {data.orderDetail && data.orderDetail.brand_name}"
                        </footer>
                    </main>
                </div>
            </div>

            )
        }
        else if(data.orderDetail.order_channel == 'deliveroo')
        {
                return(
                    <div>
                    <div className="d-flex justify-content-end ">
                        {
                            // data.orderDetail.order_status_code !== 1 &&
                            <ReactToPrint
                                trigger={() => <button type="button" className="btn btn-success">Print</button>}
                                content={() => this.componentRef}
                            />
                        }
                    </div>
                    <div style={{ width: '108mm' }} ref={el => (this.componentRef = el)}>
                        <header className="clearfix">
                            <div id="logo">
                                {/* <img width={150} height={80} src={process.env.PUBLIC_URL + "/assets/img/ky.png"} /> */}
                                {/* <img width={180} height={100} src={`${data && data.orderDetail && data.orderDetail.brand_id == 3? process.env.PUBLIC_URL + "/assets/img/BR.png":process.env.PUBLIC_URL + "/assets/img/BR.png"}`} /> */}
                                <img width={180} height={100} src={`${data && data.orderDetail && data.orderDetail.brand_id == 3? process.env.PUBLIC_URL + "/assets/img/BR.png":process.env.PUBLIC_URL + "/assets/img/ky.png"}`} />

                            </div>
                            <h6 className="text-center mt-3">
                                {/* <b>{decoded && decoded.brand && decoded.brand.brand_name}</b> */}
                                {data.orderDetail && data.orderDetail.brand_name}
                            </h6>
                            <h6 className="text-center mt-3">{data.orderDetail.city}</h6>
    
                            <div id="project" className="invoiceh1">
                                
                                {/* <h3>Customer Information</h3> */}
                                <div style={{ display: 'flex' }}><span><b>ORDER NO:</b><br/><b>رقم الطلب</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {orderId}</span></div>
                                <div style={{ display: 'flex' }}><span><b>Order Date:</b><br/><b>تاريخ الطلب</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {data.orderDetail && DateCreated}</span></div>
                                <div style={{ display: 'flex' }}><span><b>Customer:</b><br/><b>اسم العميل</b>
                                    </span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>
                                     {/* {data.orderDetail && data.orderDetail.customer_name? data.orderDetail.customer_name: 'N/A' } */}
                                     { data.orderDetail && (data.orderDetail.order_channel === 'deliveroo' || data.orderDetail.order_channel === 'Hungerstation') ? data.orderDetail.customer_name  : data.orderDetail && data.orderDetail.login_name ? data.orderDetail.login_name: 'N/A' }
                                    </span>
                                </div>
                                
                                {(data.orderDetail && data.orderDetail.phone_number) && <div style={{ display: 'flex' }}><span><b>Phone:</b><br/><b>هاتف</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {data.orderDetail.phone_number}</span></div>}
                                <div style={{ display: 'flex' }}><span><b>Store:</b><br/><b>متجر</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{data.orderDetail && data.orderDetail.store_name}</span></div>
                                <div style={{ display: 'flex' }}><span><b>From:</b><br/><b>عنوان الفرع</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{data.orderDetail && data.orderDetail.address? data.orderDetail.address: `Deliveroo Delivery`}</span></div>
                                {(data.orderDetail && data.orderDetail.delivery_status == 'Delivery') && <div style={{ display: 'flex' }}><span><b>Delivery at:</b><br/><b>عنوان التسليم</b> </span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {data.orderDetail.delivery_address?data.orderDetail.delivery_address:`Hungerstation Delivery`}</span></div>}
                                <div style={{ display: 'flex' }}><span><b>Special Ins:</b><br/><b>تعليمات خاصة</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{data.orderDetail && data.orderDetail.special_inst? data.orderDetail.special_inst : 'N/A' }</span></div>
                                {utcDateTime._isValid && <div><span><b>{data.orderDetail && data.orderDetail.delivery_status === "Delivery" ? 'Delivery' : 'Pickup'} Time:</b><br/><b>زمن</b></span><br /><span>{FutureDate}</span></div>}
                                {FbrNumber && <div style={{ display: 'flex' }}><span><b>FBR Invoice:</b><br/><b>فاتورة FBR</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{FbrNumber}</span><span style={{ whiteSpace: "normal", textAlign: 'right', flex: 'auto' }}><QRCode size='50' value={FbrNumber} /></span></div>}
                                {/* {(data.orderDetail.fbr_invoice_number!=undefined) ? 
                                <div style={{ display: 'flex' }}><span style={{ textAlign: "left", fontWeight:'bold' }}>{FbrNumber}</span><span style={{ whiteSpace: "normal", textAlign: 'right', flex: 'auto' }}> <QRCode size='50' value={FbrNumber}/></span></div>
                                :  
                                <div></div>
                                } */}
                            </div>
                            <div id="secProject">
                                <div id="project2">
                                    <div><h6 style={{ fontSize: 13 }}>Payment Type</h6><h6>طريقة الدفع</h6>{data.orderDetail && data.orderDetail.payment_method}</div>
                                </div>
                                <div id="project2">
                                    <div>
                                        {/* <h6 style={{ fontSize: 13 }}>Ordering Channel</h6><h6>تم الطلب خلال</h6>{data.orderDetail && data.orderDetail.order_channel +` (${data.orderDetail.external_orderId})`} */}
                                        <h6 style={{ fontSize: 13 }}>Ordering Channel</h6><h6>تم الطلب خلال</h6>{data.orderDetail && data.orderDetail.order_channel +` (${data.orderDetail.external_orderId})`}
                                    </div>
                                </div>
                                <div id="project2">
                                    <div>
                                        <h6 style={{ fontSize: 13 }}>Ordering Mode</h6><h6>نوع الطلب</h6>{data.orderDetail && data.orderDetail.delivery_status}
                                    </div>
                                </div>
                            </div>
                        </header>
                        <main>
                        <table className="invoicetable">
                                <thead>
                                    <tr>
                                        <th style={{ width: 40 }}>#</th>
                                        <th style={{ width: 135 }}>Name</th>
                                        <th style={{ width: 70, overflow: 'unset' }}>PRICE</th>
                                        <th style={{ width: 70, overflow: 'unset' }}>% VAT</th>
                                        <th style={{ width: 40, overflow: 'unset' }}>QTY</th>
                                        <th style={{ width: 60, overflow: 'unset' }}>TAX</th>
                                        <th style={{ width: 60, overflow: 'unset' }}>TOTAL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.Items.map((obj: any, index: any) => {
                                        let AddOnsPrice: any = 0;
                                        let AddOnsTax: any = 0;
                                        let comboChoicesPrice: any = 0;
                                        let comboChoiceTax: any = 0;
                                        let qcPrice:any = 0
                                        let qctax = 0
                                        obj.quick_combos && obj.quick_combos.map((qc:any)=>{
                                            qcPrice = qcPrice + (qc.mrp * qc.quantity)
                                            qctax = Number(qc.tax_amount)
                                        })
                                        const ingredients = obj.combo_choices && JSON.parse(obj.combo_choices).map((element: any) => {
                                            return <li><b>{element.quantity * obj.order_item_quantity} </b>{element.item_name} ({element.size && JSON.parse(element.size).size}){element.extra_price > 0 && `+${coming_currency} ${element.extra_price * obj.order_item_quantity}`}</li>
                                        })
                                        return (
                                            <>  
                                                <tr title={(obj.combo_name !== "" && obj.combo_name) || (obj.item_name !== "" && `${obj.item_name}(${JSON.parse(obj.item_size).size}) x ${obj.order_item_quantity}`)}>
                                                    <td style={{ whiteSpace: 'normal' }} colSpan={7}>
                                                        <b>
                                                            {(obj.combo_name !== "" && obj.combo_name) || (obj.item_name !== "" && `${obj.item_name}(${JSON.parse(obj.item_size).size})  x ${obj.order_item_quantity}`)}
                                                        </b>
                                                    </td>
                                                </tr>
                                                <tr key={index}>
                                                    <td title={`${index + 1}`}>{index + 1}</td>
                                                    <td style={{ padding: 10, width: 150 }}>
                                                        <ul style={{ whiteSpace: 'normal' }}>
                                                            {ingredients}
                                                            <br />
                                                            <b>{(obj.modifiers && JSON.parse(obj.modifiers).length > 0) && "Add Ons"}</b>
                                                        </ul>
                                                    </td>
                                                    <td>{obj.modifiers && JSON.parse(obj.modifiers).map((element: any) => {
                                                        AddOnsPrice += (element.quantity * element.modifier_sale_price).toFixed(2);
                                                        AddOnsTax += element.tax_amount * element.quantity;
                                                    })} 
                                                        {obj.combo_choices && JSON.parse(obj.combo_choices).map((choiceElement: any) => {
                                                            comboChoicesPrice += (choiceElement.extra_price * choiceElement.quantity);
                                                            comboChoiceTax += choiceElement.tax_amount * choiceElement.quantity;
                                                        })}
                                                        {
                                                            // `${coming_currency} ${obj.order_item_netprice}`
                                                            `${coming_currency} ${ obj.order_item_grossprice && data.orderDetail.order_channel === 'call center'  ? ((parseInt(obj.order_item_grossprice) - (parseInt(obj.order_item_grossprice) * ((parseInt(obj.tax_percent)) / 100 ))).toFixed(2)) : parseFloat(obj.order_item_netprice).toFixed(2)}`
                                                        }
                                                    </td>
                                                    <td title={`${obj.tax_percent}`}>{obj.tax_percent}%</td>
                                                    <td title={`${obj.order_item_quantity}`}>{obj.order_item_quantity}</td>
                                                    {/* <td title={`${obj.tax_amount}`}>{obj.tax_amount}</td> */}
                                                    {/* <td title={`${parseFloat(obj.tax_amount).toFixed(2)}`}>{parseFloat(obj.tax_amount).toFixed(2)}</td> */}
                                                    {console.log("iam near taxamount")}
                                                    <td title={`${obj.tax_amount && data.orderDetail.order_channel === 'call center' ? ((parseInt(obj.order_item_grossprice) * parseInt(obj.tax_percent)) / 100 ).toFixed(2) : obj.tax_amount }`}>{`${coming_currency} ${ obj.tax_amount}`} </td>
                                                    <td title={data.orderDetail.tax_type == 1 ? `${coming_currency} ${obj.order_item_grossprice}` : `${coming_currency} ${obj.order_item_grossprice}`}>{ `${coming_currency} ${obj.order_item_grossprice}`}</td>
                                                </tr>
                                                {
                                                    obj.modifiers && JSON.parse(obj.modifiers).map((element: any) => {
                                                        return <tr>
                                                            <td></td>
                                                            <td style={{ textAlign: "left" }}>
                                                                <b>{element.quantity} </b>{element.name}
                                                            </td>
                                                                <td title={`${parseFloat(element.modifier_net_price? element.modifier_net_price:0 * element.quantity).toFixed(2)}`}>{parseFloat(element.modifier_net_price? element.modifier_net_price:0 * element.quantity).toFixed(2)}</td>
                                                                <td title={`${element.tax_percent ?element.tax_percent : 0}`}>{element.tax_percent? element.tax_percent: 0 }%</td>
                                                                <td title={`${element.quantity}`}>{element.quantity}</td>
                                                                <td title={`${parseFloat(element.tax_amount? element.tax_amount:0 * element.quantity).toFixed(2)}`}>{parseFloat(element.tax_amount? element.tax_amount:0 * element.quantity).toFixed(2)}</td>
                                                                <td title={data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.modifier_sale_price * element.quantity) + Math.round(element.tax_amount? element.tax_amount : 0 * element.quantity)}` : `${coming_currency} ${(element.modifier_sale_price)}`}>{data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.modifier_sale_price * element.quantity) + Math.round(element.tax_amount? element.tax_amount: 0 * element.quantity)}` : `${coming_currency} ${(element.modifier_sale_price * element.quantity)}`}</td>
                                                        </tr>
                                                    })
                                                }
    
                                                {obj.quick_combos && obj.quick_combos.length > 0 && <tr title="Quick Combos">
                                                    <td style={{ whiteSpace: 'normal' }} colSpan={7}>
                                                        <b>
                                                            Make a combo
                                                        </b>
                                                    </td>
                                                </tr>}
                                                {
                                                    obj.quick_combos && obj.quick_combos.length > 0 && obj.quick_combos.map((element: any) => {
                                                        return <tr>
                                                            <td></td>
                                                            <td style={{ textAlign: "left" }}>
                                                                <b>{element.quantity} </b>{element.item_name} ({element.size})
                                                            </td>
                                                            <td title={data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.mrp * element.quantity)}` : `${coming_currency} ${(element.mrp * element.quantity) - (element.tax_amount)}`}>{data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.mrp * element.quantity)}` : `${coming_currency} ${(element.mrp * element.quantity) - (element.tax_amount? element.tax_amount: 0)}`}</td>
                                                            <td title={`${element.tax_percent ? element.tax_percent : 0}`}>{element.tax_percent ? element.tax_percent : 0 }%</td>
                                                            <td title={`${element.quantity}`}>{element.quantity}</td>
                                                            <td title={`${element.tax_amount? element.tax_amount: 0}`}>{element.tax_amount? element.tax_amount: 0}</td>
                                                            <td title={data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.mrp * element.quantity) + Math.round(element.tax_amount? element.tax_amount: 0)}` : `${coming_currency} ${(element.mrp * element.quantity)}`}>{data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.mrp * element.quantity) + Math.round(element.tax_amount? element.tax_amount: 0)}` : `${coming_currency} ${(element.mrp * element.quantity)}`}</td>
                                                            {/* <td title={data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.modifier_sale_price * element.quantity) + Math.round(element.tax_amount * element.quantity)}` : `${coming_currency} ${(element.modifier_sale_price * element.quantity)}`}>{data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.modifier_sale_price * element.quantity) + Math.round(element.tax_amount * element.quantity)}` : `${coming_currency} ${(element.modifier_sale_price * element.quantity)}`}</td> */}
                                                        </tr>
                                                    })
                                                }
                                            </>
                                        )
                                    })}
                                    <tr>
                                        <td style={{ border: 'none' }}></td>
                                        <td style={{ border: 'none' }}></td>
                                        <td colSpan={3} style={{ textAlign: 'left' }} className="total">Sub Total <b>المبلغ الاجمالي</b></td>
                                        <td colSpan={2} className="total" title={`${coming_currency} ${data.orderDetail && data.orderDetail.order_channel === 'call center' ? ( parseFloat(data.orderDetail.order_grossprice) - parseFloat(data.orderDetail.order_grossprice) * (parseFloat(data.orderDetail.tax_percent) / 100)).toFixed(2) : data && data.orderDetail && parseFloat(data.orderDetail.order_netprice).toFixed(2) }`}>{coming_currency} { data.orderDetail && data.orderDetail.order_channel === 'call center' ? ( parseFloat(data.orderDetail.order_grossprice) - parseFloat(data.orderDetail.order_grossprice) * (parseFloat(data.orderDetail.tax_percent) / 100)).toFixed(2) : data && data.orderDetail && parseFloat(data.orderDetail.order_netprice).toFixed(2) }</td>
                                        
                                    </tr>
                                    {data.orderDetail.coupon &&
                                        <tr>
                                            <td style={{ border: 'none' }}></td>
                                            <td style={{ border: 'none' }}></td>
                                            <td colSpan={3} style={{ textAlign: 'left' }} className="discount total">Discount</td>
                                            <td colSpan={2} className="discount total" title={`${coming_currency} ${Math.round(data.orderDetail.coupon_discount_value)}`}>{coming_currency} {Math.round(data.orderDetail.coupon_discount_value)} </td> {/* {data.Items.length > 0 && Math.round((this.calcSubTotalTotal(data.Items) * tax)/100)} PKR */}
                                        </tr>
                                    }
                                    {
                                        data.orderDetail.discount > 0 &&
                                        <tr>
                                            <td style={{ border: 'none' }}></td>
                                            <td style={{ border: 'none' }}></td>
                                            <td colSpan={3} style={{ textAlign: 'left' }} className="discount total">Discounts  <b> الخصم</b></td>
                                            <td colSpan={2} className="discount total" title={`${coming_currency} ${Math.round(data.orderDetail.discount)}`}>{coming_currency} {parseFloat(data.orderDetail.discount).toFixed(2)} </td>
                                        </tr>
                                    }

                                    <tr>
                                            <td style={{ border: 'none' }}></td>
                                            <td style={{ border: 'none' }}></td>
                                            <td colSpan={3} style={{ textAlign: 'left' }} className="discount total">Net Amount  <b> المبلغ الصافي</b></td>
                                            <td colSpan={2} className="discount total" title={`${coming_currency} ${Math.round(data.orderDetail.order_grosspricewd)}`}>{coming_currency} {parseFloat(data.orderDetail.order_grosspricewd).toFixed(2)} </td>
                                        </tr>
                                    <tr>
                                        <td style={{ border: 'none' }}></td>
                                        <td style={{ border: 'none' }}></td>
                                        <td colSpan={3} style={{ textAlign: 'left' }} className="total">{data.orderDetail.tax_percent} % VAT <b> الضريبة</b></td>
                                        {/* <td colSpan={2} className="total" title={`${coming_currency} ${data.orderDetail.tax_amount}`}>{coming_currency} {data && data.orderDetail? data.orderDetail.tax_amount:0} </td>  */}
                                        <td colSpan={2} className="total" title={`${coming_currency} ${parseFloat(data.orderDetail.tax_amount).toFixed(2)}`}>{`${coming_currency} ${ data.orderDetail.tax_amount && data.orderDetail.order_channel === 'call center' ? ((parseInt(data.orderDetail.order_grossprice) * parseInt(data.orderDetail.tax_percent)) / 100 ).toFixed(2) : parseFloat(data.orderDetail.tax_amount).toFixed(2) }`} </td>
                                        {/* {data.Items.length > 0 && Math.round((this.calcSubTotalTotal(data.Items) * tax)/100)} PKR */}
                                    </tr>
                                    {(data.orderDetail && data.orderDetail.delivery_status == "Delivery") && <tr>
                                        <td style={{ border: 'none' }}></td>
                                        <td style={{ border: 'none' }}></td>
                                        <td colSpan={3} style={{ textAlign: 'left' }} className="total">Delivery Charges <b> رسوم توصيل </b></td>
                                        {/* <td colSpan={2} className="total" title={`${coming_currency} ${data.orderDetail && data.orderDetail.delivery_fee}`}>{coming_currency} {data.orderDetail && data.orderDetail.delivery_fee? data.orderDetail.delivery_fee:0 }</td> */}
                                        <td colSpan={2} className="total" title={`${coming_currency} ${data.orderDetail && data.orderDetail.delivery_fee}`}>{coming_currency} {data.orderDetail && data.orderDetail.delivery_fee? parseInt(data.orderDetail.delivery_fee).toFixed(2) : '0.00' }</td>
                                    </tr>
                                    }
                                    {data.orderDetail.pos_service_fee > 0 &&
                                        <>
                                            <td style={{ border: 'none' }}></td>
                                            <td style={{ border: 'none' }}></td>
                                            <td colSpan={3} style={{ textAlign: 'left' }} className="total">Pos Service Fee</td>
                                            {/* <td colSpan={2} className="total" title={`${coming_currency} ${data.orderDetail.pos_service_fee}`}>{coming_currency} {data.orderDetail.pos_service_fee)}</td> */}
                                            <td colSpan={2} className="total" title={`${coming_currency} ${data.orderDetail.pos_service_fee}`}>{coming_currency} {parseInt(data.orderDetail.pos_service_fee).toFixed(2)}</td>
                                        </>
                                    }
                                    <tr>
                                        <td style={{ border: 'none' }}></td>
                                        <td style={{ border: 'none' }}></td>
                                        <td colSpan={3} style={{ textAlign: 'left' }} className="grand total"><b>Grand Total</b><b> المبلغ الإجمالي للطلب</b></td>
                                        <td colSpan={2} className="grand total">
                                            {/* <b>{`${coming_currency} ${data.orderDetail.order_grossprice }`}</b>  */}
                                            <b>{`${coming_currency} ${parseFloat(data.orderDetail.order_grossprice).toFixed(2) }`}</b> 
                                        </td>
                                    </tr>
                                </tbody>
                        </table>
                          {/* <p className="text-center mt-3">{decoded && decoded.brand && decoded.brand.brand_name}</p> */}
                          <p className="text-center mt-3">{data.orderDetail && data.orderDetail.brand_name}</p>
                            {/* <p className="text-center mt-3">NTN #<br /><b> الرقم الضريبي الوطني</b>  {data.orderDetail.ntn_number}</p> */}
                            {/* <p className="text-center mt-3">{decoded && decoded.brand && decoded.brand.website_link}</p> */}
                            <p className="text-center mt-3">{data.orderDetail.website_link == 'null' ? '' : data.orderDetail.website_link}</p>
                            <h6 className="text-center mt-3">{data.orderDetail.contact1}</h6>
                            <footer className="invoicefooter mb-3">
                                {/* "Thank you for Ordering {decoded && decoded.brand && decoded.brand.brand_name}" */}
                                "Thank you for Ordering {data.orderDetail && data.orderDetail.brand_name}"
                            </footer>
                        </main>
                    </div>
                </div>
    
                )
        }
        else
        {
            return (
                <div>
                    <div className="d-flex justify-content-end ">
                        {
                            // data.orderDetail.order_status_code !== 1 &&
                            <ReactToPrint
                                trigger={() => <button type="button" className="btn btn-success">Print</button>}
                                content={() => this.componentRef}
                            />
                        }
                    </div>
                    <div style={{ width: '108mm' }} ref={el => (this.componentRef = el)}>
                        <header className="clearfix">
                            <div id="logo">
                                {/* <img width={150} height={80} src={process.env.PUBLIC_URL + "/assets/img/ky.png"} /> */}
                                {/* <img width={180} height={100} src={`${data && data.orderDetail && data.orderDetail.brand_id == 3? process.env.PUBLIC_URL + "/assets/img/BR.png":process.env.PUBLIC_URL + "/assets/img/BR.png"}`} /> */}
                                <img width={150} height={80} src={`${data && data.orderDetail.order_channel === 'deliveroo' ?  `${API_URL}${data.orderDetail && data.orderDetail.landing_image }` :  data.orderDetail && data.orderDetail.landing_image ? `${API_URL}/${data.orderDetail.landing_image}` : process.env.PUBLIC_URL + "/assets/img/BR.png"}`} />
                            </div>
                            <h6 className="text-center mt-3">
                                {/* <b>{decoded && decoded.brand && decoded.brand.brand_name}</b> */}
                                {data.orderDetail && data.orderDetail.brand_name}
                            </h6>
                            <h6 className="text-center mt-3">{data.orderDetail.city}</h6>

                            <div id="project" className="invoiceh1">
                                {/* <h3>Customer Information</h3> */}
                                {
                                    console.log(data.orderDetail,"orders detail")
                                }
                                <div style={{ display: 'flex' }}><span><b>ORDER NO:</b><br/><b>رقم الطلب</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {orderId}</span></div>
                                <div style={{ display: 'flex' }}><span><b>Order Date:</b><br/><b>تاريخ الطلب</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {data.orderDetail && DateCreated}</span></div>
                                <div style={{ display: 'flex' }}><span><b>Customer:</b><br/><b>اسم العميل</b>
                                    </span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>
                                    {/* {data.orderDetail && data.orderDetail.customer_name? data.orderDetail.customer_name: 'N/A' } */}
                                    { data.orderDetail && data.orderDetail.order_channel === 'deliveroo' ? data.orderDetail.customer_name  : data.orderDetail && data.orderDetail.login_name ? data.orderDetail.login_name: 'N/A' }
                                    </span>
                                </div>
                                {console.log("iam nearorder no deliveroo")}
                                {(data.orderDetail && data.orderDetail.phone_number) && <div style={{ display: 'flex' }}><span><b>Phone:</b><br/><b>هاتف</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {data.orderDetail.phone_number}</span></div>}
                                <div style={{ display: 'flex' }}><span><b>Store:</b><br/><b>متجر</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{data.orderDetail && data.orderDetail.store_name}</span></div>
                                <div style={{ display: 'flex' }}><span><b>From:</b><br/><b>عنوان الفرع</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{data.orderDetail && data.orderDetail.address? data.orderDetail.address: `Deliveroo Delivery`}</span></div>
                                {(data.orderDetail && data.orderDetail.delivery_address) && <div style={{ display: 'flex' }}><span><b>Delivery at:</b><br/><b>عنوان التسليم</b> </span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {data.orderDetail.delivery_address}</span></div>}
                                <div style={{ display: 'flex' }}><span><b>Special Ins:</b><br/><b>تعليمات خاصة</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{data.orderDetail && data.orderDetail.special_inst? data.orderDetail.special_inst : 'N/A' }</span></div>
                                {utcDateTime._isValid && <div><span><b>{data.orderDetail && data.orderDetail.delivery_status === "Delivery" ? 'Delivery' : 'Pickup'} Time:</b><br/><b>زمن</b></span><br /><span>{FutureDate}</span></div>}
                                {FbrNumber && <div style={{ display: 'flex' }}><span><b>FBR Invoice:</b><br/><b>فاتورة FBR</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{FbrNumber}</span><span style={{ whiteSpace: "normal", textAlign: 'right', flex: 'auto' }}><QRCode size='50' value={FbrNumber} /></span></div>}
                                {/* {(data.orderDetail.fbr_invoice_number!=undefined) ? 
                                <div style={{ display: 'flex' }}><span style={{ textAlign: "left", fontWeight:'bold' }}>{FbrNumber}</span><span style={{ whiteSpace: "normal", textAlign: 'right', flex: 'auto' }}> <QRCode size='50' value={FbrNumber}/></span></div>
                                :  
                                <div></div>
                                } */}
                            </div>
                            <div id="secProject">
                                <div id="project2">
                                    <div><h6 style={{ fontSize: 13 }}>Payment Type</h6><h6>طريقة الدفع</h6>{data.orderDetail && data.orderDetail.payment_method}</div>
                                </div>
                                <div id="project2">
                                    <div>
                                        {/* <h6 style={{ fontSize: 13 }}>Ordering Channel</h6><h6>تم الطلب خلال</h6>{data.orderDetail && data.orderDetail.order_channel +` (${data.orderDetail.external_orderId})`} */}
                                        <h6 style={{ fontSize: 13 }}>Ordering Channel</h6><h6>تم الطلب خلال</h6>{data.orderDetail && data.orderDetail.order_channel + (data.orderDetail.external_orderId ? data.orderDetail.external_orderId : '')}
                                    </div>
                                </div>
                                <div id="project2">
                                    <div>
                                        <h6 style={{ fontSize: 13 }}>Ordering Mode</h6><h6>نوع الطلب</h6>{data.orderDetail && data.orderDetail.delivery_status}
                                    </div>
                                </div>
                            </div>
                        </header>
                        <main>
                        <table className="invoicetable">
                                <thead>
                                    <tr>
                                        <th style={{ width: 40 }}>#</th>
                                        <th style={{ width: 135 }}>Name</th>
                                        <th style={{ width: 70, overflow: 'unset' }}>PRICE</th>
                                        <th style={{ width: 70, overflow: 'unset' }}>% VAT</th>
                                        <th style={{ width: 40, overflow: 'unset' }}>QTY</th>
                                        <th style={{ width: 60, overflow: 'unset' }}>TAX</th>
                                        <th style={{ width: 60, overflow: 'unset' }}>TOTAL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.Items.map((obj: any, index: any) => {
                                        let AddOnsPrice: any = 0;
                                        let AddOnsTax: any = 0;
                                        let comboChoicesPrice: any = 0;
                                        let comboChoiceTax: any = 0;
                                        let qcPrice:any = 0
                                        let qctax = 0
                                        obj.quick_combos && obj.quick_combos.map((qc:any)=>{
                                            qcPrice = qcPrice + (qc.mrp * qc.quantity)
                                            qctax = Number(qc.tax_amount)
                                        })
                                        const ingredients = obj.combo_choices && JSON.parse(obj.combo_choices).map((element: any) => {
                                            return <li><b>{element.quantity * obj.order_item_quantity} </b>{element.item_name} ({element.size && JSON.parse(element.size).size}){element.extra_price > 0 && `+${coming_currency} ${element.extra_price * obj.order_item_quantity}`}</li>
                                        })
                                        return (
                                            <>  
                                                <tr title={(obj.combo_name !== "" && obj.combo_name) || (obj.item_name !== "" && `${obj.item_name}(${JSON.parse(obj.item_size).size}) x ${obj.order_item_quantity}`)}>
                                                    <td style={{ whiteSpace: 'normal' }} colSpan={7}>
                                                        <b>
                                                            {(obj.combo_name !== "" && obj.combo_name) || (obj.item_name !== "" && `${obj.item_name}(${JSON.parse(obj.item_size).size})  x ${obj.order_item_quantity}`)}
                                                        </b>
                                                    </td>
                                                </tr>
                                                <tr key={index}>
                                                    <td title={`${index + 1}`}>{index + 1}</td>
                                                    <td style={{ padding: 10, width: 150 }}>
                                                        <ul style={{ whiteSpace: 'normal' }}>
                                                            {ingredients}
                                                            <br />
                                                            <b>{(obj.modifiers && JSON.parse(obj.modifiers).length > 0) && "Add Ons"}</b>
                                                        </ul>
                                                    </td>
                                                    <td>{obj.modifiers && JSON.parse(obj.modifiers).map((element: any) => {
                                                        AddOnsPrice += (element.quantity * element.modifier_sale_price).toFixed(2);
                                                        AddOnsTax += element.tax_amount * element.quantity;
                                                    })}
                                                        {obj.combo_choices && JSON.parse(obj.combo_choices).map((choiceElement: any) => {
                                                            comboChoicesPrice += (choiceElement.extra_price * choiceElement.quantity);
                                                            comboChoiceTax += choiceElement.tax_amount * choiceElement.quantity;
                                                        })}
                                                        {
                                                            // `${coming_currency} ${obj.order_item_netprice}`
                                                            `${coming_currency} ${ obj.order_item_netprice && data.orderDetail.order_channel === 'call center'  ? ((parseInt(obj.order_item_netprice))* 100 / ((parseInt(obj.tax_percent)) + 100 )).toFixed(2) : parseInt(obj.order_item_netprice).toFixed(2)}`
                                                        }
                                                    </td>
                                                    <td title={`${obj.tax_percent}`}>{obj.tax_percent}%</td>
                                                    <td title={`${obj.order_item_quantity}`}>{obj.order_item_quantity}</td>
                                                    {/* <td title={`${obj.tax_amount}`}>{obj.tax_amount}</td> */}
                                                    {/* <td title={`${parseFloat(obj.tax_amount).toFixed(2)}`}>{parseFloat(obj.tax_amount).toFixed(2)}</td> */}
                                                    <td title={`${obj.tax_amount && data.orderDetail.order_channel === 'call center' ? ((parseFloat(obj.order_item_netprice) * parseInt(obj.tax_percent)) / 100 ).toFixed(2) : obj.tax_amount }`}>{`${coming_currency} ${ obj.tax_amount ? ((obj.order_item_netprice-((parseInt(obj.order_item_netprice)*100)/ (parseInt(obj.tax_percent)+ 100 ))).toFixed(2) ): parseInt( obj.tax_amount).toFixed(2) }`} </td>
                                                    <td title={data.orderDetail.tax_type == 1 ? `${coming_currency} ${obj.order_item_grossprice}` : `${coming_currency} ${obj.order_item_netprice}`}>{ `${coming_currency} ${obj.order_item_netprice}`}</td>
                                                </tr>
                                                {
                                                    obj.modifiers && JSON.parse(obj.modifiers).map((element: any) => {
                                                        return <tr>
                                                            <td></td>
                                                            <td style={{ textAlign: "left" }}>
                                                                <b>{element.quantity} </b>{element.modifier_name}
                                                            </td>
                                                            <td >{(((element?.modifier_sale_price * element.quantity) * 100)/(Number(element.tax_percent) + 100)).toFixed(2) }</td>
                                                            <td title={`${element.tax_percent ?element.tax_percent : 0}`}>{element.tax_percent? element.tax_percent: 0 }%</td>
                                                            <td title={`${element.quantity}`}>{element.quantity}</td>
                                                            <td >{((element.modifier_sale_price * element.quantity)-(((element?.modifier_sale_price * element.quantity) * 100)/(Number(element.tax_percent) + 100))).toFixed(2) }</td>
                                                            <td title={data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.modifier_sale_price * element.quantity) + Math.round(element.tax_amount? element.tax_amount : 0 * element.quantity)}` : `${coming_currency} ${(element.modifier_sale_price * element.quantity)}`}>{data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.modifier_sale_price * element.quantity) + Math.round(element.tax_amount? element.tax_amount: 0 * element.quantity)}` : `${coming_currency} ${(element.modifier_sale_price * element.quantity)}`}</td>
                                                        </tr>
                                                    })
                                                }

                                                {obj.quick_combos && obj.quick_combos.length > 0 && <tr title="Quick Combos">
                                                    <td style={{ whiteSpace: 'normal' }} colSpan={7}>
                                                        <b>
                                                            Make a combo
                                                        </b>
                                                    </td>
                                                </tr>}
                                                {
                                                    obj.quick_combos && obj.quick_combos.length > 0 && obj.quick_combos.map((element: any) => {
                                                        return <tr>
                                                            <td></td>
                                                            <td style={{ textAlign: "left" }}>
                                                                <b>{element.quantity} </b>{element.item_name} ({element.size})
                                                            </td>
                                                            <td title={data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.mrp * element.quantity)}` : `${coming_currency} ${(element.mrp * element.quantity) - (element.tax_amount)}`}>{data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.mrp * element.quantity)}` : `${coming_currency} ${(element.mrp * element.quantity) - (element.tax_amount? element.tax_amount: 0)}`}</td>
                                                            <td title={`${element.tax_percent ? element.tax_percent : 0}`}>{element.tax_percent ? element.tax_percent : 0 }%</td>
                                                            <td title={`${element.quantity}`}>{element.quantity}</td>
                                                            <td title={`${element.tax_amount? element.tax_amount: 0}`}>{element.tax_amount? element.tax_amount: 0}</td>
                                                            <td title={data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.mrp * element.quantity) + Math.round(element.tax_amount? element.tax_amount: 0)}` : `${coming_currency} ${(element.mrp * element.quantity)}`}>{data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.mrp * element.quantity) + Math.round(element.tax_amount? element.tax_amount: 0)}` : `${coming_currency} ${(element.mrp * element.quantity)}`}</td>
                                                            {/* <td title={data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.modifier_sale_price * element.quantity) + Math.round(element.tax_amount * element.quantity)}` : `${coming_currency} ${(element.modifier_sale_price * element.quantity)}`}>{data.orderDetail.tax_type == 1 ? `${coming_currency} ${(element.modifier_sale_price * element.quantity) + Math.round(element.tax_amount * element.quantity)}` : `${coming_currency} ${(element.modifier_sale_price * element.quantity)}`}</td> */}
                                                        </tr>
                                                    })
                                                }
                                            </>
                                        )
                                    })}
                                    <tr>
                                        <td style={{ border: 'none' }}></td>
                                        <td style={{ border: 'none' }}></td>
                                        <td colSpan={3} style={{ textAlign: 'left' }} className="total">Sub Total <b>المبلغ الاجمالي</b></td>
                                        <td colSpan={2} className="total" title={`${coming_currency} ${data.orderDetail && data.orderDetail.order_channel === 'call center' ? ( parseFloat(data.orderDetail.order_netprice) - parseFloat(data.orderDetail.order_netprice) * (parseFloat(data.orderDetail.tax_percent) / 100)).toFixed(2) : data && data.orderDetail && parseFloat(data.orderDetail.order_netprice).toFixed(2) }`}>{coming_currency} { data.orderDetail && data.orderDetail.order_channel === 'call center' ? ( (parseFloat(data.orderDetail.order_netprice) -parseFloat(data.orderDetail.tax_amount)).toFixed(2)) : data && data.orderDetail && parseFloat(data.orderDetail.order_netprice).toFixed(2) }</td>
                                        
                                    </tr>
                                    {data.orderDetail.coupon &&
                                        <tr>
                                            <td style={{ border: 'none' }}></td>
                                            <td style={{ border: 'none' }}></td>
                                            <td colSpan={3} style={{ textAlign: 'left' }} className="discount total">Discount</td>
                                            <td colSpan={2} className="discount total" title={`${coming_currency} ${Math.round(data.orderDetail.coupon_discount_value)}`}>{coming_currency} {Math.round(data.orderDetail.coupon_discount_value)} </td> {/* {data.Items.length > 0 && Math.round((this.calcSubTotalTotal(data.Items) * tax)/100)} PKR */}
                                        </tr>
                                    }
                                    {
                                        data.orderDetail.discount > 0 &&
                                        <tr>
                                            <td style={{ border: 'none' }}></td>
                                            <td style={{ border: 'none' }}></td>
                                            <td colSpan={3} style={{ textAlign: 'left' }} className="discount total">Discount  <b> الخصم</b></td>
                                            <td colSpan={2} className="discount total" title={`${coming_currency} ${Math.round(data.orderDetail.discount)}`}>{coming_currency} {Math.round(data.orderDetail.discount)} </td>
                                        </tr>
                                    }
                                    <tr>
                                        <td style={{ border: 'none' }}></td>
                                        <td style={{ border: 'none' }}></td>
                                        <td colSpan={3} style={{ textAlign: 'left' }} className="total">{data.orderDetail.tax_percent} % VAT <b> الضريبة</b></td>
                                        {/* <td colSpan={2} className="total" title={`${coming_currency} ${data.orderDetail.tax_amount}`}>{coming_currency} {data && data.orderDetail? data.orderDetail.tax_amount:0} </td>  */}
                                        <td colSpan={2} className="total" title={`${coming_currency} ${parseFloat(data.orderDetail.tax_amount).toFixed(2)}`}>{`${coming_currency} ${ data.orderDetail.tax_amount && data.orderDetail.order_channel === 'call center' ? parseFloat(data.orderDetail.tax_amount).toFixed(2) : parseFloat(data.orderDetail.tax_amount).toFixed(2) }`} </td>
                                        {/* {data.Items.length > 0 && Math.round((this.calcSubTotalTotal(data.Items) * tax)/100)} PKR */}
                                    </tr>
                                    {(data.orderDetail && data.orderDetail.delivery_status == "Delivery") && <tr>
                                        <td style={{ border: 'none' }}></td>
                                        <td style={{ border: 'none' }}></td>
                                        <td colSpan={3} style={{ textAlign: 'left' }} className="total">Delivery Charges <b> رسوم توصيل </b></td>
                                        {/* <td colSpan={2} className="total" title={`${coming_currency} ${data.orderDetail && data.orderDetail.delivery_fee}`}>{coming_currency} {data.orderDetail && data.orderDetail.delivery_fee? data.orderDetail.delivery_fee:0 }</td> */}
                                        <td colSpan={2} className="total" title={`${coming_currency} ${data.orderDetail && data.orderDetail.delivery_fee ? data.orderDetail.delivery_fee : '0.00' }`}>{coming_currency} {data.orderDetail && data.orderDetail.delivery_fee ? parseFloat(data.orderDetail.delivery_fee).toFixed(2) : '0.00' }</td>
                                    </tr>
                                    }
                                    {data.orderDetail.pos_service_fee > 0 &&
                                        <>
                                            <td style={{ border: 'none' }}></td>
                                            <td style={{ border: 'none' }}></td>
                                            <td colSpan={3} style={{ textAlign: 'left' }} className="total">Pos Service Fee</td>
                                            {/* <td colSpan={2} className="total" title={`${coming_currency} ${data.orderDetail.pos_service_fee}`}>{coming_currency} {data.orderDetail.pos_service_fee)}</td> */}
                                            <td colSpan={2} className="total" title={`${coming_currency} ${data.orderDetail.pos_service_fee}`}>{coming_currency} {parseInt(data.orderDetail.pos_service_fee).toFixed(2)}</td>
                                        </>
                                    }
                                    <tr>
                                        <td style={{ border: 'none' }}></td>
                                        <td style={{ border: 'none' }}></td>
                                        <td colSpan={3} style={{ textAlign: 'left' }} className="grand total"><b>Grand Total</b><b> المبلغ الإجمالي للطلب</b></td>
                                        <td colSpan={2} className="grand total">
                                            {/* <b>{`${coming_currency} ${data.orderDetail.order_grossprice }`}</b>  */}
                                            <b>{`${coming_currency} ${parseFloat(data.orderDetail.order_grossprice).toFixed(2) }`}</b> 
                                        </td>
                                    </tr>
                                </tbody>
                        </table>
                        {/* <p className="text-center mt-3">{decoded && decoded.brand && decoded.brand.brand_name}</p> */}
                        <p className="text-center mt-3">{data.orderDetail && data.orderDetail.brand_name}</p>
                            {/* <p className="text-center mt-3">NTN #<br /><b> الرقم الضريبي الوطني</b>  {data.orderDetail.ntn_number}</p> */}
                            {/* <p className="text-center mt-3">{decoded && decoded.brand && decoded.brand.website_link}</p> */}
                            <p className="text-center mt-3">{data.orderDetail.website_link == 'null' ? '' : data.orderDetail.website_link}</p>
                            <h6 className="text-center mt-3">{data.orderDetail.contact1}</h6>
                            <footer className="invoicefooter mb-3">
                                {/* "Thank you for Ordering {decoded && decoded.brand && decoded.brand.brand_name}" */}
                                "Thank you for Ordering {data.orderDetail && data.orderDetail.brand_name}"
                            </footer>
                        </main>
                    </div>
                </div>
               
            )
        }
    }
}
export default InvoiceAggregator;