import React, { Component } from 'react';
import { connect } from 'react-redux'
import { addNotification, brandsList, logoutUser } from '../../redux'
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import CheckChanges from '../../components/confirmOnLeave'
import { AddPushProps, AddPushState } from '../../interfaces/notifications';
import Select from 'react-select'

class AddNotification extends Component<AddPushProps, AddPushState> {
    constructor(props: any) {
        super(props);
        this.state = {
            title: "",
            description: "",
            expiryDate: "",
            error: "",
            brand_id:null,
            brand_name:""
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        this.props.brandsList()
        document.title = "SimplexCMS | Push Notifications"
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
        if (event.target.name == "description" && event.target.value.length > 150) {
            this.setState({
                error: "Description must be 150 characters"
            });
        } else {
            this.setState({
                error: ""
            });
        }
    }
    isNotificationReady = () => {
        const { title, description, expiryDate } = this.state
        return (title !== "" && (description !== "" && description.length <= 150) && expiryDate !== "");
    }
    handleSaveBtnClick = (event: any) => {
        let { title, description,brand_id, expiryDate } = this.state;
        let data = {
            title: title,
            body: description,
            expire_date: expiryDate,
            brand_id
        }
        this.props.addNotification(data);
    }
    handleBrandsInputChange = (e: any, index: any) => {
        this.setState({brand_id:e.value,brand_name:e.label});
    }
    render() {
        let roleId
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                } else {
                    roleId = decoded.role_id
                }
            });
        } else {
            return <Redirect to="/" />
        }
        return (
            <div className="page">
                <CheckChanges path="/add-promo" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Compaigns Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/marketing/1" className="text-primary">Compaigns</Link></li>
                                <li className="breadcrumb-item active">Add Push Notification</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Title <span className="text-danger">*</span></label>
                                                            <input id="title" type="text" name="title" required data-msg="Please enter Title" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Description <span className="text-danger">*</span></label>
                                                            <input id="description" type="text" name="description" required data-msg="Please enter Description" className="input-material" onChange={this.handleInputChange} />
                                                            <small className="form-text text-danger">{this.state.error}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    
                                                    {roleId == 1 && <div className="col">
                                                    <label className="form-control-label">Brand <span className="text-danger">*</span></label>
                                                        <Select
                                                            name="brand_id"
                                                            placeholder='Select Brand'
                                                            options={this.props.brands}
                                                            value={{label:this.state.brand_name,value:this.state.brand_id}}
                                                            className="text-capitalize select mt-2"
                                                            classNamePrefix="select"
                                                            onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                                                        />
                                                    </div>}
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Valid till <span className="text-danger">*</span></label>
                                                            <input id="expiryDate" type="date" name="expiryDate" required data-msg="Please enter Expire Date" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isNotificationReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        brands: state.brand.brands
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        addNotification: function (data: any) {
            dispatch(addNotification(data));
        },
        brandsList: function () {
            dispatch(brandsList())
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddNotification);