import React, { Component } from 'react';
import { AddModProps, AddModState } from '../../../interfaces/menu'
import { connect } from 'react-redux'
import { menusList, modGroupsList, addModifier, logoutUser, storesListForMultiSelect, brandsList } from '../../../redux'
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import Topbar from '../../../components/topbar';
import Sidebar from '../../../components/sidebar';
import Footer from '../../../components/footer/main';
import CheckChanges from '../../../components/confirmOnLeave'
import Select from 'react-select';
class AddMod extends Component<AddModProps, AddModState> {
    constructor(props: any) {
        super(props);
        this.state = {
            brand_id: null,
            menu: "",
            modGroupJson: [],
            modifiername: "",
            priority: "",
            modifiertype: "",
            modifiercost: "",
            modifiersale: "",
            images: null,
            is_lsm: '0',
            name_ar:"",
            max_quantity: "",
            min_quantity: "",
            storesJson: [],
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleModGroupsInputChange = this.handleModGroupsInputChange.bind(this);
        this.handleItemsByMenuId = this.handleItemsByMenuId.bind(this);
    }
    componentDidMount() {
        this.props.brandsList();
        document.title = "SimplexCMS | Modifiers"
    }
    handleBrand = (e: any, index: any) => {
        if (e && e.value > 0) {
            this.setState({ brand_id: e.value })
            this.props.storesList(e.value);
            this.props.menusList(e.value);
        }
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleItemsByMenuId(event: { target: { name: any; value: any; }; }) {
        this.props.modGroupsList(event.target.value);
        this.setState({ "menu": event.target.value, modGroupJson: [] })
    }
    handleModGroupsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ modGroupJson: e });
        }
    };
    handleStoresInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ storesJson: e });
        } else {
            this.setState({ storesJson: [] });
        }
    };
    fileSelectedHandler = (e: any) => {
        var size = 1048576;
        if (e.target.files[0].size > size) {
            alert("Image size too large. Upload image upto 1mb")
        }
        else {
            this.setState({ images: e.target.files })
        }
    }
    isModifierReady = () => {
        const { modifiername, modGroupJson, modifiercost, modifiersale, images, is_lsm, storesJson, menu, max_quantity, min_quantity } = this.state
        if (is_lsm == "0") {
            return (menu !== "" && modifiername !== "" && (modGroupJson && modGroupJson.length > 0) && modifiercost !== "" && modifiersale !== "" && (images && images.length > 0) && max_quantity !== "" && min_quantity !== "");
        } else if (is_lsm == "1") {
            return (menu !== "" && modifiername !== "" && (modGroupJson && modGroupJson.length > 0) && modifiercost !== "" && modifiersale !== "" && (images && images.length > 0) && storesJson.length > 0 && max_quantity !== "" && min_quantity !== "");
        }
    }
    handleChangeRad = (event: { target: { name: any; value: any; } }) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    handleSaveBtnClick = (event: any) => {
        let { menu, brand_id, modGroupJson,name_ar, priority, modifiername, modifiertype, modifiercost, modifiersale, is_lsm, storesJson, max_quantity, min_quantity } = this.state;
        const data: any = new FormData()
        if (this.state.images) {
            for (var x = 0; x < this.state.images.length; x++) {
                data.append('files', this.state.images[x])
            }
        }
        data.append('modifier_name', modifiername)
        data.append('modifier_type', modifiertype)
        data.append('modifier_cost_price', modifiercost)
        data.append('modifier_sale_price', modifiersale)
        data.append('priority', priority)
        data.append('menu_id', menu)
        data.append("name_ar",name_ar)
        data.append('brand_id', brand_id)
        data.append('max_quantity', max_quantity)
        data.append('min_quantity', min_quantity)
        data.append('mod_groups_json', JSON.stringify(modGroupJson))
        if (is_lsm == '0') {
            data.append('is_lsm', 0)
        } else if (is_lsm == '1') {
            data.append('is_lsm', 1)
            data.append('stores_json', JSON.stringify(storesJson))
        }
        this.props.addModifier(data);
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let tokendata: any = jwt.decode(sessionStorage.token)
        let roleId = tokendata.role_id;
        let brandId = tokendata.brand_id;
        const { modGroupJson, is_lsm, storesJson } = this.state;
        const { modgroups, menus } = this.props;
        return (
            <div className="page">
                <CheckChanges path="/add-modifier" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Modifier Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/modifiers" className="text-primary">Modifiers</Link></li>
                                <li className="breadcrumb-item active">Add Modifier</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Modifier name <span className="text-danger">*</span></label>
                                                            <input id="modifiername" type="text" name="modifiername" required data-msg="Please enter Modifier Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Arabic name <span className="text-danger">*</span></label>
                                                            <input id="name_ar" type="text" name="name_ar" required data-msg="Please enter Modifier Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Brand <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="brand"
                                                                isSearchable
                                                                options={roleId == 1 ? this.props.brands : this.props.brands.filter(o1 => brandId === o1.brand_id)}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleBrand(e, i)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Menu <span className="text-danger">*</span></label>
                                                            <select name="menu" className="form-control text-capitalize mt-2" required data-msg="Please select Menu" onChange={this.handleItemsByMenuId}>
                                                                <option value="">Select Menu</option>
                                                                {menus &&
                                                                    menus.map((menu, index) => (
                                                                        <option key={index} value={menu.menu_id}>{menu.menu_name} - {menu.store_type_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Modifier Group <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="groups"
                                                                value={modGroupJson}
                                                                options={modgroups}
                                                                // options={modgroups.filter(o1 => o1.menu_id == this.state.menu)}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleModGroupsInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-3 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Priority</label>
                                                            <input id="priority" type="text" name="priority" required data-msg="Please enter Priority" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Type</label>
                                                            <input id="modifiertype" type="text" name="modifiertype" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label mb-3">Modifier Image <span className="text-danger">*</span></label>
                                                            <input id="files" type="file" name="files" className="form-control-file" onChange={this.fileSelectedHandler} multiple />
                                                            <small className="form-text">You can also choose multiple images.</small>
                                                            <p><span>Dimensions 700x700</span> <span style={{ color: 'red' }}>recommended</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Cost Price <span className="text-danger">*</span></label>
                                                            <input id="modifiercost" type="number" name="modifiercost" required data-msg="Please enter Cost Price" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Sale Price <span className="text-danger">*</span></label>
                                                            <input id="modifiersale" type="number" name="modifiersale" required data-msg="Please enter Sale Price" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Min Quantity<span className="text-danger">*</span></label>
                                                            <input id="min_quantity" type="number" name="min_quantity" required data-msg="Please enter min" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Max Quantity<span className="text-danger">*</span></label>
                                                            <input id="max_quantity" type="number" name="max_quantity" required data-msg="Please enter max" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 col-sm-6 col-6 mt-5" >
                                                        <div className="i-checks">
                                                            <input id="radioCustom3" type="radio" name="is_lsm" value="0" checked={is_lsm == '0'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom3">Available for all stores</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-sm-6 col-6 mt-5" >
                                                        <div className="i-checks">
                                                            <input id="radioCustom4" type="radio" name="is_lsm" value="1" checked={is_lsm == '1'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom4">Available for specific stores</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {
                                                        is_lsm == '1' &&
                                                        <div className="col" >
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Store <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    defaultValue={storesJson}
                                                                    name="stores"
                                                                    options={this.props.stores}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isModifierReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        menus: state.menu.menus,
        groups: state.menu.groups,
        modgroups: state.menu.modgroups,
        stores: state.menu.storesoptions,
        brands: state.brand.brands
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        addModifier: function (data: any) {
            dispatch(addModifier(data));
        },
        menusList: function (brand_id: any) {
            dispatch(menusList(brand_id))
        },
        modGroupsList: function (menu_id: any) {
            dispatch(modGroupsList(menu_id))
        },
        storesList: (brand_id: any) => {
            dispatch(storesListForMultiSelect(brand_id))
        },
        brandsList: () => {
            dispatch(brandsList())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddMod);