import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Footer from '../../../components/footer/main'
import jwt from 'jsonwebtoken'
// import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import Topbar from '../../../components/topbar';
import Sidebar from '../../../components/sidebar';
import { brandsList, itemsListOfActiveMenu, logoutUser } from '../../../redux';
import { secretKey } from '../../../secret';
import { editBirthdayOffer, getBirthdayOffer, getLoyaltyTiers, getLoyaltyTiersForLoyalty, saveBirthdayOffer } from '../../../redux/actions/loyaltyAction';
import { EditBirthdayOffersProps, EditBirthdayOffersStates } from '../../../interfaces/birthdayOffers';
import CheckChanges from '../../../components/confirmOnLeave'
import Select from 'react-select';

class ActionFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    handleBlockUnblock = (id: any, is_active: any) => {
        this.props.data.blockunblockitem(id, is_active, this.props.row.item_name);
    };
    render() {
        const { row } = this.props
        return (
            <div>
                <button title={row.is_active === 0 ? "UnBlock" : "Block"} data-toggle="modal" data-target={`#blockunblock${row.menu_item_id}`} className={row.is_active === 0 ? "btn btn-outline-success mr-2" : "btn btn-outline-danger mr-2"}><i className={row.is_active === 0 ? "fa fa-unlock" : "fa fa-lock"}></i></button>
                {/* <button title="View Details" data-toggle="modal" data-target={`#itemdetails${row.menu_item_id}`} className="btn btn-outline-info"><i className="fa fa-list"></i></button> */}
                <Link title="Edit Item" className="btn btn-outline-primary ml-2" to={`/edit-loyalty-tier/${row.id}`}><i className="fa fa-edit"></i></Link>
               
                {/* <!-- Block/Unblock Modal--> */}
                <div id={`blockunblock${row.menu_item_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{row.is_active === 0 ? "UnBlock" : "Block"} Menu Item</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to {row.is_active === 0 ? "UnBlock" : "Block"} this item?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.handleBlockUnblock(row.menu_item_id, row.is_active)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        )
    }
}

function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} data={props} />
    );
}

class EditBirthdayOffers extends Component<EditBirthdayOffersProps,EditBirthdayOffersStates> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            offerName: "",
            loyaltyId: "",
            item_variant_id: [],
            multiJson: [],
            brand_id:null
        }
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.brandsList()
        this.props.getBirthdayOffer(id);
        // this.props.getLoyaltyTiers();
        this.props.itemsListOfActiveMenu();
        document.title = "SimplexCMS | Birthday Offers"
    }

    componentDidUpdate(nextProps: any) {
        if (this.props.birthdayOfferData !== nextProps.birthdayOfferData) {  //this condition is used
            if(this.state.brand_id == null) {
                let itemsJson = this.props.birthdayOfferData.birthday_offer_item_id;
                console.log("looop  ",this.props.birthdayOfferData)
                this.setState({
                    offerName: this.props.birthdayOfferData.birthday_offer_name,
                    loyaltyId: this.props.birthdayOfferData.loyalty_id.id,
                    brand_id:this.props.birthdayOfferData.brand_id && this.props.birthdayOfferData.brand_id.brand_id
                })
                if(itemsJson.length>0) {
                    console.log("looop  ")
                    let newMultiJson = [];
                    for(let i=0; i<itemsJson.length; i++) {
                        let obj = {
                            label: itemsJson[i].item_variant_id.size+" (undefined)",
                            menu_item_id: itemsJson[i].item_variant_id.id,
                            value: "item_"+itemsJson[i].item_variant_id.id
                        }
                        newMultiJson.push(obj);
                    }
                    this.setState({
                        multiJson: newMultiJson
                    })
                }
            }
        
    }
    }

    handleInputChange = (event: { target: { name: any; value: any; }; }) => {
        if(event.target.name == 'brand_id') {
            this.setState({
                [event.target.name]: event.target.value
            });

            this.props.getLoyaltyTiers(event.target.value);
        } else {
            this.setState({
                [event.target.name]: event.target.value
            });
        }
        
    }

    handleItemsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ multiJson: e });
        }
    }

    handleSaveBtnClick = (event: any) => {
        let { offerName,loyaltyId,multiJson,brand_id } = this.state;
        let id = this.props.match.params.id;
        const newArr = multiJson.map((item: any) => {
            const { menu_item_id: item_variant_id,
                menu_id: menu_id,
                 ...rest
                 } = item;
            return { item_variant_id,menu_id, ...rest }
        });
        let obj:any = {
            birthday_offer_name: offerName,
            loyalty_id: loyaltyId,
            item_variants_json: multiJson,
            brand_id:brand_id
        }
        // addLoyalty Tier data below
        this.props.editBirthdayOffer(id,obj);
        // addLoyalty Tier data above
    }

    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let { loyaltyTiers,items,birthdayOfferData } = this.props;
        let { multiJson } = this.state;

        let msg;
        let messcolor;
        const { brands } = this.props;
        return (
            <div className="page">
                <CheckChanges path="/add-birthday-offer" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Loyalty Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/birthday-offers" className="text-primary">Birthday Offers</Link></li>
                                <li className="breadcrumb-item active">Add Birthday Offer</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>
                                                <div className="row">
                                                    <div className="col-lg-12 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Birthday Offer Name <span className="text-danger">*</span></label>
                                                            <input id="offerName" type="text" value={this.state.offerName} name="offerName" required data-msg="Please Enter Loyalty Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4 col-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Items <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    name="items"
                                                                    defaultValue={multiJson}
                                                                    options={items}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleItemsInputChange(e, i)}
                                                                />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4 col-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">
                                                                Brand <span className="text-danger">*</span>
                                                            </label>
                                                            <select
                                                                name="brand_id"
                                                                className="form-control text-capitalize mt-2"
                                                                required
                                                                data-msg="Please select brand"
                                                                onChange={this.handleInputChange}
                                                            >
                                                                <option value="">Select Brand</option>
                                                                {brands &&
                                                                brands.map(
                                                                    (brand:any, index:any) =>
                                                                    brand.is_active == 1 && (
                                                                        <option key={index} value={brand.brand_id} {...birthdayOfferData.brand_id && birthdayOfferData.brand_id.brand_id === brand.brand_id && { selected: true }}>
                                                                        {brand.brand_name}
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>
                                                        </div>
                                                     </div>

                                                    <div className="col-lg-4 col-4">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Loyalty Tier</label>
                                                                <select id="MinOrder" name="loyaltyId" onChange={this.handleInputChange} className="form-control text-capitalize mt-2" required data-msg="Please select Menu">
                                                                    <option >Select Loyalty Level</option>
                                                                    {loyaltyTiers &&
                                                                            loyaltyTiers.map((tier: any, index: any) => (
                                                                                <option key={index} value={this.state.loyaltyId} {...this.state.loyaltyId == tier.id && { selected: true }}>{tier.loyalty_name}</option>
                                                                            ))
                                                                        }
                                                                </select>
                                                                {/* <input id="LoyaltyLevel" type="text" name="LoyaltyLevel" className="input-material" onChange={this.handleInputChange} /> */}
                                                            </div>
                                                        </div>
                                                </div>

                                                
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        loyaltyTiers: state.loyalty.loyaltyTiers,
        items: state.menu.allItemsOfActiveMenu,
        birthdayOfferData: state.loyalty.birthdayOfferData,
        brands: state.brand.brands,
        // groups: state.menu.groups
    }
    }
    const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        getLoyaltyTiers: function (brand_id:number) {
            dispatch(getLoyaltyTiersForLoyalty(brand_id));
        },
        getBirthdayOffer: (id:any) => {
            dispatch(getBirthdayOffer(id))
        },
        itemsListOfActiveMenu: function () {
            dispatch(itemsListOfActiveMenu())
        },
        saveBirthdayOffer: (obj: any) => {
            dispatch(saveBirthdayOffer(obj))
        },
        editBirthdayOffer: function (id: any, data: any) {
            dispatch(editBirthdayOffer(id, data));
        },
        brandsList: function () {
            dispatch(brandsList());
        },
        
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditBirthdayOffers);