import { NOTIFICATIONS_LIST, GET_NOTIFICATION } from './notificationType'
import Api from '../../components/Api';
import jwt from 'jsonwebtoken'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AUDIENCE, AUDIENCE_LIST, CAMPAIGN_LIST, FILTER_DATA, LOADING_STATE, SAVE_CAMPAIGN_DATA } from './marketingType';
toast.configure();

export const customerFilteration = (data: any) => {
    return function (dispatch: any) {
        dispatch({
            type: LOADING_STATE,
            payload: true
        })
        let token: any = sessionStorage.getItem('token');
        Api.post('/crm/customer_filteration', data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: LOADING_STATE,
                        payload: false
                    })
                    console.log(response.data.success);
                    let responseData = response.data.successResponse;
                    dispatch({
                        type: FILTER_DATA,
                        payload: response.data.successResponse
                    })
                    let customerListArr: any = []
                    if (responseData.length > 0) {
                        responseData.map((record: any) => {
                            let obj = {
                                customer_id: record.customer_id
                            }
                            customerListArr.push(obj)
                        })
                    }
                    dispatch({
                        type: AUDIENCE_LIST,
                        payload: customerListArr
                    })
                    console.log("customer list array to be sent on server", customerListArr)
                }
            }).catch(err => {
                dispatch({
                    type: LOADING_STATE,
                    payload: false
                })
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const createAudienceList = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/crm/create_audience', data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log(response.data.success);
                    let responseData = response.data.successResponse;
                    toast.success(responseData, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
                    window.location.href = ''
                }
            }).catch(err => {
                if (err.response) {
                    toast.error(err.response.data.message)
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}


export const getAudience = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/crm/get_audience', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log(response.data.success);
                    let responseData = response.data.successResponse;
                    dispatch({
                        type: AUDIENCE,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    toast.error(err.response.data.message)
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const getAudienceForAudience = (brand_id:number) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/crm/get_audience/'+brand_id, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log(response.data.success);
                    let responseData = response.data.successResponse;
                    dispatch({
                        type: AUDIENCE,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    toast.error(err.response.data.message)
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}



export const deleteAudience = (id:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/crm/delete_audience/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log(response.data.success);
                    let responseData = response.data.successResponse;
                    window.location.reload();
                }
            }).catch(err => {
                if (err.response) {
                    toast.error(err.response.data.message)
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const addCampaign = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/crm/add_campaign',data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log(response.data.success);
                    let responseData = response.data.successResponse;
                    toast.success(responseData, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
                    if(data.email_type === 'sms'){
                    window.location.href = "/sms-campaign"
                    }
                    else {
                    window.location.href = "/email-campaign"
                    }
                }
            }).catch(err => {
                if (err.response) {
                    toast.error(err.response.data.message)
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const editCampaign = (id:any ,data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/crm/edit_campaign/${id}`,data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log(response.data.success);
                    let responseData = response.data.successResponse;
                    toast.success(responseData, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
                    if(data.email_type === 'sms'){
                    window.location.href = "/sms-campaign"
                    }
                    else {
                    window.location.href = "/email-campaign"
                    }
                }
            }).catch(err => {
                if (err.response) {
                    toast.error(err.response.data.message)
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const getSmsCampaignById = (id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/crm/get_sms_campaign/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log(response.data.successResponse);
                    let responseData = response.data.successResponse;
                    toast.success(responseData, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
                    dispatch({
                        type: SAVE_CAMPAIGN_DATA,
                        payload: response.data.successResponse
                    })
                    Api.get('/crm/get_audience/'+response.data.successResponse.brand_id.brand_id, {
                        headers: { 'Authorization': 'Bearer ' + token }
                    })
                        .then((response) => {
                            if (response.data.success) {
                                console.log(response.data.success);
                                let responseData = response.data.successResponse;
                                dispatch({
                                    type: AUDIENCE,
                                    payload: response.data.successResponse
                                })
                            }
                        }).catch(err => {
                            if (err.response) {
                                toast.error(err.response.data.message)
                                console.log(err.response.data.message)
                            } else {
                                alert(err.message)
                            }
                        });
                }
            }).catch(err => {
                if (err.response) {
                    toast.error(err.response.data.message)
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getEmailCampaignById = (id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/crm/get_email_campaign/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log(response.data.successResponse);
                    let responseData = response.data.successResponse;
                    toast.success(responseData, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
                    dispatch({
                        type: SAVE_CAMPAIGN_DATA,
                        payload: response.data.successResponse
                    })
                    Api.get('/crm/get_audience/'+response.data.successResponse.brand_id.brand_id, {
                        headers: { 'Authorization': 'Bearer ' + token }
                    })
                        .then((response) => {
                            if (response.data.success) {
                                console.log(response.data.success);
                                let responseData = response.data.successResponse;
                                dispatch({
                                    type: AUDIENCE,
                                    payload: response.data.successResponse
                                })
                            }
                        }).catch(err => {
                            if (err.response) {
                                toast.error(err.response.data.message)
                                console.log(err.response.data.message)
                            } else {
                                alert(err.message)
                            }
                        });
                }
            }).catch(err => {
                if (err.response) {
                    toast.error(err.response.data.message)
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getCampaigns = (obj:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/crm/get_campaign',obj, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log(response.data.success);
                    let responseData = response.data.successResponse;
                    dispatch({
                        type: CAMPAIGN_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    toast.error(err.response.data.message)
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const deleteCampaign = (id:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/crm/delete_campaign/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log(response.data.success);
                    // let responseData = response.data.successResponse;
                    window.location.reload();
                }
            }).catch(err => {
                if (err.response) {
                    toast.error(err.response.data.message)
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const testEmail = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/crm/test_email_template',data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log(response.data.success);
                    let responseData = response.data.successResponse;
                    toast.success(responseData, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
                }
            }).catch(err => {
                if (err.response) {
                    toast.error(err.response.data.message)
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}