import React, { Component } from 'react';
import { EditComboProps, EditComboState } from '../../../interfaces/menu'
import { connect } from 'react-redux'
import { editCombo, getCombo, menusList, groupedItemsList, channelList, getTaxValue, logoutUser, statesList, groupsList, storesListForMultiSelect, brandsList } from '../../../redux'
import Footer from '../../../components/footer/main';
import jwt from 'jsonwebtoken'
import Sidebar from '../../../components/sidebar';
import Topbar from '../../../components/topbar';
import { Link, Redirect } from 'react-router-dom';
import Select from "react-select";
import { secretKey } from '../../../secret';
import CheckChanges from '../../../components/confirmOnLeave'
import moment from 'moment';
import { comboChoiceItemsByGroupId } from '../../../redux/actions/menuAction';
class EditCombo extends Component<EditComboProps, EditComboState> {
    orderModes: { value: string; label: string; }[];
    weeklyDays: { value: string; label: string; }[];
    constructor(props: any) {
        super(props);
        this.orderModes = [
            { value: 'online', label: 'Online' },
            { value: 'callcenter', label: 'Call Center' }
        ];

        this.weeklyDays = [
            { value: 'MONDAY', label: 'Mon' },
            { value: 'TUESDAY', label: 'Tue' },
            { value: 'WEDNESDAY', label: 'Wed' },
            { value: 'THURSDAY', label: 'Thu' },
            { value: 'FRIDAY', label: 'Fri' },
            { value: 'SATURDAY', label: 'Sat' },
            { value: 'SUNDAY', label: 'Sun' },
        ];
        this.state = {
            brand_id: null,
            brand_name: "",
            menu: "",
            group: "",
            state: "",
            comboname: "",
            erpid: "",
            poscode: "",
            channel: "",
            priority: "",
            combodesc: "",
            combocost: "",
            combosale: "",
            combomrp: "",
            images: null,
            hero_image: null,
            hero_mobile_image: null,
            taxstatus: false,
            settime: 'alltime',
            mode: [],
            combostart: "",
            comboclose: "",
            errtaxdef: false,
            tax_percent: "",
            hero_item: false,
            topDeal: false,
            comboUnique: "",
            is_lsm: '0',
            storesJson: [],
            metaTitle: "",
            metaDesc: "",
            alt_text: "",
            combojson: [
                {
                    id: "",
                    itemName: "",
                    poscode: "",
                    choice_group_name: "",
                    groupname: "",
                    groupposcode: "",
                    size: "",
                    quantity: "",
                    limit: "",
                    itemsize: []
                }
            ],
            comboChoices: [
                {
                    group_id: "",
                    group_erp_id: "",
                    group_name: "",
                    menu_item_id: "",
                    choicesItemOptions: [],
                    size: "",
                    itemsize: []
                }
            ],
            specific_days: false,
            daysTiming: [
                {
                    day: "",
                    label: "",
                    timePeriods: [{
                        open: "",
                        close: ""
                    }]
                }
            ]
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleItemsByMenuId = this.handleItemsByMenuId.bind(this);
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.brandsList();
        this.props.getCombo(id);
        this.props.channelList();
        document.title = "SimplexCMS | Combos"
    }
    handleBrand = (e: any, index: any) => {
        if (e && e.value > 0) {
            this.setState({ topDeal: false, brand_id: e.value, brand_name: e.label, menu: "", group: "" });
            this.props.groupsList(e.value);
            this.props.storesList(e.value);
            this.props.menusList(e.value);
        }
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        // let { menu } = this.state;
        if (event.target.value == ' ') {
            event.target.value = event.target.value.replace(/\s/g, "");
        }
        this.setState({
            [event.target.name]: event.target.value,
        });
    }
    handleItemsByMenuId(event: { target: { name: any; value: any; }; }) {
        let select: any = document.getElementById('menu');
        if (this.state.menu !== "") {
            if (window.confirm("You cannot change the menu. If you clicks OK, then menu items corresponding to present combo will lost.")) {
                this.setState({
                    combojson: [
                        {
                            id: "",
                            itemName: "",
                            poscode: "",
                            choice_group_name: "",
                            groupname: "",
                            groupposcode: "",
                            size: "",
                            quantity: "",
                            limit: "",
                            itemsize: []
                        }
                    ],
                    menu: event.target.value,
                    combocost: "",
                    combomrp: "",
                })
                this.props.groupedItemsList(event.target.value);
            } else {
                select.value = this.state.menu
            }
        } else {
            this.setState({
                "menu": event.target.value,
            });
            this.props.groupedItemsList(event.target.value);
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        if (this.props.comboData.menu_id !== nextProps.comboData.menu_id) {  //this condition is used
            //to call statements only first time
            this.props.groupedItemsList(nextProps.comboData.menu_id.menu_id);
        }
        if (this.state.brand_id == null) {
            this.setState({
                comboname: nextProps.comboData.combo_name,
                combodesc: nextProps.comboData.combo_description,
                brand_id: nextProps.comboData.menu_id && nextProps.comboData.menu_id.brand_id.brand_id,
                brand_name: nextProps.comboData.menu_id && nextProps.comboData.menu_id.brand_id.brand_name,
                menu: nextProps.comboData.menu_id && nextProps.comboData.menu_id.menu_id,
                group: nextProps.comboData.group_id && nextProps.comboData.group_id.group_id,
                combocost: nextProps.comboData.combo_cost_price,
                comboUnique: nextProps.comboData.comboUnique,
                combomrp: nextProps.comboData.combo_mrp_price,
                combojson: nextProps.combojson,
                comboChoices: nextProps.comboChoices,
                mode: nextProps.comboData.item_mode ? JSON.parse(nextProps.comboData.item_mode) : [],
                erpid: nextProps.comboData.erp_id,
                poscode: nextProps.comboData.pos_code,
                priority: nextProps.comboData.priority,
                channel: nextProps.comboData.combo_channel_id && nextProps.comboData.combo_channel_id.sales_channel_id,
                hero_item: nextProps.comboData.hero_item == 1 ? true : false,
                topDeal: nextProps.comboData.topDeal == 1 ? true : false,
                storesJson: nextProps.comboData.stores_json ? JSON.parse(nextProps.comboData.stores_json) : this.state.storesJson,
                is_lsm: nextProps.comboData.is_lsm,
                metaTitle: nextProps.comboData.meta_title ? nextProps.comboData.meta_title : "",
                metaDesc: nextProps.comboData.meta_description ? nextProps.comboData.meta_description : "",
                alt_text: nextProps.comboData.alt_text ? nextProps.comboData.alt_text : "",
                daysTiming: nextProps.comboData.serving_hours ? JSON.parse(nextProps.comboData.serving_hours) : this.state.daysTiming,
                specific_days: nextProps.comboData.serving_hours ? true : false
            })
        }
        const list: any[] = this.state.comboChoices;
        if (nextProps.choiceIndex !== "" && nextProps.comboChoiceItems) {
            if (nextProps.comboChoiceItems.length > 0) {
                //comboChoices update after select the group
                let index = nextProps.choiceIndex;
                list[index]["choicesItemOptions"] = nextProps.comboChoiceItems;
                this.setState({ comboChoices: list });
            } else {
                //comboChoices update after select the group
                let index = nextProps.choiceIndex;
                list[index]["choicesItemOptions"] = [];
                list[index]["itemsize"] = [];
                this.setState({ comboChoices: list });
            }
        }
        if (nextProps.comboData.combo_start_time !== null) {
            this.setState({
                "settime": "customtime",
                combostart: moment(nextProps.comboData.combo_start_time).local().format('YYYY-MM-DDTHH:mm'),
                comboclose: moment(nextProps.comboData.combo_close_time).local().format('YYYY-MM-DDTHH:mm'),
            })
        } else {
            this.setState({
                "settime": "alltime",
                combostart: "",
                comboclose: ""
            })
        }
    }
    handleItemsInputChange = (e: any, index: any) => {
        if (e.target) {
            const { name, value } = e.target;
            const list: any = this.state.combojson;
            list[index][name] = value;
            this.setState({ combojson: list });
        } else {
            const list: any = this.state.combojson;
            list[index]["id"] = e.id;
            list[index]["itemName"] = e.value;
            list[index]["poscode"] = e.pos_code;
            list[index]["groupname"] = e.group_name;
            list[index]["groupposcode"] = e.group_poscode;
            list[index]["quantity"] = 1;
            list[index]["limit"] = "0";
            list[index]["itemsize"] = JSON.parse(e.item_size);
            this.setState({ combojson: list });
        }
    };
    // handle click event of the Remove button
    handleRemoveClick = (e: any, index: any) => {
        const list = this.state.combojson;
        list.splice(index, 1);
        this.setState({ combojson: list });
        e.preventDefault()
    };
    // handle click event of the Add button
    handleAddClick = (event: any, i: any) => {
        const list: any = this.state.combojson;
        if (list[i]["choice_group_name"] == "" || list[i]["itemName"] == "" || list[i]["size"] == "" || list[i]["quantity"] == "" || list[i]["limit"] == "") {
            alert("Please fill in current item")
        } else if (i == '9') {
            alert("Sorry you can select only 10 items")
        } else {
            const newlist: any = { id: "", itemName: "", poscode: "", choice_group_name: "", size: "", quantity: "", limit: "", itemsize: [] }
            const combo: any = this.state.combojson.concat(newlist)
            this.setState({ combojson: combo });
        }
    };
    blockInvalidChar = (e: any) =>
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    blockSpecialCharacters = () => {
        var checkString = this.state.comboname;
        if (checkString != "") {
            if (/[%]/.test(checkString)) {
                alert("Your combo name has '%' sign. \nIt is not allowed.\nPlease remove it and try again.");
                return (false);
            } else {
                return true
            }
        }
    }
    fileSelectedHandler = (e: any) => {
        var size = 1048576;
        if (e.target.files[0].size > size) {
            alert("Image size too large. Upload image upto 1mb")
        }
        else {
            this.setState({ images: e.target.files })
        }
    }
    heroItemImageSelectedHandler = (e: any) => {
        var size = 1048576;
        if (e.target.files[0].size > size) {
            alert("Image size too large. Upload image upto 1mb")
        }
        else {
            this.setState({ hero_image: e.target.files })
        }
    }
    heroItemMobileImageSelectedHandler = (e: any) => {
        var size = 1048576;
        if (e.target.files[0].size > size) {
            alert("Image size too large. Upload image upto 1mb")
        }
        else {
            this.setState({ hero_mobile_image: e.target.files })
        }
    }
    handleHeroItem = (event: { target: { name: any; value: any; }; }) => {
        const { hero_item } = this.state;
        this.setState({ hero_item: !hero_item })
    }
    handleTopDeal = (event: { target: { name: any; value: any; }; }) => {
        const { topDeal } = this.state;
        const { comboData } = this.props;
        if (comboData.topDealsCount == "4") {
            if (topDeal == false) {
                alert("You have already 4 Top Deals!")
            } else {
                this.setState({ topDeal: !topDeal })
            }
        } else {
            this.setState({ topDeal: !topDeal })
        }
    }
    handleChangeRad = (event: { target: { name: any; value: any; } }) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    isComboReady = () => {
        const { comboname, menu, combocost, combomrp, settime, mode, combostart, comboclose, is_lsm, storesJson } = this.state
        if (settime == 'customtime') {
            if (is_lsm == "0") {
                return (comboname !== "" && menu !== "" && combocost !== "" && combomrp !== "" && combostart !== "" && comboclose !== "" && mode.length > 0);
            } else if (is_lsm == "1") {
                return (comboname !== "" && menu !== "" && combocost !== "" && combomrp !== "" && combostart !== "" && comboclose !== "" && storesJson.length > 0 && mode.length > 0);
            }
        } else {
            if (is_lsm == "0") {
                return (comboname !== "" && menu !== "" && combocost !== "" && combomrp !== "" && mode.length > 0);
            } else if (is_lsm == "1") {
                return (comboname !== "" && menu !== "" && combocost !== "" && combomrp !== "" && storesJson.length > 0 && mode.length > 0);
            }
        }
    }
    handleOrderModesInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ mode: e });
        } else {
            this.setState({ mode: [] });
        }
    };
    handleStoresInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ storesJson: e });
        } else {
            this.setState({ storesJson: [] });
        }
    };
    handleComboChoiceInputChange = (e: any, index: any) => {
        if (e.target) {
            const { name, value } = e.target;
            if (name == "group_id") {
                this.props.comboChoiceItemsByGroupId(value, index)
            }
            const list: any = this.state.comboChoices;
            // if (name == "size") {
            //     let size = list[index]["itemsize"].find((obj: any) => {
            //         return value == obj.id;
            //     })
            //     if (size) {
            //         list[index][name] = JSON.stringify(size);
            //     } else {
            //         list[index][name] = "";
            //     }
            // } else {
            //     list[index][name] = value;
            // }
            list[index][name] = value;
            this.setState({ comboChoices: list });
        } else {
            if (e !== "") {
                const list: any = this.state.comboChoices;
                list[index]["menu_item_id"] = e.value;
                list[index]["itemsize"] = JSON.parse(e.item_size);
                list[index]["size"] = "";
                this.setState({ comboChoices: list });
            } else {
                const list: any = this.state.comboChoices;
                list[index]["menu_item_id"] = "";
                list[index]["itemsize"] = [];
                list[index]["size"] = "";
                this.setState({ comboChoices: list });
            }
        }
    };
    handleAddComboChoice = (i: any) => {
        const list: any = this.state.comboChoices;
        if (list[i]["group_erp_id"] == "" || list[i]["group_id"] == "" || list[i]["group_name"] == "" || list[i]["menu_item_id"] == "" || list[i]["size"] == "") {
            alert("Please fill in current item")
        } else {
            const newlist: any = {
                group_id: "",
                group_erp_id: "",
                group_name: "",
                menu_item_id: "",
                choicesItemOptions: [],
                size: "",
                itemsize: []
            }
            const comboChoice: any = this.state.comboChoices.concat(newlist)
            this.setState({ comboChoices: comboChoice });
        }
    };
    handleRemoveComboChoice = (index: any) => {
        const list = this.state.comboChoices;
        list.splice(index, 1);
        this.setState({ comboChoices: list });
    };

    handleWeeklyDaysInputChange = (e: any, index: any, pindex?: any) => {
        if (e.target) {
            const { name, value } = e.target;
            const list: any = this.state.daysTiming;
            let timeP = list[index].timePeriods;
            timeP[pindex][name] = value;
            list[index].timePeriods = timeP;
            this.setState({ daysTiming: list });
        } else {
            console.log("e", e)
            const list: any = this.state.daysTiming;
            list[index]["day"] = e.value;
            list[index]["label"] = e.label;
            this.setState({ daysTiming: list });
        }
    };
    // handle click event of the Remove days button
    handleWeeklyDaysRemoveClick = (index: any, pindex?: any) => {
        const list = this.state.daysTiming;
        if (pindex >= 0) {
            let timePer = list[index].timePeriods;
            timePer.splice(pindex, 1);
            list[index].timePeriods = timePer;
            this.setState({ daysTiming: list });
        } else {
            list.splice(index, 1);
            this.setState({ daysTiming: list });
        }
    };
    // handle click event of the Add button
    handleweeklyDaysAddClick = (e: any, i: any, pindex?: any) => {
        const list: any = this.state.daysTiming;
        if (pindex >= 0) {
            if (list[i].timePeriods[list[i].timePeriods.length - 1].open == "" || list[i].timePeriods[list[i].timePeriods.length - 1].close == "") {
                alert("Please fill in selected day hours")
            } else {
                let timePeriods = {
                    open: "",
                    close: ""
                }
                list[i].timePeriods.push(timePeriods)
                this.setState({ daysTiming: list });
            }
        } else {
            if (list[i]["day"] == "" || list[i].timePeriods[list[i].timePeriods.length - 1].open == "" || list[i].timePeriods[list[i].timePeriods.length - 1].close == "") {
                alert("Please fill in selected day timing")
            } else {
                let timePeriods = {
                    open: "",
                    close: ""
                }
                const newlist: any = {
                    day: "",
                    timePeriods: []
                }
                newlist.timePeriods.push(timePeriods)
                const days: any = this.state.daysTiming.concat(newlist)
                this.setState({ daysTiming: days });
            }
        }
        e.preventDefault()
    };
    handleSpecificDaysCheck = (event: { target: { name: any; value: any; }; }) => {
        const { specific_days } = this.state;
        this.setState({ specific_days: !specific_days })
    }
    handleSaveBtnClick = (event: any) => {
        let { comboname, erpid, poscode, channel, priority, comboUnique, hero_image, mode, combodesc, combocost, group, combomrp, hero_item, hero_mobile_image, topDeal, comboChoices, is_lsm, storesJson, settime, combostart, comboclose, combojson, menu, metaTitle, metaDesc, alt_text, daysTiming, specific_days } = this.state;
        let id = this.props.match.params.id;
        const data: any = new FormData()
        const Days = JSON.stringify(daysTiming);
        let comboNamevalid = this.blockSpecialCharacters();
        if (comboNamevalid) {
            if (this.state.images) {
                for (var x = 0; x < this.state.images.length; x++) {
                    data.append('files', this.state.images[x])
                }
            }
            if (hero_image) {
                var file = hero_image[0];
                var newFileName = file.name.split(".")[0] + "_hero_image." + file.name.split(".")[1];
                data.append('files', file, newFileName);
            }
            if (hero_mobile_image) {
                var file = hero_mobile_image[0];
                var newFileName = file.name.split(".")[0] + "_hero_mobile_image." + file.name.split(".")[1];
                data.append('files', file, newFileName);
            }
            data.append('combo_name', comboname)
            if (erpid !== null && erpid !== "") {
                data.append('erp_id', erpid)
            } else {
                data.append('erp_id', null)
            }
            if (poscode !== null && poscode !== "") {
                data.append('pos_code', poscode)
            } else {
                data.append('pos_code', null)
            }
            if (hero_item === true) {
                data.append('hero_item', 1)
            } else {
                data.append('hero_item', 0)
            }
            if (topDeal === true) {
                data.append('topDeal', 1)
            } else {
                data.append('topDeal', 0)
            }
            if (is_lsm == '0') {
                data.append('is_lsm', 0)
            } else if (is_lsm == '1') {
                data.append('is_lsm', 1)
                data.append('stores_json', JSON.stringify(storesJson))
            }
            data.append('combo_description', combodesc)
            data.append('combo_channel_id', channel)
            data.append('combo_cost_price', combocost)
            // data.append('combo_sales_price', combosale)
            data.append('combo_mrp_price', combomrp)
            // data.append('comboUnique', comboUnique)
            data.append('item_mode', JSON.stringify(mode))
            data.append('priority', priority)
            // data.append('combo_tax_configuration', taxstatus == false ? '0' : '1')
            data.append('menu_id', menu)
            data.append('group_id', group)
            data.append('meta_title', metaTitle)
            data.append('meta_description', metaDesc)
            data.append('alt_text', alt_text)
            if (settime === 'customtime') {
                data.append('combo_start_time', moment(combostart).utc(false))
                data.append('combo_close_time', moment(comboclose).utc(false))
            } else if (settime === 'alltime') {
                data.append('combo_start_time', null)
                data.append('combo_close_time', null)
            }
            // if (!combojson[1]) {
            //     alert("Please select at least 2 items")
            // } else 
            if (combojson[combojson.length - 1]["choice_group_name"] !== "" && combojson[combojson.length - 1]["itemName"] !== "" && combojson[combojson.length - 1]["size"] !== "" && combojson[combojson.length - 1]["quantity"] !== "" && combojson[combojson.length - 1]["limit"] !== "") {
                // data.append('combo_ingredients', JSON.stringify(combojson))
                // data.append('combo_choices', JSON.stringify(comboChoices))
                if (specific_days) {
                    if (daysTiming[daysTiming.length - 1]["day"] !== "" && daysTiming[daysTiming.length - 1].timePeriods[daysTiming[daysTiming.length - 1].timePeriods.length - 1].open !== "" && daysTiming[daysTiming.length - 1].timePeriods[daysTiming[daysTiming.length - 1].timePeriods.length - 1].close !== "") {
                        data.append('serving_hours', Days)
                        data.append('combo_ingredients', JSON.stringify(combojson))
                        comboChoices.forEach((obj) => {
                            delete obj.choicesItemOptions;
                            delete obj.itemsize;
                        })
                        data.append('combo_choices', JSON.stringify(comboChoices))
                        this.props.editCombo(id, data);
                    } else {
                        alert("Please fill in selected day timing")
                    }
                } else {
                    data.append('serving_hours', null)
                    data.append('combo_ingredients', JSON.stringify(combojson))
                    comboChoices.forEach((obj) => {
                        delete obj.choicesItemOptions;
                        delete obj.itemsize;
                    })
                    data.append('combo_choices', JSON.stringify(comboChoices))
                    this.props.editCombo(id, data);
                }
                // this.props.editCombo(id, data);
            } else {
                alert("Please fill in all fields of combo items")
            }
        }
    }
    IsJsonString = (str: any) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let tokendata: any = jwt.decode(sessionStorage.token)
        let roleId = tokendata.role_id;
        let brandId = tokendata.brand_id;
        const { menus, groups, channels, combooptions, comboData } = this.props;
        const { combojson, settime, priority, combomrp, combostart, topDeal, hero_item, mode, comboclose, combocost, comboChoices, combodesc, is_lsm, storesJson, specific_days, daysTiming } = this.state;
        const groupedOptions: any = [];
        let obj: any = ""
        for (const property in combooptions) {
            obj = {
                label: property,
                options: combooptions[property]
            }
            groupedOptions.push(obj)
        }
        let groupsByMenuID: any[] = groups.filter((obj: any) => {
            return obj.menu_id == this.state.menu;
        })
        const formatGroupLabel = (data: any) => (
            <div className="d-flex justify-content-between">
                <span>{data.label}</span>
                <span className="badge badge-secondary p-1">{data.options.length}</span>
            </div>
        );
        return (
            <div className="page">
                <CheckChanges path="/edit-combo" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Combo Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/combos" className="text-primary">Combos</Link></li>
                                <li className="breadcrumb-item active">Edit Combo</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Combo Name <span className="text-danger">*</span></label>
                                                            <input id="comboname" type="text" name="comboname" defaultValue={comboData.combo_name} required data-msg="Please enter Combo Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Brand <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="brand"
                                                                isSearchable
                                                                value={{ label: this.state.brand_name, value: this.state.brand_id }}
                                                                options={roleId == 1 ? this.props.brands : this.props.brands.filter(o1 => brandId === o1.brand_id)}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleBrand(e, i)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Meta title </label>
                                                            <input id="metaTitle" type="text" name="metaTitle" value={this.state.metaTitle} required data-msg="Please enter Item Type" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Meta description</label>
                                                            <input id="metaDesc" type="text" name="metaDesc" value={this.state.metaDesc} required data-msg="Please enter Item Type" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Menu <span className="text-danger">*</span></label>
                                                            <select id="menu" name="menu" className="form-control text-capitalize mt-2" required data-msg="Please select Menu" onChange={this.handleItemsByMenuId}>
                                                                <option value="">Select Menu</option>
                                                                {menus &&
                                                                    menus.map((menu, index) => (
                                                                        <option key={index} value={menu.menu_id} {...(comboData.menu_id && comboData.menu_id.menu_id === menu.menu_id) && { selected: true }}>{menu.menu_name} - {menu.store_type_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Group <span className="text-danger">*</span></label>
                                                            <select name="group" className="form-control text-capitalize mt-2" required data-msg="Please select Group" onChange={this.handleInputChange}>
                                                                <option value="">Select Group</option>
                                                                {groupsByMenuID &&
                                                                    groupsByMenuID.map((group, index) => (
                                                                        group &&
                                                                        <option key={index} value={group.group_id} {...(comboData.group_id && comboData.group_id.group_id === group.group_id) && { selected: true }}>{group.group_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <h4 className="py-2">Default Items For Combo</h4>
                                                        <div className="form-group">
                                                            {combojson.map((x: any, i: any) => {
                                                                let obj, selectedoption = {};
                                                                for (var property in combooptions) {
                                                                    for (let i = 0; combooptions[property].length > i; i++) {
                                                                        obj = combooptions[property].find((items: any) => items.id == x.id);
                                                                        if (obj) {
                                                                            selectedoption = {
                                                                                value: obj.value,
                                                                                label: obj.label
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                return (
                                                                    <div key={i} className="row">
                                                                        <div className="col-lg-2 col-md-4 col-6">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Group Name <span className="text-danger">*</span> <i title="Group name in which default item exist" id={i} className="fa fa-question-circle-o"></i></label>
                                                                                <input
                                                                                    name="choice_group_name"
                                                                                    type="text"
                                                                                    value={x.choice_group_name}
                                                                                    data-msg="Please enter group name"
                                                                                    className="input-material"
                                                                                    onChange={e => this.handleItemsInputChange(e, i)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-md-4 col-6">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Menu Item {i + 1}  <span className="text-danger">*</span></label>
                                                                                <Select
                                                                                    className="basic-multi-select text-capitalize py-2"
                                                                                    options={groupedOptions}
                                                                                    value={selectedoption}  //selectedoption={value:"",label:""}
                                                                                    formatGroupLabel={formatGroupLabel}
                                                                                    onChange={e => this.handleItemsInputChange(e, i)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-md-4 col-6">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Size <span className="text-danger">*</span></label>
                                                                                <select name="size" className="form-control text-capitalize mt-2" required data-msg="Please select Size" onChange={e => this.handleItemsInputChange(e, i)}>
                                                                                    <option value="">Select Size</option>
                                                                                    {(x.itemsize && x.itemsize.length > 0) &&
                                                                                        x.itemsize.map((item: any, index: any) => (
                                                                                            <option key={index} value={item.size} {...x.size === item.size && { selected: true }} >{item.size}</option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-2 col-md-4 col-6">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Quantity <span className="text-danger">*</span></label>
                                                                                <input
                                                                                    name="quantity"
                                                                                    type="number"
                                                                                    defaultValue={x.quantity}
                                                                                    min="1"
                                                                                    onKeyDown={this.blockInvalidChar}
                                                                                    data-msg="Please enter qunatity"
                                                                                    className="input-material"
                                                                                    onChange={e => this.handleItemsInputChange(e, i)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-2 col-md-4 col-6">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Limit <span className="text-danger">*</span></label>
                                                                                <input
                                                                                    name="limit"
                                                                                    type="number"
                                                                                    defaultValue={x.limit}
                                                                                    min="1"
                                                                                    onKeyDown={this.blockInvalidChar}
                                                                                    data-msg="Please enter limit"
                                                                                    className="input-material"
                                                                                    onChange={e => this.handleItemsInputChange(e, i)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-12 col-md-4 col-6 text-right">
                                                                            {combojson.length !== 1 &&
                                                                                <button className="btn btn-sm btn-outline-danger"
                                                                                    onClick={(e: any) => this.handleRemoveClick(e, i)}><i className="fa fa-trash"></i></button>}
                                                                            {combojson.length - 1 === i && <button className="btn btn-sm btn-primary ml-2" onClick={(e: any) => this.handleAddClick(e, i)}><i className="fa fa-plus"></i></button>}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <h4>Select Items For Combo Options</h4>
                                                        <div className="form-group">
                                                            {comboChoices.map((x: any, i: any) => {
                                                                let selectedoption = {};
                                                                let obj = x.choicesItemOptions.find((items: any) => items.value == x.menu_item_id);
                                                                if (obj) {
                                                                    selectedoption = {
                                                                        value: obj.value,
                                                                        label: obj.label
                                                                    }
                                                                }
                                                                return (
                                                                    <div key={i} className="row">
                                                                        <div className="col-12">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Group ERP ID <span className="text-danger">*</span></label>
                                                                                <input
                                                                                    name="group_erp_id"
                                                                                    type="number"
                                                                                    value={x.group_erp_id}
                                                                                    min="1"
                                                                                    onKeyDown={this.blockInvalidChar}
                                                                                    data-msg="Please enter group_erp_id"
                                                                                    className="input-material"
                                                                                    onChange={e => this.handleComboChoiceInputChange(e, i)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-6">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Groups <span className="text-danger">*</span></label>
                                                                                <select name="group_id" className="form-control text-capitalize mt-2" required data-msg="Please select Group" onChange={e => this.handleComboChoiceInputChange(e, i)}>
                                                                                    <option value="">Select Group</option>
                                                                                    {groupsByMenuID &&
                                                                                        groupsByMenuID.map((group, index) => (
                                                                                            group &&
                                                                                            <option key={index} value={group.group_id} {...x.group_id === group.group_id && { selected: true }}>{group.group_name}</option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-6">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Group Name <span className="text-danger">*</span></label>
                                                                                <input
                                                                                    name="group_name"
                                                                                    type="text"
                                                                                    value={x.group_name}
                                                                                    data-msg="Please enter group name"
                                                                                    className="input-material"
                                                                                    onChange={e => this.handleComboChoiceInputChange(e, i)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-6">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Menu Items <span className="text-danger">*</span></label>
                                                                                <Select
                                                                                    className="basic-single text-capitalize py-2"
                                                                                    options={x.choicesItemOptions}
                                                                                    value={selectedoption}
                                                                                    onChange={e => this.handleComboChoiceInputChange(e, i)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-6">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Size <span className="text-danger">*</span></label>
                                                                                <select name="size" className="form-control text-capitalize mt-2" required data-msg="Please select Size" onChange={e => this.handleComboChoiceInputChange(e, i)}>
                                                                                    <option value="">Select Size</option>
                                                                                    {(x.itemsize && x.itemsize.length > 0) &&
                                                                                        x.itemsize.map((item: any, index: any) => (
                                                                                            <option key={index} value={item.id} {...(x.size && x.size == item.id) && { selected: true }}>{item.size}</option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 text-right">
                                                                            {comboChoices.length !== 1 &&
                                                                                <button className="btn btn-sm btn-outline-danger"
                                                                                    onClick={() => this.handleRemoveComboChoice(i)}><i className="fa fa-trash"></i></button>}
                                                                            {comboChoices.length - 1 === i && <button className="btn btn-sm btn-primary ml-2" onClick={() => this.handleAddComboChoice(i)}><i className="fa fa-plus"></i></button>}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Description</label>
                                                            <input id="combodesc" type="text" name="combodesc" value={combodesc} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4" >
                                                        <div className="form-group">
                                                            <label className="form-control-label">Ordering Mode <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="mode"
                                                                value={mode}
                                                                options={this.orderModes}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleOrderModesInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Channel <span className="text-danger">*</span></label>
                                                            <select name="channel" className="form-control text-capitalize mt-2" required data-msg="Please select Channel" onChange={this.handleInputChange}>
                                                                <option>Select Channel</option>
                                                                {channels &&
                                                                    channels.map((channel, index) => (
                                                                        <option key={index} value={channel.sales_channel_id} {...comboData.combo_channel_id && comboData.combo_channel_id.sales_channel_id === channel.sales_channel_id && { selected: true }}>{channel.channel_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label mb-3">Combo Image <span className="text-danger"> {(comboData.images && comboData.images.length) > 0 && `(${comboData.images.length} Combo Images)`}</span></label>
                                                            <input id="files" type="file" name="files" className="form-control-file" onChange={this.fileSelectedHandler} multiple />
                                                            <small className="form-text">You can also choose multiple images.</small>
                                                            <p><span>Dimensions 700x700</span> <span style={{ color: 'red' }}>recommended</span></p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Image Alt Text</label>
                                                            <input id="alt_text" type="text" name="alt_text" value={this.state.alt_text} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-6 mt-5">
                                                        <div className="i-checks">
                                                            <input id="checkboxCustom2" type="checkbox" name="topDeal" checked={topDeal} onChange={this.handleTopDeal} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom2">Top Deal</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Cost Price <span className="text-danger">*</span></label>
                                                            <input id="combocost" type="number" name="combocost" min="1" onKeyDown={this.blockInvalidChar} value={combocost} required data-msg="Please enter Combo Cost" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Max Retail Price <small className="text-danger">*</small></label>
                                                            <input
                                                                name="combomrp"
                                                                type="number"
                                                                min="1"
                                                                onKeyDown={this.blockInvalidChar}
                                                                value={combomrp}
                                                                data-msg="Please enter mrp"
                                                                className="input-material" onChange={this.handleInputChange} />
                                                            {/* <small className="form-text">Tax Price ({tax_percent}%)</small> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                {hero_item &&
                                                    <div className='row'>
                                                        <div className="col-6 mt-2">
                                                            <div className="form-group">
                                                                <label className="form-control-label mb-3">Hero Item Image(Desktop)</label>
                                                                <input id="files" type="file" name="files" accept="image/*" className="form-control-file" onChange={this.heroItemImageSelectedHandler} />
                                                            </div>
                                                        </div>
                                                        <div className="col-6 mt-2">
                                                            <div className="form-group">
                                                                <label className="form-control-label mb-3">Hero Item Image(Mobile)</label>
                                                                <input id="files" type="file" name="files" accept="image/*" className="form-control-file" onChange={this.heroItemMobileImageSelectedHandler} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row">
                                                    <div className="col-lg-3 col-sm-6 col-6 mt-5" >
                                                        <div className="i-checks">
                                                            <input id="radioCustom1" type="radio" name="settime" value="alltime" checked={settime === 'alltime'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom1">Available for all time</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-6 col-6 mt-5" >
                                                        <div className="i-checks">
                                                            <input id="radioCustom2" type="radio" name="settime" value="customtime" checked={settime === 'customtime'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom2">Available for specific time</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-sm-6 col-6 mt-5" >
                                                        <div className="i-checks">
                                                            <input id="checkboxCustom1" type="checkbox" name="taxstatus" checked={hero_item} onChange={this.handleHeroItem} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom1">Hero Item</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    settime == 'customtime' &&
                                                    <div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-lable">Combo Timing</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <input id="combostart" type="date" name="combostart" required value={combostart} data-msg="Please enter starting time" className="input-material" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <input id="comboclose" type="date" name="comboclose" required value={comboclose} data-msg="Please enter closing time" className="input-material" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row">
                                                    <div className="col-lg-4 col-sm-4 col-6 mt-5" >
                                                        <div className="i-checks">
                                                            <input id="radioCustom3" type="radio" name="is_lsm" value="0" checked={is_lsm == '0'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom3">Available for all stores</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-4 col-6 mt-5" >
                                                        <div className="i-checks">
                                                            <input id="radioCustom4" type="radio" name="is_lsm" value="1" checked={is_lsm == '1'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom4">Available for specific stores</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-4 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Priority</label>
                                                            <input id="priority" type="number" min={1} name="priority" value={priority} required data-msg="Please enter Priority" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-12" >
                                                        <div className="i-checks">
                                                            <input id="checkboxCustom3" type="checkbox" name="specific_days" checked={specific_days} onChange={this.handleSpecificDaysCheck} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom3">Available for specific days</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {
                                                        is_lsm == '1' &&
                                                        <div className="col" >
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Store <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    defaultValue={storesJson}
                                                                    name="stores"
                                                                    options={this.props.stores}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>

                                                {specific_days &&
                                                    <div className='row'>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                {
                                                                    daysTiming.map((x: any, i: any) => {
                                                                        let timePeriod = x.timePeriods;
                                                                        return (
                                                                            <div key={i} className='mb-2' style={{ borderBottom: "1px dotted grey" }}>
                                                                                <div className="row">
                                                                                    <div className="col-lg-8 col-md-8 col-8">
                                                                                        <div className="form-group">
                                                                                            <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                                            <Select
                                                                                                name="mode"
                                                                                                options={this.weeklyDays.filter(o1 => !daysTiming.some((o2: any) => o1.value === o2.day))}
                                                                                                className="text-capitalize basic-multi-select"
                                                                                                classNamePrefix="select"
                                                                                                value={{ value: x.value, label: x.label }}
                                                                                                onChange={(e) => this.handleWeeklyDaysInputChange(e, i)}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-4 col-md-4 col-4 mt-4 py-2">
                                                                                        {daysTiming.length !== 1 &&
                                                                                            <button className="btn btn-sm btn-outline-danger"
                                                                                                onClick={() => this.handleWeeklyDaysRemoveClick(i)}><i className="fa fa-trash"></i></button>}
                                                                                        {(daysTiming.length - 1 === i && daysTiming.length < 7) && <button className="btn btn-sm btn-primary ml-2" onClick={(e: any) => this.handleweeklyDaysAddClick(e, i)}><i className="fa fa-plus"></i></button>}
                                                                                    </div>
                                                                                </div>
                                                                                {timePeriod.map((t: any, pindex: any) => {
                                                                                    return (
                                                                                        <div className="row mb-3">
                                                                                            <div className="col-lg-3 col-md-3">
                                                                                            </div>
                                                                                            <div className="col-lg-3 col-md-3 col-6">
                                                                                                <div className="form-group">
                                                                                                    <label className="form-control-label mb-0">Open Time <span className="text-danger">*</span></label>
                                                                                                    <input id="open" type="time" name="open" value={t.open} className="input-material" onChange={(e) => this.handleWeeklyDaysInputChange(e, i, pindex)} />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg-3 col-md-3 col-6">
                                                                                                <div className="form-group">
                                                                                                    <label className="form-control-label mb-0">Close Time <small className="text-danger">*</small></label>
                                                                                                    <input id="close" type="time" name="close" value={t.close} className="input-material" onChange={(e) => this.handleWeeklyDaysInputChange(e, i, pindex)} />
                                                                                                    {(timePeriod.length - 1 == pindex) && <small className="text-primary" style={{ cursor: 'pointer' }} onClick={(e: any) => this.handleweeklyDaysAddClick(e, i, pindex)}>+ Add hours</small>}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg-3 col-md-3 col-12">
                                                                                                {timePeriod.length !== 1 &&
                                                                                                    <button className="btn btn-sm btn-outline-danger"
                                                                                                        onClick={() => this.handleWeeklyDaysRemoveClick(i, pindex)}><i className="fa fa-trash"></i></button>}
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button onClick={this.handleSaveBtnClick} disabled={!this.isComboReady()} className="btn btn-primary">Update Combo</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        menus: state.menu.menus,
        channels: state.store.channels,
        combooptions: state.menu.combooptions,
        stores: state.menu.storesoptions,
        comboData: state.menu.comboData,
        combojson: state.menu.combojson,
        comboChoices: state.menu.comboChoices,
        comboChoiceItems: state.menu.comboChoiceItems,
        choiceIndex: state.menu.choiceIndex,
        menuItems: state.menu.items,
        groups: state.menu.groups,
        brands: state.brand.brands
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        editCombo: function (id: any, data: any) {
            dispatch(editCombo(id, data));
        },
        getCombo: function (id: number) {
            dispatch(getCombo(id));
        },
        channelList: function () {
            dispatch(channelList())
        },
        groupedItemsList: function (menu_id: any) {
            dispatch(groupedItemsList(menu_id))
        },
        menusList: function (brand_id: any) {
            dispatch(menusList(brand_id))
        },
        storesList: (brand_id: any) => {
            dispatch(storesListForMultiSelect(brand_id))
        },
        comboChoiceItemsByGroupId: function (id: any, index: any) {
            dispatch(comboChoiceItemsByGroupId(id, index))
        },
        groupsList: function (brand_id: any) {
            dispatch(groupsList(brand_id))
        },
        logoutUser: function () {
            dispatch(logoutUser());
        },
        brandsList: () => {
            dispatch(brandsList())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditCombo);