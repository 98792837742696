import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { logoutUser, generalFeatures, updateGeneralFeatures } from '../../redux'
import Toggle from 'react-toggle';

class GenralFeatures extends Component<{}, { }> {
    constructor(readonly props: any) {
        super(props);
        this.handleToggleChange = this.handleToggleChange.bind(this);
    }
    componentDidMount() {
        this.props.generalFeatures();
        document.title = "SimplexCMS | General Features"
    }
    handleToggleChange(e: any, element: any) {
        let body: any = {
            permission: e.target.checked == false ? 0 : 1,
            feature_id: element.id
        }
        this.props.updateGeneralFeatures(body);
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const { features } = this.props;
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">General Features Management</h4>
                                </div>
                            </div>
                        </header>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12 col-12">
                                        <div className="card">
                                            <div className="card-body text-capitalize">
                                                <div className="row">
                                                    {features.length > 0 ? features.map((element: any, index: any) => {
                                                        return (
                                                            <div className="col-lg-6 my-2" key={index}>
                                                                <div className="d-flex justify-content-between">
                                                                    <div>{element.feature_name}</div>
                                                                    <div>
                                                                        <Toggle
                                                                            className='custom-classname'
                                                                            checked={element.is_active == 0 ? false : true}
                                                                            icons={false}
                                                                            onChange={(e: any) => this.handleToggleChange(e, element)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }) :
                                                        <div className="col-12 text-center">
                                                            <h5>Features not found</h5>
                                                        </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div >
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        features: state.setting.generalFeatures
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        generalFeatures: function () {
            dispatch(generalFeatures())
        },
        updateGeneralFeatures: function (body: any) {
            dispatch(updateGeneralFeatures(body))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(GenralFeatures);