import React, { Component } from 'react';
import { AddDriverProps, AddDriverState } from '../../interfaces/drivers'
import { connect } from 'react-redux'
import { brandsList, countryList, logoutUser, storesList } from '../../redux'
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import CheckChanges from '../../components/confirmOnLeave'
import { addDriver } from '../../redux/actions/driverAction';
import Select from 'react-select'
class AddDriver extends Component<AddDriverProps, AddDriverState> {
    constructor(props: any) {
        super(props);
        this.state = {
            firstname: "",
            lastname: "",
            email: "",
            password: "",
            city: "",
            phone: "",
            brand_id:"",
            cnic: "",
            country: "",
            address: "",
            storeId: "",
            gopanda: 'false',
            isValidEmail: true,
            isValidPhone: true
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.validatePhone = this.validatePhone.bind(this);
    }
    componentDidMount() {
        document.title = "SimplexHDS | Drivers"
        this.props.countryList();
        this.props.brandsList()
        //this.props.storesList();
    }

    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleBrand = (e:any)=>{
        this.setState({brand_id:e.value})
        this.props.storesList(e.value)
    }

    isUserReady = () => {
        let { firstname, country, phone, address, email, gopanda, isValidEmail, isValidPhone,brand_id } = this.state;
        return (firstname !== "" && (phone !== "" && isValidPhone === true) && country !== "" && gopanda !== "" && address !== "" && brand_id!='');
    }
    validateEmail(event: { target: { name: any; value: any; }; }) {
        let reg = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,4}$/;
        if (reg.test(event.target.value)) {
            this.setState({ email: event.target.value, isValidEmail: true });
        } else {
            this.setState({ email: event.target.value, isValidEmail: false })
        }
    };
    validatePhone(event: { target: { name: any; value: any; }; }) {
        if (event.target.value.length < 12) {
            this.setState({ phone: event.target.value, isValidPhone: false })
        } else {
            if (event.target.value.length === 12) {
                this.setState({ phone: event.target.value, isValidPhone: true });
            }
        }
    };
    blockInvalidChar = (e: any) =>
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    handleSubmit = (event: any) => {
        let { firstname, lastname,brand_id, email, isValidEmail, phone, storeId, city, country, address, gopanda, cnic } = this.state;
        let data: any = {
            first_name: firstname,
            last_name: lastname,
            user_name: lastname === '' ? firstname : firstname + " " + lastname,
            store_id: storeId,
            city: city,
            cnic: cnic,
            phone_number: phone,
            country_id: country,
            address: address,
            goPanda: gopanda,
            status: 1,
            brand_id
        }
        if (email !== "" && isValidEmail == true) {
            data.email_address = email;
        }
        this.props.addDriver(data);
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        let tokendata: any = jwt.decode(sessionStorage.token)
        let roleId = tokendata.role_id;
        let brandId = tokendata.brand_id;
        const { isInserted, message, countries,brands,stores } = this.props;
        let { isValidEmail, email, isValidPhone, phone } = this.state;
        if (!isInserted) {
            msg = message;
            messcolor = 'red'
        }
        return (
            <div className="page">
                <CheckChanges path="/add-driver" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Drivers Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/drivers" className="text-primary">Drivers</Link></li>
                                <li className="breadcrumb-item active">Add Driver</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-lable">First Name <span className="text-danger">*</span></label>
                                                            <input id="firstname" type="text" name="firstname" value={this.state.firstname} required data-msg="Please enter first name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-lable">Last Name</label>
                                                            <input id="lastname" type="text" name="lastname" value={this.state.lastname} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-lable">Email</label>
                                                            <input id="email" type="email" name="email" required data-msg="Please enter email" className="input-material" onChange={this.validateEmail} />
                                                            {(isValidEmail == false && email !== "") &&
                                                                <small className="form-text text-danger">email is not correct</small>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-lable">Phone Number <span className="text-danger">*</span></label>
                                                            <input type="number" name="phone" value={phone} onKeyDown={this.blockInvalidChar} required data-msg="Please enter Phone Number" className="input-material" onChange={this.validatePhone} />
                                                            {(isValidPhone == false && phone !== "") &&
                                                                <small className="form-text text-danger">Please enter valid phone number</small>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-lable">City</label>
                                                            <input id="city" type="text" name="city" value={this.state.city} required data-msg="Please enter city" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Country <span className="text-danger">*</span></label>
                                                            <select name="country" className="form-control mt-2" required data-msg="Please enter Country" onChange={this.handleInputChange}>
                                                                <option value="">Select Country</option>
                                                                {countries &&
                                                                    countries.map((country: any, index: any) => (
                                                                        <option key={index} value={country.country_id}>{country.country_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Brand <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="brand"
                                                                isClearable
                                                                options={roleId == 1 ? brands : brands.filter((o1:any) => brandId === o1.brand_id)}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e) => this.handleBrand(e)}
                                                            />
                                                            {/* <select name="brand" className="form-control text-capitalize mt-2" required data-msg="Please select brand" onChange={this.handleInputChange}>
                                                                <option value="">Select Brand</option>
                                                                {brands &&
                                                                    brands.map((brand, index) => (
                                                                        brand.is_active == 1 &&
                                                                        <option key={index} value={brand.brand_id}>{brand.brand_name}</option>
                                                                    ))
                                                                }
                                                            </select> */}
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Stores <span className="text-danger">*</span></label>
                                                            <select name="storeId" className="form-control mt-2" required data-msg="Please enter Country" onChange={this.handleInputChange}>
                                                                <option value="">Select Store</option>
                                                                {stores &&
                                                                    stores.map((store: any, index: any) => (
                                                                        <option key={index} value={store.store_id}>{store.store_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            {/* <select name="brand" className="form-control text-capitalize mt-2" required data-msg="Please select brand" onChange={this.handleInputChange}>
                                                                <option value="">Select Brand</option>
                                                                {brands &&
                                                                    brands.map((brand, index) => (
                                                                        brand.is_active == 1 &&
                                                                        <option key={index} value={brand.brand_id}>{brand.brand_name}</option>
                                                                    ))
                                                                }
                                                            </select> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">ID Number</label>
                                                            <input id="cnic" type="text" name="cnic" value={this.state.cnic} required data-msg="Please enter Id Number" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    {/* <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Go Panda <span className="text-danger">*</span></label>
                                                            <select name="gopanda" className="form-control mt-2" defaultValue={this.state.gopanda} onChange={this.handleInputChange}>
                                                                <option value="">Select Option</option>
                                                                <option value="false">False</option>
                                                                <option value="true">True</option>
                                                            </select>
                                                        </div>
                                                    </div> */}
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-lable">Address <span className="text-danger">*</span></label>
                                                            <input id="address" type="text" name="address" value={this.state.address} required data-msg="Please enter address" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-lable">Address <span className="text-danger">*</span></label>
                                                            <input id="address" type="text" name="address" value={this.state.address} required data-msg="Please enter address" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div> */}

                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isUserReady()} onClick={this.handleSubmit}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        message: state.setting.message,
        isInserted: state.setting.isInserted,
        countries: state.store.countries,
        stores: state.store.data,
        brands: state.brand.brands
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        addDriver: function (data: any) {
            dispatch(addDriver(data));
        },
        countryList: function () {
            dispatch(countryList())
        },
        brandsList: function () {
            dispatch(brandsList())
        },
        storesList: function (brand: any) {
            dispatch(storesList(brand))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddDriver);