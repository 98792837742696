import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import Skelton from '../skeltonLoader'
import { logoutUser, luanchTimerForFailedOrders,ERPlogsList,updateERPLogs, ordersList, repushOrder, statesList, stopTimer,storesListWithoutBrand } from '../../redux'
import { OrderProps } from '../../interfaces/order';
import moment from 'moment';
import { filterOrdersList, syncFilterOrdersList, syncFailedOrdersList, manualSyncFailedOrder, manualSyncCMSFailedOrder, searchOrder, searchAggOrder, searchSyncAggOrder } from '../../redux/actions/orderAction';
import { toast } from "react-toastify";
import { currency } from '../../client-config'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-toastify/dist/ReactToastify.css";
import Select from 'react-select'
import './orders.css'
toast.configure();


class ManualSFailedSync extends React.Component<{ data: any, row: any }, { [x: number]: any, id: any }> {
    constructor(props: any) {
        super(props);
        this.state = {
            id: ""
        }
    }
    componentDidMount() {
        const { row } = this.props
        this.setState({ id: row.id })
    }
    handleSaveSync = () => {

        const { row } = this.props
        this.props.data.updateErpLogs(row.id)
    }
    render() {
        const { row } = this.props;
        return (
            <div>
                        <div>
                                {(row.sync_status==1) ? "" : <span className="badge badge-pill badge-info sync-btn my-own" onClick={this.handleSaveSync}>Click to Sync</span>}

                        </div>
            </div>
        );
    }
}
class ManualSync extends React.Component<{ data: any, row: any }, { [x: number]: any, order_id: any }> {
    constructor(props: any) {
        super(props);
        this.state = {
            order_id: ""
        }
    }
    componentDidMount() {
        const { row } = this.props
        this.setState({ order_id: row.order_id })
    }
    handleSaveSync = () => {
        const { row } = this.props
        const data = {
            bo_json:row.bo_json
        }
            this.props.data.repushOrder(row.order_id, data, this.props.data.history)
            toast.success("Repushing order. Check list for push status", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });

    }
    render() {
        const { row } = this.props;
        return (
            <div>
                        <div>
                            { <span className="badge badge-pill badge-info sync-btn" onClick={this.handleSaveSync}>RePush</span>}
                        </div>
            </div>
        );
    }
}

class ValidateIntegratedOrder extends React.Component<{ data: any, row: any }, { [x: number]: any, order_id: any }> {
    constructor(props: any) {
        super(props);
        this.state = {
            order_id: ""
        }
    }
    componentDidMount() {
        const { row } = this.props
        this.setState({ order_id: row.order_id })
    }
    handleSaveSync = () => {
        const { row } = this.props
        const { order_id } = this.state;
        const data = {
            order_id: order_id ? order_id : ""
        }
    }
    render() {
        const { row } = this.props;
        return (
            <div>
                {((row.order_status_code === 3 || row.order_status_code === 4 || row.order_status_code === 5))
                    &&
                    (
                        <div>
                           {(row.is_fiscal_failed_order && row.is_fiscal_failed_order == 0 || row.is_fiscal_order_dump == 1) ? row.order_id :"" }
                        </div>
                    )
                }
            </div>
        );
    }
}

class ActionFormatter extends Component<{ row: any }, { selectedOrderId: any, checkTimer: any }> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            selectedOrderId: "",
            checkTimer: false
        }
    }
    handleTimer = () => {
        if (this.state.checkTimer == true) {
            this.props.data.luanchFailedTimer(3,this.props.data.history);
        }
    }
    render() {
        const { row, data } = this.props;
        return (
            <div>
                <div id={`viewitems${row.order_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Order Items</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="row" style={{ margin: '0px', padding: '15px' }}>
                                <div className="col-6 to">
                                    <h3>Customer Information</h3>
                                    <span className="d-block font-weight-light">Customer Name: </span>
                                    <span className="d-block font-weight-light">Phone Number: </span>
                                    <span className="d-block font-weight-light">Email Address: </span>
                                    {data.Items.length > 0 ? data.Items[0].address_line_1 && <span className="d-block font-weight-light">Address 1: </span> : ""}
                                </div>
                                <div className="col-6 to">
                                    <h3><br /></h3>
                                    <span className="d-block font-weight-light">{data.Items.length > 0 && data.Items[0].first_name} {data.Items.length > 0 && data.Items[0].last_name}</span>
                                    <span className="d-block font-weight-light">{data.Items.length > 0 && data.Items[0].phone_number}</span>
                                    <span className="d-block font-weight-light">{data.Items.length > 0 && data.Items[0].email_address}</span>
                                    <span className="d-block font-weight-light">{data.Items.length > 0 && data.Items[0].address_line_1}</span>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            {(data.Items.length > 0) ?
                                                <BootstrapTable data={data.Items} hover>
                                                    <TableHeaderColumn dataField="order_item_id" width="50" columnTitle isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn dataField="order_item_quantity" width="50" columnTitle>Quantity</TableHeaderColumn>
                                                    <TableHeaderColumn dataField="item_name" width="150" columnTitle>Item Name</TableHeaderColumn>
                                                    <TableHeaderColumn dataField="combo_name" width="150" columnTitle>Combo Name</TableHeaderColumn>
                                                    <TableHeaderColumn dataField="order_item_grossprice" width="100" columnTitle>Amount</TableHeaderColumn>
                                                </BootstrapTable> :
                                                <p className="text-center">Order Items Not Found</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Invoice Modal */}
                <div id={`viewinvoice${row.order_id}`} data-backdrop='static' role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog modal-lg" style={{ width: '500px' }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Invoice</h4>
                                <button type="button" onClick={() => this.handleTimer()} data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} data={props} />
    );

}
function skeltonFormatter() {
    return (
        <Skelton></Skelton>
    );
}

const manualSyncApi = (cell: any, row: any, props: any) => {
    return (
        <ManualSync row={row} data={props} />
    )
}
const manualSyncFailedApi = (cell: any, row: any, props: any) => {
    return (
        <ManualSFailedSync row={row} data={props} />
    )
}

class ERPOrders extends Component<OrderProps, { selecedjson:any,sync_status:any,store: any,store_name:any,startDate: any, endDate: any, validTimeFlag: any, [x: number]: any, days: any, orderId: any , syncOrderId:any}> {
    closeModal: any;
    constructor(readonly props: any) {
        super(props);
        this.state = {
            days: 0,
            startDate: "",
            endDate: "",
            validTimeFlag: "",
            orderId: "",
            syncOrderId: "",
            store: "",
            store_name:"",
            sync_status:0,
            selecedjson:'hello'
        }
        this.rowClassNameFormat = this.rowClassNameFormat.bind(this);
        this.handleDaysFilter = this.handleDaysFilter.bind(this);
        this.handleTimer = this.handleTimer.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleValidTime = this.handleValidTime.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleSyncInput = this.handleSyncInput.bind(this);
        
    }
    rowClassNameFormat(row: any, rowIdx: any) {
        // row is whole row object
        // rowIdx is index of row
        if (row.aggregator_orderId) {
            return 'td-column-function-aggregator-example';
        } else {
            if (row.future_status == 1 && row.order_status_code == 1) {
                return 'td-column-function-odd-example tr-pending-orders'
            } else if (row.future_status == 1) {
                return 'td-column-function-odd-example'
            } else if (row.isNew == true && row.order_status_code == 1 && row.is_order_transfer == 1) {
                return 'td-column-function-even-example tr-transfered-orders'
            } else if (row.isNew == true && row.order_status_code == 1) {
                return 'td-column-function-even-example tr-pending-orders'
            } else if (row.isNew == true && row.is_order_transfer == 1) {
                return 'td-column-function-even-example tr-transfered-orders'
            } else if (row.isNew == true) {
                return 'td-column-function-even-example'
            } else if (row.order_status_code == 1) {
                return 'tr-pending-orders'
            } else if (row.is_order_transfer == 1) {
                return 'tr-transfered-orders'
            } else {
                return ''
            }
        }
    }
    componentDidMount() {
        let obj = { days: this.state.days ,sync_status: this.state.sync_status
        };

        this.props.syncFailedOrdersList(obj);
        this.props.storesList()
        this.props.luanchFailedTimer(3,this.props.history);
        document.title = "SimplexCMS | Orders"
    }

    handleStoresInputChange = (e: any, index: any) => {
        
        if (e && e.value > 0) {
            this.setState({store: e.value});
            this.setState({store_name:e.label})
        } else {
            this.setState({ store: "" });
            this.setState({ store_name: "" });
    
        }
    };
    handleDaysFilter(event: { target: { name: any; value: any; }; }) {
        let obj = { days: event.target.value };
        this.props.syncFailedOrdersList(obj);
    }
    handleInput(event: { target: { name: any; value: any; }; }) {
        this.setState({ orderId: event.target.value })
    };
    handleSyncInput(event: { target: { name: any; value: any; }; }) {
        this.setState({ syncOrderId: event.target.value })
    };

    handleSyncStatus = (e: any, index: any) => {
        if (e && e.value !== "") {
            this.setState({ sync_status: e.value });
        } else {
            this.setState({ sync_status: "" });
        }
    };
    handleValidTime(event: { target: { name: any; value: any; }; }) {
        let futureDatetime = new Date(event.target.value)
        let validMinDateTime = new Date();
        if (futureDatetime <= validMinDateTime) {
            this.setState({ [event.target.name]: event.target.value, validTimeFlag: true });
        } else {
            this.setState({ validTimeFlag: false })
            toast.error("Invalid date and time selected");
        }
    }
    isOrderDateReady = () => {
        let { startDate, endDate, validTimeFlag } = this.state;
        return (startDate !== "" && endDate !== "" && validTimeFlag == true);
    }
    handleSubmit() {
        let { startDate, endDate } = this.state;        
        var start = moment(startDate, 'YYYY-MM-DD HH:mm:ss');
        var end = moment(endDate, 'YYYY-MM-DD HH:mm:ss');
        let data: any = {
            start: start,
            end: end,
            store_id:this.state.store,
            sync_status: this.state.sync_status
            
        }
        let days = moment.duration(end.diff(start)).asDays();

        if (startDate >= endDate) {
            toast.error("End date cannot be less than start date")
        } else if (days >= 14) {
            toast.error("Maximum 14 days can be selected")
        }
        else {
            this.props.syncFailedOrdersList(data, true);
        }

    }
    handleSearchOrder = (event: any) => {
        event.preventDefault();
        let { orderId } = this.state;
        this.props.searchOrder(orderId)
    }
    handleSearchSyncOrder = (event: any) => {
        event.preventDefault();
        let { syncOrderId } = this.state;
        this.props.searchSyncOrder(syncOrderId)
    }
    handleTimer() {
        if (this.props.timerStatus == false) {
            this.props.luanchFailedTimer();
        } else if (this.props.timerStatus == true) {
            this.props.stopTimer();
        }
        this.closeModal.click();
    }
    
    
   buttonFormatter(this:any,cell:any, row:any, ){
        

    
        return  <div>
                    <div id={`enableTimer${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                        <div role="document" className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 id="exampleModalLabel" className="modal-title">JSON FOR ERP</h4>
                                    <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                                </div>
                                <div className="modal-body">
                                    <p>{row.erp_json}</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                </div>
                            </div>
                        </div>  
                    </div>
                    <Link to="#" title={"View JSON"} data-toggle="modal"  data-target={`#enableTimer${row.id}`} className={" "}><i className="my-2 fa fa-refresh"> View ERP Json</i></Link>
                </div>
        }
    render() {

        
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />  
        }
        let maxDate = moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS')
        let { orders, filteredOrders, syncFilteredOrders, emptyFilteredOrder, syncFailedOrders, syncEmptyFilteredOrder, avg, dayVal, kitchenCount, recievedOrderCount, readyOrderCount, completedOrderCount, failedIntegOrderCount } = this.props;
        const options: any = {
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            noDataText: 'Orders Not Found',
        };
       
        
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                {/* <div className="d-flex bd-highlight py-3">
                                    <div className="mr-auto bd-highlight"><h4 className="mt-2">Orders Management</h4></div>
                                    <div className="bd-highlight"><button title={this.props.timerStatus == false ? "Enable Refresh" : "Disable Refresh"} data-toggle="modal" data-target={`#enableTimer`} className={this.props.timerStatus == false ? "btn btn-outline-success mr-2" : "btn btn-outline-danger mr-2"}><i className={"fa fa-refresh"}></i></button></div>
                                    <div className="p-2 bd-highlight">
                                        <select name="days" id="days" onChange={this.handleDaysFilter} style={{ padding: '0 15px' }}>
                                            <option value="0" {...dayVal.days == 0 && { selected: true }}>Today</option>
                                            <option value="1" {...dayVal.days == 1 && { selected: true }}>Yesterday</option>
                                            <option value="7" {...dayVal.days == 7 && { selected: true }}>7 Days</option>
                                            <option value="15" {...dayVal.days == 15 && { selected: true }}>15 Days</option>
                                            <option value="30" {...dayVal.days == 30 && { selected: true }}>30 Days</option>
                                            <option value="60" {...dayVal.days == 60 && { selected: true }}>60 Days</option>
                                            <option value="90" {...dayVal.days == 90 && { selected: true }}>90 Days</option>
                                        </select>
                                    </div> */}
                                <div className="row py-2">
                                    <div className="col-lg-6 col-md-6 col-12" style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                        <h4 className="mt-2">ERP Data Management </h4>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <div className="form-group">
                                                    <input
                                                        id="start"
                                                        type="datetime-local"
                                                        name="startDate"
                                                        max={maxDate}
                                                        value={this.state.startDate}
                                                        className="input-material"
                                                        onChange={this.handleValidTime}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input
                                                        id="end"
                                                        type="datetime-local"
                                                        name="endDate"
                                                        max={maxDate}
                                                        value={this.state.endDate}
                                                        className="input-material"
                                                        onChange={this.handleValidTime}
                                                    />
                                                </div>
                                                <button disabled={!this.isOrderDateReady()} className="badge badge-pill btn-info text-capitalize" style={{ cursor: 'pointer', float: 'right' }} onClick={() => { this.handleSubmit() }}>get</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        {/* Counters Section */}
                        
                        {/* End Counters Section */}
                     
                        <section className="tables py-2">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                       
                                                    <div className="col-lg-3 col-md-3 col-12 float-right mt-1">
                                                        <div className="form-group">
                                                        <label className="form-control-label " style={{paddingTop:'13px'}}>Store<span className="text-danger"></span></label>
                                                            <Select
                                                                isClearable
                                                                options={this.props.stores}
                                                                //value={{label:this.props.store_name, value:this.state.store}}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                                            />                                                            
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-3 col-md-3 col-12 float-right mt-1">
                                                        <div className="form-group">
                                                        <label className="form-control-label " style={{paddingTop:'13px'}}>Sync Status<span className="text-danger"></span></label>
                                                            <Select
                                                                isClearable
                                                                options={[{ label: 'Successfull', value: 1 }, { label: 'Unsucessfull', value: 0 }]}
                                                                //value={{label:this.props.store_name, value:this.state.store}}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                defaultValue={{ label: "Unsucessfull", value: 0 }}
                                                                onChange={(e, i) => this.handleSyncStatus(e, i)}
                                                            />                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    <BootstrapTable version='4' data={syncFilteredOrders.length > 0 || syncEmptyFilteredOrder ? syncFilteredOrders : syncFailedOrders}  trClassName={this.rowClassNameFormat} pagination={syncFailedOrders.length > 10 && true} options={options} exportCSV={true} csvFileName='orders.csv'>
                                                          <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold' }} width='30' tdStyle={{ fontSize: 10 }} dataField='id' csvHeader='Id' dataSort={true} isKey>Id</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='date_created' csvHeader='date_created' columnTitle>Date Created</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='60' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='store_id' csvHeader='store_id' columnTitle>Store ID</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='60' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='brand_id' csvHeader='brand_id' columnTitle>Brand ID</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='60' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='sync_status' csvHeader='sync_status' columnTitle>Sync Status</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField="erp_json"  dataFormat={this.buttonFormatter}>ERP JSON</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='erp_response' csvHeader='erp_response' columnTitle>ERP Response</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='order_ids' csvHeader='order_ids' columnTitle>Order IDs</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='40' tdStyle={{ fontSize: 10 }} dataField='manual_sync' dataFormat={manualSyncFailedApi} formatExtraData={this.props} csvHeader='Manual Sync'>Action</TableHeaderColumn>
                                                    </BootstrapTable>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
                <div>
                    
        <div id={`enableTimer`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
            <div role="document" className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 id="exampleModalLabel" className="modal-title">JSON FOR ERP</h4>
                        <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                    </div>
                    <div className="modal-body">
                        <h1>${this.state.selecedjson}</h1>
                    </div>
                    <div className="modal-footer">
                        <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                    </div>
                </div>
            </div>
        </div>

                </div>
                <div>
                    
                </div>
            </div>
        );
    }
};
function syncERPFormatter(cell: any, row: any) {
    if (row.bo_sync) {
        return (<span className="label danger" style={{ color: "green" }}>True</span>);
    } else {
        return (<span className="label danger" style={{ color: "red" }}>False</span>);
    }
}
function dateFormatter(cell: any) {
    var DateCreated: any = moment(cell).local().format('YYYY-MM-DD HH:mm').split(' ');
    DateCreated = DateCreated[1] + " , " + DateCreated[0];
    return (
        <div>
            <span {...((cell !== "" && cell !== null) && { title: DateCreated })}> {(cell !== "" && cell !== null) && DateCreated} </span>
        </div>
    )
}
function isOrderTransferFormatter(cell: any, row: any) {
    if (row.is_order_transfer == 0) {
        return <p title="No">No</p>;
    } else {
        return <p title="Yes">Yes</p>;
    }
}
function priceFormatter(cell: any, row: any) {
    if (row.aggregator_orderId) {
        return `${currency} ${cell}`;
    } else {
        return `${currency} ${Math.round(cell)}`;
    }
}
function calculateDeliveryTime(cell: any, row: any) {
    let DeliveryTime: any = row.delivery_time_json && JSON.parse(row.delivery_time_json);
    let ActualDeliveryTime = row.delivery_time_json ? DeliveryTime.pending + DeliveryTime.kitchen + DeliveryTime.ready + DeliveryTime.dispatched : 0
    return (
        <div>
            <span {...((row.order_status_code == 4 && row.delivery_time_json) && { title: `${ActualDeliveryTime} min` })}> {(row.order_status_code == 4 && row.delivery_time_json) && `${ActualDeliveryTime} min`} </span>
        </div>
    )
}


const mapStateToProps = (state: any) => {
    return {
        orders: state.order.data,
        syncFailedOrders: state.order.erpLogsList,
        filteredOrders: state.order.filteredOrders,
        syncFilteredOrders: state.order.syncFilteredOrders,
        emptyFilteredOrder: state.order.emptyFilterOrder,
        syncEmptyFilteredOrder: state.order.syncEmptyFilteredOrder,
        Items: state.order.orderItems,
        orderDetail: state.order.order,
        buttonDisable: state.order.buttonDisable,
        avg: state.order.avg,
        completedOrderCount: state.order.completedOrderCount,
        failedIntegOrderCount: state.order.failedIntegOrderCount,
        dayVal: state.order.dayVal,
        timerStatus: state.order.timerStatus,
        applyLoaderonDate: state.order.isLoading,
        updateTimer: state.order.isTimerUpdate,
        clickmenuSync: state.order.clickmenuSync,
        stores: state.store.completeStore
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        storesList:function(){
            dispatch(storesListWithoutBrand())

        },
        updateErpLogs:(id:any)=>{
            dispatch(updateERPLogs(id))
        },
        syncFailedOrdersList: (obj: any, datetime: any) => {
            dispatch(ERPlogsList(obj, datetime))
        },
        manualSyncFailedOrder: function (id: any, data: any, history: any, orderData: any) {
            dispatch(manualSyncFailedOrder(id, data, history, orderData))
        },
        manualSyncCMSFailedOrder: function (id: any, data: any, history: any, orderData: any) {
            dispatch(manualSyncCMSFailedOrder(id, data, history, orderData))
        },
        luanchFailedTimer: (brand_id:number, history: any) => {
            dispatch(luanchTimerForFailedOrders(brand_id,history))
        },
        repushOrder: function (id: any, data: any, history: any) {
            dispatch(repushOrder(id, data, history))
        },
        stopTimer: () => {
            dispatch(stopTimer())
        },
        filterOrdersList: (filteredArr: any) => {
            dispatch(filterOrdersList(filteredArr))
        },
        syncFilterOrdersList: (syncFilteredArr: any) => {
            dispatch(syncFilterOrdersList(syncFilteredArr))
        },
        searchOrder: (orderId: any) => {
            dispatch(searchOrder(orderId))
        },
        searchSyncOrder: (syncOrderId: any) => {
            dispatch(searchSyncAggOrder(syncOrderId))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ERPOrders);