import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { EditModGroupProps, EditModGroupState } from '../../../interfaces/menu'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import { logoutUser, editModGroup, getModGroup, groupsListForMultiSelect, itemsListForMultiSelect, menusList, brandsList } from '../../../redux'
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import CheckChanges from '../../../components/confirmOnLeave'
import Select from 'react-select';

class EditMod extends Component<EditModGroupProps, EditModGroupState> {
    constructor(props: any) {
        super(props);
        this.state = {
            brand_id: null,
            brand_name: "",
            menu: "",
            modgroupname: "",
            groupJson: [],
            itemsJson: [],
            modgroupdesc: "",
            type: "",
            level: "",
            priority: "",
            max_quantity: "",
            min_quantity: "",
            mod_group_secondary_name:"",
            use_secondary_name:false,
            name_ar:""
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleModifiersLevel = this.handleModifiersLevel.bind(this);
        this.handleItemsByMenuId = this.handleItemsByMenuId.bind(this);
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.brandsList();
        this.props.getModGroup(id);
        document.title = "SimplexCMS | Modifier Groups"
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        if (this.state.brand_id == null) {
            this.setState({
                modgroupname: nextProps.modGroupData.mod_group_name,
                brand_id: nextProps.modGroupData.brand_id,
                brand_name: nextProps.modGroupData.brand_name,
                menu: nextProps.modGroupData.menu_id,
                modgroupdesc: nextProps.modGroupData.mod_group_description,
                mod_group_secondary_name:nextProps.modGroupData.mod_group_secondary_name,
                name_ar:nextProps.modGroupData.name_ar,
                use_secondary_name:nextProps.modGroupData.use_secondary_name == true || nextProps.modGroupData.use_secondary_name == 1 ? 1 : 0,
                // groupJson: nextProps.modGroupData.group_json && JSON.parse(nextProps.modGroupData.group_json),
                // itemsJson: nextProps.modGroupData.items_json && JSON.parse(nextProps.modGroupData.items_json),
                type: nextProps.modGroupData.modifiers_type,
                level: nextProps.modGroupData.type,
                priority: nextProps.modGroupData.priority,
                min_quantity: nextProps.modGroupData.min_quantity,
                max_quantity: nextProps.modGroupData.max_quantity,
                groupJson: nextProps.modGroupData.group_json && JSON.parse(nextProps.modGroupData.group_json),
                itemsJson: nextProps.modGroupData.items_json && JSON.parse(nextProps.modGroupData.items_json),
            })
        }
    }
    handleSecondaryNameCheck = () =>{
        this.setState({use_secondary_name:!this.state.use_secondary_name});
    }
    handleBrand = (e: any, index: any) => {
        if (e && e.value > 0) {
            this.setState({ brand_id: e.value, brand_name: e.label })
            this.props.menusList(e.value);
        }
    }
    handleItemsByMenuId(event: { target: { name: any; value: any; }; }) {
        this.props.groupsList(event.target.value);
        this.props.itemsListForMultiSelect(event.target.value);
        this.setState({ menu: event.target.value, itemsJson: [], groupJson: [] })
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleModifiersLevel(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }
    isModGroupReady = () => {
        const { menu, modgroupname, type, groupJson, level, itemsJson, max_quantity, min_quantity } = this.state
        return (menu !== "" && modgroupname !== "" && type !== "" && (level == "group" ? groupJson && groupJson.length > 0 : itemsJson && itemsJson.length > 0) && max_quantity !== "" && min_quantity !== "");
    }
    handleGroupsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ itemsJson: [], groupJson: e });
        } else {
            this.setState({ itemsJson: [], groupJson: [] });
        }
    };
    handleItemsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ groupJson: [], itemsJson: e });
        } else {
            this.setState({ itemsJson: [], groupJson: [] });
        }
    }
    handleSubmit = (event: any) => {
        let { menu,mod_group_secondary_name,name_ar, groupJson, itemsJson, modgroupname, modgroupdesc, type, level, priority, max_quantity, min_quantity, brand_id } = this.state;
        let id = this.props.match.params.id;
        let data: any = {
            mod_group_name: modgroupname,
            mod_group_description: modgroupdesc,
            priority: priority,
            modifiers_type: type,
            type: level,
            name_ar:name_ar,
            menu_id: menu,
            brand_id: brand_id,
            max_quantity: max_quantity,
            min_quantity: min_quantity,
            mod_group_secondary_name,
            use_secondary_name:this.state.use_secondary_name ? 1 : 0
        }
        if (level == 'group') {
            data["group_json"] = JSON.stringify(groupJson);
            data["items_json"] = null;
        } else if (level == 'item') {
            data["group_json"] = null;
            data["items_json"] = JSON.stringify(itemsJson);
        }
        this.props.editModGroup(id, data);
        event.preventDefault()
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let { groupJson, itemsJson } = this.state;
        let tokendata: any = jwt.decode(sessionStorage.token)
        let roleId = tokendata.role_id;
        let brandId = tokendata.brand_id;
        const { modGroupData, groups, items, menus } = this.props;
        return (
            <div className="page">
                <CheckChanges path="/edit-modgroup" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Modifier Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/mod-groups" className="text-primary">Modifier Groups</Link></li>
                                <li className="breadcrumb-item active">Edit Modifier Group</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <form method="post" className="form-validate">
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Mod Group Name <span className="text-danger">*</span></label>
                                                                <input id="modgroupname" disabled={this.state.brand_id == 3 ? true : false}  type="text" name="modgroupname" defaultValue={modGroupData.mod_group_name} required data-msg="Please enter Modifier Group Name" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Arabic Name <span className="text-danger">*</span></label>
                                                                <input disabled={this.state.brand_id == 3 ? true : false}  id="name_ar" type="text" name="name_ar" defaultValue={modGroupData.name_ar} required data-msg="Please enter Modifier Group Name" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Secondary name<span className="text-danger">*</span></label>
                                                                <input disabled={this.state.brand_id == 3 ? true : false}  id="mod_group_secondary_name" type="text" name="mod_group_secondary_name" defaultValue={this.state.mod_group_secondary_name} required data-msg="Please enter Modifier Group Name" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Brand <span className="text-danger">*</span></label>
                                                                <Select
                                                                    disabled={this.state.brand_id == 3 ? true : false} 
                                                                    name="brand"
                                                                    isSearchable
                                                                    value={{ label: this.state.brand_name, value: this.state.brand_id }}
                                                                    options={roleId == 1 ? this.props.brands : this.props.brands.filter(o1 => brandId === o1.brand_id)}
                                                                    className="text-capitalize select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleBrand(e, i)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Menu <span className="text-danger">*</span></label>
                                                                <select name="menu" className="form-control text-capitalize mt-2" required data-msg="Please select Menu" onChange={this.handleItemsByMenuId}>
                                                                    <option disabled>Select Menu</option>
                                                                    {menus &&
                                                                        menus.map((menu, index) => (
                                                                            <option disabled={this.state.brand_id == 3 ? true : false}  key={index} value={menu.menu_id} {...(modGroupData.menu_id && modGroupData.menu_id === menu.menu_id) && { selected: true }}>{menu.menu_name} - {menu.store_type_name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Modifiers Level <span className="text-danger">*</span></label>
                                                                <select name="level" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleModifiersLevel}>
                                                                    <option value='group' {...modGroupData.type == 'group' && { selected: true }}>Group</option>
                                                                    <option value='item' {...modGroupData.type == 'item' && { selected: true }}>Menu Item</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col">
                                                            <div className='mt-5'>
                                                                <input id="checkboxCustom1" type="checkbox" name="taxstatus" checked={this.state.use_secondary_name} onChange={this.handleSecondaryNameCheck} className="checkbox-template" />
                                                                <label htmlFor="checkboxCustom1">Use secondary name</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">ERP Id</label>
                                                                <input disabled={this.state.brand_id == 3 ? true : false}  id="modgroupdesc" type="text" name="erp_id" defaultValue={modGroupData.erp_id} className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        {this.state.level === "group" &&
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Group <span className="text-danger">*</span></label>
                                                                    <Select
                                                                        isMulti
                                                                        name="groups"
                                                                        value={groupJson}
                                                                        options={groups}
                                                                        // options={groups.filter(o1 => o1.menu_id == this.state.menu)}
                                                                        className="text-capitalize basic-multi-select mt-2"
                                                                        classNamePrefix="select"
                                                                        onChange={(e, i) => this.handleGroupsInputChange(e, i)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.level === "item" &&
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Select Items <span className="text-danger">*</span></label>
                                                                    <Select
                                                                        isMulti
                                                                        name="items"
                                                                        options={items}
                                                                        // options={items.filter(o1 => o1.menu_id == this.state.menu)}
                                                                        value={itemsJson}
                                                                        className="text-capitalize basic-multi-select mt-2"
                                                                        classNamePrefix="select"
                                                                        onChange={(e, i) => this.handleItemsInputChange(e, i)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Description</label>
                                                                <input id="modgroupdesc" type="text" name="modgroupdesc" defaultValue={modGroupData.mod_group_description} className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Modifier Type <span className="text-danger">*</span></label>
                                                                <select name="type" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleInputChange}>
                                                                    <option {...modGroupData.modifiers_type === 'single' && { selected: true }} value='single'>Single Select</option>
                                                                    <option {...modGroupData.modifiers_type === 'multiple' && { selected: true }} value='multiple' >Multi Select</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Priority</label>
                                                                <input id="priority" type="number" name="priority" defaultValue={modGroupData.priority} required data-msg="Please enter Priority" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Min Quantity<span className="text-danger">*</span></label>
                                                                <input id="min_quantity" type="number" name="min_quantity" value={this.state.min_quantity} required data-msg="Please enter min" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Max Quantity<span className="text-danger">*</span></label>
                                                                <input id="max_quantity" type="number" name="max_quantity" value={this.state.max_quantity} required data-msg="Please enter max" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group d-flex justify-content-end mt-4">
                                                        <button onClick={this.handleSubmit} disabled={!this.isModGroupReady()} className="btn btn-primary">Update Modifier Group</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        menus: state.menu.menus,
        modGroupData: state.menu.modGroupData,
        groups: state.menu.groupsptions,
        items: state.menu.allActiveItems,
        brands: state.brand.brands
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        editModGroup: function (id: any, data: any) {
            dispatch(editModGroup(id, data));
        },
        getModGroup: function (id: number) {
            dispatch(getModGroup(id));
        },
        menusList: function (brand_id: any) {
            dispatch(menusList(brand_id))
        },
        groupsList: function (menu_id: any) {
            dispatch(groupsListForMultiSelect(menu_id))
        },
        itemsListForMultiSelect: function (menu_id: any) {
            dispatch(itemsListForMultiSelect(menu_id))
        },
        brandsList: () => {
            dispatch(brandsList())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditMod);