import { NOTIFICATIONS_LIST, GET_NOTIFICATION } from './notificationType'
import Api from '../../components/Api';
import jwt from 'jsonwebtoken'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
export const notificationList = (brand_id:number) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/notifications/'+brand_id, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: NOTIFICATIONS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                dispatch({
                    type: NOTIFICATIONS_LIST,
                    payload: []
                })
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getNotification = (id: number) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/notification/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_NOTIFICATION,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addNotification = (formData: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        formData.user_info = jwt.decode(token);
        Api.post('/admin/add_notification', formData, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/marketing/3";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editNotification = (id: any, formData: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        formData.user_info = jwt.decode(token);
        Api.put(`/admin/edit_notification/${id}`, formData, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/marketing/3";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const deleteNotification = (id: number, history: any,title:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        let data = {
            title: title,
            user_info: jwt.decode(token)
        }
        Api.post(`/admin/del_notification/${id}`,data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    history.push("/marketing/3")
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const activeInactiveNotification = (id: any, isActive: any, history: any,title:any) => {
    return function (dispatch: any) {
        let is_active:any;
        if (isActive === 0) {
            is_active = 1    //is_active =1 it's mean that Notification is active
        } else if (isActive === 1) {
            is_active = 0      //is_archive =0 it's mean that Notification is IN active
        }
        let token: any = sessionStorage.getItem('token');
        let data = {
            is_active: is_active,
            title:title,
            user_info: jwt.decode(token)
        }
        Api.put(`/admin/notification_active_inactive/${id}`,data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    history.push("/marketing/3")
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}