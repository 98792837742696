//map.js
import React from "react";
import { Map, GoogleApiWrapper, HeatMap } from "google-maps-react";
import { GoogleMapsAPI } from "../../client-config";
import { data } from './data'
// import "./styles.css";

class MapContainer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            ordersDAta: [],
            store_id: null
        }
    }

    render() {
        const gradient = [
            "rgba(0, 255, 255, 0)",
            "rgba(0, 255, 255, 1)",
            "rgba(0, 191, 255, 1)",
            "rgba(0, 127, 255, 1)",
            "rgba(0, 63, 255, 1)",
            "rgba(0, 0, 255, 1)",
            "rgba(0, 0, 223, 1)",
            "rgba(0, 0, 191, 1)",
            "rgba(0, 0, 159, 1)",
            "rgba(0, 0, 127, 1)",
            "rgba(63, 0, 91, 1)",
            "rgba(127, 0, 63, 1)",
            "rgba(191, 0, 31, 1)",
            "rgba(255, 0, 0, 1)"
        ];
        return (
            <div className="map-container">
                <Map
                    google={this.props.google}
                    className={"map"}
                    zoom={10}
                    initialCenter={this.props.center}
                >
                    <HeatMap
                        gradient={gradient}
                        positions={this.props.data}
                        opacity={1}
                        radius={20}
                    />
                </Map>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: GoogleMapsAPI,
    libraries: ["visualization"]
})(MapContainer);
