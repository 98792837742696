import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import { logoutUser, getCity, editCity, countryList } from '../../../redux'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import CheckChanges from '../../../components/confirmOnLeave'
import { EditCityProps } from '../../../interfaces/cities';

class EditCity extends Component<EditCityProps, { countryId: any, name: any }> {
    constructor(props: any) {
        super(props);
        this.state = {
            countryId: null,
            name: ''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.getCity(id, this.setCityState);
        document.title = "SimplexCMS | Cities"
        this.props.countryList();
    }

    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({ countryId: Number(event.target.value) })
    }
    setCityState = (value: any, Id2: any, n2: any) => {

        this.setState({ name: value, countryId: Id2 })

    }

    isCityReady = () => {
        if (this.state.countryId == '') {
            return false;
        }
        return (this.state.name !== "");
    }
    handleSubmit = () => {
        let { countryId, name } = this.state
        let id = this.props.match.params.id;
        let data = {
            name,
            country_id: countryId,
        }
        this.props.editCity(Number(id), data);
    }

    blockInvalidChar = (e: any) => {
        var regex = new RegExp("[a-zA-Z]");
        let specialkey: any = e.keyCode;
        var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (specialkey == 8 || specialkey == 9) {
            return true;
        } else {
            if (/\S/.test(e.target.value)) {
                // string is not empty and not just whitespace
                if (!regex.test(key)) {
                    if (specialkey == 32) {
                    } else {
                        e.preventDefault()
                    }
                }
            } else {
                ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
            }
        }
    }
    handleCityInput = (e: any) => {
        this.setState({ name: e.target.value })
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const { countries } = this.props;
        let id = this.props.match.params.id;
        return (
            <div className="page">
                <CheckChanges path="/edit-city" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">City Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/cities" className="text-primary">City</Link></li>
                                <li className="breadcrumb-item active">Edit City</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Country <span className="text-danger">*</span></label>
                                                            <select name="country" value={this.state.countryId} className="form-control mt-2" required data-msg="Please enter Country" onChange={this.handleInputChange}>
                                                                <option value="">Select Country</option>
                                                                {countries &&
                                                                    countries.map((country: any, index: any) => (
                                                                        <option key={index} defaultValue={this.state.countryId} value={country.country_id}>{country.country_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">City name <span className="text-danger">*</span></label>
                                                            <input id="cityname" type="text" name="cityname" onKeyDown={this.blockInvalidChar} value={this.state.name} required data-msg="Please enter city Name" className="input-material" onChange={this.handleCityInput} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    {/* <input type="reset" value="cancel" className="btn btn-danger mr-2" /> */}
                                                    <button onClick={this.handleSubmit} disabled={!this.isCityReady()} className="btn btn-primary">Update City</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        cityname: state.city.cityname,
        countryid: state.city.countryid,
        countries: state.store.countries
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: () => { dispatch(logoutUser()); },
        editCity: (id: any, data: any) => { dispatch(editCity(id, data)); },
        getCity: (id: number, setCityState: any) => { dispatch(getCity(id, setCityState)) },
        countryList: () => { dispatch(countryList()) },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditCity);