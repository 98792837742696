import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import { connect } from 'react-redux'
import { logoutUser, userGroupsList, delUserGroup, blockunblockuserGroup, brandsList } from '../../../redux'
import { UserGroupProps } from '../../../interfaces/settings';
import Select from 'react-select'

class ActionFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    handleDelete = (id: any, name: any) => {
        this.props.data.delUserGroup(id, name)
    };
    handleBlockUnblock = (id: any, status: any, name: any) => {
        this.props.data.blockunblockuserGroup(id, status, name);
    };
    render() {
        const { row } = this.props
        return (
            <div>
                {(row.all_access == 1 && row.brand_id) &&
                    <>
                        <button title={row.is_active == 0 ? "Active" : "InActive"} data-toggle="modal" data-target={`#blockunblock${row.id}`} className={row.is_active == 0 ? "btn btn-outline-success mr-2" : "btn btn-outline-danger mr-2"}><i className={row.is_active == 0 ? "fa fa-unlock" : "fa fa-lock"}></i></button>
                        <button title="Delete User Group" className="btn btn-outline-danger" data-toggle="modal" data-target={`#DelUser${row.id}`}><i className="fa fa-trash"></i></button>
                        {row.default_group == 0 && <Link title="Edit User Group" className="btn btn-outline-primary ml-2" to={`/edit-usergroup/${row.id}`}><i className="fa fa-edit"></i></Link>}
                    </>
                }
                {/* <!-- Modal--> */}
                <div id={`DelUser${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Delete User Group</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to delete this user group?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                                <button onClick={() => this.handleDelete(row.id, row.name)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Block/Unblock Modal--> */}
                <div id={`blockunblock${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{row.is_active == 0 ? "Active" : "InActive"} User Group</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to {row.is_active == 0 ? "Active" : "InActive"} this user group?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.handleBlockUnblock(row.id, row.is_active, row.name)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
class GroupStoreFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    render() {
        const { row } = this.props;
        return (
            <div>
                {row.group_store_id &&
                    <button title="View Stores" data-toggle="modal" data-target={`#groupStores${row.id}`} className="btn btn-outline-info">{row.group_store_id.length} {row.group_store_id.length > 1 ? `Stores` : `Store`}</button>
                }
                {/* <!-- Modal--> */}
                <div id={`groupStores${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Group Stores</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        {row.group_store_id.map((element: any, index: any) => {
                                            return <p>{`${index + 1}) ${element.store_id? element.store_id.store_name:''}`}</p>
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} data={props} />
    );
}
function groupStoreFormatter(cell: any, row: any, props: any) {
    return (
        <GroupStoreFormatter row={row} data={props} />
    );
}
function statusFormatter(cell: any) {
    return (
        <div className="text-capitalize">
            <span {...(cell == 1 && { className: "badge badge-success p-2" }) || (cell == 0 && { className: "badge badge-danger p-2" })}>{cell == 1 ? "Active" : "Inactive"}</span>
        </div>
    )
}
function defaultFormatter(cell: any) {
    // console.log("cell",cell);
    return (
        <div className="text-capitalize">
            <span {...(cell == 1 ? { title: "True" } : { title: "False" })}>{cell == 1 ? "True" : "False"}</span>
        </div>
    )
}
class UserGroups extends Component<UserGroupProps, { brand_id: any }> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            brand_id: {
                value: 0,
                label: "All"
            }
        }
    }
    componentDidMount() {
        let tokendata: any = jwt.decode(sessionStorage.token)
        let brandId = tokendata.brand_id;
        let roleId = tokendata.role_id;
        this.props.brandsList();
        if (roleId == 1) {
            this.props.userGroupsList(this.state.brand_id.value);
        } else {
            this.props.userGroupsList(brandId);
        }
        document.title = "SimplexCMS | User Groups"
    }
    handleBrandsInputChange = (e: any, index: any) => {
        this.setState({ brand_id: { value: e.value, label: e.label } })
        if (e && e.value > 0) {
            this.props.userGroupsList(e.value)
        } else {
            this.props.userGroupsList(0)
        }
    };
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let tokendata: any = jwt.decode(sessionStorage.token)
        let roleId = tokendata.role_id;
        const options: any = {
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/add-usergroup" /> },
            noDataText: 'User Groups Not Found'
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="row py-2">
                                    <div className="col-lg-8 col-md-8 col-12">
                                        <h4 className="mt-2">Groups Management</h4>
                                    </div>
                                    {roleId == 1 &&
                                        <div className="col-lg-4 col-md-4 col-12">
                                            <div className="row">
                                                <div className="col-12">
                                                    <Select
                                                        name="brand_id"
                                                        options={this.props.brands}
                                                        value={this.state.brand_id}
                                                        className="text-capitalize select mt-2"
                                                        classNamePrefix="select"
                                                        onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <BootstrapTable version='4' data={this.props.userGroups} search={true} pagination={this.props.userGroups.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='userGroups.csv' hover>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='id' csvHeader='#' width='70' dataSort={true} isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='name' csvHeader='Name' width='120' columnTitle>Name</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='group_store_id' dataFormat={groupStoreFormatter} width='150' columnTitle export={false}>Stores</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='default_group' csvHeader='default' width='100' dataFormat={defaultFormatter} columnTitle>Default</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='brand_name' csvHeader='Brand' width='120' columnTitle>Brand</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='is_active' width='100' dataFormat={statusFormatter} export={false}>Status</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='180' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section >
                        <Footer />
                    </div>

                </div>
            </div>
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        userGroups: state.setting.userGroups,
        brands: state.brand.brands
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        userGroupsList: function (brand: any) {
            dispatch(userGroupsList(brand))
        },
        delUserGroup: function (id: any, group_name: any) {
            dispatch(delUserGroup(id, group_name))
        },
        blockunblockuserGroup: function (id: any, status: any, group_name: any) {
            dispatch(blockunblockuserGroup(id, status, group_name))
        },
        brandsList: function () {
            dispatch(brandsList("GET_BY_BRAND"))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserGroups);