import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { logoutUser, brandsList, delBrand, blockunblockbrand } from '../../redux'
import { BrandProps } from '../../interfaces/brands';
import { getBrandDetails } from '../../redux/actions/brandAction';
class ActionFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    handleDelete = (id: any) => {
        this.props.data.delBrand(id,this.props.row.brand_name)
    };
    handleBlockUnblock = (id: any, is_active: any) => {
        this.props.data.blockunblock(id, is_active,this.props.row.brand_name);
    };

   
    render() {
        const { row } = this.props
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <button className="btn btn-primary">Authorize Foodics</button>
                    </div>
                </div>
            </div>
            // <div>
            //     <button title={row.is_active === 0 ? "UnBlock" : "Block"} data-toggle="modal" data-target={`#blockunblock${row.brand_id}`} className={row.is_active === 0 ? "btn btn-outline-success" : "btn btn-outline-danger"}><i className={row.is_active === 0 ? "fa fa-unlock" : "fa fa-lock"}></i></button>
            //     <button title="Delete Brand" className="btn btn-outline-danger ml-2" data-toggle="modal" data-target={`#DelBrand${row.brand_id}`}><i className="fa fa-trash"></i></button>
            //     <Link title="Edit Brand" className="btn btn-outline-primary ml-2" to={`/edit-brand/${row.brand_id}`}><i className="fa fa-edit"></i></Link>
            //     {/* <!-- Block/Unblock Modal--> */}
            //     <div id={`blockunblock${row.brand_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
            //         <div role="document" className="modal-dialog">
            //             <div className="modal-content">
            //                 <div className="modal-header">
            //                     <h4 id="exampleModalLabel" className="modal-title">{row.is_active === 0 ? "UnBlock" : "Block"} Brand</h4>
            //                     <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
            //                 </div>
            //                 <div className="modal-body">
            //                     <p>Are you sure,you want to {row.is_active === 0 ? "UnBlock" : "Block"} this brand?</p>
            //                 </div>
            //                 <div className="modal-footer">
            //                     <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
            //                     <button onClick={() => this.handleBlockUnblock(row.brand_id, row.is_active)} className="btn btn-primary">Confirm</button>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            //     {/* <!-- Modal--> */}
            //     <div id={`DelBrand${row.brand_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
            //         <div role="document" className="modal-dialog">
            //             <div className="modal-content">
            //                 <div className="modal-header">
            //                     <h4 id="exampleModalLabel" className="modal-title">Delete Brand</h4>
            //                     <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
            //                 </div>
            //                 <div className="modal-body">
            //                     <p>Are you sure, you want to delete this brand?</p>
            //                 </div>
            //                 <div className="modal-footer">
            //                     <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
            //                     <button onClick={() => this.handleDelete(row.brand_id)} className="btn btn-primary">Confirm</button>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </div>
        )
    }
}
function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} data={props} />
    );
}

class BrandAuthorizeFoodics extends Component<{getBraoundDetails:()=>{},logoutUser:()=>{},data:any}, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    componentDidMount() {
        this.props.getBrandDetails()
        document.title = "SimplexCMS | Authorize Foodics"
    }
    isBlocked = () => {
        let {data} = this.props
        if(data) {
            console.log(data.foodics_integration != 0 && (data.foodics_client_id != null && data.foodics_client_id != ''))
            return data.foodics_integration == 0 && (data.foodics_client_id == null || data.foodics_client_id == '')
        } else {
            console.log('else')
            return false
        }
    } 
    handleRedirect = () => {
        let {data} = this.props
        sessionStorage.removeItem('token')
        // 'https://console-sandbox.foodics.com/authorize?client_id=964fa9b7-07bb-43cd-b111-918ef90ae933&state=random_string'
        // window.location.href = `https://console-sandbox.foodics.com/authorize?client_id=${data.foodics_client_id}&state=random_string`
        window.location.href = 'https://console.foodics.com/authorize?client_id=97278209-7a8d-47f7-9280-efdabac66a79&state=random_string'
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const options: any = {
            // sizePerPageList: [5,10,15],
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/add-authorization" /> },
            noDataText: 'Brands Not Found'
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Authorization Management</h4>
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                {/* <BootstrapTable version='4' data={[]} search={true} pagination={this.props.data.length > 10 && true} options={options} hover>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='200' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                                                </BootstrapTable> */}
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <button style={{width:'100%'}} onClick={this.handleRedirect} className="btn btn-primary">Authorize Foodics</button>
                                                        </div>
                                                    </div>
                                                 </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        data: state.brand.brand_details,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        getBrandDetails:()=>{
            dispatch(getBrandDetails())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BrandAuthorizeFoodics);