import { DRIVER_LIST, ADD_DRIVER, EDIT_DRIVER, GET_DRIVER, } from './driverTypes'
import Api from '../../components/Api';
import jwt from 'jsonwebtoken'
import { storesList } from './storeAction';


export const driversList = (brand?:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/drivers/all-drivers/${brand}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: DRIVER_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const handleClockIn = (id: any, clockIn: any, email_address: any) => {
    return function (dispatch: any) {
        let clockInOut: any;
        if (clockIn == 0) {
            clockInOut = 1
        } else if (clockIn == 1) {
            clockInOut = 0
        }
        let token: any = sessionStorage.getItem('token');
        let data = {
            clockIn: clockInOut,
            email_address:email_address,
            user_info: jwt.decode(token)
        }
        Api.put(`/drivers/driver_clockin/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/drivers"
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addDriver = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/drivers/add_driver', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/drivers";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_DUP_ENTRY") {
                            error = "Driver Already Exist"
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    dispatch({
                        type: ADD_DRIVER,
                        isInserted: false,
                        payload: error
                    })
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editDriver = (id: any, data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/drivers/edit_driver/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/drivers";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    dispatch({
                        type: EDIT_DRIVER,
                        isUpdated: false,
                        payload: error
                    })
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getDriver = (id: number) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/drivers/driver/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_DRIVER,
                        payload: response.data.successResponse
                    })
                    dispatch(storesList(response.data.successResponse.brand_id))
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const delDriver = (id: number) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.delete(`/drivers/del_driver/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/drivers";
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
// export const blockunblockuser = (id: any, status: any) => {
//     return function (dispatch: any) {
//         let is_active;
//         if (status == 0) {
//             is_active = 1
//         } else if (status == 1) {
//             is_active = 0
//         }
//         let token: any = sessionStorage.getItem('token');
//         Api.put(`/drivers/driver_block_unblock/${id}`, {
//             status: is_active
//         }, {
//             headers: { 'Authorization': 'Bearer ' + token }
//         })
//             .then((response) => {
//                 if (response.data.success) {
//                     window.location.href = "/drivers"
//                 }
//             }).catch(err => {
//                 if (err.response) {
//                     console.log(err.response.data.message)
//                 } else {
//                     alert(err.message)
//                 }
//             });
//     }
// }

export const blockunblockuser = (id: any, status: any, email_address: any) => {
    return function (dispatch: any) {
        let is_active: any;
        if (status == 0) {
            is_active = 1
        } else if (status == 1) {
            is_active = 0
        }
        let token: any = sessionStorage.getItem('token');
        let data = {
            status: is_active,
            email_address:email_address,
            user_info: jwt.decode(token)
        }
        Api.put(`/drivers/driver_block_unblock/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/drivers"
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}