import { HANDLE_INPUT, BRANDS_LIST, ADD_BRAND, EDIT_BRAND, GET_BRAND, TAGS_LIST, TAG_DATA, ACTIVE_TAGS } from '../actions/brandType'
const initialState: any = {
    brandname: "",
    branddetails: "",
    brandtype: "",
    brands: [] || "",
    message: "",
    isInserted: "",
    isUpdated: "",
    tags:[] || "",
    tag:'',
    activetags:[] || '',
    foodics_integration:null,
    foodics_client_secret:'',
    foodics_client_id:'',
    website_link:'',
    brand_details:'',
    erp_id:"",
    payment:0
}

const brandReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case HANDLE_INPUT: return {
            ...state,
            [action.input.name]: action.input.value
        }
        case BRANDS_LIST: return {
            ...state,
            brands: action.payload
        }
        case ADD_BRAND: return {
            ...state,
            message: action.payload,
            isInserted: action.isInserted,
        }
        case EDIT_BRAND: return {
            ...state,
            message: action.payload,
            isUpdated: action.isUpdated
        }
        case GET_BRAND: return {
            ...state,
            brandname:action.payload.brand_name,
            branddetails:action.payload.brand_details,
            brandtype: action.payload.brand_type,
            foodics_integration:action.payload.foodics_integration,
            erp_integration:action.payload.erp_integration,
            foodics_client_secret:action.payload.foodics_client_secret,
            foodics_client_id:action.payload.foodics_client_id,
            website_link:action.payload.website_link,
            erp_id:action.payload.brand_unique_code,
            payment:action.payload.payment
        }
        case TAGS_LIST: return {
            ...state,
            tags: action.payload
        }
        case TAG_DATA: return {
            ...state,
            tag:action.payload
        }
        case ACTIVE_TAGS: return {
            ...state,
            activetags:action.payload
        }
        case "BRAND_DETAILS":return {
            ...state,
            brand_details:action.payload
        }
        default: return state;
    }
}
export default brandReducer;