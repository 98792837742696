import React, { Component } from 'react';
import { AddGroupProps, AddGroupState } from '../../../interfaces/menu'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import { addGroup, brandsList, logoutUser, menusList, storesListForMultiSelect } from '../../../redux'
import { Link, Redirect } from 'react-router-dom';
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import CheckChanges from '../../../components/confirmOnLeave'
import Select from 'react-select';
class AddGroup extends Component<AddGroupProps, AddGroupState> {
    orderModes: { value: string; label: string; }[];
    weeklyDays: { value: string; label: string; }[];
    constructor(props: any) {
        super(props);
        this.orderModes = [
            { value: 'online', label: 'Online' },
            { value: 'callcenter', label: 'Call Center' }
        ];

        this.weeklyDays = [
            { value: 'MONDAY', label: 'Mon' },
            { value: 'TUESDAY', label: 'Tue' },
            { value: 'WEDNESDAY', label: 'Wed' },
            { value: 'THURSDAY', label: 'Thu' },
            { value: 'FRIDAY', label: 'Fri' },
            { value: 'SATURDAY', label: 'Sat' },
            { value: 'SUNDAY', label: 'Sun' },
        ];
        this.state = {
            groupname: "",
            name_ar:"",
            menu: "",
            image: "",
            priority: "",
            mode: [],
            is_lsm: '0',
            storesJson: [],
            specific_days: false,
            daysTiming: [
                {
                    day: "",
                    timePeriods: [{
                        open: "",
                        close: ""
                    }]
                }
            ]
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        this.props.brandsList()
        document.title = "SimplexCMS | Groups"
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    blockInvalidChar = (e: any) => {
        var regex = new RegExp("[a-zA-Z]");
        let specialkey: any = e.keyCode;
        var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (specialkey == 8 || specialkey == 9) {
            return true;
        } else {
            if (/\S/.test(e.target.value)) {
                // string is not empty and not just whitespace
                if (!regex.test(key)) {
                    if (specialkey == 32) {
                    } else {
                        e.preventDefault()
                    }
                }
            } else {
                ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
            }
        }
    }
    handleOrderModesInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ mode: e });
        } else {
            this.setState({ mode: [] });
        }
    };
    handleStoresInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ storesJson: e });
        } else {
            this.setState({ storesJson: [] });
        }
    };
    handleChangeRad = (event: { target: { name: any; value: any; } }) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    fileSelectedHandler = (e: any) => {
        var size = 1048576;
        if (e.target.files[0].size > size) {
            alert("Image size too large. Upload image upto 1mb")
        }
        else {
            this.setState({ image: e.target.files })
        }
    }

    handleSpecificDaysCheck = (event: { target: { name: any; value: any; }; }) => {
        const { specific_days } = this.state;
        this.setState({ specific_days: !specific_days })
    }

    handleWeeklyDaysInputChange = (e: any, index: any, pindex?: any) => {
        if (e.target) {
            const { name, value } = e.target;
            const list: any = this.state.daysTiming;
            let timeP = list[index].timePeriods;
            timeP[pindex][name] = value;
            list[index].timePeriods = timeP;
            this.setState({ daysTiming: list });
        } else {
            console.log("e", e)
            const list: any = this.state.daysTiming;
            list[index]["day"] = e.value;
            list[index]["label"] = e.label;
            this.setState({ daysTiming: list });
        }
    };

    handleWeeklyDaysRemoveClick = (index: any, pindex?: any) => {
        const list = this.state.daysTiming;
        if (pindex >= 0) {
            let timePer = list[index].timePeriods;
            timePer.splice(pindex, 1);
            list[index].timePeriods = timePer;
            this.setState({ daysTiming: list });
        } else {
            list.splice(index, 1);
            this.setState({ daysTiming: list });
        }
    };

    handleweeklyDaysAddClick = (e: any, i: any, pindex?: any) => {
        const list: any = this.state.daysTiming;
        if (pindex >= 0) {
            if (list[i].timePeriods[list[i].timePeriods.length - 1].open == "" || list[i].timePeriods[list[i].timePeriods.length - 1].close == "") {
                alert("Please fill in selected day hours")
            } else {
                let timePeriods = {
                    open: "",
                    close: ""
                }
                list[i].timePeriods.push(timePeriods)
                this.setState({ daysTiming: list });
            }
        } else {
            if (list[i]["day"] == "" || list[i].timePeriods[list[i].timePeriods.length - 1].open == "" || list[i].timePeriods[list[i].timePeriods.length - 1].close == "") {
                alert("Please fill in selected day timing")
            } else {
                let timePeriods = {
                    open: "",
                    close: ""
                }
                const newlist: any = {
                    day: "",
                    timePeriods: []
                }
                newlist.timePeriods.push(timePeriods)
                const days: any = this.state.daysTiming.concat(newlist)
                this.setState({ daysTiming: days });
            }
        }
        e.preventDefault()
    };

    isGroupReady = () => {
        const { groupname, menu, image, mode, is_lsm, storesJson } = this.state
        if (is_lsm == "0") {
            return (groupname !== "" && mode.length > 0 && menu !== "" && image !== "");
        } else if (is_lsm == "1") {
            return (groupname !== "" && mode.length > 0 && menu !== "" && image !== "" && storesJson.length > 0);
        }
    }
    handleBrand = (e: any, index: any) => {
        if (e && e.value > 0) {
            this.props.storesList(e.value);
            this.props.menusList(e.value);
        } else {
            this.props.storesList(e.value);
            this.props.menusList(e.value);
        }
    }
    handleSaveBtnClick = () => {
        let { groupname, menu, image,name_ar, mode, priority, is_lsm, storesJson, specific_days, daysTiming } = this.state;
        const data: any = new FormData();
        const Days = JSON.stringify(daysTiming);
        data.append('file', image[0])
        data.append('group_name', groupname)
        data.append("name_ar",name_ar)
        data.append('menu_id', menu)
        data.append('mode', JSON.stringify(mode))
        if (is_lsm == '0') {
            data.append('is_lsm', 0)
        } else if (is_lsm == '1') {
            data.append('is_lsm', 1)
            data.append('stores_json', JSON.stringify(storesJson))
        }
        data.append('priority', priority)
        if (specific_days) {
            if (daysTiming[daysTiming.length - 1]["day"] !== "" && daysTiming[daysTiming.length - 1].timePeriods[daysTiming[daysTiming.length - 1].timePeriods.length - 1].open !== "" && daysTiming[daysTiming.length - 1].timePeriods[daysTiming[daysTiming.length - 1].timePeriods.length - 1].close !== "") {
                data.append('serving_hours', Days)
                this.props.addGroup(data);
            } else {
                alert("Please fill in selected day timing")
            }
        } else {
            this.props.addGroup(data);
        }
        // this.props.addGroup(data);
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let tokendata: any = jwt.decode(sessionStorage.token)
        let roleId = tokendata.role_id;
        let brandId = tokendata.brand_id;
        const { menus } = this.props;
        const { is_lsm, storesJson, specific_days, daysTiming } = this.state;
        return (
            <div className="page">
                <CheckChanges path="/add-group" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Groups Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/groups" className="text-primary">Groups</Link></li>
                                <li className="breadcrumb-item active">Add Group</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Group name <span className="text-danger">*</span></label>
                                                            <input id="groupname" type="text" name="groupname" onKeyDown={this.blockInvalidChar} required data-msg="Please enter Menu Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Arabic name <span className="text-danger">*</span></label>
                                                            <input id="name_ar" type="text" name="name_ar" onKeyDown={this.blockInvalidChar} required data-msg="Please enter Menu Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Brand <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="brand"
                                                                isSearchable
                                                                isClearable
                                                                options={roleId == 1 ? this.props.brands : this.props.brands.filter(o1 => brandId === o1.brand_id)}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleBrand(e, i)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Menu <span className="text-danger">*</span></label>
                                                            <select name="menu" className="form-control text-capitalize mt-2" required data-msg="Please select Menu" onChange={this.handleInputChange}>
                                                                <option>Select Menu</option>
                                                                {menus &&
                                                                    menus.map((menu, index) => (
                                                                        <option key={index} value={menu.menu_id}>{menu.menu_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label mb-3">Group Image <span className="text-danger">*</span></label>
                                                            <input id="file" type="file" name="file" className="form-control-file" onChange={this.fileSelectedHandler} />
                                                            <p><span>Dimensions 700x700</span> <span style={{ color: 'red' }}>recommended</span></p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Priority</label>
                                                            <input id="priority" type="number" name="priority" required data-msg="Please enter Priority" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col" >
                                                        <div className="form-group">
                                                            <label className="form-control-label">Ordering Mode <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="mode"
                                                                options={this.orderModes}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleOrderModesInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4 col-sm-4 col-4 mt-5" >
                                                        <div >
                                                            <input id="radioCustom3" type="radio" name="is_lsm" value="0" checked={is_lsm == '0'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom3">Available for all stores</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-4 col-4 mt-5" >
                                                        <div >
                                                            <input id="radioCustom4" type="radio" name="is_lsm" value="1" checked={is_lsm == '1'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom4">Available for specific stores</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-4 col-4 mt-5" >
                                                        <div >
                                                            <input id="checkboxCustom2" type="checkbox" name="specific_days" checked={specific_days} onChange={this.handleSpecificDaysCheck} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom2">Available for specific days</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {
                                                        is_lsm == '1' &&
                                                        <div className="col" >
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Store <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    defaultValue={storesJson}
                                                                    name="stores"
                                                                    options={this.props.stores}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                                {
                                                    specific_days && <div className='row'>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                {
                                                                    daysTiming.map((x: any, i: any) => {
                                                                        let timePeriod = x.timePeriods;
                                                                        return (
                                                                            <div key={i} className='mb-2' style={{ borderBottom: "1px dotted grey" }}>
                                                                                <div className="row">
                                                                                    <div className='col-lg-8 col-md-8 col-8'>
                                                                                        <div className="form-group">
                                                                                            <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                                            <Select
                                                                                                name="days"
                                                                                                options={this.weeklyDays.filter(o1 => !daysTiming.some((o2: any) => o1.value === o2.day))}
                                                                                                className="text-capitalize basic-multi-select mt-2"
                                                                                                classNamePrefix="select"
                                                                                                onChange={(e) => this.handleWeeklyDaysInputChange(e, i)}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-4 col-md-4 col-4 mt-4 py-2">
                                                                                        {daysTiming.length !== 1 &&
                                                                                            <button className="btn btn-sm btn-outline-danger"
                                                                                                onClick={() => this.handleWeeklyDaysRemoveClick(i)}><i className="fa fa-trash"></i></button>}
                                                                                        {(daysTiming.length - 1 === i && daysTiming.length < 7) && <button className="btn btn-sm btn-primary ml-2" onClick={(e: any) => this.handleweeklyDaysAddClick(e, i)}><i className="fa fa-plus"></i></button>}
                                                                                    </div>
                                                                                    {
                                                                                        timePeriod.map((t: any, pindex: any) => {
                                                                                            return (
                                                                                                <div className="row mb-3">
                                                                                                    <div className="col-lg-3 col-md-3">
                                                                                                    </div>

                                                                                                    <div className="col-lg-3 col-md-3 col-6">
                                                                                                        <div className="form-group">
                                                                                                            <label className="form-control-label mb-0">Open Time <span className="text-danger">*</span></label>
                                                                                                            <input id="open" type="time" name="open" value={x.open} className="input-material" onChange={(e) => this.handleWeeklyDaysInputChange(e, i, pindex)} />
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="col-lg-3 col-md-3 col-6">
                                                                                                        <div className="form-group">
                                                                                                            <label className="form-control-label mb-0">Close Time <small className="text-danger">*</small></label>
                                                                                                            <input id="close" type="time" name="close" value={x.close} className="input-material" onChange={(e) => this.handleWeeklyDaysInputChange(e, i, pindex)} />
                                                                                                            <small className="text-primary" style={{ cursor: 'pointer' }} onClick={(e: any) => this.handleweeklyDaysAddClick(e, i, pindex)}>+ Add hours</small>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="col-lg-3 col-md-3 col-12">
                                                                                                        {timePeriod.length !== 1 &&
                                                                                                            <button className="btn btn-sm btn-outline-danger"
                                                                                                                onClick={() => this.handleWeeklyDaysRemoveClick(i, pindex)}><i className="fa fa-trash"></i></button>}
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isGroupReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >

        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        menus: state.menu.menus,
        stores: state.menu.storesoptions,
        brands: state.brand.brands
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        addGroup: function (data: any) {
            dispatch(addGroup(data));
        },
        menusList: function (brand_id:any) {
            dispatch(menusList(brand_id))
        },
        storesList: (brand_id:any) => {
            dispatch(storesListForMultiSelect(brand_id))
        },
        logoutUser: function () {
            dispatch(logoutUser());
        },
        brandsList: () => {
            dispatch(brandsList())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddGroup);