import React, { useEffect, useRef, useState } from 'react'
import Timer from 'react-compound-timer'
import { connect} from 'react-redux';
import { cardOrdersList } from '../../redux';

const ReactTimer = (props:any) => {
    const [timerKey, setTimerKey] = useState(0);
    const handleTimerComplete = () => {
        let obj = { days: props.days,brand_id: 0 };
        props.ordersList(obj, false, props.updateLoader);
        setTimerKey(prevKey => prevKey + 1);
    };
    
   return (
        <Timer
            key={timerKey}
            initialTime={30000}
            direction="backward"
            checkpoints={[{ time: 0, callback: handleTimerComplete }]}
        >
            {() => (
                <React.Fragment>
                   <strong className="text-bold" style={{color:" #20A5D6", fontSize:"2em"}}>0<Timer.Minutes/>:<Timer.Seconds /></strong>
                </React.Fragment>
            )}
        </Timer>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        ordersList: (obj: any, datetime: any, updateLoader?: any) => {
            dispatch(cardOrdersList(obj, datetime, updateLoader))
        }
       
    }
}
export default connect(null,mapDispatchToProps)(ReactTimer);
