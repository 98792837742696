import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { EditModProps, EditModState } from '../../../interfaces/menu'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import { logoutUser, editModifier, getModifier, modGroupsList, storesListForMultiSelect, menusList, brandsList } from '../../../redux'
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import CheckChanges from '../../../components/confirmOnLeave'
import Select from 'react-select';

class EditMod extends Component<EditModProps, EditModState> {
    constructor(props: any) {
        super(props);
        this.state = {
            brand_id: null,
            brand_name: "",
            menu: "",
            modGroupJson: [],
            modifiername: "",
            priority: "",
            modifiertype: "",
            modifiercost: "",
            name_ar:"",
            modifiersale: "",
            images: null,
            is_lsm: '0',
            max_quantity: "",
            min_quantity: "",
            storesJson: []
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleItemsByMenuId = this.handleItemsByMenuId.bind(this);
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.brandsList();
        this.props.getModifier(id);
        document.title = "SimplexCMS | Modifiers"
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        if (this.state.brand_id == null) {
            this.setState({
                menu: nextProps.modData.menu_id && nextProps.modData.menu_id.menu_id,
                brand_id: nextProps.modData.brand_id && nextProps.modData.brand_id.brand_id,
                brand_name: nextProps.modData.brand_id && nextProps.modData.brand_id.brand_name,
                modifiername: nextProps.modData.modifier_name,
                modifiertype: nextProps.modData.modifier_type,
                modifiercost: nextProps.modData.modifier_cost_price,
                modifiersale: nextProps.modData.modifier_sale_price,
                max_quantity: nextProps.modData.max_quantity,
                name_ar:nextProps.modData.name_ar,
                min_quantity: nextProps.modData.min_quantity,
                storesJson: nextProps.modData.stores_json ? JSON.parse(nextProps.modData.stores_json) : this.state.storesJson,
                is_lsm: nextProps.modData.is_lsm,
            })
            if (nextProps.modData.menu_id && this.state.menu !== nextProps.modData.menu_id.menu_id) {
                this.setState({
                    priority: nextProps.modData.priority,
                    modGroupJson: nextProps.modData.mod_groups_json ? JSON.parse(nextProps.modData.mod_groups_json) : [],
                })
            }
        }
    }
    handleBrand = (e: any, index: any) => {
        if (e && e.value > 0) {
            this.setState({ brand_id: e.value, brand_name: e.label })
            this.props.storesList(e.value);
            this.props.menusList(e.value);
        }
    }
    handleItemsByMenuId(event: { target: { name: any; value: any; }; }) {
        this.props.modGroupsList(event.target.value);
        this.setState({ "menu": event.target.value, modGroupJson: [] })
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    fileSelectedHandler = (e: any) => {
        var size = 1048576;
        if (e.target.files[0].size > size) {
            alert("Image size too large. Upload image upto 1mb")
        }
        else {
            this.setState({ images: e.target.files })
        }
    }
    handleStoresInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ storesJson: e });
        } else {
            this.setState({ storesJson: [] });
        }
    };
    handleChangeRad = (event: { target: { name: any; value: any; } }) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    isModifierReady = () => {
        const { modifiername, modifiercost, modGroupJson, modifiersale, is_lsm, storesJson, menu, max_quantity, min_quantity } = this.state;
        if (is_lsm == "0") {
            return (menu !== "" && modifiername !== "" && (modGroupJson && modGroupJson.length > 0) && modifiercost !== "" && modifiersale !== "" && max_quantity !== "" && min_quantity !== "");
        } else if (is_lsm == "1") {
            return (menu !== "" && modifiername !== "" && (modGroupJson && modGroupJson.length > 0) && modifiercost !== "" && modifiersale !== "" && storesJson.length > 0 && max_quantity !== "" && min_quantity !== "");
        }
    }
    handleModGroupsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ modGroupJson: e });
        } else {
            this.setState({ modGroupJson: [] });
        }
    };
    handleSubmit = (event: any) => {
        let { modifiername, brand_id, menu,name_ar, priority, modifiertype, modifiercost, modGroupJson, modifiersale, is_lsm, storesJson, max_quantity, min_quantity } = this.state;
        let id = this.props.match.params.id;
        const data: any = new FormData()
        if (this.state.images) {
            for (var x = 0; x < this.state.images.length; x++) {
                data.append('files', this.state.images[x])
            }
        }
        data.append('modifier_name', modifiername)
        data.append('menu_id', menu)
        data.append("name_ar",name_ar)
        data.append('modifier_type', modifiertype)
        data.append('modifier_cost_price', parseFloat(modifiercost).toFixed(2))
        data.append('modifier_sale_price', parseFloat(modifiersale).toFixed(2))
        data.append('priority', priority)
        data.append('brand_id', brand_id)
        data.append('max_quantity', max_quantity)
        data.append('min_quantity', min_quantity)
        data.append('mod_groups_json', JSON.stringify(modGroupJson))
        if (is_lsm == '0') {
            data.append('is_lsm', 0)
        } else if (is_lsm == '1') {
            data.append('is_lsm', 1)
            data.append('stores_json', JSON.stringify(storesJson))
        }
        this.props.editModifier(id, data);
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let tokendata: any = jwt.decode(sessionStorage.token)
        let roleId = tokendata.role_id;
        let brandId = tokendata.brand_id;
        const { modGroupJson, is_lsm, storesJson } = this.state;
        const { menus, modData, modgroups } = this.props;
        return (
            <div className="page">
                <CheckChanges path="/edit-modifier" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Modifier Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/modifiers" className="text-primary">Modifiers</Link></li>
                                <li className="breadcrumb-item active">Edit Modifier</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Modifier Name <span className="text-danger">*</span></label>
                                                            <input disabled={this.state.brand_id == 3 ? true : false} id="modifiername" type="text" name="modifiername" defaultValue={modData.modifier_name} required data-msg="Please enter Modifier Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Arabic Name <span className="text-danger">*</span></label>
                                                            <input disabled={this.state.brand_id == 3 ? true : false}  id="name_ar" type="text" name="name_ar" defaultValue={modData.name_ar} required data-msg="Please enter Modifier Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">ERP Id <span className="text-danger">*</span></label>
                                                            <input disabled={true} id="modifiername" type="text" name="erp_id" defaultValue={modData.erp_id} required data-msg="Please enter Modifier Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Brand <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="brand"
                                                                isSearchable
                                                                value={{ label: this.state.brand_name, value: this.state.brand_id }}
                                                                options={roleId == 1 ? this.props.brands : this.props.brands.filter(o1 => brandId === o1.brand_id)}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleBrand(e, i)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Menu <span className="text-danger">*</span></label>
                                                            <select name="menu" className="form-control text-capitalize mt-2" required data-msg="Please select Menu" onChange={this.handleItemsByMenuId}>
                                                                <option value="">Select Menu</option>
                                                                {menus &&
                                                                    menus.map((menu, index) => (
                                                                        <option key={index} value={menu.menu_id} {...(modData.menu_id.menu_id === menu.menu_id) && { selected: true }}>{menu.menu_name} - {menu.store_type_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Modifier Group <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="groups"
                                                                value={modGroupJson}
                                                                options={modgroups}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleModGroupsInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-3 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Priority</label>
                                                            <input id="priority" type="text" name="priority" defaultValue={modData.priority} required data-msg="Please enter Priority" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Modifier Type</label>
                                                            <input id="modifiertype" type="text" name="modifiertype" defaultValue={modData.modifier_type} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label mb-3">Modifier Image</label>
                                                            <input id="files" type="file" name="files" className="form-control-file" onChange={this.fileSelectedHandler} multiple />
                                                            <small className="form-text">You can also choose multiple images.</small>
                                                            <p><span>Dimensions 700x700</span> <span style={{ color: 'red' }}>recommended</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Cost Price <span className="text-danger">*</span></label>
                                                            <input disabled={this.state.brand_id == 3 ? true : false}  id="modifiercost" type="number" name="modifiercost" defaultValue={modData.modifier_cost_price} required data-msg="Please enter Cost Price" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Sale Price <span className="text-danger">*</span></label>
                                                            <input  disabled={this.state.brand_id == 3 ? true : false} id="modifiersale" type="number" name="modifiersale" defaultValue={modData.modifier_sale_price} required data-msg="Please enter Sale Price" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Min Quantity<span className="text-danger">*</span></label>
                                                            <input disabled={this.state.brand_id == 3 ? true : false}  id="min_quantity" type="number" name="min_quantity" value={this.state.min_quantity} required data-msg="Please enter min" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Max Quantity<span className="text-danger">*</span></label>
                                                            <input disabled={this.state.brand_id == 3 ? true : false}  id="max_quantity" type="number" name="max_quantity" value={this.state.max_quantity} required data-msg="Please enter max" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 col-sm-6 col-6 mt-5" >
                                                        <div className="i-checks">
                                                            <input id="radioCustom3" type="radio" name="is_lsm" value="0" checked={is_lsm == '0'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom3">Available for all stores</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-sm-6 col-6 mt-5" >
                                                        <div className="i-checks">
                                                            <input id="radioCustom4" type="radio" name="is_lsm" value="1" checked={is_lsm == '1'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom4">Available for specific stores</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {
                                                        is_lsm == '1' &&
                                                        <div className="col" >
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Store <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    defaultValue={storesJson}
                                                                    name="stores"
                                                                    options={this.props.stores}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button onClick={this.handleSubmit} disabled={!this.isModifierReady()} className="btn btn-primary">Update Modifier</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>

                </div>
            </div >
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        menus: state.menu.menus,
        modData: state.menu.modData,
        modgroups: state.menu.modgroups,
        stores: state.menu.storesoptions,
        brands: state.brand.brands
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        editModifier: function (id: any, data: any) {
            dispatch(editModifier(id, data));
        },
        menusList: function (brand:any) {
            dispatch(menusList(brand))
        },
        getModifier: function (id: number) {
            dispatch(getModifier(id));
        },
        modGroupsList: function (menu_id: any) {
            dispatch(modGroupsList(menu_id))
        },
        storesList: (brand:any) => {
            dispatch(storesListForMultiSelect(brand))
        },
        brandsList: () => {
            dispatch(brandsList())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditMod);