import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import { connect } from 'react-redux'
import { groupsList, blockunblockgroup, logoutUser, brandsList } from '../../../redux'
import { GroupProps } from '../../../interfaces/menu';
import { itemsListByGroupId } from '../../../redux/actions/menuAction';
import Select from 'react-select'
class ActionFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    showitems = (id: any) => {
        this.props.itemsdata.itemsListByGroupId(id)
    }
    handleBlockUnblock = (id: any, is_active: any) => {
        this.props.itemsdata.blockunblockgroup(id, is_active, this.props.row.group_name);
    };
    render() {
        const { row, itemsdata } = this.props
        return (
            <div>
                <button title={row.is_active === 0 ? "UnBlock" : "Block"} data-toggle="modal" data-target={`#blockunblock${row.group_id}`} className={row.is_active === 0 ? "btn btn-outline-success mr-2" : "btn btn-outline-danger mr-2"}><i className={row.is_active === 0 ? "fa fa-unlock" : "fa fa-lock"}></i></button>
                <button title="View Items" data-toggle="modal" data-target={`#viewitems${row.group_id}`} className="btn btn-outline-info" onClick={() => this.showitems(row.group_id)}><i className="fa fa-list"></i></button>
                <Link title="Edit Group" className="btn btn-outline-primary ml-2" to={`/edit-group/${row.group_id}`}><i className="fa fa-edit"></i></Link>
                {/* <!-- Modal--> */}
                <div id={`viewitems${row.group_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Menu Items</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group text-capitalize">
                                            {(itemsdata.items.length > 0) ?
                                                <BootstrapTable version='4' data={itemsdata.items} hover>
                                                    <TableHeaderColumn dataField="menu_item_id" columnTitle isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='item_name' columnTitle>Menu Item Name</TableHeaderColumn>
                                                </BootstrapTable> :
                                                <p className="text-center">Items not found</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Block/Unblock Modal--> */}
                <div id={`blockunblock${row.group_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{row.is_active === 0 ? "UnBlock" : "Block"} Group</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to {row.is_active === 0 ? "UnBlock" : "Block"} this group?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.handleBlockUnblock(row.group_id, row.is_active)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} itemsdata={props} />
    );
}
function orderModesFormatter(cell: any, row: any, props: any) {
    return (
        <OrderModesFormatter row={row} data={props} />
    );
}
class OrderModesFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    IsJsonString = (str: any) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    render() {
        const { row } = this.props;
        return (
            <div>
                {row.mode &&
                    <button title="View Modes" data-toggle="modal" data-target={`#groupmodes${row.group_id}`} className="btn btn-outline-info"><i className="fa fa-list"></i></button>
                }
                {/* <!-- Modal--> */}
                <div id={`groupmodes${row.group_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Order Modes</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group text-capitalize">
                                            <BootstrapTable version='4' data={(row.mode && this.IsJsonString(row.mode)) ? JSON.parse(row.mode) : ""} hover>
                                                <TableHeaderColumn dataField='label' columnTitle isKey>Order Mode</TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
function weeklyTimingFormatter(cell: any, row: any, props: any) {
    return (
        <WeekylyTimingFormatter row={row} data={props} />
    );
}
class WeekylyTimingFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    IsJsonString = (str: any) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    render() {
        const { row } = this.props;
        return (
            <div>
                <button title="View weeklyTimings" data-toggle="modal" data-target={`#weeklytiming${row.group_id}`} className="btn btn-outline-info"><i className="fa fa-list"></i></button>
                {/* <!-- Modal--> */}
                <div id={`weeklytiming${row.group_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Serving hours</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group text-capitalize">
                                            <BootstrapTable version='4' data={(row.serving_hours && this.IsJsonString(row.serving_hours)) ? JSON.parse(row.serving_hours) : ""} hover>
                                                <TableHeaderColumn dataField='dayOfWeek' dataSort={true} isKey>Day</TableHeaderColumn>
                                                <TableHeaderColumn dataField='timePeriods' dataFormat={startPeriodFormatter} columnTitle>Start</TableHeaderColumn>
                                                <TableHeaderColumn dataField='timePeriods' dataFormat={closePeriodFormatter} columnTitle>End</TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
function startPeriodFormatter(cell: any) {
    let startPeriod = cell[0].start;
    return startPeriod;
}
function closePeriodFormatter(cell: any) {
    let endPeriod = cell[0].end;
    return endPeriod;
}
class Groups extends Component<GroupProps, { brand_id: any }> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            brand_id: {
                value: 0,
                label: "All"
            }
        }
    }
    componentDidMount() {
        this.props.brandsList();
        this.props.groupsList(this.state.brand_id.value)
        document.title = "SimplexCMS | Groups"
    }
    handleBrandsInputChange = (e: any, index: any) => {
        this.setState({ brand_id: { value: e.value, label: e.label } })
        if (e && e.value > 0) {
            this.props.groupsList(e.value)
        } else {
            this.props.groupsList(0)
        }
    };
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let tokendata: any = jwt.decode(sessionStorage.token)
        let roleId = tokendata.role_id;
        const options: any = {
            // sizePerPageList: [5,10,15],
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/add-group" /> },
            noDataText: 'Groups Not Found'
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="row py-2">
                                    <div className="col-lg-8 col-md-8 col-12">
                                        <h4 className="mt-2">Groups Management</h4>
                                    </div>
                                    {roleId == 1 &&
                                        <div className="col-lg-4 col-md-4 col-12">
                                            <div className="row">
                                                <div className="col-12">
                                                    <Select
                                                        name="brand_id"
                                                        options={this.props.brands}
                                                        value={this.state.brand_id}
                                                        className="text-capitalize select mt-2"
                                                        classNamePrefix="select"
                                                        onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body text-capitalize">
                                                <BootstrapTable version='4' data={this.props.data} search={true} pagination={this.props.data.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='groups.csv' hover>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='group_id' csvHeader='#' width='50' dataSort={true} isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='brand_name' csvHeader='Brand' width='100' columnTitle>Brand</TableHeaderColumn>
                                                    {/* <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='erp_id' csvHeader='ERP ID' width='50' columnTitle>ERP ID</TableHeaderColumn> */}
                                                    {/* <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='pos_code' csvHeader='Pos Code' width='50'>POS Code</TableHeaderColumn> */}
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='group_name' csvHeader='Group Name' width='100' columnTitle>Group name</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='priority' csvHeader='Priority' width='100' columnTitle>Priority</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='menu_name' csvHeader='Menu Name' width='100' columnTitle>Menu</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='mode' csvHeader='Modes' width='70' dataFormat={orderModesFormatter} columnTitle>Mode</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='120' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        data: state.menu.groups,
        items: state.menu.items,
        brands: state.brand.brands
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        groupsList: function (brand: any) {
            dispatch(groupsList(brand))
        },
        blockunblockgroup: function (id: any, is_active: any, group_name: any) {
            dispatch(blockunblockgroup(id, is_active, group_name))
        },
        itemsListByGroupId: function (id: any) {
            dispatch(itemsListByGroupId(id))
        },
        brandsList: function () {
            dispatch(brandsList("GET_BY_BRAND"))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Groups);