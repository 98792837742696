import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { logoutUser, getTaxValue, saveDiscountValue, countryList, saveDeliveryFee, discountsList, statesList, saveTaxByState, brandsList, editBrand } from '../../redux'
import { SettingsProps, SettingsState } from '../../interfaces/settings';
import { getLoyaltyTiers } from '../../redux/actions/loyaltyAction';
import { saveTierLevelMinCartValue, updateMinCartValue } from '../../redux/actions/settingsAction';
import Select from 'react-select';
import { currencies } from "./currencies"
import * as momentTimezone from 'moment-timezone'
class Settings extends Component<SettingsProps, SettingsState> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            country: "",
            currency: "",
            deliveryCountry: "",
            state: "",
            deliveryState: "",
            tax_value: "",
            delivery_fee: "",
            loyaltyLevel: "",
            min_cart_value: "",
            brand: null,
            email_address: '',
            phone_number: '',
            landing_image: '',
            timezone: ''
        }
        this.handleTaxSubmit = this.handleTaxSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleDeliveryFeeStateChange = this.handleDeliveryFeeStateChange.bind(this);
    }
    componentDidMount() {
        // this.props.getTaxValue();
        this.props.countryList();
        this.props.statesList();
        this.props.discountsList();
        this.props.brandsList()
        document.title = "SimplexCMS | Global Settings"
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
        if (event.target.name == 'country') {
            if (event.target.value == "") {
                this.setState({ state: "", tax_value: "" });
            } else {
                if (event.target.value !== this.state.country) {
                    this.setState({ state: "", tax_value: "" });
                }
            }
        } else if (event.target.name == 'deliveryCountry') {
            if (event.target.value == "") {
                this.setState({ deliveryState: "", delivery_fee: "" });
            } else {
                if (event.target.value !== this.state.deliveryCountry) {
                    this.setState({ deliveryState: "", delivery_fee: "" });
                }
            }
        }
    }
    handleBrandCurrencyChange = (event: any) => {
        this.setState({ currency: event.value })
    }
    handleBrandChange = (event: any) => {
        if (event.value !== "") {
            this.setState({ brand: event.value,state: "",deliveryState:"",min_cart_value:event.min_cart_value });
        }
        else {
            this.setState({ brand: null,state: "",deliveryState:"" });
        }
    }

    handleTaxCountryChange = (event: any) => {
        this.setState({ country: event.value })
    }

    handleDeliveryCountryChange = (event: any) => {
        this.setState({ deliveryCountry: event.value })
    }
    handleStateChange(event: { target: { name: any; value: any; }; }) {
        let { states } = this.props;
        if (event.target.value !== "") {
            let data: any = states.find((state: any) => state.state_id == event.target.value);
            this.setState({ state: data.state_id, tax_value: data.tax_percent ? data.tax_percent : "" });
        } else {
            this.setState({ state: "", tax_value: "" });
        }
    }

    handleTierInputChange = (event: { target: { name: any; value: any; }; }) => {
        let { loyaltyTiers } = this.props;
        if (event.target.value !== "") {
            let data: any = loyaltyTiers.find((tier: any) => tier.loyalty_level == event.target.value);
            this.setState({ loyaltyLevel: data.loyalty_level, min_cart_value: data.min_cart_value });
        } else {
            this.setState({ loyaltyLevel: "", min_cart_value: 0 });
        }
    }
    handleDeliveryFeeStateChange(event: { target: { name: any; value: any; }; }) {
        let { states } = this.props;
        if (event.target.value !== "") {
            let data: any = states.find((state: any) => state.state_id == event.target.value);
            this.setState({ deliveryState: data.state_id, delivery_fee: data.delivery_fee ? data.delivery_fee : "" });
        } else {
            this.setState({ deliveryState: "", delivery_fee: "" });
        }
    }
    isTaxSubmitReady = () => {
        let { brand, country, state, tax_value } = this.state;
        return (brand !== null && country !== "" && state !== "" && tax_value !== "");
    }
    isCartValueReady = () => {
        let { min_cart_value,brand } = this.state;
        return (min_cart_value !== "" && brand != null);
    }
    isCountrySubmitReady = () => {
        let { brand, deliveryCountry, deliveryState, delivery_fee } = this.state;
        return (brand !== null && deliveryCountry !== "" && deliveryState !== "" && delivery_fee !== "");
    }
    handleTaxSubmit = (event: any) => {
        let { tax_value, state, brand } = this.state;
        let data = {
            state_id: state,
            tax_percent: tax_value,
            brand_id: brand
        }
        this.props.saveTaxByState(data);
        event.preventDefault()
    }

    handleTierCartValue = (event: any) => {
        let { min_cart_value,brand} = this.state;
        let data = {
            min_cart_value: min_cart_value,
            brand_id:brand
        }
        this.props.saveTierLevelMinCartValue(data);
        event.preventDefault()
    }
    blockInvalidChar = (e: any) =>
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    handleDeliveryFeeSubmit = (event: any) => {
        let { delivery_fee, deliveryState,brand } = this.state;
        let data = {
            state_id: deliveryState,
            delivery_fee: delivery_fee,
            brand_id: brand
        }
        this.props.saveDeliveryFee(data);
        event.preventDefault()
    }

    ishandleBrandReady = () => {
        let { brand, currency, email_address, phone_number, timezone } = this.state;
        return (currency !== '' && email_address !== '' && timezone != '' && phone_number !== '')
    }

    handleBrandDetails = () => {
        let { brand, currency, email_address, phone_number, landing_image, timezone } = this.state
        let brandId
        let roleId
        jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
            if (err) {
                this.props.logoutUser();
            } else {
                brandId = decoded.brand_id
                roleId = decoded.role_id
            }
        });
        const data: any = new FormData();
        data.append("currency",currency)
        data.append("timezone",timezone)
        data.append("email_address",email_address)
        data.append("phone_number",phone_number)
        if(roleId != 1) {
            data.append("file",landing_image[0])
        } 
        this.props.updateBrand(brandId, data)
    }
    fileSelectedHandler = (e: any) => {
        var size = 1048576;
        if (e.target.files[0].size > size) {
            alert("Image size too large. Upload image upto 1mb")
        }
        else {
            this.setState({ landing_image: e.target.files })
        }
    }
    handleTimezoneChange = (event: any) => {
        this.setState({ timezone: event.value })
    }
    render() {
        let arr: any = momentTimezone.tz.names()
        let timeZones: any = []

        arr.map((name: any) => {
            timeZones.push({ label: name, value: name })
        })
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let tokendata: any = jwt.decode(sessionStorage.token)
        let roleId = tokendata.role_id;
        let brandId = tokendata.brand_id;
        let { country, tax_value, delivery_fee, deliveryCountry, email_address, phone_number } = this.state;
        const { states, countries, brands } = this.props;
        let countryData: any = []
        countries.map((country: any) => {
            countryData.push({ label: country.country_name, value: country.country_id })
        })
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="row py-2">
                                    <div className="col-lg-8 col-md-8 col-12">
                                        <h4 className="mt-2">Global Settings</h4>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <Select
                                                    name="brand_id"
                                                    options={roleId == 1 ? brands : brands.filter((o1: any) => brandId === o1.brand_id)}
                                                    className="text-capitalize select mt-2"
                                                    classNamePrefix="select"
                                                    onChange={(e, i) => this.handleBrandChange(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="card shadow rounded">
                                    <div className="row p-3">
                                        <div className="col-lg-6">
                                            <div className="card shadow rounded">
                                                <div className="card-content">
                                                    <div className="card-header border-bottom-grey">
                                                        <strong className="">Global Tax</strong>
                                                    </div>
                                                    <div className="card-body">
                                                        <form className="form-validate">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Country <span className="text-danger">*</span></label>
                                                                        <Select
                                                                            isSearchable
                                                                            options={countryData}
                                                                            onChange={this.handleTaxCountryChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">States <span className="text-danger">*</span></label>
                                                                        <select name="state" className="form-control mt-2" required data-msg="Please select state" onChange={this.handleStateChange}>
                                                                            <option value="">Select State</option>
                                                                            {country !== "" &&
                                                                                states &&
                                                                                states.map((state: any, index: any) => (
                                                                                    (state.country_id == country && state.brand_id == this.state.brand) &&
                                                                                    <option key={index} value={state.state_id}>{state.state_name}-({state.payment_method})</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Tax Value <span className="text-danger">*</span></label>
                                                                        <input id="taxValue" type="number" name="tax_value" min={0} onKeyDown={this.blockInvalidChar} value={tax_value} required data-msg="Please enter Tax value" className="input-material" onChange={this.handleInputChange} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="form-group d-flex justify-content-end">
                                                                <button onClick={this.handleTaxSubmit} disabled={!this.isTaxSubmitReady()} className="btn btn-primary">Save</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="card shadow rounded">
                                                <div className="card-content">
                                                    <div className="card-header border-bottom-grey">
                                                        <strong className="">Delivery Fee</strong>
                                                    </div>
                                                    <div className="card-body">
                                                        <form className="form-validate">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Country <span className="text-danger">*</span></label>
                                                                        <Select
                                                                            isSearchable
                                                                            options={countryData}
                                                                            onChange={this.handleDeliveryCountryChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">States <span className="text-danger">*</span></label>
                                                                        <select name="deliveryState" className="form-control mt-2" required data-msg="Please select state" onChange={this.handleDeliveryFeeStateChange}>
                                                                            <option value="">Select State</option>
                                                                            {deliveryCountry !== "" &&
                                                                                states &&
                                                                                states.map((state: any, index: any) => (
                                                                                    (state.country_id == deliveryCountry && state.payment_method == "cash" && state.brand_id == this.state.brand) &&
                                                                                    <option key={index} value={state.state_id}>{state.state_name}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Delivery Fee <span className="text-danger">*</span></label>
                                                                        <input id="deliveryFee" type="number" name="delivery_fee" min={0}
                                                                            onKeyDown={this.blockInvalidChar} value={delivery_fee} required data-msg="Please enter Delivery Fee" className="input-material" onChange={this.handleInputChange} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="form-group d-flex justify-content-end">
                                                                <button onClick={this.handleDeliveryFeeSubmit} disabled={!this.isCountrySubmitReady()} className="btn btn-primary">Save</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="card shadow rounded">
                                                <div className="card-content">
                                                    <div className="card-header border-bottom-grey">
                                                        <strong className="">Brand Details</strong>
                                                    </div>
                                                    <div className="card-body">
                                                        <form className="form-validate">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Min Cart Value <span className="text-danger">*</span></label>
                                                                        <input id="min_cart_value" type="number" name="min_cart_value" min={0}
                                                                            onKeyDown={this.blockInvalidChar} value={this.state.min_cart_value} required data-msg="Please enter Min Cart Value" className="input-material" onChange={this.handleInputChange} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="form-group d-flex justify-content-end">
                                                                <button onClick={this.handleTierCartValue} disabled={!this.isCartValueReady()} className="btn btn-primary">Save</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-6">
                                            <div className="card shadow rounded">
                                                <div className="card-content">
                                                    <div className="card-header border-bottom-grey">
                                                        <strong className="">Loyalty Tier Minimum Cart Value</strong>
                                                    </div>
                                                    <div className="card-body">
                                                        <strong><p className="text-center font-weight-light mb-4" style={{ color: taxcolor }}>
                                                            {msg}
                                                        </p></strong>
                                                        <form className="form-validate">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Tier Level<span className="text-danger">*</span></label>
                                                                            <select id="MinOrder" name="LoyaltyLevel" onChange={this.handleTierInputChange} className="form-control text-capitalize mt-2" required data-msg="Please select Menu">
                                                                                <option value="">Select Tier Level</option>
                                                                                <option value='First'>First</option>
                                                                                <option value='Second'>Second</option>
                                                                                <option value='Third'>Third</option>
                                                                            </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Minimum Cart Value <span className="text-danger">*</span></label>
                                                                        <input id="min_cart_value" type="number" name="min_cart_value" min={0} onKeyDown={this.blockInvalidChar} value={this.state.min_cart_value} required data-msg="Please enter Tax value" className="input-material" onChange={this.handleInputChange} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="form-group d-flex justify-content-end">
                                                                <button onClick={this.handleTierCartValue} disabled={!this.isCartValueReady()} className="btn btn-primary">Save</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    {/* <div className="row p-3">
                                        <div className="col-lg-6">
                                            <div className="card shadow rounded">
                                                <div className="card-content">
                                                    <div className="card-header border-bottom-grey">
                                                        <strong className="">Brand Details</strong>
                                                    </div>
                                                    <div className="card-body">
                                                        <form className="form-validate">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Currency <span className="text-danger">*</span></label>
                                                                        <Select
                                                                            isSearchable
                                                                            options={currencies}
                                                                            onChange={this.handleBrandCurrencyChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />

                                                            <div className='row'>
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Time Zone <span className="text-danger">*</span></label>
                                                                        <div style={{ marginTop: '10px' }}>
                                                                            <Select
                                                                                isSearchable
                                                                                options={timeZones}
                                                                                onChange={this.handleTimezoneChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />

                                                            <div className='row'>
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Email Address <span className="text-danger">*</span></label>
                                                                        <input id="email_address" type="email" name="email_address" 
                                                                            required data-msg="Please enter valid email" className="input-material" onChange={this.handleInputChange} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='row'>
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Phone Number <span className="text-danger">*</span></label>
                                                                        <input id="phone_number" type="text" name="phone_number" 
                                                                            required data-msg="Please enter Delivery Fee" className="input-material" onChange={this.handleInputChange} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />

                                                            {roleId != 1 && <div className='row'>
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label mb-3">Brand Image <span className="text-danger">*</span></label>
                                                                        <input id="files" type="file" name="files" accept="image/*" className="form-control-file" onChange={(e) => this.fileSelectedHandler(e)} />
                                                                    </div>
                                                                </div>
                                                            </div>}

                                                            <br />
                                                            <div className="form-group d-flex justify-content-end">
                                                                <button onClick={this.handleBrandDetails} disabled={!this.ishandleBrandReady()} className="btn btn-primary">Save</button>

                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div >
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        message: state.setting.message,
        discounts: state.setting.discounts,
        discountMsg: state.setting.discMsg,
        states: state.setting.states,
        countries: state.store.countries,
        tax: state.setting.taxData,
        saveDelivery: state.setting.saveDelivery,
        loyaltyTiers: state.loyalty.loyaltyTiers,
        brands: state.brand.brands
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        countryList: function () {
            dispatch(countryList())
        },
        discountsList: function () {
            dispatch(discountsList())
        },
        statesList: function () {
            dispatch(statesList())
        },
        saveTaxByState: function (data: any) {
            dispatch(saveTaxByState(data))
        },
        getTaxValue: function () {
            dispatch(getTaxValue());
        },
        saveDiscountValue: function (data: any) {
            dispatch(saveDiscountValue(data))
        },
        saveDeliveryFee: function (data: any) {
            dispatch(saveDeliveryFee(data))
        },
        getLoyaltyTiers: function () {
            dispatch(getLoyaltyTiers());
        },
        saveTierLevelMinCartValue: function (data: any) {
            dispatch(updateMinCartValue(data))
        },
        brandsList: () => {
            dispatch(brandsList())
        },
        updateBrand: (id: any, data: any) => {
            dispatch(editBrand(id, data, "setting"))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Settings);