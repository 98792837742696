import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Sidebar from '../../components/sidebar';
import Topbar from '../../components/topbar'
import Footer from '../../components/footer/main'
import { AddCampaignProps, AddCampaignStates, segmentationProps, segmentationStates } from '../../interfaces/marketing';
import { itemsListForMultiSelect } from '../../redux/actions/menuAction';
import { brandsList, logoutUser } from '../../redux';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { data } from 'jquery';
import { addCampaign, createAudienceList, customerFilteration, getAudience, getAudienceForAudience, testEmail } from '../../redux/actions/marketing';
import { Redirect } from 'react-router-dom';
import { currency } from '../../client-config'
import _ from 'lodash';
import moment from 'moment';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState,convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import './campaign.css';
// const createSliderWithTooltip = Slider.createSliderWithTooltip;
// const Range = createSliderWithTooltip(Slider.Range);
class AddSmsCampaign extends Component<AddCampaignProps, AddCampaignStates> {
    constructor(props: any) {
        super(props);
        this.state = {
            activeTab: 0,
            audience_id: '',
            emailType: '',
            fullName: 'Simplex',
            email_address: '',
            replyTo: '',
            startDate: '',
            subject: '',
            message: '',
            html_content: '',
            test_email: '',
            netWork: false,
            brand:'',
            editorState: EditorState.createEmpty(),
            campaignTypeList: [
                {
                    id: 1,
                    name: 'brand_awareness'
                },
                {
                    id: 2,
                    name: 'promotional'
                },
                {
                    id: 3,
                    name: 'discount'
                },
            ]
        }
        this.handleValidTime = this.handleValidTime.bind(this)

    }

    componentWillMount() {
        this.props.brandsList();
    }

    componentDidMount() {
    }



    onValueChange = (e: any) => {
        console.log("marking value of checkbox", e.target.value)
    }


    handleInputChange = (event: { target: { name: any; value: any; }; }) => {
        if(event.target.name == 'brand') {
            this.setState({
                [event.target.name]: event.target.value
            });
            this.props.getAudience(event.target.value)
        } else {
            this.setState({
                [event.target.name]: event.target.value
            });
        }
    }




    // handleItemsInputChange = (e: any, index: any) => {
    //     console.log(e)
    //     if (!_.isEmpty(e)) {
    //         if (e.menu_item_id) {
    //             this.setState({ itemId: e.menu_item_id, comboId: '' });

    //         }
    //         else {
    //             this.setState({ comboId: e.combo_id, itemId: '' })
    //         }
    //     }
    //     else {
    //         this.setState({ itemId: '' })
    //         this.setState({ comboId: '' })
    //     }
    // }

    // handleValidTime = (event: { target: { name: any; value: any } }) => {
    //     let selectedDateTime = new Date(event.target.value)
    //     let validMinDateTime = new Date();
    //     if (selectedDateTime <= validMinDateTime) {
    //         this.setState({ [event.target.name]: event.target.value });
    //         if (event.target.name == 'startDate') {
    //             this.setState({ validTimeFlagFrom: true })
    //         }
    //         else {
    //             this.setState({ validTimeFlagTo: true })
    //         }
    //     } else {
    //         toast.error("Invalid date and time selected");
    //         if (event.target.name == 'startDate') {
    //             this.setState({ validTimeFlagFrom: false })
    //         }
    //         else {
    //             this.setState({ validTimeFlagTo: false })
    //         }
    //     }
    // }

    submitData = () => {
        let { fullName, replyTo, audience_id, emailType, startDate, subject, message,html_content,editorState } = this.state;
        this.setState({netWork: true});
        let objData: any = {
            campaign_name: "Test Campaign",
            sender_name: 'Simplex',
            campaign_start_time: new Date(startDate),
            campaign_type: "sms",
            email_type: emailType,
            audience: audience_id,
            message: this.state.editorState.getCurrentContent().getPlainText(),
        }
        this.props.addCampaign(objData);
        setTimeout(() => {
            this.setState({netWork: false});
        }, 5000);
        // console.log(this.state.editorState.getCurrentContent().getPlainText())
    }

    isCampaignDataReady = () => {
        const {emailType, audience_id,startDate,subject,editorState} = this.state;
        return (emailType !== '' && audience_id !== '' && startDate !== '' && editorState !== '')
    }

    submitTestData = () => {
        let { fullName, replyTo,brand, audience_id, emailType, startDate, subject, test_email,html_content,editorState } = this.state;
        let objData: any = {
            phone_number: test_email,
            email_from: replyTo,
            sender_name: fullName,
            campaign_type: 'sms',
            subject: subject,
            message: this.state.editorState.getCurrentContent().getPlainText(),
            html_content: html_content,
            brand_id:brand
        }
        this.props.testEmail(objData);
        // console.log(this.state.editorState.getCurrentContent().getPlainText())
    }

    handleValidTime(event: { target: { name: any; value: any } }) {
        this.setState({ [event.target.name]: event.target.value });
    }


    createAudience = () => {
    }

    onEditorStateChange = (editorState: any) => {
        this.setState({
            editorState,
        });
        this.setState({
            html_content: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
        })
        // draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
    };

    nextTab = () => {
        this.setState({activeTab: 1})
    }


    render() {
        const { audienceType,brands } = this.props;
        const options: any = {
            // sizePerPageList: [5,10,15],
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/add-menu" /> },
            noDataText: 'Menus Not Found'
        };
        let todayDateTime = new Date();
        let m = moment(todayDateTime)
        let momentDate = m.format()
        let momentUpdate = momentDate.split("+")[0]
        let today = momentUpdate
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        <div className="container-fluid mt-3">
                            <div className="row">
                                <div className="col">
                                    <div className="card">
                                        <div className="card-body">
                                            <Tabs selectedIndex={this.state.activeTab}
                                                onSelect={(index) =>
                                                    this.setState({
                                                        activeTab: index
                                                    })
                                                }>
                                                <TabList
                                                    style={{
                                                        background: "#20a5d6",
                                                        color: "#FFF",
                                                        padding: "10px",
                                                    }}
                                                >
                                                    <Tab>Basic</Tab>
                                                    <Tab>Advance</Tab>
                                                </TabList>
                                                <TabPanel>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <h4>SMS Campaign</h4>
                                                                    <div className="row">
                                                                        <div className="col-lg-6">
                                                                            <div className="form-group d-flex flex-column ">
                                                                                <label className="form-control-label">
                                                                                    Send From Name   <span className="text-danger">*</span>
                                                                                </label>
                                                                                <input disabled={true} onChange={this.handleInputChange} value={this.state.fullName} name="fullName" placeholder="Tortilla" className="input-material"></input>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-lg-4">
                                                                            <div className="form-group d-flex flex-column ">
                                                                                <label className="form-control-label">
                                                                                    Campaign Purpose   <span className="text-danger">*</span>
                                                                                </label>
                                                                                <select onChange={this.handleInputChange} name="emailType" className="form-control text-capitalize">
                                                                                    <option>
                                                                                        Select campaign purpose
                                                                                    </option>
                                                                                    {this.state.campaignTypeList.map((campaign: any, index: any) => (
                                                                                        <option key={index} value={campaign.name} {...this.state.emailType == campaign.name && { selected: true }}>{campaign.name}</option>
                                                                                    ))
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </div>

                                                                        <div className='col-lg-4'>
                                                                            <div className="form-group d-flex flex-column">
                                                                                    <label className="form-control-label">
                                                                                        Brand <span className="text-danger">*</span>
                                                                                    </label>
                                                                                    <select
                                                                                        name="brand"
                                                                                        className="form-control text-capitalize"
                                                                                        required
                                                                                        data-msg="Please select brand"
                                                                                        onChange={this.handleInputChange}
                                                                                    >
                                                                                        <option value="">Select Brand</option>
                                                                                        {brands &&
                                                                                        brands.map(
                                                                                            (brand:any, index:any) =>
                                                                                            brand.is_active == 1 && (
                                                                                                <option key={index} value={brand.brand_id}>
                                                                                                {brand.brand_name}
                                                                                                </option>
                                                                                            )
                                                                                        )}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        <div className="col-lg-4">
                                                                            <div className="form-group d-flex flex-column ">
                                                                                <label className="form-control-label">
                                                                                    Audience List   <span className="text-danger">*</span>
                                                                                </label>
                                                                                <select onChange={this.handleInputChange} name="audience_id" className="form-control text-capitalize">
                                                                                    <option>
                                                                                        Select Audience
                                                                                    </option>
                                                                                    {audienceType.map((audience: any, index: any) => (
                                                                                        <option key={index} value={audience.id} {...this.state.audience_id == audience.id && { selected: true }}>{audience.audience_name}</option>
                                                                                    ))}
                                                                                </select>
                                                                                <span>
                                                                                    {audienceType.map((audience: any, index: any) => {
                                                                                        if (this.state.audience_id == audience.id)
                                                                                            return (
                                                                                                <span key={index}>
                                                                                                    No. of participants: {audience.participants} members
                                                                                                </span>
                                                                                            )
                                                                                    })}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-lg-6">
                                                                            <div className="form-group d-flex flex-column ">
                                                                                <label className="form-control-label">
                                                                                    Send On Or After   <span className="text-danger">*</span>
                                                                                </label>
                                                                                <input
                                                                                    id="start"
                                                                                    type="datetime-local"
                                                                                    name="startDate"
                                                                                    min={today}
                                                                                    required
                                                                                    data-msg="Please enter Start Date"
                                                                                    className="input-material"
                                                                                    onChange={this.handleValidTime}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group d-flex justify-content-end mt-4">
                                                                        <button onClick={this.nextTab} className='btn btn-primary'>Next</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <h4>SMS Campaign</h4>
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group d-flex flex-column ">
                                                                                <label className="form-control-label">
                                                                                    Message   <span className="text-danger">*</span>
                                                                                </label>
                                                                                <Editor
                                                                                    editorState={this.state.editorState}
                                                                                    toolbarClassName="toolbarClassName"
                                                                                    wrapperClassName="wrapperClassName"
                                                                                    editorClassName="editorClassName"
                                                                                    onEditorStateChange={this.onEditorStateChange}
                                                                                />
                                                                                {/* <textarea
                                                                                    disabled
                                                                                    value={draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))}
                                                                                /> */}
                                                                                {/* <textarea placeholder="abc@gmail.com" onChange={this.handleInputChange} name="message" style={{ border: '1px solid #eaeaea', height: '250px', fontSize: '14px' }} rows={4} cols={50} className="input-material"></textarea> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group d-flex justify-content-between mt-4">
                                                                        <button data-toggle="modal" data-target={`#testEmailModal`} style={{ color: '#fff' }} className='btn btn-secondary'>Test SMS</button>
                                                                        {!this.state.netWork ? 
                                                                        <button disabled={!this.isCampaignDataReady()} onClick={this.submitData} className='btn btn-primary'>Submit</button>
                                                                        :
                                                                        <button disabled={true} className='btn btn-primary'>Submit</button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <section>
                                <div id={`testEmailModal`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                                    <div role="document" className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h4 id="exampleModalLabel" className="modal-title">Test SMS</h4>
                                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                                            </div>
                                            <div className="modal-body">
                                                {/* <div className="row">
                                                <div className="col-12">
                                                    <span>Are you sure,you want to this store?</span>
                                                </div>
                                            </div> */}
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">
                                                                Testing number
                                                            </label>
                                                            <input id="percent" type="text" name="test_email" required data-msg="Please enter testing email" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="modal-footer">
                                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                                <button onClick={this.submitTestData}  className="btn btn-primary">Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

function priceFormatter(cell: any, row: any) {
    if (row.order_grossprice) {
        return `${currency}.${cell}`
    }
    else {
        return 'N/A'
    }
}

function columnFormatter(cell: any, row: any) {
    console.log(row)
    // if (row.item_name) {
    //     return 'item_name';
    // } else {
    //     return 'combo_name';
    // }
}

const mapStateToProps = (state: any) => {
    return {
        audienceType: state.marketing.audienceType,
        brands:state.brand.brands
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        getAudience: function (brand_id:number) {
            dispatch(getAudienceForAudience(brand_id))
        },
        addCampaign: function (data: any) {
            dispatch(addCampaign(data))
        },
        testEmail: function (data: any) {
            dispatch(testEmail(data))
        },
        brandsList: function () {
            dispatch(brandsList());
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddSmsCampaign);