import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { EditMealsProps, EditMealsState } from '../../../../interfaces/menu';
import { connect } from 'react-redux';
import jwt from 'jsonwebtoken';
import { secretKey } from '../../../../secret';
import { editGroup, logoutUser, getGroup, editMeal, getMeal } from '../../../../redux';
import Topbar from '../../../../components/topbar';
import Sidebar from '../../../../components/sidebar';
import Footer from '../../../../components/footer/main';
import CheckChanges from '../../../../components/confirmOnLeave';
import Select from 'react-select';

class EditMeal extends Component<EditMealsProps, EditMealsState> {
    constructor(props: any) {
        super(props);
        this.state = {
            mealName: "",
            mealNameAR: "",
            categories: [
                {
                    category: "",
                    categoryAR: ""
                }
            ],
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.getMeal(id);
        document.title = "SimplexCMS | Meals"
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        this.setState({
            mealName: nextProps.mealData.meal_name,
            mealNameAR: nextProps.mealData.meal_name_ar && nextProps.mealData.meal_name_ar
        })
        var categories = nextProps && nextProps.mealData.nutritionalCat;
        if (categories.length > 0) {
            var listCategories: any = [];
            categories.forEach((element: any) => {
                const newlist: any = { category: element.group_name, categoryAR: element.group_name_ar }
                listCategories.push(newlist);
                this.setState({ categories: listCategories });
            });
        }
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleCategoryInputChange = (e: any, index: any) => {
        if (e.target) {
            const { name, value } = e.target;
            const list: any = this.state.categories;
            list[index][name] = value;
            this.setState({ categories: list });
        }
    };
    handleRemoveCategory = (index: any) => {
        const list = this.state.categories;
        list.splice(index, 1);
        this.setState({ categories: list });
    };
    handleAddCategory = (i: any) => {
        const list: any = this.state.categories;
        if (list[i]["category"] == "") {
            alert("Please fill in current item")
        } else {
            const newlist: any = { category: "", categoryAR: "" }
            const categories: any = this.state.categories.concat(newlist)
            this.setState({ categories: categories });
        }
    };
    isMealReady = () => {
        const { mealName, categories } = this.state
        return (mealName !== "" && categories[categories.length - 1].category !== "");
    }
    handleSaveBtnClick = (event: any) => {
        let { mealName, mealNameAR, categories } = this.state;
        let id = this.props.match.params.id;
        let data: any = {
            meal_name: mealName,
            meal_name_ar: mealNameAR !== "" ? mealNameAR : null,
            nutritionalCat: categories
        }
        this.props.editMeal(id,data);
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let { categories } = this.state;
        const { mealData } = this.props;
        return (
            <div className="page">
                <CheckChanges path="/edit-meal" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Meals Management</h4>
                                </div>
                            </div>
                        </header>
                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/meals" className="text-primary">Meals</Link></li>
                                <li className="breadcrumb-item active">Edit Meals</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Meal Name <span className="text-danger">*</span></label>
                                                            <input id="mealName" type="text" name="mealName" required data-msg="Please enter meal name" className="input-material" defaultValue={mealData.meal_name} onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Meal Name (Arabic)</label>
                                                            <input id="mealNameAR" type="text" name="mealNameAR" required data-msg="Please enter meal arabic name" className="input-material" defaultValue={mealData.meal_name_ar} onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ paddingTop: '1em' }}>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            {categories.map((x: any, i: any) => {
                                                                return (
                                                                    <div key={i} className="row">
                                                                        <div className="col-md-5 col-12">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Category Name <span className="text-danger">*</span></label>
                                                                                <input id="category" type="text" name="category" value={x.category} required data-msg="Please enter category name" className="input-material" onChange={e => this.handleCategoryInputChange(e, i)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-5 col-12">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Category Name (Arabic)</label>
                                                                                <input id="categoryAR" type="text" name="categoryAR" value={x.categoryAR} required data-msg="Please enter category arabic name" className="input-material" onChange={e => this.handleCategoryInputChange(e, i)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-2 col-12 py-4 text-right">
                                                                            {categories.length !== 1 &&
                                                                                <button className="btn btn-sm btn-outline-danger"
                                                                                    onClick={() => this.handleRemoveCategory(i)}><i className="fa fa-trash"></i></button>}
                                                                            {categories.length - 1 === i && <button className="btn btn-sm btn-primary ml-2" onClick={() => this.handleAddCategory(i)}><i className="fa fa-plus"></i></button>}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group float-right mt-3">
                                                    <button onClick={() => { this.props.history.push("/meals") }} className="btn btn-danger mr-2">Cancel</button>
                                                    <button className='btn btn-primary' disabled={!this.isMealReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>

                </div>
            </div >
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        mealData: state.menu.mealData
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        editMeal: function (id: any, data: any) {
            dispatch(editMeal(id, data));
        },
        getMeal: function (id: number) {
            dispatch(getMeal(id));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditMeal);