import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { EditBrandProps } from '../../interfaces/brands'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { logoutUser, getBrand, editBrand, handleBrandInput } from '../../redux'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import CheckChanges from '../../components/confirmOnLeave'

class EditBrand extends Component<EditBrandProps, {payment:number,image:any,foodics_integration:any, erp_integration:any, foodics_client_id:any,foodics_client_secret:any,erp_id:any}> {
    constructor(props: any) {
        super(props);
        this.state = {
            image:'',
            foodics_integration:0,
            erp_integration:0,
            foodics_client_id:'',
            foodics_client_secret:'',
            erp_id:"",
            payment:0
            
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.getBrand(id);
        document.title = "SimplexCMS | Brands"
    }
    isBrandReady = () => {
        const { brandname,foodics_integration, erp_integration } = this.props;
        return (brandname !== "" && foodics_integration != null);
    }
    handleSubmit = () => {
        let { brandname, brandtype,erp_id, branddetails, erp_integration, foodics_integration,foodics_client_id,foodics_client_secret,website_link,payment } = this.props;
        let id = this.props.match.params.id;
        const data: any = new FormData();
        data.append("brand_name",brandname)
        data.append("brand_details",branddetails)
        data.append("brand_unique_code",erp_id)
        data.append("brand_type",brandtype)
        data.append("payment",payment)
        data.append("file",this.state.image[0])
        data.append("foodics_integration",foodics_integration)
        data.append("erp_integration",erp_integration)
        data.append("foodics_client_id",foodics_client_id)
        data.append("foodics_client_secret",foodics_client_secret)
        data.append("website_link",website_link)
        this.props.editBrand(id, data);
    }
    fileSelectedHandler = (e: any) => {
        var size = 1048576;
        if (e.target.files[0].size > size) {
            alert("Image size too large. Upload image upto 1mb")
        }
        else {
            this.setState({ image: e.target.files })
        }
    }
    blockInvalidChar = (e: any) => {
        var regex = new RegExp("^[a-zA-Z0-9]");
        let specialkey: any = e.keyCode;
        var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (specialkey == 8 || specialkey == 9) {
            return true;
        } else {
            if (/\S/.test(e.target.value)) {
                // string is not empty and not just whitespace
                if (!regex.test(key)) {
                    if (specialkey == 32) {
                    } else {
                        e.preventDefault()
                    }
                }
            } else {
                ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
            }
        }
    }
    handleChange = (e:any) => {
        this.setState({foodics_integration:e.target.value})
    }
    handleERPChange = (e:any) => {
        this.setState({erp_integration:e.target.value})
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { isUpdated, message, brandname, brandtype, branddetails,foodics_integration, erp_integration, foodics_client_id,foodics_client_secret,website_link } = this.props;
        if (isUpdated) {
            msg = message;
            messcolor = 'green'
        } else {
            msg = message;
            messcolor = 'red'
        }
        return (
            <div className="page">
                <CheckChanges path="/edit-brand" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Brands Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/brands" className="text-primary">Brands</Link></li>
                                <li className="breadcrumb-item active">Edit Brand</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label ">Brand Name <span className="text-danger">*</span></label>
                                                            <input id="brandname" type="text" name="brandname" onKeyDown={this.blockInvalidChar} value={brandname} required data-msg="Please enter Brand Name" className="input-material" onChange={this.props.handleBrandInput} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Brand Type</label>
                                                            <input id="brandtype" type="text" name="brandtype" onKeyDown={this.blockInvalidChar} value={brandtype} className="input-material" onChange={this.props.handleBrandInput} />
                                                        </div>
                                                    </div>

                                                    <div className="col">
                                                           <div className="form-group">
                                                              <label className="form-control-label mb-3">Erp Id <span className="text-danger">*</span></label>
                                                                  <input
                                                                   disabled
                                                                   value={this.props.erp_id}
                                                                   name="erp_id"
                                                                   type="text"
                                                                   data-msg="Please enter erp"
                                                                  className="input-material"
                                                                                        // onChange={e => this.handleVariantInputChange(e, ind)}
                                                                  />
                                                            </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Brand Details</label>
                                                            <input id="branddetails" type="text" name="branddetails" value={branddetails} className="input-material" onChange={this.props.handleBrandInput} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                          <div className="form-group">
                                                            <label className="form-control-label mb-3">Brand Image <span className="text-danger">*</span></label>
                                                            <input id="files" type="file" name="files" accept="image/*" className="form-control-file" onChange={(e) => this.fileSelectedHandler(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                          <div className="form-group">
                                                            <label className="form-control-label mb-3">Foodics Integration? <span className="text-danger">*</span></label>
                                                            <select onChange={this.props.handleBrandInput} name='foodics_integration' value={foodics_integration} className="form-control text-capitalize">
                                                                <option>Select</option>
                                                                <option value='1'>Yes</option>
                                                                <option value='0'>No</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                          <div className="form-group">
                                                            <label className="form-control-label mb-3">ERP Integration? <span className="text-danger">*</span></label>
                                                            <select onChange={this.props.handleBrandInput} name='erp_integration' value={erp_integration} className="form-control text-capitalize">
                                                                <option>Select</option>
                                                                <option value='1'>Yes</option>
                                                                <option value='0'>No</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col">
                                                <label>
                                                    <input
                                                    type="checkbox"
                                                    name = "payment"
                                                    checked={(this.props.payment == 1)? false:true}
                                                    value={(this.props.payment == 1)? 0:1}
                                                    onChange={this.props.handleBrandInput} />
                                                    <p style={{ display: 'inline', marginLeft:10, paddingBottom:10}}>Payment  Received</p>
                                                </label>
                                                </div>
                                                {this.props.foodics_integration == 1 && <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Foodics Client Id <span className="text-danger">*</span></label>
                                                            <input id="foodics_client_id" value={foodics_client_id} type="text" name="foodics_client_id" required data-msg="Please enter Brand Name" className="input-material" onChange={this.props.handleBrandInput} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Foodics Client Secret</label>
                                                            <input id="foodics_client_secret" value={foodics_client_secret} type="text" name="foodics_client_secret" className="input-material" onChange={this.props.handleBrandInput} />
                                                        </div>
                                                    </div>
                                                </div>}
                                                <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Website Link</label>
                                                            <input id="website_link" value={website_link} type="text" name="website_link" className="input-material" onChange={this.props.handleBrandInput} />
                                                        </div>
                                                    </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    {/* <input type="reset" value="cancel" className="btn btn-danger mr-2" /> */}
                                                    <button onClick={this.handleSubmit} disabled={!this.isBrandReady()} className="btn btn-primary">Update Brand</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>

                </div>
            </div >
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        brandname: state.brand.brandname,
        branddetails: state.brand.branddetails,
        brandtype: state.brand.brandtype,
        foodics_integration:state.brand.foodics_integration,
        erp_integration:state.brand.erp_integration,
        foodics_client_id:state.brand.foodics_client_id,
        foodics_client_secret:state.brand.foodics_client_secret,
        website_link:state.brand.website_link,
        erp_id:state.brand.erp_id,
        message: state.brand.message,
        isUpdated: state.brand.isUpdated,
        payment: state.brand.payment
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: () => { dispatch(logoutUser()); },
        handleBrandInput: (event: any) => { dispatch(handleBrandInput(event)) },
        editBrand: (id: any, data: any) => { dispatch(editBrand(id, data)); },
        getBrand: (id: number) => { dispatch(getBrand(id)) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditBrand);