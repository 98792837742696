export const ORDERS_LIST = "ORDERS_LIST"
export const SYNC_FAILED_ORDERS_LIST = "SYNC_FAILED_ORDERS_LIST"
export const ORDER_ITEMS_LIST = "ORDER_ITEMS_LIST"
export const ORDER_STATUS_LIST = "ORDER_STATUS_LIST"
export const DELIVERY_BOYS = "DELIVERY_BOYS"
export const UPDATE_ORDER = "UPDATE_ORDER"
export const MANUAL_SYNC_ORDER = "MANUAL_SYNC_ORDER"
export const OUTBOUND_CONTACTS = "OUTBOUND_CONTACTS"
export const CANCEL_ORDER_REASONS = "CANCEL_ORDER_REASONS"
export const FILTERED_ORDERS = "FILTERED_ORDERS"
export const SYNC_FILTERED_ORDERS = "SYNC_FILTERED_ORDERS"
export const ERP_LOGS_LIST = "ERP_LOGS_LIST"
export const TIMER_STATUS = "TIMER_STATUS"
export const ORDERS_BY_STORE = "ORDERS_BY_STORE"
export const UPDATE_LOADER = "UPDATE_LOADER"
export const UPDATE_TIMER = "UPDATE_TIMER"
export const CARD_ORDERS_LIST = "CARD_ORDERS_LIST"
export const FILTERED_CARD_ORDERS = "FILTERED_CARD_ORDERS"
export const CARD_ORDER_ITEMS_LIST = "CARD_ORDER_ITEMS_LIST"
export const UPDATE_SELECTED_ROW = "UPDATE_SELECTED_ROW"