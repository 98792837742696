import React, { Component } from 'react';
import { AddDiscountProps, AddDiscountState } from '../../interfaces/discounts'
import { connect } from 'react-redux'
import { brandsList, getallActiveMenus, getAllActiveGroups, logoutUser, addDiscount, itemsListOfActiveMenu } from '../../redux'
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import Select from 'react-select';
import CheckChanges from '../../components/confirmOnLeave'
import { getAllActiveGroupsForDiscount, getallActiveMenusForPromo, itemsListOfActiveMenuForDicount } from '../../redux/actions/menuAction';
class AddDiscount extends Component<AddDiscountProps, AddDiscountState> {
    selectInputRef:any
    selectInputRef2:any
    orderModes: { value: string; label: string; }[];
    constructor(props: any) {
        super(props);
        this.orderModes = [
            { value: 'online', label: 'Online' },
            { value: 'mobile', label: 'Mobile' },
            { value: 'callcenter', label: 'Call Center' }
        ];
        this.state = {
            discountvalue: "",
            expiryDate: "",
            percent: null,
            mode: [],
            channel: "delivery",
            type: "menu",
            discountType: "value",
            multiJson: [],
            type_id: "",
            brand_id:null
        }
        this.selectInputRef = React.createRef()
        this.selectInputRef2 = React.createRef()
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDiscountLevel = this.handleDiscountLevel.bind(this);
        // this.handleDiscountValue=this.handleDiscountValue.bind(this);
        this.handleDiscountType = this.handleDiscountType.bind(this);
    }
    componentDidMount() {
        document.title = "SimplexCMS | Discounts"
        this.props.brandsList();
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                } else {
                    this.props.menusList(decoded.brand_id);
                    this.props.groupsList(decoded.brand_id);
                    this.props.itemsListOfActiveMenu(decoded.brand_id);
                }
            });
        }
        
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }
    // handleDiscountType(event: { target: { name: any; value: any; }; }) {
    //     this.setState({
    //         type_id:"",
    //         [event.target.name]: event.target.value,
    //     }); 
    // }
    handleGroupsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ multiJson: e });
        }
    };
    handleItemsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ multiJson: e });
        }
    }
    handleOrderModesInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ mode: e });
        } else {
            this.setState({ mode: [] });
        }
    };
    handleDiscountType(event: { target: { name: any; value: any; }; }) {
        this.setState({
            percent: "",
            discountvalue: "",
            [event.target.name]: event.target.value,
        });
    }

    isDiscountReady = () => {
        const { expiryDate, mode, discountType, discountvalue, type, type_id, multiJson, percent } = this.state;
        if (type == "menu") {
            return (expiryDate !== "" && mode.length > 0 && type_id !== "" && discountType !== "" && (percent !== "" || discountvalue !== ""));
        } else if (type == "group" || type == "item") {
            return (expiryDate !== "" && mode.length > 0 && multiJson.length > 0 && discountType !== "" && (percent !== "" || discountvalue !== ""));
        }
    }
    handleSaveBtnClick = (history: any) => {
        let { discountvalue,brand_id, expiryDate, mode, discountType, type, type_id, multiJson, percent } = this.state;
        let data: any = {
            discount_value: discountvalue,
            discount_type: discountType,
            expire_date: expiryDate,
            mode: JSON.stringify(mode),
            type: type,
            percent: percent,
            brand_id:brand_id
        }
        if (type == "menu") {
            data.type_id = type_id;
        } else if (type == "group" || type == "item") {
            data.items_json = JSON.stringify(multiJson);
        }
        this.props.addDiscount(data, history);
    }

    handleDiscountLevel(event: { target: { name: any; value: any; }; }) {
        this.setState({
            type_id: "",
            [event.target.name]: event.target.value,
        });
    }

    handleBrandsInputChange = (e: any, index: any) => {
        let doc = document as any
        let v
        doc.getElementById("menu") ? doc.getElementById("menu").value = "Select Menu" : v=''
        this.selectInputRef.current ? this.selectInputRef.current.select.clearValue() : v =''
        this.selectInputRef2.current ? this.selectInputRef2.current.select.clearValue() : v =''
        this.setState({brand_id:e.value,type_id:'',multiJson:[]});
        this.props.menusList(e.value);
        this.props.groupsList(e.value);
        this.props.itemsListOfActiveMenu(e.value);
    }

    blockInvalidChar = (e: any) =>
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
        
    render() {
        let roleId
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                } else {
                    roleId = decoded.role_id
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { isInserted, message, menus, items } = this.props;
        if (!isInserted) {
            msg = message;
            messcolor = 'red'
        }
        return (
            <div className="page">
                <CheckChanges path="/add-coupon" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Compaigns Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/marketing/2" className="text-primary">Compaigns</Link></li>
                                <li className="breadcrumb-item active">Add Discount</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Discount Level <span className="text-danger">*</span></label>
                                                            <select name="type" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleDiscountLevel}>
                                                                <option value='menu'>Menu</option>
                                                                <option value='group'>Group</option>
                                                                <option value='item'>Menu Item</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {roleId == 1 && <div className="col">
                                                    <label className="form-control-label">Brand <span className="text-danger">*</span></label>
                                                        <Select
                                                            name="brand_id"
                                                            placeholder='Select Brand'
                                                            options={this.props.brands}
                                                            // value={{label:this.state.brand_name,value:this.state.brand_id}}
                                                            className="text-capitalize select mt-2"
                                                            classNamePrefix="select"
                                                            onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                                                        />
                                                    </div>}
                                                    {this.state.type === "menu" &&
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Menu <span className="text-danger">*</span></label>
                                                                <select id='menu' name="type_id" className="form-control text-capitalize mt-2" required data-msg="Please select Group" onChange={this.handleInputChange}>
                                                                    <option selected>Select Menu</option>
                                                                    {menus &&
                                                                        menus.map((menu: any, index: any) => (
                                                                            <option key={index} value={menu.menu_id}>{menu.menu_name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    }

                                                    {this.state.type === "group" &&
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Group <span className="text-danger">*</span></label>
                                                                <Select
                                                                    ref={this.selectInputRef}
                                                                    isMulti
                                                                    name="groups"
                                                                    options={this.props.groups}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleGroupsInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.type === "item" &&
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Items <span className="text-danger">*</span></label>
                                                                <Select
                                                                    ref={this.selectInputRef2}
                                                                    isMulti
                                                                    name="items"
                                                                    options={items}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleItemsInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Discount Type <span className="text-danger">*</span></label>
                                                            <select name="discountType" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleDiscountType}>
                                                                <option value='value'>Value</option>
                                                                <option value='percentage'>Percentage</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    {this.state.discountType === "percentage" &&
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Discount Percentage <span className="text-danger">*</span></label>
                                                                <input id="percent" type="text" name="percent" min="1" onKeyDown={this.blockInvalidChar} required data-msg="Please enter Discount Percentage" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.discountType === "value" &&
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Discount value <span className="text-danger">*</span></label>
                                                                <input id="discountvalue" type="text" min="1" onKeyDown={this.blockInvalidChar} name="discountvalue" required data-msg="Please enter Discount Value" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Valid till <span className="text-danger">*</span></label>
                                                            <input id="expiryDate" type="date" name="expiryDate" required data-msg="Please enter Expire Date" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6" >
                                                        <div className="form-group">
                                                            <label className="form-control-label">Ordering Mode <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="mode"
                                                                options={this.orderModes}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleOrderModesInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isDiscountReady()} onClick={() => this.handleSaveBtnClick(this.props.history)}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        brands: state.brand.brands,
        menus: state.menu.allActiveMenus,
        groups: state.menu.allActiveGroups,
        items: state.menu.allItemsOfActiveMenu,
        message: state.discount.message,
        isInserted: state.discount.isInserted
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        addDiscount: function (data: any, history: any) {
            dispatch(addDiscount(data, history));
        },
        brandsList: function () {
            dispatch(brandsList())
        },
        menusList: function (brand_id:number) {
            dispatch(getallActiveMenusForPromo(brand_id))
        },
        groupsList: function (brand_id:number) {
            dispatch(getAllActiveGroupsForDiscount(brand_id))
        },
        itemsListOfActiveMenu: function (brand_id:number) {
            dispatch(itemsListOfActiveMenuForDicount(brand_id))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddDiscount);