import { COUPONS_LIST, GET_COUPON, ADD_COUPON, EDIT_COUPON } from '../actions/couponType'
const initialState: any = {
    data: [] || "",
    couponData: {},
    isInserted: "",
    isUpdated: "",
    message: "",
}

const couponReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case COUPONS_LIST: return {
            ...state,
            data: action.payload
        }
        case GET_COUPON: return {
            ...state,
            couponData: action.payload
        }
        case ADD_COUPON: return {
            ...state,
            isInserted: action.isInserted,
            message: action.payload
        }
        case EDIT_COUPON: return {
            ...state,
            isUpdated: action.isUpdated,
            message: action.payload
        }
        default: return state;
    }
}
export default couponReducer;