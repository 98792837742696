import React, { Component } from "react";
import { AddMenuProps, AddMenuState } from "../../interfaces/menu";
import { connect } from "react-redux";
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import {
  addMenu,
  storeTypeList,
  brandsList,
  logoutUser,
  storesListForMultiSelect
} from "../../redux";
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import { Link, Redirect } from "react-router-dom";
import CheckChanges from '../../components/confirmOnLeave'
import Select from 'react-select';
class AddMenu extends Component<AddMenuProps, AddMenuState> {
  constructor(props: any) {
    super(props);
    this.state = {
      menuname: "",
      storetype: "",
      brand: "",
      country: "",
      state: "",
      storesJson: []
    };
    this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    this.props.storeTypeList();
    this.props.brandsList();
    document.title = "SimplexCMS | Menus"
  }
  handleStoresInputChange = (e: any, index: any) => {
    if (e && e.length > 0) {
      let newArr = e.map((item:any) => {
        return {
          label: item.label,
          value: item.value
        }
      });
      this.setState({ storesJson: newArr });
    } else {
      this.setState({ storesJson: [] });
    }
  };
  handleInputChange(event: { target: { name: any; value: any } }) {
    if (event.target.name == "brand") {
      this.setState({
        [event.target.name]: event.target.value,
      });
      this.setState({ storesJson: [] });
      this.props.storesList(event.target.value)
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  }
  isMenuReady = () => {
    const { menuname, brand, storetype, storesJson } = this.state;
    return (
      menuname !== "" &&
      brand !== "" &&
      storetype !== "" && storesJson.length > 0
    );
  };
  handleSaveBtnClick = (event: any) => {
      let { menuname, storetype, brand, storesJson } = this.state;
      let data = {
        menu_name: menuname,
        store_type_id: storetype,
        brand_id: brand,
        stores_json: JSON.stringify(storesJson)
      };
      this.props.addMenu(data);

  };
  blockInvalidChar = (e: any) => {
    var regex = new RegExp("[a-zA-Z]");
    let specialkey: any = e.keyCode;
    var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (specialkey == 8 || specialkey == 9) {
      return true;
    } else {
      if (/\S/.test(e.target.value)) {
        // string is not empty and not just whitespace
        if (!regex.test(key)) {
          if (specialkey == 32) {
          } else {
            e.preventDefault()
          }
        }
      } else {
        ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
      }
    }
  }
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />
    }
    const {
      storetype,
      brands,
    } = this.props;
    return (
      <div className="page">
        <CheckChanges path="/add-menu" />
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/*  Page Header */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Menus Management</h4>
                </div>
              </div>
            </header>
            {/* Breadcrumb */}
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/menus" className="text-primary">Menus</Link></li>
                <li className="breadcrumb-item active">Add Menu</li>
              </ul>
            </div>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Menu name <span className="text-danger">*</span>
                              </label>
                              <input
                                onKeyDown={this.blockInvalidChar}
                                id="menuname"
                                type="text"
                                name="menuname"
                                required
                                data-msg="Please enter Menu Name"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Brand <span className="text-danger">*</span>
                              </label>
                              <select
                                name="brand"
                                className="form-control text-capitalize mt-2"
                                required
                                data-msg="Please select brand"
                                onChange={this.handleInputChange}
                              >
                                <option value="">Select Brand</option>
                                {brands &&
                                  brands.map(
                                    (brand, index) =>
                                      brand.is_active == 1 && (
                                        <option key={index} value={brand.brand_id}>
                                          {brand.brand_name}
                                        </option>
                                      )
                                  )}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col" >
                            <div className="form-group">
                              <label className="form-control-label">Select Store <span className="text-danger">*</span></label>
                              <Select
                                isMulti
                                name="stores"
                                options={this.props.stores}
                                value={this.state.storesJson}
                                className="text-capitalize basic-multi-select mt-2"
                                classNamePrefix="select"
                                onChange={(e, i) => this.handleStoresInputChange(e, i)}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Store Type <span className="text-danger">*</span>
                              </label>
                              <select
                                name="storetype"
                                className="form-control text-capitalize mt-2"
                                required
                                data-msg="Please select type"
                                onChange={this.handleInputChange}
                              >
                                <option>Select Store Type</option>
                                {storetype &&
                                  storetype.map((type, index) => (
                                    <option key={index} value={type.store_type_id}>
                                      {type.store_type_name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="form-group d-flex justify-content-end mt-4">
                          {/* <input type="reset" value="cancel" className="btn btn-danger mr-2" /> */}
                          <button onClick={this.handleSaveBtnClick} disabled={!this.isMenuReady()} className="btn btn-primary">Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>

        </div>
      </div >
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    storetype: state.store.types,
    // stores: state.menu.menuStoresOptions,
    brands: state.store.brands,
    stores: state.menu.storesoptions
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    addMenu: function (data: any) {
      dispatch(addMenu(data));
    },
    brandsList: function () {
      dispatch(brandsList());
    },
    storeTypeList: function () {
      dispatch(storeTypeList());
    },
    // storesList: function (brand:any) {
    //   dispatch(storesListForMenuStoreRelation(brand))
    // },
    storesList: (brand_id:any) => {
      dispatch(storesListForMultiSelect(brand_id))
  }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddMenu);
