import { TIMER_STATUS, ORDERS_LIST, SYNC_FAILED_ORDERS_LIST,ERP_LOGS_LIST, ORDER_ITEMS_LIST, CANCEL_ORDER_REASONS, ORDER_STATUS_LIST, DELIVERY_BOYS, UPDATE_ORDER, OUTBOUND_CONTACTS, FILTERED_ORDERS, ORDERS_BY_STORE, SYNC_FILTERED_ORDERS, UPDATE_LOADER, UPDATE_TIMER, MANUAL_SYNC_ORDER, CARD_ORDERS_LIST, FILTERED_CARD_ORDERS, CARD_ORDER_ITEMS_LIST, UPDATE_SELECTED_ROW } from './orderType'
import Api from '../../components/Api';
import { toast } from "react-toastify";
import jwt from 'jsonwebtoken'
import "react-toastify/dist/ReactToastify.css";
import moment from 'moment';
import Axios from 'axios';
import { SYNC_URL } from '../../client-config'
toast.configure();
let timer: any;
export const luanchTimer = (brand_id:number,history: any) => {
    return function (dispatch: any) {
        dispatch({
            type: TIMER_STATUS,
            payload: true
        })
        timer = setInterval(() => {
            let obj = { days: 0,brand_id:brand_id }
            dispatch(ordersList(obj));
        }, 30000);
    }
}
export const luanchTimerForFailedOrders = (brand_id:number,history: any) => {
    return function (dispatch: any) {
        dispatch({
            type: TIMER_STATUS,
            payload: true
        })
        timer = setInterval(() => {
            let obj = { days: 0,brand_id:brand_id }
            dispatch(failedOrdersList(obj));
        }, 30000);
    }
}
export const stopTimer = () => {
    return function (dispatch: any) {
        dispatch({
            type: TIMER_STATUS,
            payload: false
        });
        clearInterval(timer)
    }
}

export const filterOrdersList = (filteredArr: any) => {
    return function (dispatch: any) {
        dispatch({
            type: FILTERED_ORDERS,
            payload: filteredArr,
            emptyFilterOrder: filteredArr.length > 0 ? false : true
        })
    }
}
export const syncFilterOrdersList = (syncFilteredArr: any) => {
    return function (dispatch: any) {
        dispatch({
            type: SYNC_FILTERED_ORDERS,
            payload: syncFilteredArr,
            emptyFilterOrder: syncFilteredArr.length > 0 ? false : true
        })
    }
}
export const searchOrder = (orderId: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/search_order/${orderId}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    let recieved = 0;
                    let kitchen = 0;
                    let readyOrder = 0;
                    let completed = 0;
                    let failedIntg = 0;
                    for (let i = 0; i < response.data.successResponse.length; i++) {
                        response.data.successResponse.forEach((element:any) => {
                            element.date_created = moment(element.date_created).local().format('YYYY-MM-DD HH:mm');
                        })
                        const obj = response.data.successResponse[i];
                        if (obj.order_status_code === 1) {
                            console.log("if (obj.order_status_code === 1) {",recieved);
                            
                            recieved += 1;
                        } if (obj.order_status_code === 2) {
                            console.log("if (obj.order_status_code === 1) {",kitchen);
                            kitchen += 1;
                        } if (obj.order_status_code === 3 || obj.order_status_code === 8) {
                            console.log("} if (obj.order_status_code === 3 || obj.order_status_code === 8) {",readyOrder);
                            readyOrder += 1;
                        } if (obj.order_status_code === 4 || obj.order_status_code === 5) {
                            console.log("} if (obj.order_status_code === 4 || obj.order_status_code === 5) {",completed);
                            
                            completed += 1;
                        } if ((obj.order_status_code === 4 || obj.order_status_code === 5) && obj.check_number === '') {
                            console.log("} if ((obj.order_status_code === 4 || obj.order_status_code === 5) && obj.check_number !== '') {",failedIntg);
                            
                            failedIntg += 1;
                        }
                    }
                    // response.data.successResponse.map((obj: any) => {
                    //     if (obj.order_status_code === 1) {
                    //         return recieved += 1;
                    //     } if (obj.order_status_code === 2) {
                    //         return kitchen += 1;
                    //     } if (obj.order_status_code === 3 || obj.order_status_code === 8) {
                    //         return readyOrder += 1;
                    //     } if (obj.order_status_code === 4 || obj.order_status_code === 5) {
                    //         return completed += 1;
                    //     } if ((obj.order_status_code === 4 || obj.order_status_code === 5) && obj.check_number === '') {
                    //         return failedIntg += 1;
                    //     }
                    // })
                    response.data.successResponse.map((obj: any) => {
                        let mintDiff: any = "";
                        if (obj.future_status == 1) {
                            let futureDay = moment(obj.future_date).local().startOf('day');
                            let currentDay = moment(new Date()).startOf('day');
                            const currentTime = moment().format('HH:mm:ss');
                            const futureTime = moment(obj.future_date).local().format('HH:mm:ss');
                            if (futureDay.isSame(currentDay)) {
                                mintDiff = moment(futureTime, 'HH:mm').diff(moment(currentTime, 'HH:mm'), 'minutes');
                                if (mintDiff >= 0 && mintDiff <= 60) {
                                    obj.orderStatus = "now";
                                } else if (mintDiff > 60) {
                                    obj.orderStatus = "future";
                                } else {
                                    obj.orderStatus = "now";
                                }
                            } else {
                                let futureDate = moment(obj.future_date).local().format('YYYY-MM-DD')
                                let currentDate = moment().format('YYYY-MM-DD');
                                if (moment(currentDate).isAfter(futureDate, 'day')) {
                                    obj.orderStatus = "now";
                                } else {
                                    obj.orderStatus = "future";
                                }
                            }
                        } else {
                            obj.orderStatus = "now";
                        }
                    })
                    let avg = response.data.successResponse[0].avg_delivery_time;
                    dispatch({
                        type: FILTERED_ORDERS,
                        payload: [],
                        emptyFilterOrder: false
                    })
                    dispatch({
                        type: ORDERS_LIST,
                        payload: response.data.successResponse,
                        avg: avg,
                        kitchenCount: kitchen,
                        dayVal: "",
                        recievedOrderCount: recieved,
                        readyOrderCount: readyOrder,
                        completedOrderCount: completed,
                        failedIntegOrderCount: failedIntg,
                    })
                     // get delivery drivers
                     dispatch(deliveryBoysList(response.data.successResponse))
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    dispatch({
                        type: ORDERS_LIST,
                        payload: [],
                        avg: 0,
                        kitchenCount: 0,
                        dayVal: "",
                        recievedOrderCount: 0,
                        readyOrderCount: 0,
                        completedOrderCount: 0,
                        failedIntegOrderCount: 0
                    })
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const searchAggOrder = (orderId: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        let data ={searchData: orderId}
        Api.post(`/admin/agg_search_order`,data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    let recieved = 0;
                    let kitchen = 0;
                    let readyOrder = 0;
                    let completed = 0;
                    let failedIntg = 0;
                    for (let i = 0; i < response.data.successResponse.length; i++) {
                        response.data.successResponse.forEach((element:any) => {
                            element.date_created = moment(element.date_created).local().format('YYYY-MM-DD HH:mm');
                        })
                        const obj = response.data.successResponse[i];
                        if (obj.order_status_code === 1) {
                            console.log("if (obj.order_status_code === 1) {",recieved);
                            
                            recieved += 1;
                        } if (obj.order_status_code === 2) {
                            console.log("if (obj.order_status_code === 1) {",kitchen);
                            kitchen += 1;
                        } if (obj.order_status_code === 3 || obj.order_status_code === 8) {
                            console.log("} if (obj.order_status_code === 3 || obj.order_status_code === 8) {",readyOrder);
                            readyOrder += 1;
                        } if (obj.order_status_code === 4 || obj.order_status_code === 5) {
                            console.log("} if (obj.order_status_code === 4 || obj.order_status_code === 5) {",completed);
                            
                            completed += 1;
                        } if ((obj.order_status_code === 4 || obj.order_status_code === 5) && obj.check_number === '') {
                            console.log("} if ((obj.order_status_code === 4 || obj.order_status_code === 5) && obj.check_number !== '') {",failedIntg);
                            
                            failedIntg += 1;
                        }
                    }
                    // response.data.successResponse.map((obj: any) => {
                    //     if (obj.order_status_code === 1) {
                    //         return recieved += 1;
                    //     } if (obj.order_status_code === 2) {
                    //         return kitchen += 1;
                    //     } if (obj.order_status_code === 3 || obj.order_status_code === 8) {
                    //         return readyOrder += 1;
                    //     } if (obj.order_status_code === 4 || obj.order_status_code === 5) {
                    //         return completed += 1;
                    //     } if ((obj.order_status_code === 4 || obj.order_status_code === 5) && obj.check_number === '') {
                    //         return failedIntg += 1;
                    //     }
                    // })
                    response.data.successResponse.map((obj: any) => {
                        let mintDiff: any = "";
                        if (obj.future_status == 1) {
                            let futureDay = moment(obj.future_date).local().startOf('day');
                            let currentDay = moment(new Date()).startOf('day');
                            const currentTime = moment().format('HH:mm:ss');
                            const futureTime = moment(obj.future_date).local().format('HH:mm:ss');
                            if (futureDay.isSame(currentDay)) {
                                mintDiff = moment(futureTime, 'HH:mm').diff(moment(currentTime, 'HH:mm'), 'minutes');
                                if (mintDiff >= 0 && mintDiff <= 60) {
                                    obj.orderStatus = "now";
                                } else if (mintDiff > 60) {
                                    obj.orderStatus = "future";
                                } else {
                                    obj.orderStatus = "now";
                                }
                            } else {
                                let futureDate = moment(obj.future_date).local().format('YYYY-MM-DD')
                                let currentDate = moment().format('YYYY-MM-DD');
                                if (moment(currentDate).isAfter(futureDate, 'day')) {
                                    obj.orderStatus = "now";
                                } else {
                                    obj.orderStatus = "future";
                                }
                            }
                        } else {
                            obj.orderStatus = "now";
                        }
                    })
                    let avg = response.data.successResponse[0].avg_delivery_time;
                    dispatch({
                        type: FILTERED_ORDERS,
                        payload: [],
                        emptyFilterOrder: false
                    })
                    dispatch({
                        type: ORDERS_LIST,
                        payload: response.data.successResponse,
                        avg: avg,
                        kitchenCount: kitchen,
                        dayVal: "",
                        recievedOrderCount: recieved,
                        readyOrderCount: readyOrder,
                        completedOrderCount: completed,
                        failedIntegOrderCount: failedIntg,
                    })
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    dispatch({
                        type: ORDERS_LIST,
                        payload: [],
                        avg: 0,
                        kitchenCount: 0,
                        dayVal: "",
                        recievedOrderCount: 0,
                        readyOrderCount: 0,
                        completedOrderCount: 0,
                        failedIntegOrderCount: 0
                    })
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const searchSyncAggOrder = (orderId: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        let data ={searchData: orderId}
        Axios.post(`${SYNC_URL}/admin/agg_failed_search_order`,data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    let recieved = 0;
                    let kitchen = 0;
                    let readyOrder = 0;
                    let completed = 0;
                    let failedIntg = 0;
                    response.data.successResponse.map((obj: any) => {
                        let mintDiff: any = "";
                        if (obj.future_status == 1) {
                            let futureDay = moment(obj.future_date).local().startOf('day');
                            let currentDay = moment(new Date()).startOf('day');
                            const currentTime = moment().format('HH:mm:ss');
                            const futureTime = moment(obj.future_date).local().format('HH:mm:ss');
                            if (futureDay.isSame(currentDay)) {
                                mintDiff = moment(futureTime, 'HH:mm').diff(moment(currentTime, 'HH:mm'), 'minutes');
                                if (mintDiff >= 0 && mintDiff <= 60) {
                                    obj.orderStatus = "now";
                                } else if (mintDiff > 60) {
                                    obj.orderStatus = "future";
                                } else {
                                    obj.orderStatus = "now";
                                }
                            } else {
                                let futureDate = moment(obj.future_date).local().format('YYYY-MM-DD')
                                let currentDate = moment().format('YYYY-MM-DD');
                                if (moment(currentDate).isAfter(futureDate, 'day')) {
                                    obj.orderStatus = "now";
                                } else {
                                    obj.orderStatus = "future";
                                }
                            }
                        } else {
                            obj.orderStatus = "now";
                        }
                    })
                    let avg = response.data.successResponse[0].avg_delivery_time;
                    dispatch({
                        type: SYNC_FILTERED_ORDERS,
                        payload: [],
                        emptyFilterOrder: false
                    })
                    dispatch({
                        type: SYNC_FAILED_ORDERS_LIST,
                        payload: response.data.successResponse,
                        avg: avg,
                        kitchenCount: kitchen,
                        dayVal: "",
                        failedIntegOrderCount: failedIntg,
                    })
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    dispatch({
                        type: SYNC_FAILED_ORDERS_LIST,
                        payload: [],
                        avg: 0,
                        kitchenCount: 0,
                        dayVal: "",
                        recievedOrderCount: 0,
                        readyOrderCount: 0,
                        completedOrderCount: 0,
                        failedIntegOrderCount: 0
                    })
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const ordersList = (obj: any, datetime?: any) => {
    return function (dispatch: any) {
        if (datetime) {
            dispatch({
                type: UPDATE_LOADER,
                isLoading: true
            })
        }
        dispatch({
            type: UPDATE_TIMER,
            isTimerUpdate: true
        })
        let token: any = sessionStorage.getItem('token');
        Api.post('/admin/orders', obj, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    response.data.successResponse.forEach((element:any) => {
                        element.date_created = moment(element.date_created).local().format('YYYY-MM-DD HH:mm');
                    })
                    let recieved = 0;
                    let kitchen = 0;
                    let readyOrder = 0;
                    let completed = 0;
                    let failedIntg = 0;
                    
                    for (let i = 0; i < response.data.successResponse.length; i++) {
                        const obj = response.data.successResponse[i];
                        if (obj.order_status_code === 1) {
                            recieved += 1;
                        } if (obj.order_status_code === 2) {
                            kitchen += 1;
                        } if (obj.order_status_code === 3 || obj.order_status_code === 8) {
                            readyOrder += 1;
                        } if (obj.order_status_code === 4 || obj.order_status_code === 5) {
                            completed += 1;
                        } if ((obj.order_status_code === 4 || obj.order_status_code === 5) && obj.check_number === '') {
                            failedIntg += 1;
                        }
                    }
                    // response.data.successResponse.map((obj: any) => {
                    //     if (obj.order_status_code === 1) {
                    //         return recieved += 1;
                    //     } if (obj.order_status_code === 2) {
                    //         return kitchen += 1;
                    //     } if (obj.order_status_code === 3 || obj.order_status_code === 8) {
                    //         return readyOrder += 1;
                    //     } if (obj.order_status_code === 4 || obj.order_status_code === 5) {
                    //         return completed += 1;
                    //     } if ((obj.order_status_code === 4 || obj.order_status_code === 5) && obj.check_number !== '') {
                    //         return failedIntg += 1;
                    //     }
                    // })

                    response.data.successResponse.map((obj: any) => {
                        let mintDiff: any = "";
                        if (obj.future_status == 1) {
                            let futureDay = moment(obj.future_date).local().startOf('day');
                            let currentDay = moment(new Date()).startOf('day');
                            const currentTime = moment().format('HH:mm:ss');
                            const futureTime = moment(obj.future_date).local().format('HH:mm:ss');
                            if (futureDay.isSame(currentDay)) {
                                mintDiff = moment(futureTime, 'HH:mm').diff(moment(currentTime, 'HH:mm'), 'minutes');
                                if (mintDiff >= 0 && mintDiff <= 60) {
                                    obj.orderStatus = "now";
                                } else if (mintDiff > 60) {
                                    obj.orderStatus = "future";
                                } else {
                                    obj.orderStatus = "now";
                                }
                            } else {
                                let futureDate = moment(obj.future_date).local().format('YYYY-MM-DD')
                                let currentDate = moment().format('YYYY-MM-DD');
                                if (moment(currentDate).isAfter(futureDate, 'day')) {
                                    obj.orderStatus = "now";
                                } else {
                                    obj.orderStatus = "future";
                                }
                            }
                        } else {
                            obj.orderStatus = "now";
                        }
                    })
                    let avg = response.data.successResponse[0].avg_delivery_time;
                    dispatch({
                        type: FILTERED_ORDERS,
                        payload: [],
                        emptyFilterOrder: false
                    })
                    dispatch({
                        type: ORDERS_LIST,
                        payload: response.data.successResponse,
                        avg: avg,
                        kitchenCount: kitchen,
                        dayVal: obj,
                        recievedOrderCount: recieved,
                        readyOrderCount: readyOrder,
                        completedOrderCount: completed,
                        failedIntegOrderCount: failedIntg,
                        isLoading: false,
                        isTimerUpdate: false
                    })
                    dispatch(deliveryBoysList(response.data.successResponse))
                    sessionStorage.setItem('prevLength', response.data.successResponse.length)
                }
            }).catch(err => {
                if (err.response) {
                    dispatch({
                        type: FILTERED_ORDERS,
                        payload: [],
                        emptyFilterOrder: false
                    })
                    dispatch({
                        type: ORDERS_LIST,
                        payload: [],
                        kitchenCount: 0,
                        dayVal: obj,
                        recievedOrderCount: 0,
                        readyOrderCount: 0,
                        completedOrderCount: 0,
                        failedIntegOrderCount: 0,
                        isLoading: false,
                        isTimerUpdate: false

                    })
                    dispatch({
                        type: UPDATE_TIMER,
                        isTimerUpdate: false
                    })
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const failedOrdersList = (obj: any, datetime?: any) => {
    return function (dispatch: any) {
        if (datetime) {
            dispatch({
                type: UPDATE_LOADER,
                isLoading: true
            })
        }
        dispatch({
            type: UPDATE_TIMER,
            isTimerUpdate: true
        })
        let token: any = sessionStorage.getItem('token');
        Api.post('/admin/failedOrders', obj, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    response.data.successResponse.forEach((element:any) => {
                        element.date_created = moment(element.date_created).local().format('YYYY-MM-DD HH:mm');
                    })

                    dispatch({
                        type: FILTERED_ORDERS,
                        payload: [],
                        emptyFilterOrder: false
                    })
                    dispatch({
                        type: ORDERS_LIST,
                        payload: response.data.successResponse,
                        dayVal: obj,
                        isLoading: false,
                        isTimerUpdate: false
                    })
                    dispatch(deliveryBoysList(response.data.successResponse))
                    sessionStorage.setItem('prevLength', response.data.successResponse.length)
                }
            }).catch(err => {
                if (err.response) {
                    dispatch({
                        type: FILTERED_ORDERS,
                        payload: [],
                        emptyFilterOrder: false
                    })
                    dispatch({
                        type: ORDERS_LIST,
                        payload: [],
                        dayVal: obj,
                        failedIntegOrderCount: 0,
                        isLoading: false,
                        isTimerUpdate: false

                    })
                    dispatch({
                        type: UPDATE_TIMER,
                        isTimerUpdate: false
                    })
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const syncFailedOrdersList = (obj: any, datetime?: any) => {
    return function (dispatch: any) {
        if (datetime) {
            dispatch({
                type: UPDATE_LOADER,
                isLoading: true
            })
        }
        dispatch({
            type: UPDATE_TIMER,
            isTimerUpdate: true
        })
        obj.brand_id = 1;
        let token: any = sessionStorage.getItem('token');
        Axios.post(`${SYNC_URL}/admin/failed_sync_orders`, obj, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                console.log('sync Failed Orders List00000000000000000000000000000000000',response);
                
                if (response.data.success) {
                    response.data.successResponse.forEach((element:any) => {
                        element.date_created = moment(element.date_created).local().format('YYYY-MM-DD HH:mm');
                    })

                    dispatch({
                        type: SYNC_FILTERED_ORDERS,
                        payload: [],
                        emptyFilterOrder: false
                    })
                    dispatch({
                        type: SYNC_FAILED_ORDERS_LIST,
                        payload: response.data.successResponse,
                        dayVal: obj,
                        isLoading: false,
                        isTimerUpdate: false
                    })
                    dispatch(deliveryBoysList(response.data.successResponse))
                    sessionStorage.setItem('prevLength', response.data.successResponse.length)
                }
            }).catch(err => {
                if (err.response) {
                    dispatch({
                        type: SYNC_FILTERED_ORDERS,
                        payload: [],
                        emptyFilterOrder: false
                    })
                    dispatch({
                        type: SYNC_FAILED_ORDERS_LIST,
                        payload: [],
                        dayVal: obj,
                        failedIntegOrderCount: 0,
                        isLoading: false,
                        isTimerUpdate: false

                    })
                    dispatch({
                        type: UPDATE_TIMER,
                        isTimerUpdate: false
                    })
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const updateERPLogs = (id:any)=>{

    return function (dispatch: any) {
        
        let obj
        let token: any = sessionStorage.getItem('token');
        Api.post(`/admin/updateErp/${id}`, obj,{
            headers: { 'Authorization': 'Bearer ' + token }
        })
        .then((response) => {
                
            if (response.data.success) {

                setTimeout(() => {
                    window.location.href = "/erp_orders"
                }, 3000);
                toast.info(response.data.successResponse, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });

            }
            if (response.data.success == false) {

                setTimeout(() => {
                    window.location.href = "/erp_orders"
                }, 2000);

                toast.error(response.data.successResponse, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });


            }
        }).catch(err => {
            if (err.response) {
               
            } else {
                alert(err.message)
            }
        });
    }

}
export const ERPlogsList = (obj: any, datetime?: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post(`/admin/erpOrders`, obj, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
        .then((response) => {
                
            if (response.data.success) {
                dispatch({
                    type: ERP_LOGS_LIST,
                    payload: response.data.successResponse,
                    
                })
            }
        }).catch(err => {
            if (err.response) {
                dispatch({
                    type: ERP_LOGS_LIST,
                    payload: [],
                })   
            } else {
                alert(err.message)
            }
        });
    }
}
export const cancelOrdersReasons = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/reasons/reasonByType', {
            type: 'cancel order'
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: CANCEL_ORDER_REASONS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const repushOrder = (id: any, data: any, history: any) => {
    return function (dispatch: any) {
        console.log('reeeeeeeeeeeeeeeeeeeeeeeeeeeeeee00000');
        
        let token: any = sessionStorage.getItem('token');
        Api.put(`/admin/repush_bo_order/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    history.push("/orders");
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    dispatch({
                        type: UPDATE_ORDER,
                        isUpdated: false,
                        payload: error
                    })
                } else {
                    alert(err.message)
                }
            });
    }
}
export const showorderItems = (order_id: any, orders: any,channel:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        if (order_id === "") {
            dispatch({
                type: ORDER_ITEMS_LIST,
                payload: []
            })
        } else {
            Api.get(`/admin/order_items/${order_id}/${channel}`, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
                .then((response) => {
                    if (response.data.success) {
                        let order = orders.find((obj: any) => {
                            return obj.order_id == order_id;
                        })
                        dispatch({
                            type: ORDER_ITEMS_LIST,
                            payload: response.data.successResponse,
                            orderDetail: order
                        })
                    }
                }).catch(err => {
                    if (err.response) {
                        dispatch({
                            type: ORDER_ITEMS_LIST,
                            payload: []
                        })
                        console.log(err.response.data.message)
                    } else {
                        alert(err.message)
                    }
                });
        }
    }
}
export const orderStatusList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/order_status', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ORDER_STATUS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const deliveryBoysList = (orders: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/Drivers/getActiveDrivers', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((driversData) => {
                if (driversData.data.success) {
                    // To lock local rider after one order dispatched
                    // driversData.data.successResponse.map((obj: any) => {
                    //     if (obj.goPanda == "false") {
                    //         orders.map((order: any) => {
                    //             if (obj.id == order.delivery_boy && (order.order_status_code == 1 || order.order_status_code == 2 || order.order_status_code == 3 || order.order_status_code == 8)) {
                    //                 obj.order_id = order.order_id;
                    //             }
                    //         })
                    //     }
                    // })
                    dispatch({
                        type: DELIVERY_BOYS,
                        payload: driversData.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const updateOrder = (id: any, data: any, history: any,brand_id:number) => {
    return function (dispatch: any) {
        dispatch({
            type: UPDATE_ORDER,
            payload: true
        })
        if (data.orderData) {
            let orderTime = moment(data.orderData.date_created).utc(false).format();
            data.orderData.date_created = orderTime;
        }
        let token: any = sessionStorage.getItem('token');
        data.user_info = jwt.decode(token);
        Api.put(`/admin/update_order/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    if (data.delivery_boy) {
                        toast.info("Driver Saved Succesfully!", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                        dispatch({
                            type: UPDATE_ORDER,
                            payload: false
                        })
                    } else {
                        if (response.data.error == false) {
                            toast.error(`${response.data.successResponse}`, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 10000 });
                        } else {
                            toast.info(`${response.data.successResponse}`, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 5000 });
                        }
                        dispatch({
                            type: UPDATE_ORDER,
                            payload: false
                        })
                    }
                    dispatch(ordersList({ days: 0,brand_id:brand_id }))
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please select the driver";
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    dispatch({
                        type: UPDATE_ORDER,
                        payload: false
                    })
                } else {
                    alert(err.message)
                }
            });
    }
}
export const updatefutureOrder = (id: any, data: any, history: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/admin/update_future_order/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    history.push("/orders");
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    dispatch({
                        type: UPDATE_ORDER,
                        isUpdated: false,
                        payload: error
                    })
                } else {
                    alert(err.message)
                }
            });
    }
}

//outbound contacts
export const outboundContacts = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/outbound/contacts', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: OUTBOUND_CONTACTS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const updateContactStatus = (id: any, data: any, history: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/outbound/update_contact_status/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    history.push("/outbound_contacts");
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    console.log(error)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const manualSyncOrder = (id: any, data: any, history: any, orders: any) => {
    return function (dispatch: any) {
        dispatch({
            type: MANUAL_SYNC_ORDER,
            payload: true
        })
        orders.forEach((element: any) => {
            if (id == element.order_id) {
                element.clickmenuSync = true;
            } else {
                element.clickmenuSync = false;
            }
        });
        dispatch({
            type: FILTERED_ORDERS,
            payload: orders,
            emptyFilterOrder: orders.length > 0 ? false : true
        })
        let token: any = sessionStorage.getItem('token');
        data.user_info = jwt.decode(token);
        Api.get(`/admin/dumpIntegOrdersManually/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                console.log("MANUAL_SYNC_ORDER: ",response.data.success);
                
                if (response.data.success) {
                    toast.info(`${response.data.message}`, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 5000 });
                        dispatch({
                            type: MANUAL_SYNC_ORDER,
                            payload: false
                        })
                    }else{
                        toast.error(`${response.data.message}`, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 10000 });
                        dispatch({
                            type: MANUAL_SYNC_ORDER,
                            payload: false
                        })
                    }
                orders.forEach((element: any) => {
                    element.clickmenuSync = false;
                });
                    dispatch(ordersList({ days: 0 }))
                
            }).catch(err => {
                
            });
    }
}
export const manualSyncCMSFailedOrder = (id: any, data: any, history: any, orders: any) => {
    return function (dispatch: any) {
        // dispatch({
        //     type: MANUAL_SYNC_ORDER,
        //     payload: true
        // })
        console.log('wwwwwwwwwwwwwwww', orders);
        
        // orders.forEach((element: any) => {
        //     if (id == element.id) {
        //         element.clickmenuSync = true;
        //     } else {
        //         element.clickmenuSync = false;
        //     }
        // });
        dispatch({
            type: SYNC_FAILED_ORDERS_LIST,
            payload: orders,
            emptyFilterOrder: orders.length > 0 ? false : true
        })
        let token: any = sessionStorage.getItem('token');
        data.user_info = jwt.decode(token);
        Axios.get(`${SYNC_URL}/admin/sync_deliveroo_failed_Order_manually/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                console.log("MANUAL_SYNC_ORDER: 11111111111111111111111111111111111111s",response.data.success);
                
                if (response.data.success) {
                    toast.info(`${response.data.successResponse}`, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 5000 });
                        dispatch({
                            type: SYNC_FAILED_ORDERS_LIST,
                            payload: false
                        })
                    }else{
                        toast.error(`${response.data.successResponse}`, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 10000 });
                        dispatch({
                            type: SYNC_FAILED_ORDERS_LIST,
                            payload: false
                        })
                    }
                orders.forEach((element: any) => {
                    element.clickmenuSync = false;
                });
                    dispatch(syncFailedOrdersList({ days: 0 }))
                
            }).catch(err => {
                
            });
    }
}
export const manualSyncFailedOrder = (id: any, data: any, history: any, orders: any) => {
    return function (dispatch: any) {
        dispatch({
            type: MANUAL_SYNC_ORDER,
            payload: true
        })
        orders.forEach((element: any) => {
            if (id == element.order_id) {
                element.clickmenuSync = true;
            } else {
                element.clickmenuSync = false;
            }
        });
        dispatch({
            type: FILTERED_ORDERS,
            payload: orders,
            emptyFilterOrder: orders.length > 0 ? false : true
        })
        let token: any = sessionStorage.getItem('token');
        data.user_info = jwt.decode(token);
        Api.get(`/admin/manuallySyncAggFailedOrder/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                console.log("MANUAL_SYNC_ORDER: ",response.data.success);
                
                if (response.data.success) {
                    toast.info(`${response.data.message}`, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 5000 });
                        dispatch({
                            type: MANUAL_SYNC_ORDER,
                            payload: false
                        })
                    }else{
                        toast.error(`${response.data.message}`, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 10000 });
                        dispatch({
                            type: MANUAL_SYNC_ORDER,
                            payload: false
                        })
                    }
                orders.forEach((element: any) => {
                    element.clickmenuSync = false;
                });
                    dispatch(ordersList({ days: 0 }))
                
            }).catch(err => {
                
            });
    }
}
export const manualSyncOrderFiscal = (id: any) => {
    return function (dispatch: any) {
        // dispatch({
        //     type: MANUAL_SYNC_ORDER,
        //     payload: true
        // })
        // orders.forEach((element: any) => {
        //     if (id == element.order_id) {
        //         element.clickmenuSync = true;
        //     } else {
        //         element.clickmenuSync = false;
        //     }
        // });
        // dispatch({
        //     type: FILTERED_ORDERS,
        //     payload: orders,
        //     emptyFilterOrder: orders.length > 0 ? false : true
        // })
        let token: any = sessionStorage.getItem('token');
        // data.user_info = jwt.decode(token);
        Api.post('/api/orders/readyFiscalFailedOrder', {
            order_id: id
        })
            .then((response) => {
                console.log("MANUAL_SYNC_ORDER: ",response.data.success);
                
                if (response.data.success) {
                    // toast.info(`${response.data.message}`, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 5000 });
                        // dispatch({
                        //     type: MANUAL_SYNC_ORDER,
                        //     payload: false
                        // })
                    }else{
                        // toast.error(`${response.data.message}`, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 10000 });
                        // dispatch({
                        //     type: MANUAL_SYNC_ORDER,
                        //     payload: false
                        // })
                    }
                // orders.forEach((element: any) => {
                //     element.clickmenuSync = false;
                // });
                //     dispatch(ordersList({ days: 0 }))
                
            }).catch(err => {
                
            });
    }
}

export const getOrdersByStore = (id: any, store: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/orders_by_store/' + id, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ORDERS_BY_STORE,
                        payload: response.data.successResponse,
                        heatmapLat: response.data.storeData[0].storeLat,
                        heatmapLng: response.data.storeData[0].storeLng,
                        store_name: store,
                        store_id: id
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
                dispatch({
                    type: ORDERS_BY_STORE,
                    payload: [],
                    store_name: store,
                    store_id: id
                })
            });
    }
}
export const cardOrdersList = (obj: any, datetime?: any, updateLoader?: any) => {
    return function (dispatch: any) {
        if (datetime) {
            dispatch({
                type: UPDATE_LOADER,
                isLoading: true
            })
        }
        let token: any = sessionStorage.getItem('token');
        Api.post('/admin/card_orders', obj, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    if (updateLoader) {
                        updateLoader()
                    }
                    response.data.successResponse.forEach((element: any) => {
                        element.date_created = moment(element.date_created).local().format('YYYY-MM-DD HH:mm');
                    })
                    let verified = 0;
                    let unverified = 0;

                    for (let i = 0; i < response.data.successResponse.length; i++) {
                        const obj = response.data.successResponse[i];
                        if (obj.is_verify === 0) {
                            unverified += 1;
                        } if (obj.is_verify === 1) {
                            verified += 1;
                        }
                    }

                    response.data.successResponse.map((obj: any) => {
                        let mintDiff: any = "";
                        if (obj.future_status == 1) {
                            let futureDay = moment(obj.future_date).local().startOf('day');
                            let currentDay = moment(new Date()).startOf('day');
                            const currentTime = moment().format('HH:mm:ss');
                            const futureTime = moment(obj.future_date).local().format('HH:mm:ss');
                            if (futureDay.isSame(currentDay)) {
                                mintDiff = moment(futureTime, 'HH:mm').diff(moment(currentTime, 'HH:mm'), 'minutes');
                                if (mintDiff >= 0 && mintDiff <= 60) {
                                    obj.orderStatus = "now";
                                } else if (mintDiff > 60) {
                                    obj.orderStatus = "future";
                                } else {
                                    obj.orderStatus = "now";
                                }
                            } else {
                                let futureDate = moment(obj.future_date).local().format('YYYY-MM-DD')
                                let currentDate = moment().format('YYYY-MM-DD');
                                if (moment(currentDate).isAfter(futureDate, 'day')) {
                                    obj.orderStatus = "now";
                                } else {
                                    obj.orderStatus = "future";
                                }
                            }
                        } else {
                            obj.orderStatus = "now";
                        }
                    })
                    dispatch({
                        type: FILTERED_CARD_ORDERS,
                        payload: [],
                        emptyFilterCardOrder: false
                    })
                    dispatch({
                        type: CARD_ORDERS_LIST,
                        payload: response.data.successResponse,
                        verifyCount: verified,
                        unverifyCount: unverified,
                        isLoading: false
                    })
                }
            }).catch(err => {
                if (err.response) {
                    if (updateLoader) {
                        updateLoader()
                    }
                    dispatch({
                        type: FILTERED_CARD_ORDERS,
                        payload: [],
                        emptyFilterCardOrder: false
                    })
                    dispatch({
                        type: CARD_ORDERS_LIST,
                        payload: [],
                        verifyCount: 0,
                        unverifyCount: 0,
                        isLoading: false
                    })
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const filterCardOrdersList = (filteredArr: any) => {
    return function (dispatch: any) {
        dispatch({
            type: FILTERED_CARD_ORDERS,
            payload: filteredArr,
            emptyFilterCardOrder: filteredArr.length > 0 ? false : true
        })
    }
}
export const showCardOrderItems = (order_id: any, orders: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        if (order_id === "") {
            dispatch({
                type: ORDER_ITEMS_LIST,
                payload: []
            })
        } else {
            Api.get(`/admin/temp_order_items/${order_id}`, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
                .then((response) => {
                    if (response.data.success) {
                        let order = orders.find((obj: any) => {
                            return obj.order_id == order_id;
                        })
                        dispatch({
                            type: CARD_ORDER_ITEMS_LIST,
                            payload: response.data.successResponse,
                            orderDetail: order
                        })
                    }
                }).catch(err => {
                    if (err.response) {
                        dispatch({
                            type: CARD_ORDER_ITEMS_LIST,
                            payload: []
                        })
                        console.log(err.response.data.message)
                    } else {
                        alert(err.message)
                    }
                });
        }
    }
}
export const updateSelectedRow = (row: any) => {
    return function (dispatch: any) {
        dispatch({
            type: UPDATE_SELECTED_ROW,
            payload: row
        })
    }
};