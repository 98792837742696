import { CANCEL_ORDER_REASONS, ORDERS_BY_STORE,SYNC_FAILED_ORDERS_LIST, DELIVERY_BOYS, FILTERED_ORDERS, ORDERS_LIST, ORDER_ITEMS_LIST, ORDER_STATUS_LIST, OUTBOUND_CONTACTS, TIMER_STATUS, UPDATE_ORDER, UPDATE_LOADER, UPDATE_TIMER, MANUAL_SYNC_ORDER, SYNC_FILTERED_ORDERS, CARD_ORDERS_LIST, CARD_ORDER_ITEMS_LIST, FILTERED_CARD_ORDERS, UPDATE_SELECTED_ROW, ERP_LOGS_LIST } from '../actions/orderType'
const initialState: any = {
    data: [] || "",
    syncFailedOrders: [] || "",
    filteredOrders: [] || "",
    syncFilteredOrders: [] || "",
    erpLogsList:[] || "",
    orderItems: [] || "",
    order: {},
    orderStatus: [] || "",
    deliveryBoys: [] || "",
    outboundContacts: [] || "",
    buttonDisable: false,
    timerStatus: true,
    kitchenCount: 0,
    recievedOrderCount: 0,
    readyOrderCount: 0,
    completedOrderCount: 0,
    failedIntegOrderCount: 0,
    avg: 0,
    cancelReasons: [] || "",
    dayVal: {},
    emptyFilterOrder: false,
    syncEmptyFilterOrder: false,
    isLoading: false,
    isTimerUpdate: false,
    clickmenuSync:false,
    cardOrders:[]||"",
    cardOrderItems: [] || "",
    filteredCardOrders: [] || "",
    emptyFilterCardOrder: false,
    verifyCount: 0,
    unverifyCount: 0,
    selectedRow: null,
}

const orderReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ORDERS_LIST: return {
            ...state,
            data: action.payload,
            avg: action.avg ? action.avg : 0,
            dayVal: action.dayVal,
            kitchenCount: action.kitchenCount,
            recievedOrderCount: action.recievedOrderCount,
            readyOrderCount: action.readyOrderCount,
            completedOrderCount: action.completedOrderCount,
            isLoading: action.isLoading,
            isTimerUpdate: action.isTimer
        }
        case SYNC_FAILED_ORDERS_LIST: return {
            ...state,
            syncFailedOrders: action.payload,
            dayVal: action.dayVal,
            isLoading: action.isLoading,
            isTimerUpdate: action.isTimer
        }
        case ORDER_ITEMS_LIST: return {
            ...state,
            orderItems: action.payload,
            order: action.orderDetail
        }
        case ORDER_STATUS_LIST: return {
            ...state,
            orderStatus: action.payload
        }
        case DELIVERY_BOYS: return {
            ...state,
            deliveryBoys: action.payload
        }
        case UPDATE_ORDER: return {
            ...state,
            buttonDisable: action.payload
        }
        case OUTBOUND_CONTACTS: return {
            ...state,
            outboundContacts: action.payload
        }
        case CANCEL_ORDER_REASONS: return {
            ...state,
            cancelReasons: action.payload
        }
        case FILTERED_ORDERS: return {
            ...state,
            filteredOrders: action.payload,
            emptyFilterOrder: action.emptyFilterOrder
        }
        case SYNC_FILTERED_ORDERS: return {
            ...state,
            syncFilteredOrders: action.payload,
            syncEmptyFilterOrder: action.syncEmptyFilterOrder
        }
        case ERP_LOGS_LIST: return {
            ...state,
            erpLogsList: action.payload,
        }
        
        case TIMER_STATUS: return {
            ...state,
            timerStatus: action.payload == false ? false : true
        }
        case ORDERS_BY_STORE: return {
            ...state,
            orderByStores: action.payload,
            heatmapLat: action.heatmapLat,
            heatmapLng: action.heatmapLng,
            store_name: action.store_name,
            store_id: action.store_id
        }
        case UPDATE_LOADER: return {
            ...state,
            isLoading: action.isLoading
        }
        case UPDATE_TIMER: return {
            ...state,
            isTimerUpdate: action.isTimerUpdate
        }
        case MANUAL_SYNC_ORDER: return {
            ...state,
            clickmenuSync: action.payload
        }
        case CARD_ORDERS_LIST: return {
            ...state,
            cardOrders: action.payload,
            verifyCount: action.verifyCount,
            unverifyCount: action.unverifyCount,
            isLoading: action.isLoading
        }
        case FILTERED_CARD_ORDERS: return {
            ...state,
            filteredCardOrders: action.payload,
            emptyFilterCardOrder: action.emptyFilterCardOrder
        }
        case CARD_ORDER_ITEMS_LIST: return {
            ...state,
            cardOrderItems: action.payload,
            order: action.orderDetail
        }
        case UPDATE_SELECTED_ROW: return {
            ...state,
            selectedRow: action.payload
        }
        default: return state;
    }
}
export default orderReducer;