import React, { Component } from 'react';
import { Link } from 'react-router-dom'

export default class Footer extends Component<{}, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    render() {
        return (
            <div className="copyrights text-center">
                <p>Powered By <Link to="#" className="external text-primary">SimpleX Technology Solutions</Link>
                </p>
            </div>
        );
    }
};