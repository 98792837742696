//marketing
/* global google */

import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { couponsList, logoutUser, deleteCoupon, activeInactiveCoupon, promosList, deletePromo, activeInactivePromo, discountsList, deleteDiscount, activeInactiveDiscount, notificationList, deleteNotification, activeInactiveNotification, storesList, getOrdersByStore, brandsList } from '../../redux'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { marketingProps } from '../../interfaces/marketing';
import MapContainer from './map'
import './heatmap.css'
import { data } from './data';
import _ from 'lodash';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Select from 'react-select';
import moment from 'moment';
import { storesList2 } from '../../redux/actions/storeAction';
import { discountsListForDiscount } from '../../redux/actions/discountAction';
class ActionFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    handleDelete = (id: any) => {
        this.props.data.deleteCoupon(id, this.props.data.history, this.props.row.coupon_code)
    };
    handleActiveInActive = (id: any, is_archive: any) => {
        this.props.data.activeInactiveCoupon(id, is_archive, this.props.data.history, this.props.row.coupon_code);
    };
    render() {
        const { row } = this.props
        return (
            <div>
                <button title={row.is_archive === 0 ? "Inactive" : "Active"} data-toggle="modal" data-target={`#activeInactive${row.coupon_id}`} className={row.is_archive === 0 ? "btn btn-outline-danger" : "btn btn-outline-success"}><i className={row.is_archive === 0 ? "fa fa-lock" : "fa fa-unlock"}></i></button>
                <button title="Delete Coupon" className="btn btn-outline-danger mx-2" data-toggle="modal" data-target={`#DelCoupon${row.coupon_id}`}><i className="fa fa-trash"></i></button>
                <Link title="Edit Coupon" className="btn btn-outline-primary mr-2" to={`/edit-coupon/${row.coupon_id}`}><i className="fa fa-edit"></i></Link>
                {/* <!-- Modal--> */}
                <div id={`DelCoupon${row.coupon_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Delete Coupon</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to delete this Coupon?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                                <button onClick={() => this.handleDelete(row.coupon_id)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Block/Unblock Modal--> */}
                <div id={`activeInactive${row.coupon_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{row.is_archive === 0 ? "Inactive" : "Active"} Coupon</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to {row.is_archive === 0 ? "Inactive" : "Active"} this coupon?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.handleActiveInActive(row.coupon_id, row.is_archive)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} data={props} />
    );
}
function orderModesFormatter(cell: any, row: any, props: any) {
    return (
        <OrderModesFormatter row={row} data={props} />
    );
}
class OrderModesFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    IsJsonString = (str: any) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    render() {
        const { row } = this.props;
        return (
            <div>
                {row.mode &&
                    <button title="View Modes" data-toggle="modal" data-target={`#dismodes${row.discount_id}`} className="btn btn-outline-info"><i className="fa fa-list"></i></button>
                }
                {/* <!-- Modal--> */}
                <div id={`dismodes${row.discount_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Order Modes</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group text-capitalize">
                                            <BootstrapTable version='4' data={(row.mode && this.IsJsonString(row.mode)) ? JSON.parse(row.mode) : ""} hover>
                                                <TableHeaderColumn dataField='label' columnTitle isKey>Order Mode</TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
class PromoActionFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    handleDelete = (id: any) => {
        this.props.data.deletePromo(id, this.props.data.history, this.props.row.promo_name)
    };
    handleActiveInActive = (id: any, is_archive: any) => {
        this.props.data.activeInactivePromo(id, is_archive, this.props.data.history, this.props.row.promo_name);
    };
    render() {
        const { row } = this.props;
        return (
            <div>
                <button title={row.is_archive === 0 ? "Inactive" : "Active"} data-toggle="modal" data-target={`#activeInactive${row.promo_id}`} className={row.is_archive === 0 ? "btn btn-outline-danger mr-2" : "btn btn-outline-success mr-2"}><i className={row.is_archive === 0 ? "fa fa-lock" : "fa fa-unlock"}></i></button>
                <button title="Delete Promo" className="btn btn-outline-danger" data-toggle="modal" data-target={`#DelPromo${row.promo_id}`}><i className="fa fa-trash"></i></button>
                <Link title="Edit Promo" className="btn btn-outline-primary ml-2" to={`/edit-promo/${row.promo_id}`}><i className="fa fa-edit"></i></Link>
                {/* <!-- Modal--> */}
                <div id={`DelPromo${row.promo_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Delete Promo</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure, you want to delete this Promotion?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                                <button onClick={() => this.handleDelete(row.promo_id)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Block/Unblock Modal--> */}
                <div id={`activeInactive${row.promo_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{row.is_archive === 0 ? "Inactive" : "Active"} Promo</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure, you want to {row.is_archive === 0 ? "Inactive" : "Active"} this promo?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.handleActiveInActive(row.promo_id, row.is_archive)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function promosActionFormatter(cell: any, row: any, props: any) {
    return (
        <PromoActionFormatter row={row} data={props} />
    );
}
//Push Notificaions Actions
class NotificationActionFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    handleDelete = (id: any) => {
        this.props.data.deleteNotification(id, this.props.data.history, this.props.row.title)
    };
    handleActiveInActive = (id: any, is_archive: any) => {
        this.props.data.activeInactiveNotification(id, is_archive, this.props.data.history, this.props.row.title);
    };
    render() {
        const { row } = this.props;
        return (
            <div>
                <button title={row.is_active === 1 ? "Inactive" : "Active"} data-toggle="modal" data-target={`#activeInactive${row.id}`} className={row.is_active === 1 ? "btn btn-outline-danger mr-2" : "btn btn-outline-success mr-2"}><i className={row.is_active === 1 ? "fa fa-lock" : "fa fa-unlock"}></i></button>
                <button title="Delete Notification" className="btn btn-outline-danger" data-toggle="modal" data-target={`#DelNotification${row.id}`}><i className="fa fa-trash"></i></button>
                <Link title="Edit Notification" className="btn btn-outline-primary ml-2" to={`/edit-notification/${row.id}`}><i className="fa fa-edit"></i></Link>
                {/* <!-- Modal--> */}
                <div id={`DelNotification${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Delete Notification</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure, you want to delete this Notification?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                                <button onClick={() => this.handleDelete(row.id)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Block/Unblock Modal--> */}
                <div id={`activeInactive${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{row.is_active === 1 ? "Inactive" : "Active"} Notification</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure, you want to {row.is_active === 1 ? "Inactive" : "Active"} this notification?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.handleActiveInActive(row.id, row.is_active)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function notificationActionFormatter(cell: any, row: any, props: any) {
    return (
        <NotificationActionFormatter row={row} data={props} />
    );
}
class DiscountActionFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    handleDelete = (id: any) => {
        this.props.data.deleteDiscount(id, this.props.data.history)
    };
    handleActiveInActive = (id: any, is_active: any) => {
        this.props.data.activeInactiveDiscount(id, is_active, this.props.data.history);
    };
    render() {
        const { row } = this.props;
        return (
            <div>
                <button title={row.is_active === 1 ? "Inactive" : "Active"} data-toggle="modal" data-target={`#activeInactive${row.discount_id}`} className={row.is_active === 1 ? "btn btn-outline-danger mr-2" : "btn btn-outline-success mr-2"}><i className={row.is_active === 1 ? "fa fa-lock" : "fa fa-unlock"}></i></button>
                <button title="Delete Discount" className="btn btn-outline-danger" data-toggle="modal" data-target={`#DelDiscount${row.discount_id}`}><i className="fa fa-trash"></i></button>
                <Link title="Edit Discount" className="btn btn-outline-primary ml-2" to={`/edit-discount/${row.discount_id}`}><i className="fa fa-edit"></i></Link>
                {/* <!-- Modal--> */}
                <div id={`DelDiscount${row.discount_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Delete Discount</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to delete this Discount?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                                <button onClick={() => this.handleDelete(row.discount_id)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Block/Unblock Modal--> */}
                <div id={`activeInactive${row.discount_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{row.is_active === 1 ? "Inactive" : "Active"} Discount</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to {row.is_active === 1 ? "Inactive" : "Active"} this discount?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.handleActiveInActive(row.discount_id, row.is_active)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function discountActionFormatter(cell: any, row: any, props: any) {
    return (
        <DiscountActionFormatter row={row} data={props} />
    );
}
function promoFormatter(cell: any) {
    return ` ${cell} %`;
}
class DiscountGroupsFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    IsJsonString = (str: any) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    render() {
        const { row } = this.props;
        return (
            <div>
                {(row.items_json && row.type == 'group') &&
                    <button title="View Items" data-toggle="modal" data-target={`#itemdetails${row.discount_id}group`} className="btn btn-outline-info"><i className="fa fa-list"></i></button>
                }
                {/* <!-- Modal--> */}
                <div id={`itemdetails${row.discount_id}group`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Groups</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group text-capitalize">
                                            <BootstrapTable version='4' data={(row.items_json && this.IsJsonString(row.items_json)) ? JSON.parse(row.items_json) : ""} hover>
                                                <TableHeaderColumn dataField='value' csvHeader='#' width='50' dataSort={true} isKey>#</TableHeaderColumn>
                                                <TableHeaderColumn dataField='label' columnTitle>Group Name</TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
function groupsFormatter(cell: any, row: any, props: any) {
    return (
        <DiscountGroupsFormatter row={row} data={props} />
    );
}
class DiscountItemsFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    IsJsonString = (str: any) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    render() {
        const { row } = this.props;
        return (
            <div>
                {(row.items_json && row.type == 'item') &&
                    <button title="View Items" data-toggle="modal" data-target={`#itemdetails${row.discount_id}item`} className="btn btn-outline-info"><i className="fa fa-list"></i></button>
                }
                {/* <!-- Modal--> */}
                <div id={`itemdetails${row.discount_id}item`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Items</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group text-capitalize">
                                            <BootstrapTable version='4' data={(row.items_json && this.IsJsonString(row.items_json)) ? JSON.parse(row.items_json) : ""} hover>
                                                <TableHeaderColumn dataField='label' columnTitle isKey>Item Name</TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
function itemsFormatter(cell: any, row: any, props: any) {
    return (
        <DiscountItemsFormatter row={row} data={props} />
    );
}
class CouponItemsFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    IsJsonString = (str: any) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    render() {
        const { row } = this.props;
        return (
            <div>
                {(row.type == "menu" || row.type == 'item' || row.type == "group" || row.type == "store") &&
                    <button title={`View ${row.type == "item" ? "Items" : row.type == "group" ? "Groups" : row.type == "menu" ? "Menus" : row.type == "store" && "Stores"}`} data-toggle="modal" data-target={`#items${row.coupon_id}`} className="btn btn-outline-info"><i className="fa fa-list"></i></button>
                }
                {/* <!-- Modal--> */}
                <div id={`items${row.coupon_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{row.type == "item" ? "Items" : row.type == "group" ? "Groups" : row.type == "menu" ? "Menus" : row.type == "store" && "Stores"}</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group text-capitalize">
                                            {(row.items_json && this.IsJsonString(row.items_json)) &&
                                                <BootstrapTable version='4' data={(row.items_json && this.IsJsonString(row.items_json)) ? JSON.parse(row.items_json) : ""} hover>
                                                    <TableHeaderColumn dataField='label' columnTitle isKey>Item Name</TableHeaderColumn>
                                                </BootstrapTable>
                                            }
                                            {(row.stores_json && this.IsJsonString(row.stores_json)) &&
                                                <BootstrapTable version='4' data={(row.stores_json && this.IsJsonString(row.stores_json)) ? JSON.parse(row.stores_json) : ""} hover>
                                                    <TableHeaderColumn dataField='label' columnTitle isKey>Store Name</TableHeaderColumn>
                                                </BootstrapTable>
                                            }
                                            {(row.multiMenuJson && this.IsJsonString(row.multiMenuJson)) &&
                                                <BootstrapTable version='4' data={(row.multiMenuJson && this.IsJsonString(row.multiMenuJson)) ? JSON.parse(row.multiMenuJson) : ""} hover>
                                                    <TableHeaderColumn dataField='label' columnTitle isKey>Menu Name</TableHeaderColumn>
                                                </BootstrapTable>
                                            }
                                            {(row.multiGroupJson && this.IsJsonString(row.multiGroupJson)) &&
                                                <BootstrapTable version='4' data={(row.multiGroupJson && this.IsJsonString(row.multiGroupJson)) ? JSON.parse(row.multiGroupJson) : ""} hover>
                                                    <TableHeaderColumn dataField='label' columnTitle isKey>Group Name</TableHeaderColumn>
                                                </BootstrapTable>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
function couponsitemsFormatter(cell: any, row: any, props: any) {
    return (
        <CouponItemsFormatter row={row} data={props} />
    );
}
function dateFormatter(cell: any) {
    var Date: any = moment(cell).local().format('YYYY-MM-DD HH:mm').split(' ');
    Date = Date[1] + " , " + Date[0];
    return (
        <div>
            <span {...((cell !== "" && cell !== null) && { title: Date })}> {(cell !== "" && cell !== null) && Date} </span>
        </div>
    )
}
class Marketing extends Component<marketingProps, { activeTab: any, store_id: any, loading: any, clicked: any,brand_id:any }> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            activeTab: "",
            store_id: null,
            loading: false,
            clicked: false,
            brand_id:{
                label:"All",
                value:0,
                new:true
            }
        }
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.brandsList()
        this.setState({ activeTab: parseInt(id) });
        this.props.couponsList(0);
        this.props.promosList(0);
        this.props.discountsList(0);
        this.props.notificationList(0);
        this.props.storesList(0);
        document.title = "SimplexCMS | Marketing"
        this.setState({ loading: false })

    }

    componentWillReceiveProps(prevProps: any) {
        // this.setState({ activeTab: 4 })
        // this.setState({ data1: prevProps.orderByStores })
        if(this.state.brand_id.new) {
            this.state.clicked ? this.setState({ loading: false, activeTab: 4 }) : this.setState({ loading: false, activeTab: 0})
        }
        // this.forceUpdate();
    }

    handleInputChange = (e: any) => {
        let doc = document as any
        // var sel = doc.getElementById("heatmap");
        var text = e;

        this.setState({ loading: true, activeTab: 3, clicked: true })
        this.props.getOrdersByStore(e.value, text)
    }
    handleBrandsInputChange = (e: any, index: any) => {
        this.props.notificationList(e.value);
        this.props.storesList(e.value);
        this.props.promosList(e.value);
        this.props.discountsList(e.value);
        this.props.couponsList(e.value);
        this.setState({brand_id:{label:e.label,value:e.value,new:false}});
    }
    render() {
        const { orderByStores, heatmapLat, heatmapLng } = this.props
        let arr: any = []
        this.props.stores.map((store: any) => {
            arr.push({ label: store.store_name, value: store.store_id, lat: store.lat, lng: store.lng })
        })
        let roleId
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                } else {
                    roleId = decoded.role_id
                }
            });
        } else {
            return <Redirect to="/" />
        }

        const options: any = {
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to={this.state.activeTab == 0 ? `/add-coupon` : this.state.activeTab == 1 ? "/add-promo" : this.state.activeTab == 2 ? "/add-discount" : this.state.activeTab == 3 ? "/add-notification" : ""} /> },
            noDataText: 'Compaigns Not Found'
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Compaigns Management</h4>
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        {this.state.loading ?<Loader style={{ color: 'red', position: "fixed", top: "60%", left: "60%", transform: "translate(-50%, -50%)" }} type="ThreeDots" color="#555555" height={70} width={70} /> :
                                            <div className="card">
                                                <div className="card-body p-0 mb-5">
                                                    <Tabs
                                                        selectedIndex={this.state.activeTab}
                                                        onSelect={(index) =>
                                                            this.setState({
                                                                activeTab: index
                                                            })
                                                        }
                                                    >
                                                        <TabList
                                                            style={{
                                                                background: "#20a5d6",
                                                                color: "#FFF",
                                                                padding: "10px",
                                                            }}
                                                        >
                                                            <Tab>Coupons</Tab>
                                                            <Tab>Promos</Tab>
                                                            <Tab>Discount</Tab>
                                                            <Tab>Push Notifications</Tab>
                                                            <Tab>Heat Map</Tab>

                                                        </TabList>

                                                        <TabPanel style={{ marginTop: '2em' }}>
                                                            <div className="container-fluid">
                                                            <div className='row'>
                                                                {roleId == 1 && <div className="col-12">
                                                                <Select
                                                                        name="brand_id"
                                                                        options={this.props.brands}
                                                                        value={this.state.brand_id}
                                                                        className="text-capitalize select mt-2"
                                                                        classNamePrefix="select"
                                                                        onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                                                                    />
                                                                </div>}
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12 col-12">
                                                                        <div className="card-header">
                                                                            <strong>All Coupons</strong>
                                                                        </div>
                                                                        <div className="card">
                                                                            <div className="card-body">
                                                                                <BootstrapTable version="4" data={this.props.data} search={true} pagination={this.props.data.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='coupons.csv' hover>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='coupon_id' csvHeader='#' width='70' dataSort={true} isKey>#</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='coupon_name' csvHeader='Coupon Name' width='120' columnTitle>Coupon name</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='coupon_code' csvHeader='Coupon Code' width='120' columnTitle>Coupon Code</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='coupon_description' csvHeader='Description' width='120' columnTitle>Description</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='coupon_value' csvHeader='Coupon value' width='100' columnTitle>Coupon value</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='percent' dataFormat={couponPFormatter} csvHeader='Coupon Percent' width='100' columnTitle>Coupon Percent</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='mode' csvHeader='Mode' width='100' columnTitle>Mode</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='type' csvHeader='Type' width='100' columnTitle>Type</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='100' dataFormat={couponsitemsFormatter} export={false}>List</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='expire_date' csvHeader='Expiry' width='130' dataFormat={dateFormatter} columnTitle>Valid Till</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='limit' csvHeader='Limit' width='70' columnTitle>Limit</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='total_usage' csvHeader='Total limit' width='70' columnTitle>Total Limit</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='min_amount' csvHeader='Min total' width='70' columnTitle>Min Total</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='free_delivery' width='100' dataFormat={freeDeliveryFormatter} export={false}>Free Delivery</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='is_archive' width='100' dataFormat={statusFormatter} export={false}>Status</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='200' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                                                                                </BootstrapTable>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </TabPanel>
                                                        <TabPanel style={{ marginTop: '2em' }}>
                                                            <div className="container-fluid">
                                                            <div className='row'>
                                                                {roleId == 1 && <div className="col-12">
                                                                <Select
                                                                        name="brand_id"
                                                                        options={this.props.brands}
                                                                        value={this.state.brand_id}
                                                                        className="text-capitalize select mt-2"
                                                                        classNamePrefix="select"
                                                                        onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                                                                    />
                                                                </div>}
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12 col-12">
                                                                        <div className="card-header">
                                                                            <strong>All Promos</strong>
                                                                        </div>
                                                                        <div className="card">
                                                                            <div className="card-body">
                                                                                <BootstrapTable data={this.props.promos} search={true} pagination={this.props.promos.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='promos.csv' hover>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='promo_id' csvHeader='#' width='50' dataSort={true} isKey>#</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='promo_name' csvHeader='Coupon Name' width='100' columnTitle>Promo name</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='menu_name' csvHeader='Menu Name' width='100' columnTitle>Menu</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='promo_description' csvHeader='Description' width='100' columnTitle>Description</TableHeaderColumn>
                                                                                    {/* <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='promo_discount_percentage' dataFormat={promoFormatter} csvHeader='Promo Discount(%)' width='100' columnTitle>Promo Discount(%)</TableHeaderColumn> */}
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='expire_date' csvHeader='Expiry' width='100' columnTitle>Valid Till</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='is_archive' width='100' dataFormat={statusFormatter} export={false}>Status</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='150' dataFormat={promosActionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                                                                                </BootstrapTable>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </TabPanel>
                                                        <TabPanel style={{ marginTop: '2em' }}>
                                                            <div className="container-fluid">
                                                            <div className='row'>
                                                                {roleId == 1 && <div className="col-12">
                                                                <Select
                                                                        name="brand_id"
                                                                        options={this.props.brands}
                                                                        value={this.state.brand_id}
                                                                        className="text-capitalize select mt-2"
                                                                        classNamePrefix="select"
                                                                        onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                                                                    />
                                                                </div>}
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12 col-12">
                                                                        <div className="card-header">
                                                                            <strong>All Discount</strong>
                                                                        </div>
                                                                        <div className="card">
                                                                            <div className="card-body">
                                                                                <BootstrapTable version="4" data={this.props.discounts} search={true} pagination={this.props.discounts.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='discounts.csv' hover>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='discount_id' csvHeader='#' width='70' dataSort={true} isKey>#</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='discount_value' csvHeader='Discount value' width='100' columnTitle>Discount value</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='percent' dataFormat={couponPFormatter} csvHeader='Discount Percent' width='100' columnTitle>Discount Percent</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='mode' csvHeader='Modes' width='100' dataFormat={orderModesFormatter} columnTitle>Mode</TableHeaderColumn>
                                                                                    {/* <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='channel' csvHeader='Channel' width='70' columnTitle>Channel</TableHeaderColumn> */}
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='type' csvHeader='Type' width='100' columnTitle>Type</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='menu_name' csvHeader='Menu' width='100' columnTitle>Menu</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='100' dataFormat={groupsFormatter} export={false}>Groups</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='100' dataFormat={itemsFormatter} export={false}>Items</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='expire_date' csvHeader='Expiry' width='120' columnTitle>Valid Till</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='is_active' width='100' dataFormat={discountStatusFormatter} export={false}>Status</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='200' dataFormat={discountActionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                                                                                </BootstrapTable>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </TabPanel>
                                                        <TabPanel style={{ marginTop: '2em' }}>
                                                            <div className="container-fluid">
                                                                <div className='row'>
                                                                {roleId == 1 && <div className="col-12">
                                                                <Select
                                                                        name="brand_id"
                                                                        options={this.props.brands}
                                                                        value={this.state.brand_id}
                                                                        className="text-capitalize select mt-2"
                                                                        classNamePrefix="select"
                                                                        onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                                                                    />
                                                                </div>}
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12 col-12">
                                                                        <div className="card-header">
                                                                            <strong>All Notifications</strong>
                                                                        </div>
                                                                        <div className="card">
                                                                            <div className="card-body">
                                                                                <BootstrapTable data={this.props.notifications} search={true} pagination={this.props.notifications.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='notifications.csv' hover>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='id' csvHeader='#' width='50' dataSort={true} isKey>#</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='title' csvHeader='Title' width='100' columnTitle>Title</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='description' csvHeader='Description' width='100' columnTitle>Description</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='expire_date' csvHeader='Expiry' width='100' columnTitle>Valid Till</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='is_active' width='100' dataFormat={discountStatusFormatter} export={false}>Status</TableHeaderColumn>
                                                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='150' dataFormat={notificationActionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                                                                                </BootstrapTable>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </TabPanel>


                                                        {/* Store Orders --------------------------------------- */}

                                                        <TabPanel style={{ marginTop: '2em', display: 'flex' }}>
                                                            <div className="container-fluid">
                                                                <div className="form-horizontal">
                                                                <div className='row form-group'>
                                                                    {roleId == 1 && 
                                                                    <>
                                                                    <label
                                                                    className="col-md-3 col-sm-3 form-control-label font-weight-bold"
                                                                    style={{ paddingTop: "1em" }}
                                                                >
                                                                    Select Brand
                                                                    <span className="text-danger">*</span>
                                                                </label>
                                                                <div className="col-md-9 col-sm-9">
                                                                    <Select
                                                                            name="brand_id"
                                                                            options={this.props.brands}
                                                                            value={this.state.brand_id}
                                                                            className="text-capitalize select mt-2"
                                                                            classNamePrefix="select"
                                                                            onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                                                                        />
                                                                    </div></>}
                                                                </div>
                                                                    <div className="row form-group">

                                                                        <label
                                                                            className="col-md-3 col-sm-3 form-control-label font-weight-bold"
                                                                            style={{ paddingTop: "1em" }}
                                                                        >
                                                                            Select Store
                                                                            <span className="text-danger">*</span>
                                                                        </label>

                                                                        <div className="col-md-9 col-sm-9">
                                                                            <Select
                                                                                id='heatmap'
                                                                                isSearchable={true}
                                                                                value={this.props.storeName}
                                                                                onChange={this.handleInputChange}
                                                                                options={arr}
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div className="form-horizontal">
                                                                    {(orderByStores && orderByStores.length !== 0 && !_.isEmpty(orderByStores)) ?
                                                                        <div style={{ height: '400px', position: 'relative' }} className="row form-group heatmap">
                                                                            <MapContainer store_id={this.state.store_id} data={orderByStores} center={{ lat: heatmapLat, lng: heatmapLng }} />
                                                                        </div>
                                                                        : <div className="row form-group .no-orders">
                                                                            <h3 className='no-orders'>No Orders to Display</h3>
                                                                        </div>
                                                                    }

                                                                    {/* <div style={{ height: '400px', position: 'relative' }} className="row form-group heatmap">
                                                                    <MapContainer data={data} center={{ lat: 31.4446272, lng: 74.2830316 }} />
                                                                </div> */}


                                                                </div>

                                                            </div>
                                                        </TabPanel>
                                                        {/* <MapContainer center={{ lat: -24.9923319, lng: 135.2252427 }} /> */}
                                                    </Tabs>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
;
function couponPFormatter(cell: any) {
    return cell && `${cell}%`;
}
function discountStatusFormatter(cell: any) {
    return (
        <div className="text-capitalize">
            <span {...(cell == 1 && { className: "badge badge-success p-2" }) || (cell == 0 && { className: "badge badge-danger p-2" })}>{cell == 1 ? "active" : "Inactive"}</span>
        </div>
    )
}
function statusFormatter(cell: any) {
    return (
        <div className="text-capitalize">
            <span {...(cell == 0 && { className: "badge badge-success p-2" }) || (cell == 1 && { className: "badge badge-danger p-2" })}>{cell == 0 ? "active" : "Inactive"}</span>
        </div>
    )
}
function freeDeliveryFormatter(cell: any) {
    return (
        <div className="text-capitalize">
            <span {...(cell == 1 && { className: "badge badge-success p-2" })}>{cell == 1 && <i className="fa fa-check-square-o"></i>}</span>
        </div>
    )
}
const mapStateToProps = (state: any) => {
    return {
        data: state.coupon.data,
        promos: state.promo.data,
        notifications: state.notification.data,
        discounts: state.discount.data,
        stores: state.store.data,
        orderByStores: state.order.orderByStores,
        storeName: state.order.store_name,
        store_id: state.order.store_id,
        heatmapLat: state.order.heatmapLat,
        heatmapLng: state.order.heatmapLng,
        brands: state.brand.brands

    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        couponsList: function (brand_id:number) {
            dispatch(couponsList(brand_id))
        },
        deleteCoupon: function (id: any, history: any, coupon_name: any) {
            dispatch(deleteCoupon(id, history, coupon_name))
        },
        activeInactiveCoupon: function (id: any, is_archive: any, history: any, coupon_name: any) {
            dispatch(activeInactiveCoupon(id, is_archive, history, coupon_name));
        },
        promosList: function (brand_id:number) {
            dispatch(promosList(brand_id))
        },
        deletePromo: function (id: any, history: any, promo_name: any) {
            dispatch(deletePromo(id, history, promo_name))
        },
        activeInactivePromo: function (id: any, is_archive: any, history: any, promo_name: any) {
            dispatch(activeInactivePromo(id, is_archive, history, promo_name))
        },
        notificationList: function (brand_id:number) {
            dispatch(notificationList(brand_id))
        },
        deleteNotification: function (id: any, history: any, title: any) {
            dispatch(deleteNotification(id, history, title))
        },
        activeInactiveNotification: function (id: any, is_active: any, history: any, title: any) {
            dispatch(activeInactiveNotification(id, is_active, history, title))
        },
        discountsList: function (brand_id:number) {
            dispatch(discountsListForDiscount(brand_id))
        },
        deleteDiscount: function (id: any, history: any) {
            dispatch(deleteDiscount(id, history))
        },
        activeInactiveDiscount: function (id: any, is_active: any, history: any) {
            dispatch(activeInactiveDiscount(id, is_active, history))
        },
        storesList: function (brand_id:number) {
            dispatch(storesList2(brand_id));
        },
        getOrdersByStore: function (id: any, store_name: any) {
            dispatch(getOrdersByStore(id, store_name));
        },
        brandsList: function () {
            dispatch(brandsList("GET_BY_BRAND"))
        },

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Marketing);