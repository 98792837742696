import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { logoutUser, brandsList, delBrand, blockunblockbrand } from '../../redux'
import { BrandProps } from '../../interfaces/brands';
import { uploadMenuFile } from '../../redux/actions/brandAction';
import { API_URL } from '../../client-config';
class ActionFormatter extends Component<{ row: any }, {menu_file: any}> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            menu_file: ''
        }
    }
    handleDelete = (id: any) => {
        this.props.data.delBrand(id,this.props.row.brand_name)
    };
    handleBlockUnblock = (id: any, is_active: any) => {
        this.props.data.blockunblock(id, is_active,this.props.row.brand_name);
    };
    fileSelectedHandler = (event: any) => {
        this.setState({
            menu_file: event.target.files[0]
        })
    }
    handleMenuFileUpload = () => {
        let { menu_file } = this.state
        let { brand_id } = this.props.row
        const data: any = new FormData();
        if(menu_file) {         
            data.append('menu_file', menu_file);
            data.append('brand_id', brand_id);
            this.props.data.uploadMenuFile(data)
        }
    }
    render() {
        const { row } = this.props
        return (
            <div>
                <button title={row.is_active === 0 ? "UnBlock" : "Block"} data-toggle="modal" data-target={`#blockunblock${row.brand_id}`} className={row.is_active === 0 ? "btn btn-outline-success" : "btn btn-outline-danger"}><i className={row.is_active === 0 ? "fa fa-unlock" : "fa fa-lock"}></i></button>
                <button title="Delete Brand" className="btn btn-outline-danger ml-2" data-toggle="modal" data-target={`#DelBrand${row.brand_id}`}><i className="fa fa-trash"></i></button>
                <Link title="Edit Brand" className="btn btn-outline-primary ml-2" to={`/edit-brand/${row.brand_id}`}><i className="fa fa-edit"></i></Link>
                <button title="Web Menu" data-toggle="modal" data-target={`#webmenu${row.brand_id}`} className="btn btn-outline-info ml-2"><i className="fa fa-file"></i></button>
                {/* <!-- Block/Unblock Modal--> */}
                <div id={`blockunblock${row.brand_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{row.is_active === 0 ? "UnBlock" : "Block"} Brand</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to {row.is_active === 0 ? "UnBlock" : "Block"} this brand?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.handleBlockUnblock(row.brand_id, row.is_active)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Modal--> */}
                <div id={`DelBrand${row.brand_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Delete Brand</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure, you want to delete this brand?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                                <button onClick={() => this.handleDelete(row.brand_id)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id={`webmenu${row.brand_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Web Menu</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    {
                                        row.menu_file && row.menu_file !== undefined ? 
                                        <div className='col-12'>
                                            <div className="form-group">
                                                <label className="form-control-label mb-3 d-block">Menu File</label>
                                                <a href={`${API_URL}/${row.menu_file}`} target="_blank">
                                                    <i className="fa fa-file mr-2"></i> Uploaded File
                                                </a>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-control-label mb-3">Menu File <span className="text-danger">*</span></label>
                                            <input id="files" type="file" name="menu_file" accept="application/pdf, image/*" className="form-control-file" onChange={(e) => this.fileSelectedHandler(e)} />
                                            <small className="form-text text-muted">Upload PDF or Image file only</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button disabled={this.state.menu_file === '' ? true : false } onClick={() => this.handleMenuFileUpload()} className="btn btn-primary">Upload</button>
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} data={props} />
    );
}

class Brand extends Component<BrandProps, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    componentDidMount() {
        this.props.brandsList()
        document.title = "SimplexCMS | Brands"
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const options: any = {
            // sizePerPageList: [5,10,15],
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/add-brand" /> },
            noDataText: 'Brands Not Found'
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Brands Management</h4>
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <BootstrapTable version='4' data={this.props.data} search={true} pagination={this.props.data.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='brands.csv' hover>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='brand_id' csvHeader='#' width='100' dataSort={true} isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='brand_name' csvHeader='Brand Name' width='100' columnTitle>Brand name</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='brand_unique_code' csvHeader='Brand Unique Code' width='100' columnTitle>Brand Unique Code</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='brand_type' csvHeader='Brand Type' width='100' columnTitle>Brand type</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='brand_details' csvHeader='Brand Detail' width='100' columnTitle>Brand Details</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='200' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        data: state.brand.brands,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        brandsList: function () {
            dispatch(brandsList())
        },
        delBrand: function (id: any,brand_name:any) {
            dispatch(delBrand(id,brand_name))
        },
        uploadMenuFile: function (data: any) {
            dispatch(uploadMenuFile(data))
        },
        blockunblock: function (id: any, is_active: any,brand_name:any) {
            dispatch(blockunblockbrand(id, is_active,brand_name))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Brand);