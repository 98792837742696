import React, { Component } from "react";
import { AddCrossSellProps, AddCrossSellState, AddMenuProps, AddMenuState } from "../../../interfaces/menu";
import { connect } from "react-redux";
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import {
  addMenu,
  storeTypeList,
  brandsList,
  logoutUser,
  storesListForMenuStoreRelation,
  menusList
} from "../../../redux";
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import { Link, Redirect } from "react-router-dom";
import CheckChanges from '../../../components/confirmOnLeave'
import Select from 'react-select';
import { addcrossSellItem, crossSellActiveItems, itemsListForMultiSelectByBrand } from "../../../redux/actions/menuAction";
class AddCrossSellItem extends Component<AddCrossSellProps, AddCrossSellState> {
  constructor(props: any) {
    super(props);
    this.state = {
      brand: "",
      menu_item_id:'',
      priority:'',
      choices:[]
    };
    // this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    this.props.brandsList();
    document.title = "SimplexCMS | Cross Sell Items"
  }
  handleInputChange(event: { target: { name: any; value: any } }) {
      console.log("event.target.name",event.target.name)
    if (event.target.name == "brand") {
      this.setState({
        [event.target.name]: event.target.value,
      });
      this.props.itemsListForMultiSelectByBrand(event.target.value)
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  }

  handleItemChange = (event:any)=>{
        this.props.crossSellActiveItems(event.value.split("_")[1])
        this.setState({
            menu_item_id: event.value.split("_")[1],
          });
  }

  handleChoicesChange = (event:any)=>{
      console.log(event)
    this.setState({
        choices: event,
      });
}
  isMenuReady = () => {
    let {brand,menu_item_id,choices,priority} = this.state
    return (
        menu_item_id !== "" &&
      brand !== "" && choices.length > 0
    );
  };
  handleSaveBtnClick = (event: any) => {
      let {brand,menu_item_id,choices,priority} = this.state
      let finalChoices:any = []
      for(let i =0;i<choices.length;i++) {
        finalChoices.push({
            menu_item_id:choices[i].menu_item_id
        })
      }
      let data = {
        crossSellItem:{
            brand_id:brand,
            menu_item_id,
            is_active:1
        },
        crossSellChoices:finalChoices,
      };
      this.props.addcrossSellItem(data);

  };
  blockInvalidChar = (e: any) => {
    var regex = new RegExp("[a-zA-Z]");
    let specialkey: any = e.keyCode;
    var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (specialkey == 8 || specialkey == 9) {
      return true;
    } else {
      if (/\S/.test(e.target.value)) {
        // string is not empty and not just whitespace
        if (!regex.test(key)) {
          if (specialkey == 32) {
          } else {
            e.preventDefault()
          }
        }
      } else {
        ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
      }
    }
  }
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />
    }
    let tokendata: any = jwt.decode(sessionStorage.token)
    let roleId = tokendata.role_id;
    const {
      brands,
    } = this.props;
    return (
      <div className="page">
        <CheckChanges path="/add-menu" />
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/*  Page Header */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Cross Sell Items Management</h4>
                </div>
              </div>
            </header>
            {/* Breadcrumb */}
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/menus" className="text-primary">Cross Sell Items</Link></li>
                <li className="breadcrumb-item active">Add Cross Sell Item</li>
              </ul>
            </div>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                        {roleId == 1 && <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Brand <span className="text-danger">*</span>
                              </label>
                              <select
                                name="brand"
                                className="form-control text-capitalize mt-2"
                                required
                                data-msg="Please select brand"
                                onChange={this.handleInputChange}
                              >
                                <option value="">Select Brand</option>
                                {brands &&
                                  brands.map(
                                    (brand:any, index:any) =>
                                      brand.is_active == 1 && (
                                        <option key={index} value={brand.brand_id}>
                                          {brand.brand_name}
                                        </option>
                                      )
                                  )}
                              </select>
                            </div>
                          </div>}
                        <div className="col" >
                            <div className="form-group">
                              <label className="form-control-label">Select Item <span className="text-danger">*</span></label>
                              <Select
                                name="menu_item_id"
                                options={this.props.allActiveItems}
                                // value={this.state.storesJson}
                                className="text-capitalize basic-multi-select mt-2"
                                classNamePrefix="select"
                                onChange={(e, i) => this.handleItemChange(e)}
                              />
                            </div>
                          </div>

                          {/* <div className="col" >
                            <div className="form-group">
                              <label className="form-control-label">Priority <span className="text-danger">*</span></label>
                              <input
                                onKeyDown={this.blockInvalidChar}
                                id="priority"
                                type="text"
                                name="priority"
                                required
                                data-msg="Please enter priority"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div> */}
                        </div>

                        <div className='row'>
                            <div className="col" >
                                <div className="form-group">
                                    <label className="form-control-label">Cross Sell Choices <span className="text-danger">*</span></label>
                                    <Select
                                        isMulti
                                        name="choices"
                                        options={this.props.cross_sell_active_items}
                                        // value={this.state.storesJson}
                                        className="text-capitalize basic-multi-select mt-2"
                                        classNamePrefix="select"
                                        onChange={(e, i) => this.handleChoicesChange(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group d-flex justify-content-end mt-4">
                          {/* <input type="reset" value="cancel" className="btn btn-danger mr-2" /> */}
                          <button onClick={this.handleSaveBtnClick} disabled={!this.isMenuReady()} className="btn btn-primary">Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>

        </div>
      </div >
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    brands: state.store.brands,
    allActiveItems:state.menu.allActiveItems,
    cross_sell_active_items:state.menu.cross_sell_active_items
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    brandsList: function () {
      dispatch(brandsList());
    },
    itemsListForMultiSelectByBrand:(brand:any)=>{
        dispatch(itemsListForMultiSelectByBrand(brand));
    },
    addcrossSellItem:(brand:any)=>{
        dispatch(addcrossSellItem(brand))
    },
    crossSellActiveItems:(id:any)=>{
        dispatch(crossSellActiveItems(id))
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddCrossSellItem);
