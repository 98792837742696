import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { EditStoreProps, EditStoreState } from '../../interfaces/store'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import Select from "react-select";
import { getStore, editStore, countryList, addStates, tradeZoneList } from '../../redux'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import CheckChanges from '../../components/confirmOnLeave'
import Map from './map';
import { businessTypeList } from '../../redux/actions/settingsAction';
import { channelList, citiesList, getBackupStores, getTradeAreas, storesList, storeTypeList } from '../../redux/actions/storeAction';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class EditStore extends Component<EditStoreProps, EditStoreState> {
    constructor(props: any) {
        super(props);
        this.state = {
            store_id: "",
            store_name_ar:'',
            hungerstation_branchCode: "",
            talabat_branchCode:"",
            mannual_address:"",
            use_mannual_address:false,
            storename: "",
            storeemail: "",
            escalationemail: "",
            is_featured: false,
            contact1: "",
            contact2: "",
            rgmContact: "",
            brand: "",
            brand_id: "",
            ntn: "",
            country: "",
            country_code: "",
            state: "",
            state_id: "",
            country_id: "",
            city: "",
            address: "",
            storeopen: "",
            storeclose: "",
            tradezonename: "",
            expected_delivery_time: 0,
            kmlFile: null,
            kml: "",
            lat: 0,
            lng: 0,
            newlat: 0,
            newlng: 0,
            radius: 0,
            businessType: "",
            timeByDays: '',
            branchCode: "",
            fiscal_pos_code: "",
            cloudKitchen: "Disabled",
            saleschannel: "",
            storetype: "",
            trade_zone_id: "",
            city_name: "",
            backupStore: [
                {
                    storeId: "",
                    backUpStoreId: "",
                    priority: ""
                }
            ],
            tradeAreas: [
                {
                    tradeArea: "",
                    tradeZone: ""
                }
            ],
            mondayOpening: '',
            mondayClosing: '',
            mondayTime: {
                day: 'Monday',
                opening: '',
                closing: ''
            },
            tuesdayTime: {
                day: 'Tuesday',
                opening: '',
                closing: ''
            },
            wednesdayTime: {
                day: 'Wednesday',
                opening: '',
                closing: ''
            },
            thursdayTime: {
                day: 'Thursday',
                opening: '',
                closing: ''
            },
            fridayTime: {
                day: 'Friday',
                opening: '',
                closing: ''
            },
            saturdayTime: {
                day: 'Saturday',
                opening: '',
                closing: ''
            },
            sundayTime: {
                day: 'Sunday',
                opening: '',
                closing: ''
            }
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.getStore(id);
        this.props.countryList();
        this.props.businessTypeList();
        this.props.storesList();
        this.props.getBackupStores(id);
        this.props.tradeZoneList();
        this.props.getTradeAreas(id);
        this.props.channelsList();
        this.props.storeTypeList();
        this.props.citiesList();
        document.title = "SimplexCMS | Stores"
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        if (nextProps && nextProps.storeData && nextProps.storeData.daysTiming) {
            var timings = JSON.parse(nextProps.storeData.daysTiming);
            if (timings.length > 0) {
                timings.forEach((element: any) => {
                    const timeObj = { 'day': element.day, 'opening': element.opening, 'closing': element.closing };
                    if (element.day == "Monday") {
                        // this.setState({ mondayTime: timeObj, mondayOpening: element.opening, mondayClosing: element.closing })
                        this.setState({ mondayTime: timeObj })
                    } else if (element.day == "Tuesday") {
                        this.setState({ tuesdayTime: timeObj })
                    } else if (element.day == "Wednesday") {
                        this.setState({ wednesdayTime: timeObj })
                    } else if (element.day == "Thursday") {
                        this.setState({ thursdayTime: timeObj })
                    } else if (element.day == "Friday") {
                        this.setState({ fridayTime: timeObj })
                    } else if (element.day == "Saturday") {
                        this.setState({ saturdayTime: timeObj })
                    } else if (element.day == "Sunday") {
                        this.setState({ sundayTime: timeObj })
                    }
                });
            }
        }

        var backupStores = nextProps && nextProps.backupStores;
        var tradeAreas = nextProps && nextProps.tradeAreas;
        if (backupStores.length > 0) {
            var listBackUp: any = [];
            backupStores.forEach((elementBackup: any) => {
                const newlist: any = { backUpStoreId: elementBackup.backupStoreId.store_id, priority: elementBackup.priority, storeId: "" }
                listBackUp.push(newlist);
                // const backupStr: any = this.state.backupStore.concat(newlist);
                // alert("backupStores Length" + backupStr);
                this.setState({ backupStore: listBackUp });
            });
        }
        if (tradeAreas.length > 0) {
            var listTradeArea: any = [];
            tradeAreas.forEach((elementArea: any) => {
                const newlist: any = { tradeArea: elementArea.area_name, tradeZone: elementArea.delivery_zone_id }
                listTradeArea.push(newlist);
                this.setState({ tradeAreas: listTradeArea });
            });
        }
        this.setState({
            store_id: nextProps.storeData.store_id,
            use_mannual_address:nextProps.storeData.use_mannual_address == 1 ? true : false,
            mannual_address:nextProps.storeData.mannual_address ,
            storename: nextProps.storeData.store_name,
            store_name_ar:nextProps.storeData.store_name_ar,
            storeemail: nextProps.storeData.store_email,
            branchCode: nextProps.storeData.branch_code ? nextProps.storeData.branch_code : "",
            hungerstation_branchCode: nextProps.storeData.hungerstation_vendor_code ? nextProps.storeData.hungerstation_vendor_code : "",
            talabat_branchCode: nextProps.storeData.talabat_vendor_code ? nextProps.storeData.talabat_vendor_code : "",
            // fiscal_pos_code: nextProps.storeData.fiscal_pos_code,
            escalationemail: nextProps.storeData.escalation_email,
            contact1: nextProps.storeData.contact1,
            contact2: nextProps.storeData.contact2,
            rgmContact: nextProps.storeData.contact3,
            brand: nextProps.storeData.brand_name,
            brand_id: nextProps.storeData.brand_id,
            // city: nextProps.storeData.city,
            state_id: nextProps.storeData.state_id,
            city_name: nextProps.storeData.city,
            ntn: nextProps.storeData.ntn_number,
            expected_delivery_time: nextProps.storeData.expected_delivery_time,
            country_id: nextProps.storeData.country_id,
            address: nextProps.storeData.address,
            radius: 0,
            kml: nextProps.storeData.kml_shape,
            lat: nextProps.storeData.lat,
            lng: nextProps.storeData.lng,
            timeByDays: nextProps.storeData.isDays == 1 ? true : false,
            is_featured: nextProps.storeData.is_featured == 1 ? true : false,
            businessType: nextProps.storeData.business_type_id,
            cloudKitchen: nextProps.storeData.cloudKitchen,
            saleschannel: nextProps.storeData.sales_channel_id,
            storetype: nextProps.storeData.store_type_id,
            trade_zone_id: nextProps.storeData.delivery_zone_id
        })
        if (nextProps.storeData.store_open_time == '00:00:00') {
            this.setState({
                storeopen: "",
                storeclose: ""
            })
        } else {
            this.setState({
                storeopen: nextProps.storeData.store_open_time,
                storeclose: nextProps.storeData.store_close_time,
            })
        }
    }
    handleInputChange(event: { target: { name: any; value: any }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    isStoreReady = () => {
        const { trade_zone_id, storename, storeemail, businessType, branchCode, timeByDays, storeopen, storeclose, mondayTime, expected_delivery_time, tuesdayTime, wednesdayTime, thursdayTime, fridayTime, saturdayTime, sundayTime, state_id, saleschannel, lat, lng, city_name } = this.state

        if (timeByDays) {
            return (city_name !== "" && trade_zone_id !== "" && storename !== "" && businessType !== "" && state_id !== "" && branchCode !== "" && storeemail !== "" && expected_delivery_time > 0 &&
                mondayTime.opening !== "" && mondayTime.closing !== "" && tuesdayTime.opening !== "" && tuesdayTime.closing !== ""
                && wednesdayTime.opening !== "" && wednesdayTime.closing !== "" && thursdayTime.opening !== "" && thursdayTime.closing !== ""
                && fridayTime.opening !== "" && fridayTime.closing !== "" && saturdayTime.opening !== "" && saturdayTime.closing !== ""
                && sundayTime.opening !== "" && sundayTime.closing !== "");
        } else {
            // return (storename !== "" && storeemail !== "" && kml !== "" && kmlFile !== null && brand !== "" && saleschannel !== "" && storetype !== "" && storeopen !== "" && storeclose !== "");
            return (trade_zone_id !== "" && storename !== "" && businessType !== "" && state_id !== "" && branchCode !== "" && storeemail !== "" && expected_delivery_time > 0 && storeopen !== "" && storeclose !== "" && city_name !=="");
        }
    }
    handleRadius = (radius: any) => {    //to Get the Radius from the map component and set in state
        this.setState({
            radius: radius
        })
    }
    fileSelectedHandler = (e: any) => {
        this.setState({ kmlFile: e.target.files })
    }
    handleMapData = (obj: any) => {    //to Get the values from the map component and set in state
        this.setState({
            address: obj.address,
            city: obj.city,
            // state: obj.state,
            country: obj.country,
            country_code: obj.country_code,
            lat: obj.lat,
            lng: obj.lng
        })
    }
    checkAreaNameDuplication = () => {
        const { tradeAreas } = this.state;
        var result: any = [];
        // To Remove all Duplicate objects
        var array: any = tradeAreas,
            seen = Object.create(null),
            result = array.filter((o: any) => {
                var key = ['tradeZone', 'tradeArea'].map(k => o[k]).join('|');
                if (!seen[key]) {
                    seen[key] = true;
                    return true;
                }
            });
        if (array.length !== result.length) {
            //check original array length after remove duplicates
            return true;
        } else {
            return false;
        }
    }
    blockInvalidChar = (e: any) =>
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
    blockInvalidNumberData = (e: any) => {
        var regex = new RegExp("[0-9]");
        let specialkey: any = e.keyCode;
        var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (specialkey == 8 || specialkey == 9) {
            return true;
        } else {
            if (/\S/.test(e.target.value)) {
                // string is not empty and not just whitespace
                if (!regex.test(key)) {
                    if (specialkey == 32) {
                    } else {
                        e.preventDefault()
                    }
                }
            } else {
                ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
            }
        }
    }
    handleSubmit = async (event: any) => {
        let daysTiming: any = [];
        let { trade_zone_id,store_name_ar, cloudKitchen, storename, storeemail, escalationemail, brand_id, contact1, contact2, rgmContact, city, saleschannel, address, state_id, storeopen, storeclose, tradezonename, kml, lat, lng,
            businessType, timeByDays, backupStore, branchCode, is_featured, ntn, mondayTime, expected_delivery_time, tuesdayTime, wednesdayTime, thursdayTime, fridayTime, saturdayTime, sundayTime, country_id, tradeAreas,use_mannual_address,mannual_address, city_name,hungerstation_branchCode,talabat_branchCode,  } = this.state;
        let id = this.props.match.params.id;
        let { countries } = this.props;
        let DuplicationFlag: any = await this.checkAreaNameDuplication();
        if (DuplicationFlag) {
            toast.error("Duplicate areas found for one tradezone", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
        } else {
            let data: any = {
                store_name: storename,
                store_email: storeemail,
                store_name_ar:store_name_ar,
                use_mannual_address:use_mannual_address ? 1 : 0,
                mannual_address,
                trade_zone_id: trade_zone_id,
                escalation_email: escalationemail,
                contact1: contact1,
                contact2: contact2,
                contact3: rgmContact,
                branch_code: branchCode,
                hungerstation_branchCode:hungerstation_branchCode,
                talabat_branchCode:talabat_branchCode,
                // city: city,
                sales_channel_id: saleschannel,
                is_featured: is_featured ? 1 : 0,
                brand_id: brand_id,
                city: city_name,
                expected_delivery_time: expected_delivery_time,
                ntn_number: ntn,
                address: address,
                lat: lat,
                lng: lng,
                business_type_id: businessType,
                cloudKitchen: cloudKitchen,
                backupStore: JSON.stringify(backupStore),
                tradeAreas: JSON.stringify(tradeAreas)
            }

            if (timeByDays) {
                daysTiming.push(mondayTime);
                daysTiming.push(tuesdayTime);
                daysTiming.push(wednesdayTime);
                daysTiming.push(thursdayTime);
                daysTiming.push(fridayTime);
                daysTiming.push(saturdayTime);
                daysTiming.push(sundayTime);
                data.daysTiming = JSON.stringify(daysTiming)
            } else {
                data.store_open_time = storeopen;
                data.store_close_time = storeclose;
                data.daysTiming = '';
            }
            if (timeByDays === true) {
                data.timeByDays = 1;
            } else {
                data.timeByDays = 0;
            }
            if (country_id !== "") {
                data.country_id = country_id;
            }
            if (state_id !== "") {
                data.state_id = state_id;
            }
            if(city_name !== ""){
                data.city = city_name;
            }
            this.props.editStore(id, data);
        }
    }
    handleTradeZones = (e: any, index: any) => {
        if (e && e.value > 0) {
            this.setState({ trade_zone_id: e.value, kml: e.kml_shape });
        } else {
            this.setState({ trade_zone_id: "", kml: "" });
        }
    };
    handleTimeByDays = (event: { target: { name: any; value: any; }; }) => {
        const { timeByDays } = this.state;
        this.setState({ timeByDays: !timeByDays })
    }
    dayOpeningTime = (e: any) => {
        const { name, value } = e.target;
        if (name == "mondayTime") {
            const list: any = this.state.mondayTime;
            list.opening = value;
            this.setState({ mondayTime: list });

        } else if (name == "tuesdayTime") {
            const list: any = this.state.tuesdayTime;
            list.opening = value;
            this.setState({ tuesdayTime: list });

        } else if (name == "wednesdayTime") {
            const list: any = this.state.wednesdayTime;
            list.opening = value;
            this.setState({ wednesdayTime: list });

        } else if (name == "thursdayTime") {
            const list: any = this.state.thursdayTime;
            list.opening = value;
            this.setState({ thursdayTime: list });

        } else if (name == "fridayTime") {
            const list: any = this.state.fridayTime;
            list.opening = value;
            this.setState({ fridayTime: list });

        } else if (name == "saturdayTime") {
            const list: any = this.state.saturdayTime;
            list.opening = value;
            this.setState({ saturdayTime: list });

        } else if (name == "sundayTime") {
            const list: any = this.state.sundayTime;
            list.opening = value;
            this.setState({ sundayTime: list });
        }
    };

    dayClosingTime = (e: any) => {
        const { name, value } = e.target;
        if (name == "mondayTime") {
            const list: any = this.state.mondayTime;
            list.closing = value;
            this.setState({ mondayTime: list });

        } else if (name == "tuesdayTime") {
            const list: any = this.state.tuesdayTime;
            list.closing = value;
            this.setState({ tuesdayTime: list });

        } else if (name == "wednesdayTime") {
            const list: any = this.state.wednesdayTime;
            list.closing = value;
            this.setState({ wednesdayTime: list });

        } else if (name == "thursdayTime") {
            const list: any = this.state.thursdayTime;
            list.closing = value;
            this.setState({ thursdayTime: list });

        } else if (name == "fridayTime") {
            const list: any = this.state.fridayTime;
            list.closing = value;
            this.setState({ fridayTime: list });

        } else if (name == "saturdayTime") {
            const list: any = this.state.saturdayTime;
            list.closing = value;
            this.setState({ saturdayTime: list });

        } else if (name == "sundayTime") {
            const list: any = this.state.sundayTime;
            list.closing = value;
            this.setState({ sundayTime: list });
        }
    };

    handleBackupStoreInputChange = (e: any, index: any) => {
        if (e && e.target) {
            const { name, value } = e.target;
            const list: any = this.state.backupStore;
            list[index][name] = value;
            this.setState({ backupStore: list });
        } else {
            const list: any = this.state.backupStore;
            if (e && e.value > 0) {
                list[index]["backUpStoreId"] = e.value;
            } else {
                list[index]["backUpStoreId"] = "";
            }
            this.setState({ backupStore: list });
        }
    };
    handleTradeAreaInputChange = (e: any, index: any) => {
        if (e && e.target) {
            const { name, value } = e.target;
            const list: any = this.state.tradeAreas;
            list[index][name] = value;
            this.setState({ tradeAreas: list });
        } else {
            const list: any = this.state.tradeAreas;
            if (e && e.value > 0) {
                list[index]["tradeZone"] = e.value;
            } else {
                list[index]["tradeZone"] = "";
            }
            this.setState({ tradeAreas: list });
        }
    };
    handleMannualAddressCheck = () =>{
        this.setState({use_mannual_address:!this.state.use_mannual_address})
    }
    handleFeaturedCheck = () => {
        this.setState({
            is_featured: !this.state.is_featured
        })
    }
    handleAddBackupStore = (i: any) => {
        const list: any = this.state.backupStore;
        if (list[i]["backUpStoreId"] == "" || list[i]["priority"] == "") {
            alert("Please fill in current item")
        } else if (i == '1' || i > 1) {
            alert("Sorry you can select only 2 Backup Stores")
        } else {
            const newlist: any = { backUpStoreId: "", priority: "", storeId: "" }
            const backupStr: any = this.state.backupStore.concat(newlist)
            this.setState({ backupStore: backupStr });
        }
    };

    handleAddTradeArea = (i: any) => {
        const list: any = this.state.tradeAreas;
        if (list[i]["tradeZone"] == "" || list[i]["tradeArea"] == "") {
            alert("Please fill in current item")
        } else {
            const newlist: any = { tradeZone: "", tradeArea: "" }
            const tradeArea: any = this.state.tradeAreas.concat(newlist)
            this.setState({ tradeAreas: tradeArea });
        }
    };
    handleRemoveBackupStore = (index: any) => {
        const list = this.state.backupStore;
        list.splice(index, 1);
        this.setState({ backupStore: list });
    };
    handleRemoveTradeArea = (index: any) => {
        const list = this.state.tradeAreas;
        list.splice(index, 1);
        this.setState({ tradeAreas: list });
    };
    handleBusinessType = (e: any, index: any) => {
        if (e && e.value > 0) {
            this.setState({ businessType: e.value });
        } else {
            this.setState({ businessType: "" });
        }
    };
    handleStates = (e: any, index: any) => {
        if (e && e.value > 0) {
            this.setState({ state_id: e.value, country_id: e.country_id });
        } else {
            this.setState({ state_id: "", country_id: "" });
        }
    };
    handleCities = (e: any) => {
        if (e) {
          this.setState({ city_name: e.label });
        } else {
          this.setState({ city_name: "" });
        }
    };
    render() {
        let tokendata: any = jwt.decode(sessionStorage.token)
        let roleId = tokendata.role_id;
        let brandId = tokendata.brand_id;
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        if (this.props.storeData && this.props.storeData.brand_id && roleId !== 1) {
            if (this.props.storeData.brand_id !== brandId) {
                return <Redirect to="/stores" />
            }
        }
        const { business_list, stores, states, tradezones, channels, types, cities } = this.props;
        const { trade_zone_id, storename, store_name_ar, lat, lng, brand, storeopen, state_id, storeemail, branchCode, fiscal_pos_code, expected_delivery_time, escalationemail, ntn, contact1, contact2, rgmContact, storeclose, businessType, timeByDays, mondayTime, tuesdayTime, wednesdayTime, thursdayTime, fridayTime, saturdayTime, sundayTime, backupStore, tradeAreas, cloudKitchen, storetype, saleschannel, city_name,hungerstation_branchCode,talabat_branchCode, } = this.state
        return (
            <div className="page">
                <CheckChanges path="/edit-store" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Stores Management</h4>
                                </div>
                            </div>
                        </header>
                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/stores" className="text-primary">Stores</Link></li>
                                <li className="breadcrumb-item active">Edit Store</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label ">Store Name <span className="text-danger">*</span></label>
                                                            <input id="storename" disabled={this.props.storeData.brand_id == 3 ? true : false}  type="text" name="storename" defaultValue={storename} required data-msg="Please enter Store name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Store Name Arabic <span className="text-danger">*</span></label>
                                                            <input id="store_name_ar" disabled={this.props.storeData.brand_id == 3 ? true : false} type="text" name="store_name_ar" defaultValue={store_name_ar} required data-msg="Please enter Store Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Brand Name <span className="text-danger">*</span></label>
                                                            <input id="brandname" disabled={this.props.storeData.brand_id == 3 ? true : false} type="text" name="brandname" defaultValue={brand} data-msg="Please enter Brand name" className="input-material" />
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Branch Id <span className="text-danger">*</span></label>
                                                            <input id="branchCode" disabled={this.props.storeData.brand_id == 3 ? true : false} type="text" name="branchCode" defaultValue={branchCode} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="form-control-label">Hungerstation Branch Id  <span className="text-danger">*</span></label>
                                                            <input id="branchCode" type="text" name="hungerstation_branchCode" defaultValue={hungerstation_branchCode} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="form-control-label"> Talabat Branch Id <span className="text-danger">*</span></label>
                                                            <input id="branchCode"  type="text" name="talabat_branchCode" defaultValue={talabat_branchCode} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Business Type <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="businessType"
                                                                isClearable
                                                                options={business_list}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                value={business_list.map((element: any) => {
                                                                    if (element.id == businessType) {
                                                                        return { label: element.name, value: businessType }
                                                                    }
                                                                })}
                                                                onChange={(e, i) => this.handleBusinessType(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Branch Code <span className="text-danger">*</span></label>
                                                            <input id="fiscal_pos_code" type="text" name="fiscal_pos_code" defaultValue={fiscal_pos_code} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Email Contact <span className="text-danger">*</span></label>
                                                            <input id="storeemail" type="email" name="storeemail" defaultValue={storeemail} required data-msg="Please enter Store Email" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Email Escalation</label>
                                                            <input id="escalationemail" type="email" name="escalationemail" defaultValue={escalationemail} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">NTN Number</label>
                                                            <input id="ntn" type="text" name="ntn" className="input-material" defaultValue={ntn} onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Contact 1</label>
                                                            <input id="contact1" type="text" onKeyDown={this.blockInvalidNumberData} name="contact1" defaultValue={contact1} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Contact 2</label>
                                                            <input id="contact2" type="text" onKeyDown={this.blockInvalidNumberData} name="contact2" defaultValue={contact2} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">RGM Contact</label>
                                                            <input id="rgmContact" type="text" onKeyDown={this.blockInvalidNumberData} name="rgmContact" defaultValue={rgmContact} required data-msg="Please enter RGM Contact" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Cloud Kitchen <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="cloudKitchen"
                                                                isClearable
                                                                isDisabled
                                                                value={{ value: cloudKitchen, label: cloudKitchen }}
                                                                options={[{ label: 'Disabled', value: 'Disabled' }, { label: 'Enabled', value: 'Enabled' }]}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                defaultValue={{ label: "Disabled", value: 'Disabled' }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Sale Channel <span className="text-danger">*</span></label>
                                                            <select name="saleschannel" value={saleschannel} className="form-control text-capitalize mt-2" required disabled>
                                                                {channels &&
                                                                    channels.map((channel, index) => (
                                                                        <option key={index} value={channel.sales_channel_id}>{channel.channel_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Store Type <span className="text-danger">*</span></label>
                                                            <select name="storetype" value={storetype} className="form-control text-capitalize mt-2" required disabled>
                                                                {types &&
                                                                    types.map((type, index) => (
                                                                        <option key={index} value={type.store_type_id}>{type.store_type_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Expected Time <span className="text-danger">* (In minutes)</span></label>
                                                            <input id="expected_delivery_time" type="number" value={expected_delivery_time} name="expected_delivery_time" required data-msg="Please enter expected time" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">States <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="state_id"
                                                                isClearable
                                                                options={states}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                value={states.map((element: any) => {
                                                                    if (element.state_id == state_id) {
                                                                        return { label: element.state_name, value: state_id }
                                                                    }
                                                                })}
                                                                onChange={(e, i) => this.handleStates(e, i)}
                                                            />
                                                            {/* <select name="state_id" className="form-control mt-2" required data-msg="Please select state" onChange={this.handleInputChange}>
                                                                <option value="">Select State</option>
                                                                {states.map((state: any, index: any) => (
                                                                    (state.payment_method == "cash") &&
                                                                    <option key={index} value={state.state_id} {...state_id === state.state_id && { selected: true }}>{state.state_name}</option>
                                                                ))
                                                                }
                                                            </select> */}
                                                        </div>
                                                    </div>
                                                    {/* for city add */}
                                                    <div className="col">
                                                        <label className="form-control-label">City <span className="text-danger">*</span></label>
                                                        <Select
                                                            name="city_name"
                                                            isClearable
                                                            options={cities.map((element) => ({
                                                                label: element.name,
                                                                value: element.id
                                                            }))}
                                                            className="text-capitalize select mt-2"
                                                            classNamePrefix="select"
                                                            value={this.state.city_name ? { label: this.state.city_name, value: this.state.city_name } : null}
                                                            onChange={this.handleCities}
                                                            />
                                                        </div>
                                                    {/* for city add end */}
                                                </div>
                                                <div style={{ width: '100%' }}>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label htmlFor="">Latitude</label>
                                                                <input type="text"  disabled={this.props.storeData.brand_id == 3 ? true : false} name="newlat" className="form-control" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label htmlFor="">Longitude</label>
                                                                <input type="text" disabled={this.props.storeData.brand_id == 3 ? true : false} name="newlng" className="form-control" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-6'>
                                                            <div className="form-group">
                                                                <label htmlFor="">Mannual Address</label>
                                                                <input type="text" name="mannual_address" value={this.state.mannual_address} className="form-control" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className='col-3 mt-4'>
                                                            <div className="form-group">
                                                            <input id="checkboxCustom1" type="checkbox" name="use_mannual_address" checked={this.state.use_mannual_address} onChange={this.handleMannualAddressCheck} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom1">Use mannual address</label>
                                                            </div>
                                                        </div>
                                                        <div className='col-3 mt-4'>
                                                            <div className="form-group">
                                                            <input id="featuredStore" type="checkbox" name="is_featured" checked={this.state.is_featured} onChange={this.handleFeaturedCheck} className="checkbox-template" />
                                                            <label htmlFor="featuredStore">Featured Store</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Map
                                                        data={this.state}
                                                        center={{ lat: this.state.newlat, lng: this.state.newlng }}
                                                        kml={this.state.kml}
                                                        radius={parseInt(this.state.radius)}
                                                        onSetRadius={this.handleRadius}
                                                        onSetData={this.handleMapData}
                                                        google={this.props.google}
                                                        height='400px'
                                                        zoom={15}
                                                    />
                                                </div>
                                                <div className='row'>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Trade Zones <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="tradeZone"
                                                                isClearable
                                                                value={tradezones.map((element: any) => {
                                                                    if (element.id == trade_zone_id) {
                                                                        return { label: element.delivery_zone_name, value: trade_zone_id }
                                                                    }
                                                                })}
                                                                options={tradezones}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleTradeZones(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Trade Area */}
                                                {/* <div className='row'>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Trade Areas <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="tradeArea"
                                                                isClearable
                                                                value={tradeAreas.map((element: any) => {
                                                                    return element.area_name;
                                                                    // if (element.id == trade_zone_id) {
                                                                    //     return { label: element.delivery_zone_name, value: trade_zone_id }
                                                                    // }
                                                                })}
                                                                options={tradeAreas}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                // onChange={(e, i) => this.handleTradeZones(e, i)}
                                                                onChange={(e, i) => this.handleTradeAreaInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {/* End */}

                                                {/* <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label ">TradeZone Name <span className="text-danger">*</span></label>
                                                            <input id="tradezonename" type="text" name="tradezonename" defaultValue={this.state.tradezonename} required data-msg="Please enter TradeZone name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label mb-3">KML File</label>
                                                            <input id="files" type="file" name="files" className="form-control-file" onChange={this.fileSelectedHandler} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label ">KML Link <span className="text-danger">*</span></label>
                                                            <input id="kml" type="text" name="kml" defaultValue={this.state.kml} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {
                                                    (!timeByDays) &&
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                <input id="storeopen" type="time" name="storeopen" defaultValue={storeopen} required data-msg="Please enter opening time" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                <input id="storeclose" type="time" name="storeclose" defaultValue={storeclose} required data-msg="Please enter closing time" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }


                                                <div className="row">
                                                    <div className="col">
                                                        {/* <div className="form-group">
                                                            <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                            <input id="storeopen" type="time" name="storeopen" required data-msg="Please enter Opening Time" className="input-material" onChange={this.handleInputChange} />
                                                        </div> */}
                                                        <div className="i-checks">
                                                            <input id="checkboxCustom1" type="checkbox" name="timeByDays" checked={timeByDays == true && true} onChange={this.handleTimeByDays} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom1">Add Time by Week Days</label>
                                                        </div>
                                                    </div>
                                                </div>


                                                {timeByDays &&
                                                    <div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                    <input id="day" type="text" disabled={this.props.storeData.brand_id == 3 ? true : false} value={'Monday'} name="day" required data-msg="Please enter Opening Time" className="input-material" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                    <input id="mondayTime" type="time" name="mondayTime" defaultValue={mondayTime.opening} required data-msg="Please enter Opening Time" className="input-material" onChange={(e) => this.dayOpeningTime(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                    <input id="mondayTime" type="time" name="mondayTime" defaultValue={mondayTime.closing} required className="input-material" onChange={(e) => this.dayClosingTime(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                    <input id="day" type="text" disabled={this.props.storeData.brand_id == 3 ? true : false} value={'Tuesday'} name="day" required data-msg="Please enter Opening Time" className="input-material" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                    <input id="tuesdayTime" type="time" name="tuesdayTime" defaultValue={tuesdayTime.opening} required data-msg="Please enter Opening Time" className="input-material" onChange={(e) => this.dayOpeningTime(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                    <input id="tuesdayTime" type="time" name="tuesdayTime" defaultValue={tuesdayTime.closing} required className="input-material" onChange={(e) => this.dayClosingTime(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                    <input id="day" type="text" disabled={this.props.storeData.brand_id == 3 ? true : false} value={'Wednesday'} name="day" required data-msg="Please enter Opening Time" className="input-material" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                    <input id="wednesdayTime" type="time" name="wednesdayTime" defaultValue={wednesdayTime.opening} required data-msg="Please enter Opening Time" className="input-material" onChange={(e) => this.dayOpeningTime(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                    <input id="wednesdayTime" type="time" name="wednesdayTime" defaultValue={wednesdayTime.closing} required className="input-material" onChange={(e) => this.dayClosingTime(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                    <input id="day" type="text" disabled={this.props.storeData.brand_id == 3 ? true : false} value={'Thursday'} name="day" required data-msg="Please enter Opening Time" className="input-material" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                    <input id="thursdayTime" type="time" name="thursdayTime" defaultValue={thursdayTime.opening} required data-msg="Please enter Opening Time" className="input-material" onChange={(e) => this.dayOpeningTime(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                    <input id="thursdayTime" type="time" name="thursdayTime" defaultValue={thursdayTime.closing} required className="input-material" onChange={(e) => this.dayClosingTime(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                    <input id="day" type="text" disabled={this.props.storeData.brand_id == 3 ? true : false} value={'Friday'} name="day" required data-msg="Please enter Opening Time" className="input-material" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                    <input id="fridayTime" type="time" name="fridayTime" defaultValue={fridayTime.opening} required data-msg="Please enter Opening Time" className="input-material" onChange={(e) => this.dayOpeningTime(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                    <input id="fridayTime" type="time" name="fridayTime" defaultValue={fridayTime.closing} required className="input-material" onChange={(e) => this.dayClosingTime(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                    <input id="day" type="text" disabled={this.props.storeData.brand_id == 3 ? true : false} value={'Saturday'} name="day" required data-msg="Please enter Opening Time" className="input-material" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                    <input id="saturdayTime" type="time" name="saturdayTime" defaultValue={saturdayTime.opening} required data-msg="Please enter Opening Time" className="input-material" onChange={(e) => this.dayOpeningTime(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                    <input id="saturdayTime" type="time" name="saturdayTime" defaultValue={saturdayTime.closing} required className="input-material" onChange={(e) => this.dayClosingTime(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                    <input id="day" type="text" disabled={this.props.storeData.brand_id == 3 ? true : false} value={'Sunday'} name="day" required data-msg="Please enter Opening Time" className="input-material" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                    <input id="sundayTime" type="time" name="sundayTime" defaultValue={sundayTime.opening} required data-msg="Please enter Opening Time" className="input-material" onChange={(e) => this.dayOpeningTime(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                    <input id="sundayTime" type="time" name="sundayTime" defaultValue={sundayTime.closing} required className="input-material" onChange={(e) => this.dayClosingTime(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                <div className="row" style={{ paddingTop: '1em' }}>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            {backupStore.map((x: any, i: any) => {
                                                                return (
                                                                    <div key={i} className="row">
                                                                        <div className="col">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Backup Store</label>
                                                                                <Select
                                                                                    name="backUpStoreId"
                                                                                    isClearable
                                                                                    options={stores.filter((o1: any) => !backupStore.some((o2: any) => o1.store_id === o2.backUpStoreId))}
                                                                                    className="text-capitalize select mt-2"
                                                                                    classNamePrefix="select"
                                                                                    value={stores.map((element: any) => {
                                                                                        if (element.store_id == x.backUpStoreId) {
                                                                                            return { label: element.store_name, value: element.store_id }
                                                                                        }
                                                                                    })}
                                                                                    onChange={e => this.handleBackupStoreInputChange(e, i)} />
                                                                                {/* <select name="backUpStoreId" className="form-control text-capitalize mt-2" required data-msg="Please select Channel" onChange={e => this.handleBackupStoreInputChange(e, i)}>
                                                                                    <option value="">Backup Store</option>
                                                                                    {stores.length > 0 &&
                                                                                        stores.map((store: any, index: any) => (
                                                                                            store.store_id !== store_id &&
                                                                                            // <option key={index} value={store.store_id}>{store.store_name}</option>
                                                                                            <option key={index} value={store.store_id} {...x.backUpStoreId === store.store_id && { selected: true }}>{store.store_name}</option>
                                                                                        ))
                                                                                    }
                                                                                </select> */}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Priority</label>
                                                                                <input
                                                                                    name="priority"
                                                                                    type="number"
                                                                                    value={x.priority}
                                                                                    min={0}
                                                                                    disabled={x.backUpStoreId == ""}
                                                                                    onKeyDown={this.blockInvalidChar}
                                                                                    data-msg="Please enter qunatity"
                                                                                    className="input-material"
                                                                                    onChange={e => this.handleBackupStoreInputChange(e, i)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col py-4">
                                                                            {backupStore.length !== 1 &&
                                                                                <button className="btn btn-sm btn-outline-danger"
                                                                                    onClick={() => this.handleRemoveBackupStore(i)}><i className="fa fa-trash"></i></button>}
                                                                            {backupStore.length - 1 === i && <button className="btn btn-sm btn-primary ml-2" onClick={() => this.handleAddBackupStore(i)}><i className="fa fa-plus"></i></button>}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row" style={{ paddingTop: '1em' }}>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            {tradeAreas.map((x: any, i: any) => {
                                                                return (
                                                                    <div key={i} className="row">
                                                                        <div className="col">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">TradeZone <span className="text-danger">*</span></label>
                                                                                <select name="tradeZone" className="form-control mt-2" required data-msg="Please Select TradeZone" onChange={e => this.handleTradeAreaInputChange(e, i)}>
                                                                                    <option value="">Select TradeZone</option>
                                                                                    {(tradezones && tradezones.length > 0) &&
                                                                                        tradezones.map((zone: any, index: any) => (
                                                                                            <option key={index} value={zone.id} {...x.tradeZone == zone.id && { selected: true }}>{zone.delivery_zone_name} ({zone.city})</option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">TradeArea name <span className="text-danger">*</span></label>
                                                                                <input id="tradeArea" type="text" name="tradeArea" value={x.tradeArea} required data-msg="Please enter TradeAreaName" className="input-material" onChange={e => this.handleTradeAreaInputChange(e, i)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col py-4">
                                                                            {tradeAreas.length !== 1 &&
                                                                                <button className="btn btn-sm btn-outline-danger"
                                                                                    onClick={() => this.handleRemoveTradeArea(i)}><i className="fa fa-trash"></i></button>}
                                                                            {tradeAreas.length - 1 === i && <button className="btn btn-sm btn-primary ml-2" onClick={() => this.handleAddTradeArea(i)}><i className="fa fa-plus"></i></button>}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group float-right mt-3">
                                                    <button onClick={() => { this.props.history.push("/stores") }} className="btn btn-danger mr-2">Cancel</button>
                                                    <button onClick={this.handleSubmit} disabled={!this.isStoreReady()} className="btn btn-primary">Update Store</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>

                </div>
            </div >
        );
    }
};
const mapStateToProps = (state: any) => {
    return {
        storeData: state.store.storeData,
        message: state.store.message,
        isUpdated: state.store.isUpdated,
        countries: state.store.countries,
        channels: state.store.channels,
        states: state.setting.states,
        cities: state.store.cities,
        types: state.store.types,
        business_list: state.store.businessType,
        backupStores: state.store.getBackupStores,
        tradeAreas: state.store.getTradeAreas,
        stores: state.store.data,
        tradezones: state.tradezone.tradezones
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        editStore: function (id: any, data: any) {
            dispatch(editStore(id, data));
        },
        addStates: function (data: any, type: any) {
            dispatch(addStates(data, type));
        },
        getStore: function (id: number) {
            dispatch(getStore(id));
        },
        countryList: function () {
            dispatch(countryList())
        },
        businessTypeList: function () {
            dispatch(businessTypeList())
        },
        getBackupStores: function (id: number) {
            dispatch(getBackupStores(id))
        },
        storesList: function () {
            dispatch(storesList())
        },
        tradeZoneList: () => { dispatch(tradeZoneList()) },
        getTradeAreas: function (id: number) {
            dispatch(getTradeAreas(id))
        },
        channelsList: function () {
            dispatch(channelList())
        },
        storeTypeList: function () {
            dispatch(storeTypeList())
        },
        citiesList: function(){
            dispatch(citiesList())
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditStore);