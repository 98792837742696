import React, { Component } from 'react';
import { AddModGroupProps, AddModGroupState } from '../../../interfaces/menu'
import { connect } from 'react-redux'
import { addModGroup, menusList, logoutUser, groupsListForMultiSelect, itemsListForMultiSelect, brandsList } from '../../../redux'
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import CheckChanges from '../../../components/confirmOnLeave'
import Select from 'react-select';
class AddModGroup extends Component<AddModGroupProps, AddModGroupState> {
    constructor(props: any) {
        super(props);
        this.state = {
            brand_id: null,
            menu: "",
            groupJson: [],
            itemsJson: [],
            modgroupname: "",
            modgroupdesc: "",
            level: "group",
            type: "single",
            priority: "",
            name_ar:"",
            max_quantity: "",
            min_quantity: "",
            mod_group_secondary_name:"",
            use_secondary_name:false
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleModifiersLevel = this.handleModifiersLevel.bind(this);
        this.handleItemsByMenuId = this.handleItemsByMenuId.bind(this);
    }
    componentDidMount() {
        this.props.brandsList();
        document.title = "SimplexCMS | Modifier Groups"
    }
    handleBrand = (e: any, index: any) => {
        if (e && e.value > 0) {
            this.setState({ brand_id: e.value })
            this.props.menusList(e.value);
        }
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleItemsByMenuId(event: { target: { name: any; value: any; }; }) {
        this.props.groupsList(event.target.value);
        this.props.itemsListForMultiSelect(event.target.value);
        this.setState({ "menu": event.target.value, itemsJson: [], groupJson: [] })
    }
    handleModifiersLevel(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }
    handleGroupsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ itemsJson: [], groupJson: e });
        }
    };
    handleItemsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ groupJson: [], itemsJson: e });
        }
    }
    isModGroupReady = () => {
        const { modgroupname, groupJson, itemsJson, type, level, priority, menu, max_quantity, min_quantity } = this.state
        return (menu !== "" && modgroupname !== "" && (level == "group" ? groupJson.length > 0 : itemsJson.length > 0) && type != "" && level != "" && priority !== "" && max_quantity !== "" && min_quantity !== "");
    }
    handleSaveBtnClick = (event: any) => {
        let { groupJson,mod_group_secondary_name,name_ar, itemsJson, modgroupname, modgroupdesc, type, level, priority, menu, max_quantity, min_quantity,brand_id } = this.state;
        let data: any = {
            mod_group_name: modgroupname,
            mod_group_description: modgroupdesc,
            type: level,
            modifiers_type: type,
            name_ar:name_ar,
            priority: priority,
            menu_id: menu,
            brand_id: brand_id,
            max_quantity: max_quantity,
            min_quantity: min_quantity,
            mod_group_secondary_name,
            use_secondary_name:this.state.use_secondary_name ? 1 : 0
        }
        if (level == 'group') {
            data["group_json"] = JSON.stringify(groupJson);
        } else if (level == 'item') {
            data["items_json"] = JSON.stringify(itemsJson);
        }
        this.props.addModGroup(data);
    }
    handleSecondaryNameCheck = () =>{
        this.setState({use_secondary_name:!this.state.use_secondary_name});
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let tokendata: any = jwt.decode(sessionStorage.token)
        let roleId = tokendata.role_id;
        let brandId = tokendata.brand_id;
        const { groups, items, menus } = this.props;
        return (
            <div className="page">
                <CheckChanges path="/add-modgroup" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Modifier Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/mod-groups" className="text-primary">Modifier Groups</Link></li>
                                <li className="breadcrumb-item active">Add Modifier Group</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Primary name<span className="text-danger">*</span></label>
                                                            <input id="modgroupname" type="text" name="modgroupname" required data-msg="Please enter Modifier Group Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Arabic name<span className="text-danger">*</span></label>
                                                            <input id="name_ar" type="text" name="name_ar" required data-msg="Please enter Modifier Group Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Secondary name<span className="text-danger">*</span></label>
                                                            <input id="mod_group_secondary_name" type="text" name="mod_group_secondary_name" required data-msg="Please enter Modifier Group Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Brand <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="brand"
                                                                isSearchable
                                                                options={roleId == 1 ? this.props.brands : this.props.brands.filter(o1 => brandId === o1.brand_id)}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleBrand(e, i)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Menu <span className="text-danger">*</span></label>
                                                            <select name="menu" className="form-control text-capitalize mt-2" required data-msg="Please select Menu" onChange={this.handleItemsByMenuId}>
                                                                <option value="">Select Menu</option>
                                                                {menus &&
                                                                    menus.map((menu, index) => (
                                                                        <option key={index} value={menu.menu_id}>{menu.menu_name} - {menu.store_type_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Modifiers Level <span className="text-danger">*</span></label>
                                                            <select name="level" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleModifiersLevel}>
                                                                <option value='group'>Group</option>
                                                                <option value='item'>Menu Item</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col" >
                                                        <div >
                                                            <input id="checkboxCustom1" type="checkbox" name="taxstatus" checked={this.state.use_secondary_name} onChange={this.handleSecondaryNameCheck} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom1">Use secondary name</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {this.state.level === "group" &&
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Group <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    name="groups"
                                                                    // options={groups.filter(o1 => o1.menu_id == this.state.menu)}
                                                                    options={groups}
                                                                    value={this.state.groupJson}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleGroupsInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.level === "item" &&
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Items <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    name="items"
                                                                    // options={items.filter(o1 => o1.menu_id == this.state.menu)}
                                                                    options={items}
                                                                    value={this.state.itemsJson}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleItemsInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Description</label>
                                                            <input id="modgroupdesc" type="text" name="modgroupdesc" required data-msg="Please enter Group Description" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Modifier Type <span className="text-danger">*</span></label>
                                                            <select name="type" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleInputChange}>
                                                                <option value='single'>Single Select</option>
                                                                <option value='multiple' >Multi Select</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Priority<span className="text-danger">*</span></label>
                                                            <input id="priority" type="number" name="priority" required data-msg="Please enter Priority" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Min Quantity<span className="text-danger">*</span></label>
                                                            <input id="min_quantity" type="number" name="min_quantity" required data-msg="Please enter min" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Max Quantity<span className="text-danger">*</span></label>
                                                            <input id="max_quantity" type="number" name="max_quantity" required data-msg="Please enter max" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isModGroupReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        menus: state.menu.menus,
        groups: state.menu.groupsptions,
        items: state.menu.allActiveItems,
        brands: state.brand.brands
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        addModGroup: function (data: any) {
            dispatch(addModGroup(data));
        },
        menusList: function (brand_id: any) {
            dispatch(menusList(brand_id))
        },
        groupsList: function (menu_id: any) {
            dispatch(groupsListForMultiSelect(menu_id))
        },
        itemsListForMultiSelect: function (menu_id: any) {
            dispatch(itemsListForMultiSelect(menu_id))
        },
        brandsList: () => {
            dispatch(brandsList())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddModGroup);