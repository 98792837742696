import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { EditCustomerProps, EditCustomerState } from '../../interfaces/customers'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { getCustomer, editCustomer } from '../../redux'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'

class EditCustomer extends Component<EditCustomerProps, EditCustomerState> {
    constructor(props: any) {
        super(props);
        this.state = {
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            addresses: []
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.getCustomer(id);
        document.title = "SimplexCMS | Customers"
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        this.setState({
            firstname: nextProps.customer.first_name,
            lastname: nextProps.customer.last_name,
            email: nextProps.customer.email_address,
            phone: nextProps.customer.phone_number,
            addresses: nextProps.customer.address_id
        })
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    isCustomerReady = () => {
        const { firstname,email } = this.state
        return (firstname !== "" && email!=="");
    }
    handleSubmit = (event: any) => {
        event.preventDefault();
        let { firstname, lastname, email, phone } = this.state;
        let id = this.props.match.params.id;
        let data = {
            first_name: firstname,
            last_name: lastname,
            login_name: firstname + " " + lastname,
            email_address: email,
            phone_number: phone
        }
        this.props.editCustomer(id, data)
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { isUpdated, message, customer } = this.props;
        const { addresses } = this.state
        console.log(addresses)
        if (isUpdated) {
            msg = message;
            messcolor = 'green'
        } else {
            msg = message;
            messcolor = 'red'
        }
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Customer Management</h4>
                                </div>
                            </div>
                        </header>
                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/customers" className="text-primary">Customers</Link></li>
                                <li className="breadcrumb-item active">Edit Customer</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>
                                                <form className="form-validate">
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label ">First Name <span className="text-danger">*</span></label>
                                                                <input id="firstname" type="text" name="firstname" defaultValue={customer.first_name} required data-msg="Please enter First name" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label ">Last Name</label>
                                                                <input id="lastname" type="text" name="lastname" defaultValue={customer.last_name} required data-msg="Please enter Last name" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-lable">Email <span className="text-danger">*</span></label>
                                                                <input id="email" type="email" name="email" defaultValue={customer.email_address} required data-msg="Please enter email" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-lable">Phone</label>
                                                                <input id="phone" type="phone" name="phone" defaultValue={customer.phone_number} required data-msg="Please enter Phone" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                            addresses.map(element => {
                                                                return (
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <div className="form-group">
                                                                                <label className="form-control-lable"><b>{element.place} Address</b></label>
                                                                                <input id={`address${element.address_id}`} type="text" name={`address${element.address_id}`} defaultValue={element.extra_details+" "+element.full_address} disabled className="input-material" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                    }
                                                    <div className="form-group float-right">
                                                        <button onClick={this.handleSubmit} disabled={!this.isCustomerReady()} className="btn btn-primary">Update Customer</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>

                </div>
            </div>
        );
    }
};
const mapStateToProps = (state: any) => {
    return {
        customer: state.customer.customerData,
        message: state.customer.message,
        isUpdated: state.customer.isUpdated
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        editCustomer: function (id: any, data: any) {
            dispatch(editCustomer(id, data));
        },
        getCustomer: function (id: number) {
            dispatch(getCustomer(id));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditCustomer);