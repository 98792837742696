// export const GoogleMapsAPI = 'AIzaSyDTaA9BspUUJtGr_9oN7u5wEaKQIvq3XyE'; // for our own yum solution

export const GoogleMapsAPI = 'AIzaSyD63nbTLBPArnmu6jpOvukRHxo5QbCFRrY';  //for kyochon solution

var ip = window.location.hostname;

var protocol = window.location.protocol == "https:" ? 'https' : 'http';
// var baseURL  = window.location.origin;
export const API_URL = `${protocol}://${ip}:3004`;
// export const API_URL = `https://kyochoncmsstg.simplexts.com.au:3007`;
export const QR_BASE_URL = `https://www.kyochonme.com`;
export const currency = 'AED'
export const hungerstation_currency = 'SAR'
export const SYNC_URL = `https://sync-cms.simplexts.com.au:3002`;