import React, { Component } from 'react';
import { AddIngProps, AddIngState } from '../../../../interfaces/menu'
import { connect } from 'react-redux'
import Topbar from '../../../../components/topbar'
import Sidebar from '../../../../components/sidebar'
import Footer from '../../../../components/footer/main'
import CheckChanges from '../../../../components/confirmOnLeave'
import { addIng, addMeals, nutritionalCatList } from '../../../../redux'
import { Link } from 'react-router-dom';
import Select from 'react-select';
class AddIng extends Component<AddIngProps, AddIngState> {
    constructor(props: any) {
        super(props);
        this.state = {
            itemName: "",
            itemNameAR: "",
            nutCatId: "",
            kcal: "",
            kj: "", fat: "",
            sat_fat: "", carbs: "",
            sugar: "", fibre: "",
            protein: "",
            salt: ""
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        document.title = "SimplexCMS | Ingredients"
        this.props.ingCatList()
        // let modal:any= document.querySelector('.modal-dialog');
        // modal.classList.add('modal-lg')

    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    isMealReady = () => {
        const { itemName, nutCatId, kcal, kj, fat, sat_fat, salt, fibre, carbs, sugar, protein } = this.state
        return (itemName !== "" && nutCatId !== "" && kcal !== "" && kj !== "" && fat !== "" && sat_fat !== "" && salt !== "" && fibre !== "" && carbs !== "" && sugar !== "" && protein !== "");
    }
    handleCategory = (e: any, index: any) => {
        if (e && e.value !== "") {
            this.setState({ nutCatId: e.value });
        } else {
            this.setState({ nutCatId: "" });
        }
    };
    handleSaveBtnClick = (event: any) => {
        let { itemName, itemNameAR, nutCatId, kcal, kj, fat, sat_fat, salt, fibre, carbs, sugar, protein } = this.state
        let data: any = {
            item_name: itemName,
            item_name_ar: itemNameAR !== "" ? itemNameAR : null,
            nutritional_cat_id: nutCatId,
            Kcal: kcal,
            KJ: kj, FAT: fat, SAT_FAT: sat_fat, CARBS: carbs,
            SALT: salt, FIBRE: fibre, PROTEIN: protein, SUGARS: sugar
        }
        this.props.addIng(data);
    }
    render() {
        return (
            <div className="page">
                <CheckChanges path="/add-meal" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Ingredients Management</h4>
                                </div>
                            </div>
                        </header>
                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/ingredients" className="text-primary">Ingredients</Link></li>
                                <li className="breadcrumb-item active">Add Ingredient</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Item Name <span className="text-danger">*</span></label>
                                                            <input id="itemName" type="text" name="itemName" required data-msg="Please enter item name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Item Name (Arabic)</label>
                                                            <input id="itemNameAR" type="text" name="itemNameAR" required data-msg="Please enter item arabic name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Meal Categories <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="IngCategories"
                                                                isClearable
                                                                options={this.props.ingCat}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleCategory(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Kcal <span className="text-danger">*</span></label>
                                                            <input id="kcal" type="text" name="kcal" required data-msg="Please enter kcal" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">KJ <span className="text-danger">*</span></label>
                                                            <input id="kj" type="text" name="kj" required data-msg="Please enter kj" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">FAT <span className="text-danger">*</span></label>
                                                            <input id="fat" type="text" name="fat" required data-msg="Please enter fat" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">SAT FAT <span className="text-danger">*</span></label>
                                                            <input id="sat_fat" type="text" name="sat_fat" required data-msg="Please enter sat fat" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">CARBS <span className="text-danger">*</span></label>
                                                            <input id="carbs" type="text" name="carbs" required data-msg="Please enter carbs" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">SUGARS <span className="text-danger">*</span></label>
                                                            <input id="sugar" type="text" name="sugar" required data-msg="Please enter sugar" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">FIBRE <span className="text-danger">*</span></label>
                                                            <input id="fibre" type="text" name="fibre" required data-msg="Please enter fibre" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">PROTEIN <span className="text-danger">*</span></label>
                                                            <input id="protein" type="text" name="protein" required data-msg="Please enter protein" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">SALT <span className="text-danger">*</span></label>
                                                            <input id="salt" type="text" name="salt" required data-msg="Please enter salt" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group float-right mt-3">
                                                    <button onClick={() => { this.props.history.push("/ingredients") }} className="btn btn-danger mr-2">Cancel</button>
                                                    <button className='btn btn-primary' disabled={!this.isMealReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        ingCat: state.menu.ingCat
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        addIng: (data: any) => {
            dispatch(addIng(data));
        },
        ingCatList: () => {
            dispatch(nutritionalCatList())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddIng);