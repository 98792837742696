import React, { Component } from 'react';
import { AddMenuItemProps, AddMenuItemState } from '../../../interfaces/menu'
import { connect } from 'react-redux'
import Footer from '../../../components/footer/main';
import Sidebar from '../../../components/sidebar';
import Topbar from '../../../components/topbar';
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import { addItems, brandsList, groupsList, logoutUser, storesListForMultiSelect } from '../../../redux'
import { Link, Redirect } from 'react-router-dom';
import CheckChanges from '../../../components/confirmOnLeave'
import moment from 'moment';
import Select from 'react-select';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
class AddMenuItem extends Component<AddMenuItemProps, AddMenuItemState> {
    orderModes: { value: string; label: string; }[];
    weeklyDays: { value: string; label: string; }[];
    constructor(props: any) {
        super(props);
        this.orderModes = [
            { value: 'online', label: 'Online' },
            { value: 'callcenter', label: 'Call Center' }
        ];

        this.weeklyDays = [
            { value: 'MONDAY', label: 'Mon' },
            { value: 'TUESDAY', label: 'Tue' },
            { value: 'WEDNESDAY', label: 'Wed' },
            { value: 'THURSDAY', label: 'Thu' },
            { value: 'FRIDAY', label: 'Fri' },
            { value: 'SATURDAY', label: 'Sat' },
            { value: 'SUNDAY', label: 'Sun' },
        ];
        this.state = {
            itemname: "",
            group: "",
            type: "",
            cross_sell: false,
            priority: "",
            hero_image_priority: "",
            images: null,
            hero_image: null,
            hero_mobile_image: null,
            hero_image_arabic: null,
            hero_mobile_image_arabic: null,
            hero_item: false,
            settime: 'alltime',
            tax_percent: '',
            itemstart: "",
            itemclose: "",
            state: "",
            mode: [],
            is_lsm: '0',
            storesJson: [],
            metaTitle: "",
            metaDesc: "",
            best_seller: false,
            name_ar: "",
            sizejson: [
                {
                    size: "",
                    cost: "",
                    mrp: "",
                    extra_price: "",
                    description: "",
                    name_ar: "",
                    description_ar: "",
                    image_url: null,
                    alt_text: ""
                }
            ],
            specific_days: false,
            daysTiming: [
                {
                    day: "",
                    label: "",
                    timePeriods: [{
                        open: "",
                        close: ""
                    }]
                }
            ]
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleItemsByGroupId = this.handleItemsByGroupId.bind(this);
    }
    componentDidMount() {
        this.props.brandsList()
        // this.props.groupsList();
        // this.props.storesList();
        document.title = "SimplexCMS | Menu Items"
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        if (event.target.value == ' ') {
            event.target.value = event.target.value.replace(/\s/g, "");
        }
        this.setState({
            [event.target.name]: event.target.value,
        });
    }
    handleBrand = (e: any, index: any) => {
        if (e && e.value > 0) {
            this.props.storesList(e.value);
            this.props.groupsList(e.value);
            // this.setState({ brand_id: e.value, brand_name: e.label })
        } else {
            this.props.storesList(e.value);
            this.props.groupsList(e.value);
            // this.setState({ brand_id: null, brand_name: '' })
        }
    }
    handleItemsByGroupId(event: { target: { name: any; value: any; }; }) {
        this.setState({
            group: event.target.value,
            sizejson: [{
                size: "",
                cost: "",
                mrp: "",
                extra_price: "",
                description: "",
                image_url: null,
                alt_text: ""
            }]
        });
    }
    // handle click event of the Time Radio buttons
    handleChangeRad = (event: { target: { name: any; value: any; } }) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    handleVariantInputChange = (e: any, index: any) => {
        const { name, value } = e.target;
        const list: any = this.state.sizejson;
        list[index][name] = value;
        list[index]["name_ar"] = this.state.name_ar
        this.setState({ sizejson: list });
        if (name == "pos_code") {
            this.props.checkDuplicatePosCode(value)
        }
        if (name == "erp_id") {
            this.props.checkDuplicateErpId(value)
        }
    };
    // handle click event of the Remove button
    handleRemoveClick = (index: any) => {
        const list = this.state.sizejson;
        list.splice(index, 1);
        this.setState({ sizejson: list });
    };
    // handle click event of the Add button
    handleAddClick = (i: any) => {
        const list: any = this.state.sizejson;
        let { duplicateItemErp, duplicateItemPos } = this.props;
        if (list[i]["size"] == "" || list[i]["cost"] == "" || list[i]["mrp"] == "" || list[i]["image_url"] == null) {
            alert("Please fill all mandatory variant fields")
        } else if (i == '3') {
            alert("Sorry you can select only 4 variants")
        } else {
            const newlist: any = { size: "", cost: "", mrp: "", extra_price: "", description: "", image_url: null, alt_text: "" }
            const menu_items: any = this.state.sizejson.concat(newlist)
            this.setState({ sizejson: menu_items });
        }
    };
    fileSelectedHandler = (e: any, index: any) => {
        var size = 1048576;
        const list: any = this.state.sizejson;
        if (e.target.files[0].size > size) {
            alert("Image size too large. Upload image upto 1mb")
        }
        else {
            list[index]["image_url"] = e.target.files;
            this.setState({ sizejson: list });
        }
    }
    handleOrderModesInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ mode: e });
        } else {
            this.setState({ mode: [] });
        }
    };
    handleStoresInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ storesJson: e });
        } else {
            this.setState({ storesJson: [] });
        }
    };
    handleHeroItem = (event: { target: { name: any; value: any; }; }) => {
        const { hero_item } = this.state;
        this.setState({ hero_item: !hero_item })
    }

    handleBestSellerItem = (event: { target: { name: any; value: any; }; }) => {
        const { best_seller } = this.state;
        this.setState({ best_seller: !best_seller })
    }
    handleCrossSellItem = (event: { target: { name: any; value: any; }; }) => {
        const { cross_sell } = this.state;
        this.setState({ cross_sell: !cross_sell })
    }
    heroItemImageSelectedHandler = (e: any) => {
        var size = 1048576;
        if (e.target.files[0].size > size) {
            alert("Image size too large. Upload image upto 1mb")
        }
        else {
            this.setState({ hero_image: e.target.files })
        }
    }
    heroItemMobileImageSelectedHandler = (e: any) => {
        var size = 1048576;
        if (e.target.files[0].size > size) {
            alert("Image size too large. Upload image upto 1mb")
        }
        else {
            this.setState({ hero_mobile_image: e.target.files })
        }
    }

    heroItemImageArabicSelectedHandler = (e: any) => {
        var size = 1048576;
        if (e.target.files[0].size > size) {
            alert("Image size too large. Upload image upto 1mb")
        }
        else {
            this.setState({ hero_image_arabic: e.target.files })
        }
    }
    heroItemMobileImageArabicSelectedHandler = (e: any) => {
        var size = 1048576;
        if (e.target.files[0].size > size) {
            alert("Image size too large. Upload image upto 1mb")
        }
        else {
            this.setState({ hero_mobile_image_arabic: e.target.files })
        }
    }
    handleWeeklyDaysInputChange = (e: any, index: any, pindex?: any) => {
        if (e.target) {
            const { name, value } = e.target;
            const list: any = this.state.daysTiming;
            let timeP = list[index].timePeriods;
            timeP[pindex][name] = value;
            list[index].timePeriods = timeP;
            this.setState({ daysTiming: list });
        } else {
            console.log("e", e)
            const list: any = this.state.daysTiming;
            list[index]["day"] = e.value;
            list[index]["label"] = e.label;
            this.setState({ daysTiming: list });
        }
    };
    // handle click event of the Remove days button
    handleWeeklyDaysRemoveClick = (index: any, pindex?: any) => {
        const list = this.state.daysTiming;
        if (pindex >= 0) {
            let timePer = list[index].timePeriods;
            timePer.splice(pindex, 1);
            list[index].timePeriods = timePer;
            this.setState({ daysTiming: list });
        } else {
            list.splice(index, 1);
            this.setState({ daysTiming: list });
        }
    };
    // handle click event of the Add button
    handleweeklyDaysAddClick = (e: any, i: any, pindex?: any) => {
        const list: any = this.state.daysTiming;
        if (pindex >= 0) {
            if (list[i].timePeriods[list[i].timePeriods.length - 1].open == "" || list[i].timePeriods[list[i].timePeriods.length - 1].close == "") {
                alert("Please fill in selected day hours")
            } else {
                let timePeriods = {
                    open: "",
                    close: ""
                }
                list[i].timePeriods.push(timePeriods)
                this.setState({ daysTiming: list });
            }
        } else {
            if (list[i]["day"] == "" || list[i].timePeriods[list[i].timePeriods.length - 1].open == "" || list[i].timePeriods[list[i].timePeriods.length - 1].close == "") {
                alert("Please fill in selected day timing")
            } else {
                let timePeriods = {
                    open: "",
                    close: ""
                }
                const newlist: any = {
                    day: "",
                    timePeriods: []
                }
                newlist.timePeriods.push(timePeriods)
                const days: any = this.state.daysTiming.concat(newlist)
                this.setState({ daysTiming: days });
            }
        }
        e.preventDefault()
    };
    handleSpecificDaysCheck = (event: { target: { name: any; value: any; }; }) => {
        const { specific_days } = this.state;
        this.setState({ specific_days: !specific_days })
    }
    isMenuItemReady = () => {
        const { itemname, group, settime, itemstart, hero_image_arabic, itemclose, hero_item, mode, hero_image, is_lsm, storesJson } = this.state;
        let { duplicateItemPos, duplicateItemErp } = this.props;
        if (settime == 'customtime') {
            if (hero_item == false) {
                if (is_lsm == "0") {
                    return (itemname !== "" && group !== "" && itemstart !== "" && itemclose !== "" && mode.length > 0);
                } else if (is_lsm == "1") {
                    return (itemname !== "" && group !== "" && itemstart !== "" && itemclose !== "" && storesJson.length > 0 && mode.length > 0);
                }
            } else {
                if (is_lsm == "0") {
                    return (itemname !== "" && group !== "" && hero_image !== null && hero_image_arabic != null && itemstart !== "" && itemclose !== "" && mode.length > 0);
                } else if (is_lsm == "1") {
                    return (itemname !== "" && group !== "" && hero_image !== null && hero_image_arabic != null && itemstart !== "" && itemclose !== "" && storesJson.length > 0 && mode.length > 0);
                }
            }
        } else {
            if (hero_item == false) {
                if (is_lsm == "0") {
                    return (itemname !== "" && group !== "" && mode.length > 0);
                } else if (is_lsm == "1") {
                    return (itemname !== "" && group !== "" && storesJson.length > 0 && mode.length > 0);
                }
            } else {
                if (is_lsm == "0") {
                    return (itemname !== "" && group !== "" && hero_image !== null && mode.length > 0);
                } else if (is_lsm == "1") {
                    return (itemname !== "" && group !== "" && hero_image !== null && storesJson.length > 0 && mode.length > 0);
                }
            }
        }
    }
    isUnique = (arr: any) => {
        const len = arr.length;
        for (let i = 0; i < len; i++) {
            for (let j = 0; j < len; j++) {
                // if the elements match, this wouldn't be a unique array
                if ((arr[i].pos_code !== "" && arr[j].pos_code !== "") || (arr[i].erp_id !== "" && arr[j].erp_id !== "")) {
                    if (i !== j && (arr[i].pos_code === arr[j].pos_code || arr[i].erp_id === arr[j].erp_id)) {
                        return false;
                    }
                }
            }
        }
        return true;
    }
    handleSaveBtnClick = (event: any) => {
        let { itemname, group, hero_mobile_image_arabic, hero_image_arabic, type, priority, hero_image_priority, mode, hero_item, hero_image, hero_mobile_image, sizejson, settime, itemstart, itemclose, is_lsm, storesJson, metaTitle, metaDesc, specific_days, daysTiming } = this.state;
        sizejson.map((size: any) => {
            if (size.cost != '') {
                size.cost = parseFloat(size.cost).toFixed(2)
            }

            if (size.mrp != '') {
                size.mrp = parseFloat(size.mrp).toFixed(2)
            }

            if (size.extra_price != '') {
                size.extra_price = parseFloat(size.extra_price).toFixed(2)
            }
        })
        const data: any = new FormData();
        const Days = JSON.stringify(daysTiming);
        if (this.state.sizejson) {
            for (var x = 0; x < this.state.sizejson.length; x++) {
                if (this.state.sizejson[x].image_url !== null) {
                    var file = this.state.sizejson[x].image_url[0];
                    var newFileName = file.name.split(".")[0] + `_variant_${x}.` + file.name.split(".")[1];
                    data.append('files', file, newFileName)
                }
            }
        }
        const SizeJsonString = JSON.stringify(sizejson)
        if (hero_image) {
            var file = hero_image[0];
            var newFileName = file.name.split(".")[0] + "_hero_image." + file.name.split(".")[1];
            data.append('files', file, newFileName);
        }
        if (hero_mobile_image) {
            var file = hero_mobile_image[0];
            var newFileName = file.name.split(".")[0] + "_hero_mobile_image." + file.name.split(".")[1];
            data.append('files', file, newFileName);
        }
        if (hero_image_arabic) {
            var file = hero_image_arabic[0];
            var newFileName = file.name.split(".")[0] + "image_arabic." + file.name.split(".")[1];
            data.append('files', file, newFileName);
        }
        if (hero_mobile_image_arabic) {
            var file = hero_mobile_image_arabic[0];
            var newFileName = file.name.split(".")[0] + "ar_image." + file.name.split(".")[1];
            data.append('files', file, newFileName);
        }
        if (hero_image_priority) {
            data.append('hero_image_priority', hero_image_priority);
        }

        data.append('item_name', itemname)
        data.append("best_seller", this.state.best_seller)
        data.append("cross_sell", this.state.cross_sell)
        // if (erpid !== null) {
        //     data.append('erp_id', erpid)
        // }
        // if (poscode !== null) {
        //     data.append('pos_code', poscode)
        // }
        if (hero_item === true) {
            data.append('hero_item', 1)
        }
        if (is_lsm == '0') {
            data.append('is_lsm', 0)
        } else if (is_lsm == '1') {
            data.append('is_lsm', 1)
            data.append('stores_json', JSON.stringify(storesJson))
        }
        data.append('item_group_id', group)
        data.append('item_mode', JSON.stringify(mode))
        // data.append('item_type', type)
        data.append('priority', priority)
        data.append('meta_title', metaTitle)
        data.append('meta_description', metaDesc)
        if (settime === 'customtime') {
            data.append('item_start_time', moment(itemstart).utc(false))
            data.append('item_close_time', moment(itemclose).utc(false))
        }
        if (sizejson[sizejson.length - 1]["size"] !== "" && sizejson[sizejson.length - 1]["cost"] !== "" && sizejson[sizejson.length - 1]["mrp"] !== "" && sizejson[sizejson.length - 1]["image_url"] !== null) {
            // if (this.isUnique(sizejson)) {
            // data.append('itemSizes', SizeJsonString)
            // this.props.addItems(data);
            if (specific_days) {
                if (daysTiming[daysTiming.length - 1]["day"] !== "" && daysTiming[daysTiming.length - 1].timePeriods[daysTiming[daysTiming.length - 1].timePeriods.length - 1].open !== "" && daysTiming[daysTiming.length - 1].timePeriods[daysTiming[daysTiming.length - 1].timePeriods.length - 1].close !== "") {
                    data.append('serving_hours', Days)
                    data.append('itemSizes', SizeJsonString)
                    this.props.addItems(data);
                } else {
                    alert("Please fill in selected day timing")
                }
            } else {
                data.append('itemSizes', SizeJsonString)
                this.props.addItems(data);
            }
            // } else {
            //     toast.error("Please fill unique POS & ERP codes", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
            // }
        } else {
            alert("Please fill all mandatory variant fields")
        }
    }

    blockInvalidChar = (e: any) => {
        var regex = new RegExp("[a-zA-Z]");
        let specialkey: any = e.keyCode;
        var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (specialkey == 8 || specialkey == 9) {
            return true;
        } else {
            if (/\S/.test(e.target.value)) {
                // string is not empty and not just whitespace
                if (!regex.test(key)) {
                    if (specialkey == 32) {
                    } else {
                        e.preventDefault()
                    }
                }
            } else {
                ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
            }
        }
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let tokendata: any = jwt.decode(sessionStorage.token)
        let roleId = tokendata.role_id;
        let brandId = tokendata.brand_id;
        const { groups } = this.props;
        const { sizejson, settime, hero_item, is_lsm, specific_days, daysTiming } = this.state;
        return (
            <div className="page">
                <CheckChanges path="/add-item" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Menu Item Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/menu-items" className="text-primary">Menu Items</Link></li>
                                <li className="breadcrumb-item active">Add Menu Item</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Item name <span className="text-danger">*</span></label>
                                                            <input id="itemname" onKeyDown={this.blockInvalidChar} type="text" name="itemname" required data-msg="Please enter Item Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Arabic Name <span className="text-danger">*</span></label>
                                                            <input id="name_ar" onKeyDown={this.blockInvalidChar} type="text" name="name_ar" required data-msg="Please enter Item Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Brand <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="brand"
                                                                isSearchable
                                                                isClearable
                                                                options={roleId == 1 ? this.props.brands : this.props.brands.filter(o1 => brandId === o1.brand_id)}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleBrand(e, i)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {/* <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Description</label>
                                                            <input id="itemdesc" type="text" name="itemdesc" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div> */}
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Group <span className="text-danger">*</span></label>
                                                            <select name="group" className="form-control text-capitalize mt-2" required data-msg="Please select Group" onChange={this.handleItemsByGroupId}>
                                                                <option>Select Group</option>
                                                                {groups &&
                                                                    groups.map((group, index) => (
                                                                        group &&
                                                                        <option key={index} value={group.group_id}>{group.group_name + "-" + group.menu_name + "-" + group.store_type_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12" >
                                                        <div className="form-group">
                                                            <label className="form-control-label">Ordering Mode <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="mode"
                                                                options={this.orderModes}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleOrderModesInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="form-control-label">Type</label>
                                                        <input id="type" onKeyDown={this.blockInvalidChar} type="text" name="type" required data-msg="Please enter Item Type" className="input-material" onChange={this.handleInputChange} />
                                                    </div>
                                                   </div> */}
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Meta title </label>
                                                            <input id="metaTitle" type="text" name="metaTitle" required className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Meta description</label>
                                                            <input id="metaDesc" type="text" name="metaDesc" required className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label mb-3">Item Image <span className="text-danger">*</span></label>
                                                            <input id="files" type="file" name="files" className="form-control-file" onChange={this.fileSelectedHandler} multiple />
                                                            <small className="form-text">You can also choose multiple images.</small>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Variants</label>
                                                            {sizejson.map((x: any, i: any) => {
                                                                return (
                                                                    <div key={i}>
                                                                        <div className="row">
                                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label mb-3">Size <span className="text-danger">*</span></label>
                                                                                    <input
                                                                                        onKeyDown={this.blockInvalidChar}
                                                                                        name="size"
                                                                                        type="text"
                                                                                        value={x.size}
                                                                                        data-msg="Please enter size"
                                                                                        className="input-material"
                                                                                        onChange={e => this.handleVariantInputChange(e, i)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label mb-3">Cost <span className="text-danger">*</span></label>
                                                                                    <input
                                                                                        min={0}
                                                                                        name="cost"
                                                                                        type="number"
                                                                                        value={x.cost}
                                                                                        data-msg="Please enter cost"
                                                                                        className="input-material"
                                                                                        onChange={e => this.handleVariantInputChange(e, i)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label mb-3">Max Retail Price <span className="text-danger">*</span></label>
                                                                                    <input
                                                                                        min={0}
                                                                                        name="mrp"
                                                                                        type="number"
                                                                                        value={x.mrp}
                                                                                        data-msg="Please enter mrp"
                                                                                        className="input-material"
                                                                                        onChange={e => this.handleVariantInputChange(e, i)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label mb-3">Extra Price</label>
                                                                                    <input
                                                                                        min={0}
                                                                                        name="extra_price"
                                                                                        type="number"
                                                                                        value={x.extra_price}
                                                                                        className="input-material"
                                                                                        onChange={e => this.handleVariantInputChange(e, i)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-3 col-12">
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label">Description</label>
                                                                                    <input id="description_ar" onKeyDown={this.blockInvalidChar} type="text" name="description_ar" value={x.description_ar} className="input-material" onChange={(e) => this.handleVariantInputChange(e, i)} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-lg-3 col-6">
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label mb-3">Item Image <span className="text-danger">*</span></label>
                                                                                    <input id="files" type="file" name="files" className="form-control-file" onChange={(e) => this.fileSelectedHandler(e, i)} />
                                                                                    <p><span>Dimensions 700x700</span> <span style={{ color: 'red' }}>recommended</span></p>

                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-3 col-4">
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label">Image Alt Text</label>
                                                                                    <input id="alt_text" type="text" name="alt_text" className="input-material" onChange={(e) => this.handleVariantInputChange(e, i)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-3 col-4">
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label">Arabic Description<span className="text-danger">*</span></label>
                                                                                    <input id="name_ar" onKeyDown={this.blockInvalidChar} type="text" name="name_ar" required data-msg="Please enter Item Name" className="input-material" onChange={this.handleInputChange} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-2 col-6 py-2 px-2">
                                                                                {sizejson.length !== 1 &&
                                                                                    <button className="btn btn-sm btn-outline-danger" onClick={() => this.handleRemoveClick(i)}><i className="fa fa-trash"></i></button>}
                                                                                {sizejson.length - 1 === i && <button className="btn btn-sm btn-primary ml-2" onClick={() => this.handleAddClick(i)}><i className="fa fa-plus"></i></button>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="row">
                                                    <div className="col-lg-5 col-sm-6 col-6 mt-5" >
                                                        <div >
                                                            <input id="radioCustom1" type="radio" name="settime" value="alltime" checked={settime === 'alltime'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom1">Available for all time</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-5 col-sm-6 col-6 mt-5" >
                                                        <div >
                                                            <input id="radioCustom2" type="radio" name="settime" value="customtime" checked={settime === 'customtime'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom2">Available for specific time</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-sm-6 col-6 mt-5" >
                                                        <div >
                                                            <input id="checkboxCustom1" type="checkbox" name="taxstatus" checked={hero_item} onChange={this.handleHeroItem} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom1">Hero Item</label>
                                                        </div>
                                                    </div>

                                                </div>
                                                {hero_item &&
                                                    <div>
                                                        <div className='row'>
                                                            <div className="col-6 mt-2">
                                                                <div className="form-group">
                                                                    <label className="form-control-label mb-3">Hero Item Image(Desktop)</label>
                                                                    <input id="files" type="file" name="files" accept="image/*" className="form-control-file" onChange={this.heroItemImageSelectedHandler} />
                                                                </div>
                                                            </div>
                                                            <div className="col-6 mt-2">
                                                                <div className="form-group">
                                                                    <label className="form-control-label mb-3">Hero Item Image(Mobile)</label>
                                                                    <input id="files" type="file" name="files" accept="image/*" className="form-control-file" onChange={this.heroItemMobileImageSelectedHandler} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='row'>
                                                            <div className="col-4 mt-2">
                                                                <div className="form-group">
                                                                    <label className="form-control-label mb-3">Hero Item Image(Desktop Arabic)</label>
                                                                    <input id="files" type="file" name="files" accept="image/*" className="form-control-file" onChange={this.heroItemImageArabicSelectedHandler} />
                                                                </div>
                                                            </div>
                                                            <div className="col-4 mt-2">
                                                                <div className="form-group">
                                                                    <label className="form-control-label mb-3">Hero Item Image(Mobile Arabic)</label>
                                                                    <input id="files" type="file" name="files" accept="image/*" className="form-control-file" onChange={this.heroItemMobileImageArabicSelectedHandler} />
                                                                </div>
                                                            </div>
                                                            <div className="col-4 mt-2">
                                                                <div className="form-group">
                                                                    <label className="form-control-label mb-3">Hero Item Priority</label>
                                                                    <input id="hero_image_priority" min={1} type="number" name="hero_image_priority" required data-msg="Please enter Priority" className="input-material" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    settime === 'customtime' &&
                                                    <div>
                                                        <div className="row mt-3">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-lable">Menu Item Timing</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <input id="itemstart" type="date" name="itemstart" required data-msg="Please enter starting time" className="input-material" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <input id="itemclose" type="date" name="itemclose" required data-msg="Please enter closing time" className="input-material" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row">
                                                    <div className="col-lg-4 col-sm-4 col-6 mt-5" >
                                                        <div >
                                                            <input id="radioCustom3" type="radio" name="is_lsm" value="0" checked={is_lsm == '0'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom3">Available for all stores</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-4 col-6 mt-5" >
                                                        <div >
                                                            <input id="radioCustom4" type="radio" name="is_lsm" value="1" checked={is_lsm == '1'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom4">Available for specific stores</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-4 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Group Priority</label>
                                                            <input id="priority" min={1} type="number" name="priority" required data-msg="Please enter Priority" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className="col" >
                                                        <div >
                                                            <input id="checkboxCustom2" type="checkbox" name="specific_days" checked={specific_days} onChange={this.handleSpecificDaysCheck} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom2">Available for specific days</label>
                                                        </div>
                                                    </div>

                                                    <div className="col" >
                                                        <div >
                                                            <input id="checkboxCustom1" type="checkbox" name="taxstatus" checked={this.state.best_seller} onChange={this.handleBestSellerItem} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom1">Best Seller</label>
                                                        </div>
                                                    </div>

                                                    <div className="col" >
                                                        <div >
                                                            <input id="checkboxCustom1" type="checkbox" name="taxstatus" checked={this.state.cross_sell} onChange={this.handleCrossSellItem} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom1">Cross Sell</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    {
                                                        is_lsm == '1' &&
                                                        <div className="col" >
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Store <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    name="stores"
                                                                    options={this.props.stores}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>

                                                {specific_days &&
                                                    <div className='row'>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                {
                                                                    daysTiming.map((x: any, i: any) => {
                                                                        let timePeriod = x.timePeriods;
                                                                        return (
                                                                            <div key={i} className='mb-2' style={{ borderBottom: "1px dotted grey" }}>
                                                                                <div className="row">
                                                                                    <div className="col-lg-8 col-md-8 col-8">
                                                                                        <div className="form-group">
                                                                                            <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                                            <Select
                                                                                                name="mode"
                                                                                                options={this.weeklyDays.filter(o1 => !daysTiming.some((o2: any) => o1.value === o2.day))}
                                                                                                className="text-capitalize basic-multi-select"
                                                                                                classNamePrefix="select"
                                                                                                value={{ value: x.value, label: x.label }}
                                                                                                onChange={(e) => this.handleWeeklyDaysInputChange(e, i)}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-4 col-md-4 col-4 mt-4 py-2">
                                                                                        {daysTiming.length !== 1 &&
                                                                                            <button className="btn btn-sm btn-outline-danger"
                                                                                                onClick={() => this.handleWeeklyDaysRemoveClick(i)}><i className="fa fa-trash"></i></button>}
                                                                                        {(daysTiming.length - 1 === i && daysTiming.length < 7) && <button className="btn btn-sm btn-primary ml-2" onClick={(e: any) => this.handleweeklyDaysAddClick(e, i)}><i className="fa fa-plus"></i></button>}
                                                                                    </div>
                                                                                </div>
                                                                                {timePeriod.map((t: any, pindex: any) => {
                                                                                    return (
                                                                                        <div className="row mb-3">
                                                                                            <div className="col-lg-3 col-md-3">
                                                                                            </div>
                                                                                            <div className="col-lg-3 col-md-3 col-6">
                                                                                                <div className="form-group">
                                                                                                    <label className="form-control-label mb-0">Open Time <span className="text-danger">*</span></label>
                                                                                                    <input id="open" type="time" name="open" value={t.open} className="input-material" onChange={(e) => this.handleWeeklyDaysInputChange(e, i, pindex)} />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg-3 col-md-3 col-6">
                                                                                                <div className="form-group">
                                                                                                    <label className="form-control-label mb-0">Close Time <small className="text-danger">*</small></label>
                                                                                                    <input id="close" type="time" name="close" value={t.close} className="input-material" onChange={(e) => this.handleWeeklyDaysInputChange(e, i, pindex)} />
                                                                                                    {(timePeriod.length - 1 == pindex) && <small className="text-primary" style={{ cursor: 'pointer' }} onClick={(e: any) => this.handleweeklyDaysAddClick(e, i, pindex)}>+ Add hours</small>}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg-3 col-md-3 col-12">
                                                                                                {timePeriod.length !== 1 &&
                                                                                                    <button className="btn btn-sm btn-outline-danger"
                                                                                                        onClick={() => this.handleWeeklyDaysRemoveClick(i, pindex)}><i className="fa fa-trash"></i></button>}
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isMenuItemReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div >
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        groups: state.menu.groups,
        brands: state.brand.brands,
        stores: state.menu.storesoptions
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        addItems: function (formData: any) {
            dispatch(addItems(formData));
        },
        groupsList: function (brand_id: any) {
            dispatch(groupsList(brand_id))
        },
        brandsList: () => {
            dispatch(brandsList())
        },
        storesList: (brand_id: any) => {
            dispatch(storesListForMultiSelect(brand_id))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddMenuItem);