import Api from '../../components/Api';
import { HANDLE_INPUT, ADD_BRAND, BRANDS_LIST, EDIT_BRAND, GET_BRAND, TAGS_LIST, TAG_DATA, ACTIVE_TAGS } from './brandType';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jwt from 'jsonwebtoken'
toast.configure();
export const handleBrandInput = (event: { target: { name: any; value: any; }; }) => {
    return {
        type: HANDLE_INPUT,
        input: event.target
    }
}
export const brandsList = (requestType?: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/brands', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    response.data.successResponse.forEach((obj: any) => {
                        obj.label = obj.brand_name;
                        obj.value = obj.brand_id;
                    }) 
                    if (requestType == "GET_BY_BRAND") {
                        let newObj = {
                            value: 0,
                            label: "All"
                        }
                        response.data.successResponse.unshift(newObj)
                    }
                    dispatch({
                        type: BRANDS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const tagsList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/tags/get', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: TAGS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const activeTagsList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/tags/active_tags', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ACTIVE_TAGS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const tagData = (id:number) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/tags/tag/' + id, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: TAG_DATA,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const addTag = (data:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/tags/add-tag',data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = '/tags'
                }
            }).catch(err => {
                if (err.response) {
                    toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}

export const editTag = (id:number,data:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put('/tags/edit-tag/' + id,data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = '/tags'
                }
            }).catch(err => {
                if (err.response) {
                    toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}

export const blockUnblockTag = (id:number,value:number) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put('/tags/block-unblock-tag/' + id,value, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = '/tags'
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addBrand = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        data.user_info = jwt.decode(token);
        Api.post('/admin/add_brand', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/brands"
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editBrand = (id: any, data: any,editType?:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/admin/edit_brand/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    if(editType && editType=="setting"){
                        toast.info("Brand Updated Successfully", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    }else{
                        window.location.href = "/brands"
                    }
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getBrand = (id: number) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/brand/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_BRAND,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const delBrand = (id: number, brand_name: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        let data = {
            brand_name: brand_name
        }
        Api.post(`/admin/del_brand/${id}`,data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/brands";
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const uploadMenuFile = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/admin/menu_file_upload',data, {
            headers: { 
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/brands";
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const blockunblockbrand = (id: any, isActive: any, brand_name: any) => {
    return function (dispatch: any) {
        let is_active:any;
        if (isActive === 0) {
            is_active = 1
        } else if (isActive === 1) {
            is_active = 0
        }
        let token: any = sessionStorage.getItem('token');
        let data = {
            is_active: is_active,
            brand_name:brand_name
        }
        Api.put(`/admin/brand_block_unblock/${id}`,data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch(brandsList())
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const getBrandDetails = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/brand_details', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: "BRAND_DETAILS",
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}