import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
// import DataTable from 'react-data-table-component'
import { ExcelRenderer } from 'react-excel-renderer';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../components/topbar'
import _ from 'lodash';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
//import { ProgressBar } from 'react-bootstrap';
import * as XLSX from "xlsx";
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { bulkState, bulkProps } from '../../interfaces/bulk';
import { AddressBulkUpload, CustomerBulkUpload, MenueBulkUpload } from '../../redux/actions/customerAction';

import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class Bulk extends Component<bulkProps, bulkState> {
  constructor(readonly props: any) {
    super(props);
    this.state = {
      selectFile: "",
      name: "",
      email_address: "",
      phone_number: "",
      town_city: "",
      city: "",
      menu_name: "",
      uploadPercentage: 0,
      store_name: "",
      store_email: "",
      branch_code: "",
      address: "",
      store_open_time: "",
      store_close_time: "",
      isSelected: false,
      isLoad: false,
      file: null,
      rows: [],

      customerArr: ["CustomerName", "Email", "MobileNo","DeliveryAddress", "CityName"],
    }
    //this.fileHandler = this.fileHandler.bind(this);
    this.submitFile = this.submitFile.bind(this);
    this.handleSlecetChange = this.handleSlecetChange.bind(this);
    //this.inputRef = React.createRef();
  }
  inputRef: any;
  //npm i --save - dev @types/react-fileupload-progress npm install --save - dev @types/react-bootstrap

  componentDidMount() {
    document.title = "SimplexCMS | Bulk Upload"
  }

  isEqual = (columns: any, fileFormat: any) => {
    if (columns.length != fileFormat.length) {
      return false;
    } else {
      for (var i = 0; i < columns.length; i++) {
        if (columns[i] != fileFormat[i]) {
          return false
        } else {
          return true;
        }
      }
    }
  }

  fileHandler = (event: any) => {
    let fileObj = event.target.files[0];
    console.log("file OBj", fileObj);

    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err: any, resp: any) => {
      if (err) {
        console.log(err);
      }
      else {
        this.setState({
          rows: resp.rows
        });

        const realData = resp.rows;
        console.log("excel Data: ", realData);
        const cleanData = realData.filter((el: any) => {
          return el.length;
        })
        // console.log("Clean Data: ", cleanData);

        // // File Limit
        // if (cleanData.length > 20000) {
        //   alert("Please select file upto 20000 rows!");
        //   this.props.history.push('/bulk');
        // }
        if (cleanData.length == 0) {
          alert("Empty files can't be slected!");
          this.props.history.push('/addressBulk');
        }
        // // Validate for Correct file
        const columns = cleanData.shift();
        // console.log("columns: ", columns);
        // if (this.state.selectFile === "customers") {
          let response = this.isEqual(columns, this.state.customerArr)
        //   console.log("asljdfasklfjklas", response)
          this.setState({ isSelected: true })
          if (response == true) {
          } else {
            //toast.error("Please Select the Correct file", { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            alert("Please select the correct format file.")
            this.props.history.replace('/addressBulk');
          }
        // }
      }
    });
  }

  handleSlecetChange(event: { target: { name: any; value: any; } }) {
    this.setState({ [event.target.name]: event.target.value });
  }

  submitFile() {

    let { rows } = this.state;
    let arr: any = [];
    rows.forEach((element: any) => {
      let obj: any = {
        login_name: element[0],
        email_address: element[1],
        phone_number: element[2],
        address: element[3]
      }
      arr.push(obj);
    });
    arr.shift();
    // console.log(arr)
    //this.inputRef.current.value = '';
    // Clean Array to avoid null data
    const cleanArr = arr.filter((el: any) => {
      return el.email_address !== undefined && el.phone_number !== undefined;
    })
    // console.log("cleanArr: ", cleanArr);

    // // Loop for getting lower case email
    // const clean = cleanArr.map((el: any) => {
    //   return el.email_address.toLowerCase();
    //   //return (el.email_address.toLowerCase());
    // })
    // console.log("lowerCase: ", clean);
    // Use filter to get Array wit lower case email
    // const lower = cleanArr.filter((el: any, i: any) => {
    //   return el.email_address = clean[i];
    // })
    // console.log("LowerData", lower);

    // Find Unique rows from array of abjects
    // const unique = lower.filter((v: any, i: any) => {
    //   return (
    //     lower.map((val: any) => val.email_address).indexOf(v.email_address) == i &&
    //     lower.map((val: any) => val.phone_number).indexOf(v.phone_number) == i
    //   )
    // })
    // console.log("Unique: ", unique);
    this.props.AddressBulkUpload(arr);
    this.setState({ isLoad: true });
    this.setState({ isSelected: true })
    // console.log("Dup Data", this.props.dupData);
  }

  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />
    }

    const { dupData, storeDUPdata } = this.props;
    console.log("JUNAID Duplicate Data: ", dupData);

    const { isLoad, rows, file } = this.state;

    const options: any = {
      sizePerPage: 10, // which size per page you want to locate as default
      page: 1,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
      hideSizePerPage: true, //You can hide the dropdown for sizePerPage
      insertModal: () => { return <Redirect to="/add-store" /> },
      noDataText: 'Stores Not Found'
    };
    return (
      <div className="page">
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Address Upload</h4>
                </div>
              </div>
            </header>

            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">

                        <div className="row form-group mt-3">
                          <div className="col-md-3"></div>
                          {/* <div style={{ marginLeft: 15 }} className="custom-file form-contral col-md-5">
                            <input style={{ cursor: "pointer" }} type="file" disabled={!this.state.selectFile} multiple={false} name="rows" className="custom-file-input" onChange={this.fileHandler} id="customFile" />
                            <label className="bg-primary text-white custom-file-label">Choose File</label>
                          </div> */}
                          {(!isLoad) &&
                            <div className="col-md-5">
                              <input
                                type="file"
                                className="btn btn-primary text-white btn-sm"
                                // disabled={!this.state.selectFile}
                                name="rows"
                                //ref={this.inputRef}
                                onClick={(event: any) => {
                                  event.target.value = null
                                }}
                                multiple={false}
                                onChange={this.fileHandler}
                                style={{ "padding": "6px" }} />
                            </div>
                          }
                          {(dupData && dupData.length > 0) &&
                            <Link to="/bulk" className="btn btn-primary col-md-6" style={{}}><i className="fa fa-upload"></i> Upload Again</Link>
                          }
                          <div className="col-md-2">
                            {/* {(isLoad) ? */}
                            {/* <Loader style={{ marginLeft: 210, display: dupData && dupData.length > 0 ? "none" : "block" }} type="ThreeDots" color="#00BFFF" height={50} width={50} /> : */}
                            <button className="btn btn-primary" style={{}} onClick={this.submitFile}><i className="fa fa-upload"></i> Upload File</button>
                            {/* } */}
                          </div>

                        </div>
                      </div>

                      <div className="card-body">
                        {
                          (dupData && dupData.length > 0) &&
                          <BootstrapTable version='4' data={this.props.dupData} search={true} pagination={this.props.dupData.length > 10 && true} options={options} exportCSV={true} csvFileName='customers.csv' hover>
                            <TableHeaderColumn dataField='customer_id' csvHeader='#' width='70' dataSort={true} isKey>#</TableHeaderColumn>
                            <TableHeaderColumn dataField='email_address' csvHeader='Email' width='100' columnTitle>Email Address</TableHeaderColumn>
                            <TableHeaderColumn dataField='phone_number' csvHeader='Phone' width='100' columnTitle>Phone Number</TableHeaderColumn>
                          </BootstrapTable>
                        }
                      </div>
                    </div>



                  </div>
                </div>
              </div>
            </section>

            <Footer />
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state: any) => {

  return {
    customers: state.customer.data,
    dupData: state.customer.dupData,
    storeDUPdata: state.store.storeDUPdata,
    stores: state.store.data,
    menu: state.menu.menus,
    isLoad: state.customer.isLoad
  }
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    CustomerBulkUpload: (data: any) => dispatch(CustomerBulkUpload(data)),
    AddressBulkUpload: (data: any) => dispatch(AddressBulkUpload(data)),

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Bulk);