import jwtDecode from 'jwt-decode';
import moment from 'moment';
import React, { Component } from 'react';
import ReactToPrint from "react-to-print";
import {API_URL, currency} from '../../client-config'
import './invoice.css'
var QRCode = require('qrcode.react');
class Invoice extends Component<{ data: any, orderId: any }, {}> {
    componentRef: any;
    calcSubTotalTotal = (orderItems: any) => {
        let sum = "";
        orderItems.forEach((item: any) => {
            sum += item.order_item_grossprice;
        });
        return parseFloat(sum).toFixed(2);
    };
    calcTotal = (orderItems: any) => {
        let sum = 0;
        orderItems.forEach((item: any) => {
            sum += item.order_item_grossprice;
        });
        return sum;
    };
    render() {
        let token:any = sessionStorage.getItem("token")
        let decoded:any = jwtDecode(token)
        let { data, orderId } = this.props;
        // console.log("data.orderDetail.fbr_invoice_number",data);
        let DateCreated: any = "";
        let FutureDate: any = "";
        let utcDateTime: any = "";
        let FbrNumber: any = "";
        let FbrNumberComplete: any = "";
        if (data.orderDetail) {
            DateCreated = moment(data.orderDetail.date_created).local().format('YYYY-MM-DD HH:mm').split(' ');
            DateCreated = DateCreated[1] + " , " + DateCreated[0];
            if (data.orderDetail.fbr_invoice_number != undefined) {
                FbrNumberComplete = data.orderDetail.fbr_invoice_number;
                FbrNumber = FbrNumberComplete.split(':');
                FbrNumber = FbrNumber[1];
                // console.log("FbrNumber", FbrNumber);
            }
        }
        if (data.orderDetail && data.orderDetail.future_date) {
            utcDateTime = moment(data.orderDetail.future_date).utc(false);
            FutureDate = moment(utcDateTime).local().format('YYYY-MM-DD HH:mm').split(' ');
            FutureDate = FutureDate[1] + " , " + FutureDate[0];
        }
        let orderChannel:any
        if(data.orderDetail.order_channel == 'web' || data.orderDetail.order_channel == 'callcenter' || data.orderDetail.order_channel == 'mobile') {
            orderChannel = 'master_cms'
        } else {
            orderChannel = 'sync_sync'
        }
        // console.log("data.orderDetaildata.orderDetail", data.orderDetail)
        
        return (
            <div>
                <div className="d-flex justify-content-end ">
                    {
                        data.orderDetail.order_status_code !== 1 &&
                        <ReactToPrint
                            trigger={() => <button type="button" className="btn btn-success">Print</button>}
                            content={() => this.componentRef}
                        />
                    }
                </div>
                <div style={{ width: '108mm' }} ref={el => (this.componentRef = el)}>
                    <header className="clearfix">
                        <div id="logo">
                            {/* <img width={150} height={80} src={process.env.PUBLIC_URL + "/assets/img/tortilla.svg"} /> */}
                            {/* <img width={150} height={80} src={`${data && data.orderDetail && data.orderDetail.brand_id == 3? process.env.PUBLIC_URL + "/assets/img/BR.png":process.env.PUBLIC_URL + "/assets/img/ky.png"}`} /> */}
                            <img width={150} height={80} src={`${data.orderDetail && data.orderDetail.landing_image ? `${API_URL}/${data.orderDetail.landing_image}` : process.env.PUBLIC_URL + "/assets/img/ky.png"}`} />
                        </div>
                        <h6 className="text-center mt-3"><b>
                            {/* {decoded && decoded.brand && decoded.brand.brand_name} */}
                            {data.orderDetail && data.orderDetail.brand_name}
                        </b>
                        </h6>
                        <h6 className="text-center mt-3">{data.orderDetail.city}</h6>

                        <div id="project" className="invoiceh1">
                            {/* <h3>Customer Information</h3> */}
                            <div style={{ display: 'flex' }}><span><b>ORDER NO:</b><br/><b>رقم الطلب</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {orderId}</span></div>
                            <div style={{ display: 'flex' }}><span><b>Order Date:</b><br/><b>تاريخ الطلب</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {data.orderDetail && DateCreated}</span></div>
                            <div style={{ display: 'flex' }}><span><b>Customer:</b><br/><b>اسم العميل</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {data.orderDetail && data.orderDetail.login_name? data.orderDetail.login_name: 'N/A' }</span></div>
                            {(data.orderDetail && data.orderDetail.phone_number) && <div style={{ display: 'flex' }}><span><b>Phone:</b><br/><b>هاتف</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {data.orderDetail.phone_number}</span></div>}
                            <div style={{ display: 'flex' }}><span><b>Store:</b><br/><b>متجر</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{data.orderDetail && data.orderDetail.store_name}</span></div>
                            <div style={{ display: 'flex' }}><span><b>From:</b><br/><b>عنوان الفرع</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{data.orderDetail && data.orderDetail.address? data.orderDetail.address: 'Deliveroo'}</span></div>
                            {(data.orderDetail && data.orderDetail.delivery_address) && <div style={{ display: 'flex' }}><span><b>Delivery at:</b><br/><b>عنوان التسليم</b> </span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {data.orderDetail.delivery_address}</span></div>}
                            <div style={{ display: 'flex' }}><span><b>Special Ins:</b><br/><b>تعليمات خاصة</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{data.orderDetail && data.orderDetail.special_inst? data.orderDetail.special_inst : 'N/A' }</span></div>
                            {utcDateTime._isValid && <div><span><b>{data.orderDetail && data.orderDetail.delivery_status === "Delivery" ? 'Delivery' : 'Pickup'} Time:</b><br/><b>زمن</b></span><br /><span>{FutureDate}</span></div>}
                            {FbrNumber && <div style={{ display: 'flex' }}><span><b>FBR Invoice:</b><br/><b>فاتورة FBR</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{FbrNumber}</span><span style={{ whiteSpace: "normal", textAlign: 'right', flex: 'auto' }}><QRCode size='50' value={FbrNumber} /></span></div>}
                            {/* {(data.orderDetail.fbr_invoice_number!=undefined) ? 
                            <div style={{ display: 'flex' }}><span style={{ textAlign: "left", fontWeight:'bold' }}>{FbrNumber}</span><span style={{ whiteSpace: "normal", textAlign: 'right', flex: 'auto' }}> <QRCode size='50' value={FbrNumber}/></span></div>
                            :
                            <div></div>
                            } */}
                        </div>
                        <div id="secProject">
                            <div id="project2">
                                <div><h6 style={{ fontSize: 13 }}>Payment Type</h6><h6>طريقة الدفع</h6>{data.orderDetail && data.orderDetail.payment_method}</div>
                            </div>
                            <div id="project2">
                                <div>
                                    <h6 style={{ fontSize: 13 }}>Ordering Channel</h6><h6>تم الطلب خلال</h6>{data.orderDetail && data.orderDetail.order_channel}
                                </div>
                            </div>
                            <div id="project2">
                                <div>
                                    <h6 style={{ fontSize: 13 }}>Ordering Mode</h6><h6>نوع الطلب</h6>{data.orderDetail && data.orderDetail.delivery_status}
                                </div>
                            </div>
                        </div>
                    </header>
                    <main>
                        <table className="invoicetable">
                            <thead>
                                <tr>
                                    {/* <th className="service" style={{ width: "5vw" }}></th> */}
                                    <th className="desc">Name <b>الاسم</b></th>
                                    {/* <th className="desc">DESCRIPTION</th> */}
                                    {/* <th>PRICE</th> */}
                                    {/* <th style={{ width: 50 }}>QTY</th> */}
                                    <th style={{ width: 100 }}>TOTAL <br/><b>الإجمالي</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.Items.map((obj: any, index: any) => {
                                    let AddOnsPrice: any = 0;
                                    const ingredients = obj.combo_choices && JSON.parse(obj.combo_choices).map((element: any) => {
                                        return <li><b>{element.quantity*obj.order_item_quantity} </b>{element.item_name} ({element.size && JSON.parse(element.size).size}){element.extra_price > 0 && `+${currency} ${element.extra_price*obj.order_item_quantity}`}</li>
                                    })
                                    return (
                                        <>
                                            <tr key={index}>
                                                {/* <td className="service">{index + 1}</td> */}
                                                <td className="desc" title={(obj.combo_name !== "" && obj.combo_name) || (obj.item_name !== "" && `${obj.item_name}(${JSON.parse(obj.item_size).size})`)}><b>{(obj.combo_name !== "" && obj.combo_name) || (obj.item_name !== "" && `${obj.item_name}(${JSON.parse(obj.item_size).size})`)} x {obj.order_item_quantity}</b>
                                                    <ul>
                                                        {ingredients}
                                                        <br />
                                                        <b>{(obj.modifiers && JSON.parse(obj.modifiers).length > 0) && "Add Ons"}</b>
                                                    </ul>
                                                </td>
                                                {/* <td className="desc" title={modifiers}>{modifiers}</td> */}
                                                {/* <td className="unit">{Math.round(obj.order_item_grossprice / obj.order_item_quantity)}</td> */}
                                                {/* <td className="qty">{obj.order_item_quantity}</td> */}
                                                <td className="total">{obj.modifiers && JSON.parse(obj.modifiers).map((element: any) => {
                                                    AddOnsPrice += (element.quantity * element.modifier_sale_price);
                                                })}
                                                    {currency} {data.orderDetail.order_channel=='web' || data.orderDetail.order_channel=='mobile' || data.orderDetail.order_channel=='callcenter' ? (obj.order_item_grossprice - (AddOnsPrice * obj.order_item_quantity)).toFixed(2) : (obj.order_item_netprice)}</td>
                                            </tr>
                                            {
                                                obj.modifiers && JSON.parse(obj.modifiers).map((element: any) => {
                                                    // console.log("Total Item-PriceData9999999", obj)
                                                    let quantity:any = 0
                                                    if(element.modGroup_max_quantity > 1){
                                                        
                                                        quantity =  element.quantity > 1 && obj.order_item_quantity> 1 ? element.quantity + obj.order_item_quantity : element.quantity
                                                    }else{
                                                        quantity =   obj.order_item_quantity> 1 ? obj.order_item_quantity : element.quantity
                                                    }
                                                    return <tr><td style={{ textAlign: "left" }}><b>{element.quantity* obj.order_item_quantity} </b>{element.modifier_name}</td><td>{currency} {element.modifier_sale_price * quantity}</td></tr>
                                                })
                                            }
                                            <tr><td style={{border: 'none'}}></td><td style={{border: 'none'}}></td></tr>
                                            <tr>
                                                <td style={{paddingLeft: '30%'}}>Total <b> الإجمالي</b></td>
                                                <td>{currency} {parseFloat(obj.order_item_grossprice).toFixed(2)}</td>
                                                {/* <td>{currency} {parseFloat(obj.order_item_netprice).toFixed(2)}</td> */}
                                                {/* <td className='title'>{`${currency} ${ obj.order_item_grossprice && data.orderDetail.order_channel === 'call center' ? ((parseInt(obj.order_item_grossprice) - (parseInt(obj.order_item_grossprice) * ((parseInt(obj.tax_percent)) / 100 ))).toFixed(2)) : parseInt(obj.order_item_netprice).toFixed(2)}`}</td> */}
                                            </tr>
                                        </>
                                    )
                                })}
                                <tr>
                                <td colSpan={1} style={{paddingLeft: '30%'}}>Sub Total <b>المبلغ الاجمالي</b></td>
                                    {/* <td className="total">{currency} {data.Items.length > 0 && parseFloat(data.orderDetail.order_grosspricewd).toFixed(2)}</td> */}
                                    <td className="total" title={`${currency} ${data.Items.length  > 0 &&  this.calcSubTotalTotal(data.Items)}`}>{currency} { data.orderDetail && data.Items.length > 0 &&  data.orderDetail.order_channel === 'call center' ? ( parseFloat(data.orderDetail.order_grossprice) - parseFloat(data.orderDetail.order_grossprice) * (parseFloat(data.orderDetail.tax_percent) / 100)).toFixed(2) : data.Items.length > 0 && parseFloat(data.orderDetail.order_grosspricewd).toFixed(2) }</td>
                                </tr>
                                {(data.orderDetail && data.orderDetail.radeem_cash>0) &&
                                <tr>
                                    <td style={{paddingLeft: '30%'}} colSpan={1}>Redeem Cash <b> استرداد النقود</b></td>
                                    {/* <td className="total">- {currency}  {data.orderDetail && data.orderDetail.radeem_cash.toFixed(2)}</td> */}
                                    <td> {`${currency} ${ data.orderDetail.tax_amount ? ((parseInt(data.orderDetail.order_grossprice) * parseInt(data.orderDetail.tax_percent)) / 100 ).toFixed(2) : data.orderDetail.tax_amount }`} </td>
                                </tr>
                                }
                                {(data.orderDetail && data.orderDetail.delivery_status == "Delivery") && <tr>
                                    <td style={{paddingLeft: '30%'}} colSpan={1}>Delivery Charges <b> رسوم توصيل </b></td>
                                    <td className="total">{currency} {data.orderDetail && data.orderDetail.delivery_fee?.toFixed(2)}</td>
                                </tr>
                                }
                                {data.orderDetail.coupon &&
                                    <tr>
                                        <td style={{paddingLeft: '30%'}} colSpan={1} className="discount">Your Discount <b> الخصم</b></td>
                                        <td className="discount">{currency} {data.orderDetail && data.orderDetail.coupon_discount_value} </td> {/* {data.Items.length > 0 && Math.round((this.calcSubTotalTotal(data.Items) * tax)/100)} PKR */}
                                    </tr>
                                }

                                <tr>
                                    <td style={{paddingLeft: '30%'}} colSpan={1}>{data.orderDetail.tax_percent}% VAT <b> الضريبة</b></td>
                                    {/* <td>{currency} {data.orderDetail.tax_amount ? data.orderDetail.tax_amount : 0} </td>  */}
                                    {/* {data.Items.length > 0 && Math.round((this.calcSubTotalTotal(data.Items) * tax)/100)} PKR */}
                                    <td className='total' title={`${parseInt(data.orderDetail.tax_amount).toFixed(2)}`}>{`${currency} ${ data.orderDetail.tax_amount && data.orderDetail.order_channel === 'call center' ? ((parseInt(data.orderDetail.order_grossprice) * parseInt(data.orderDetail.tax_percent)) / 100 ).toFixed(2) : parseInt(data.orderDetail.tax_amount) }`} </td>
                                </tr>
                                {
                                    data.orderDetail.discount > 0 &&
                                    <tr>
                                        <td style={{paddingLeft: '30%'}} colSpan={1} className="discount">Your Discount <b> خصمك</b></td>
                                        <td className="discount">{currency} {data.orderDetail && data.orderDetail.discount} </td>
                                    </tr>
                                }
                                <tr>
                                    <td style={{paddingLeft: '30%'}} colSpan={1} className="grand total"><b>GRAND TOTAL</b> <b> المبلغ الإجمالي للطلب</b></td>
                                    <td className="grand total"><b>{currency} {(data.Items.length > 0 && data.orderDetail) && parseFloat(data.orderDetail.order_grossprice).toFixed(2)}</b> </td>
                                    {/* <td className="grand total"><b>{currency} {(data.Items.length > 0 && data.orderDetail) && (this.calcTotal(data.Items) - data.orderDetail.coupon_discount_value) + data.orderDetail.delivery_fee + parseFloat(data.orderDetail.tax_amount)}</b> </td> */}
                                </tr>
                            </tbody>
                        </table>
                        <p className="text-center mt-3">{decoded && decoded.brand && decoded.brand.brand_name}</p>
                        {/* <p className="text-center mt-3">NTN #<br /><b> الرقم الضريبي الوطني</b>  {data.orderDetail.ntn_number}</p> */}
                        <p className="text-center mt-3">{decoded && decoded.brand && decoded.brand.website_link}</p>
                        <h6 className="text-center mt-3">{data.orderDetail.contact1}</h6>
                        <footer className="invoicefooter mb-3">
                            "Thank you for Ordering {decoded && decoded.brand && decoded.brand.brand_name}"
                        </footer>
                    </main>
                </div>
            </div>
        )
    }
}
export default Invoice;